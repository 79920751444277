import { Button, Checkbox } from 'antd'
import React, { useEffect, useState } from 'react'
import { FaSearch, FaTimes } from 'react-icons/fa'
import { ButtonSpinner } from '../../components/spinners'
import { getTaskAllowance } from '../../helper/worksite/worksiteDetails.js/areas'

const TaskAllowanceModal = ({ closeModal,selectedTaskArr,setSelectedTaskArr,setTaskAllowData,taskAllowData }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [taskData,setTaskData] = useState()
    const [searchValue,setSearchValue] = useState('')
    const [tkData,setTkData] = useState([])
    const fetchTaskAllowanceList= async(param)=>{
      let res = await getTaskAllowance(param)
      if(res?.status){
        setTaskData({
          ...taskData,
          data:res?.data?.data
        })
      }
    }
    const searchTask=async(e)=>{
      setSearchValue(e?.target?.value)
      fetchTaskAllowanceList(e?.target?.value)
    }
    const selectTask =async(e,item)=>{
      if (e?.target?.checked) {
        setSelectedTaskArr([...selectedTaskArr, item?.id]);
        setTkData([...tkData,item])
      } else {
        const updatedTaskArr = selectedTaskArr.filter((taskId) => taskId !== item?.id);
        setSelectedTaskArr(updatedTaskArr);
        const uptArr = taskAllowData.filter((taskId) => taskId !== item)
        setTkData(uptArr)
      }
    }
    const setArray=()=>{
      setTaskAllowData(...taskAllowData,tkData)
      closeModal()
    }
    useEffect(() => {
      fetchTaskAllowanceList()
    }, [])
    return (
        <div className="modal shift-details">
            <div className="modal-content">
                <div className="text-right">
                    <button className="close-modal" onClick={closeModal}>
                        <FaTimes />
                    </button>
                </div>
                <div className="modal-body mt-2">
                    <h2 className="text-xl font-bold">Task Allowance</h2>
                    <div className="mb-5">
                  <div className="border border-[#111111] flex items-center p-1 rounded-md w-full  my-2 bg-white">
                    <FaSearch className="ml-2" />{" "}
                    <input
                      placeholder='Search by task name'
                      className="h-[31px] bg-transparent text-sm w-full px-2"
                      onChange={(e)=>searchTask(e)}
                      value={searchValue}
                    />
                  </div>
                </div>
                <div className="border divide-y rounded-md">
                  <div className="px-6 py-2 bg-slate-100 flex justify-between">
                    <label
                      className="text-sm font-bold"
                    >
                      Task Name
                    </label>
                    <label
                      className="text-sm font-bold"

                    >
                      Select All
                    </label>
                  </div>
                 {taskData?.data?.map((item, index) => {
                    return (

                      <div
                        className="px-6 py-2 hover:bg-slate-100 cursor-pointer flex justify-between"
                        key={index}
                      >

                        <label
                          className="text-sm"

                        >
                          {item?.name}
                        </label>

                        <div>
                          <Checkbox
                            id={index}
                            key={index}
                            onChange={(e)=>selectTask(e,item)}
                            
                          />
                        </div>
                      </div>

                    );
                  })} 
                </div>
                <div className="">
                  <div className="pt-4">
                    <Button
                      className="float-right btn btn-blue w-1/5 bg-blue-disable"
                      onClick={setArray}
                      disabled={selectedTaskArr?.length == 0}
                    >
                      {isLoading ? <ButtonSpinner /> : 'Add'}
                    </Button>
                  </div>
                </div>
                </div>
            </div>
        </div>
    )
}
export default TaskAllowanceModal