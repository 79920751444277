import React, { Fragment, useState } from "react";
import TerminatedProfileTable from "../../../../../shared/DataTable/TerminatedProfileTable";
import EditProfileModal from "../../../../../shared/Modal/EditProfileModal";
import CodeTable from "../../../../../shared/DataTable/CodeTable";
import { Select } from "antd";
import { UserService, WorkService } from "../../../../../config/axiosUrl";
import PayrollCategories from "../../../../../shared/DataTable/PayrollCategories";
import { useParams } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import PayrollCategoriesWorkers from "./PayrollCategoriesWorkers";

const TerminatedProfile = ({ worker_termination_logs, handle, worker }) => {
  const { logID, id } = useParams();

  const [modalVisible, setModalVisible] = React.useState(false);
  const [task, setTask] = useState();
  const [site, setWorksite] = useState([]);
  const [selectedWorksiteId, setSelectedWorksiteId] = useState("");

  const workTaskBreakdown = useCallback(() => {
    UserService.post(
      `/api/v1/personal/workers/Worksite-task-breakdown?worker_id=${id}&worksite_id=${selectedWorksiteId}`
    )
      .then((res) => {
        setTask(res?.data?.data?.Worksite_task_breakdown_data || []);
      })
      .catch((error) => {
        console.error("Error fetching site manager history:", error);
      });
  }, [selectedWorksiteId]);

  useEffect(() => {
    workTaskBreakdown();
  }, [selectedWorksiteId]);

  // for workers
  const Worksite = useCallback(() => {
    WorkService.get("/api/v1/worksite/get-worksite-data").then((res) => {
      setWorksite(res?.data?.data?.data || []);
    });
  }, []);

  useEffect(() => {
    Worksite();
  }, []);

  const handleSelectChange = (value) => {
    setSelectedWorksiteId(value);
  };
  return (
    <div>
      {modalVisible && <EditProfileModal setModalVisible={setModalVisible} />}
      <div className="details mt-5 bg-white ">
        <div className="worker-full-details mt-5">
          <div className="payroll mt-8">
            <div className="lg:overflow-hidden overflow-x-auto">
              {!logID ? (
                <TerminatedProfileTable
                  worker_termination_logs={worker_termination_logs}
                  handle={handle}
                />
              ) : (
                <>
                  {/* Worker Full Details */}
                  <div className="worker-full-details mt-5">
                    <p className="fs-18">Worker Full Details</p>

                    <div className="flex justify-between w-full items-center mt-3">
                      <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">Shift Worked</p>
                        <p className="fs-14">N/A</p>
                      </div>
                      <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">Hours Worked</p>
                        <p className="fs-14">N/A</p>
                      </div>
                      <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">
                          Productivity Average
                        </p>
                        <p className="fs-14">N/A</p>
                      </div>
                      <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">Hourly Average</p>
                        <p className="fs-14">N/A</p>
                      </div>
                      <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1] flex items-center">
                          Induction
                          <span className="fs-10 ml-3 text-[#FF0000] border rounded-md px-2 py-1 border-[#FF0000] bg-[#FF000033]">
                            Induction Incomplete
                          </span>
                          {false && (
                            <span className="fs-10 ml-3 text-[#41C700] border rounded-md px-2 py-1 border-[#41C700] bg-[#42c70033]">
                              Induction Completed
                            </span>
                          )}
                        </p>
                        <p className="fs-14">N/A</p>
                      </div>
                    </div>
                  </div>

                  <div className="payroll mt-8">
                    <div className="flex justify-between items-center">
                      <p className="fs-18">Payroll Categories</p>
                    </div>

                    <div className="mt-8 lg:overflow-hidden overflow-x-auto">
                      <PayrollCategoriesWorkers
                        worker={worker}
                        handle={handle}
                      />
                    </div>

                    <div className="mt-8 lg:overflow-hidden overflow-x-auto">
                      {/* {worker?.workers_payroll_category?.length == 0 ? (
                    <>
                      <TableDefaultPage data={deafaulPage} />
                    </>
                  ) : (
                    // <Fragment>
                    //   <table className="w-full">
                    //     <thead className="bg-[#E8F3FF] text-center">
                    //       <tr className="text-left">
                    //         <th className=" px-4 py-3 ">
                    //           <div className="flex items-center justify-start">
                    //             Accural Type
                    //           </div>
                    //         </th>
                    //         <th className=" px-4 py-3 ">
                    //           <div className="flex items-center justify-start">
                    //             Accured
                    //           </div>
                    //         </th>
                    //         <th className=" px-4 py-3 ">
                    //           <div className="flex items-center justify-start">
                    //             Taken
                    //           </div>
                    //         </th>
                    //         <th className=" px-4 py-3 ">
                    //           <div className="flex items-center justify-start">
                    //             Adjustment
                    //           </div>
                    //         </th>
                    //         <th className=" px-4 py-3 ">
                    //           <div className="flex items-center justify-start">
                    //             Entitled
                    //           </div>
                    //         </th>
                    //       </tr>
                    //     </thead>
                    //     <tbody className="bg-white">
                    //       {worker?.workers_payroll_category?.map(
                    //         (item, index) => (
                    //           <tr
                    //             key={index}
                    //             className="border-b border-[#F0F0F0]"
                    //           >
                    //             <td className="px-4 py-3">
                    //               <div className="flex items-center justify-start">
                    //                 (Con) RDO CW3
                    //               </div>
                    //             </td>
                    //             <td className="px-4 py-3">
                    //               <div className="flex items-center justify-start">
                    //                 -565.66
                    //               </div>
                    //             </td>
                    //             <td className="px-4 py-3">
                    //               <div className="flex items-center justify-start">
                    //                 26.66
                    //               </div>
                    //             </td>
                    //             <td className="px-4 py-3">
                    //               <div className="flex items-center justify-start">
                    //                 20
                    //               </div>
                    //             </td>
                    //             <td className="px-4 py-3">
                    //               <div className="flex items-center justify-start">
                    //                 437
                    //               </div>
                    //             </td>
                    //           </tr>
                    //         )
                    //       )}
                    //     </tbody>
                    //   </table>
                    // </Fragment>
                  )} */}
                    </div>
                  </div>
                  <div className="breakdown mt-5 bg-white p-4">
                    <p className="fs-18 mb-5">Task Breakdown</p>

                    <label>Worksite</label>
                    <Select
                      className="lg:w-3/5 w-full block mt-3 mb-20"
                      onChange={handleSelectChange}
                    >
                      {site?.map((item, index) => (
                        <Select.Option key={index} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                    <Fragment>
                      <table className="w-full">
                        <thead className="bg-[#E8F3FF] text-center">
                          <tr className="text-left">
                            <th className=" px-4 py-3 ">
                              <div className="flex items-center justify-start">
                                Code
                              </div>
                            </th>
                            <th className=" px-4 py-3 ">
                              <div className="flex items-center justify-start">
                                Task
                              </div>
                            </th>
                            <th className=" px-4 py-3 ">
                              <div className="flex items-center justify-start">
                                Hours
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white">
                          {task?.map((item, index) =>
                            item?.worksite_timesheet_workdone?.productivity_orders.map(
                              (order, orderIndex) => (
                                <tr
                                  key={orderIndex}
                                  className="border-b border-[#F0F0F0]"
                                >
                                  <td className="px-4 py-3">{order.code}</td>
                                  <td className="px-4 py-3">{order.name}</td>
                                  <td className="px-4 py-3">
                                    {order.time_spent}
                                  </td>
                                </tr>
                              )
                            )
                          )}
                        </tbody>
                      </table>
                    </Fragment>
                    {task?.every(
                      (item) =>
                        item?.worksite_timesheet_workdone?.productivity_orders
                          .length === 0
                    ) && (
                      <div className="mt-8 lg:overflow-hidden overflow-x-auto">
                        <CodeTable />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TerminatedProfile;
