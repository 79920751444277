import React from "react";

const TickSvg = ({ data }) => {
  let height = data?.height ? data?.height : 25;
  let width = data?.width ? data?.width : 25;

  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 13.948 13.948"
      >
        <g data-name="Group 72971">
          <path
            data-name="Path 1072"
            d="M6.974 0A6.974 6.974 0 1 1 0 6.974 6.974 6.974 0 0 1 6.974 0Z"
            fill="#1bbc8d"
          />
          <path
            data-name="Path 1073"
            d="M9.562 4.395c-.188.045-.1-.037-1.943 1.8l-1.706 1.7-.594-.594a6.721 6.721 0 0 0-.661-.626.663.663 0 0 0-.314-.075.634.634 0 0 0-.475.191.67.67 0 0 0-.1.839c.057.091 1.663 1.7 1.764 1.771a.549.549 0 0 0 .377.11.556.556 0 0 0 .371-.1c.038-.025.952-.929 2.03-2.009 2.136-2.14 2.015-2.011 2.064-2.214a.673.673 0 0 0-.813-.793Z"
            fill="#fff"
          />
        </g>
      </svg>
    </>
  );
};

export default TickSvg;
