import React, { useState } from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import DeleteAbsenceModal from "../Modal/DeleteAbsenceModal";
import { Archive } from "../../assets/svg";

const AbsencesTable = ({
  data,
  setData,
  handle,
  visibleDelete,
  setVisibleDelete,
  onArchive,
}) => {
  const visibleDeleteModal = (item, index) => {
    setData({
      ...data,
      selectedData: { ...item, index: index },
    });
    setVisibleDelete(true);
  };

  return (
    <>
      {visibleDelete && (
        <DeleteAbsenceModal
          setVisibleDelete={setVisibleDelete}
          data={data}
          setData={setData}
          handle={handle}
        />
      )}
      <table className="w-full">
        <thead className="bg-[#E8F3FF] text-center">
          <tr className="text-left">
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-start">Name</div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">Accured</div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">Taken</div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">
                Default Hours
              </div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">
                Apply <br />
                <span className="text-xs ml-2"> ( During Weekdays)</span>
              </div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">
                Apply <br />
                <span className="text-xs ml-2">( During Weekends)</span>
              </div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">
                Entitlement
              </div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">Action</div>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {data?.data?.map((item, index) => (
            <tr key={index} className="border-b border-[#F0F0F0]">
              <td className="px-4 py-3">
                <div className="flex items-center justify-start">
                  {item?.name}
                </div>
              </td>
              <td className="px-4 py-3">
                <div className="flex items-center justify-center">
                  {item?.absence_accrued_name}
                </div>
              </td>
              <td className="px-4 py-3">
                <div className="flex items-center justify-center">
                  {item?.absence_taken_name}
                </div>
              </td>
              <td className="px-4 py-3">
                <div className="flex items-center justify-center">
                  {item?.default_duration}
                </div>
              </td>
              <td className="px-4 py-3">
                <div className="flex items-center justify-center">
                  {item?.required_weekday_hours}
                </div>
              </td>
              <td className="px-4 py-3">
                <div className="flex items-center justify-center">
                  {item?.required_weekend_hours}
                </div>
              </td>
              <td className="px-4 py-3">
                <div className="flex items-center justify-center">
                  {item?.track_as_entitlement == 1 ? "Yes" : "No"}
                </div>
              </td>
              <td className="px-4 py-3">
                <div className="flex items-center justify-center">
                  <Link
                    to={`/admin/settings/payroll/absences/edit/${item?.id}`}
                    className="mx-2"
                  >
                    <FaEdit title="Edit" />
                  </Link>
                  <button
                    className="mx-2"
                    onClick={() => {
                      onArchive(item);
                    }}
                  >
                    <Archive title="Archive" />
                  </button>
                  <button
                    className="mx-2"
                    onClick={() => {
                      visibleDeleteModal(item, index);
                    }}
                  >
                    <FaTrashAlt title="Delete" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default AbsencesTable;
