import React, { createContext, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { GetAdministratorDetails } from "../helper/setup/user/administrator";
import SettingsSidebar from "../pages/Admin/Settings/SettingsSidebar/SettingsSidebar";
import SettingsTopBar from "../pages/Admin/Settings/SettingsTopBar/SettingsTopBar";
import { LoggedInUser } from "../auth/appAuth";

export const CreateAdminContext = createContext("");

const SettingsLaout = () => {
  const [createAdmincontext, setcreateAdminContext] = useState(true);

  let path = useLocation();

  const [user, setUser] = useState(LoggedInUser() ? true : false);

  useEffect(() => {
    setUser(LoggedInUser() ? true : false);
  }, [path?.pathname]);

  return (
    <CreateAdminContext.Provider
      value={[createAdmincontext, setcreateAdminContext]}
    >
      <div>
        <SettingsTopBar />
        <div className="lg:flex">
          <div className="lg:w-1/6">
            <SettingsSidebar />
          </div>
          <div className="lg:w-5/6 content-holder">
            {user ? <Outlet /> : <Navigate to="/" replace={true} />}
          </div>
        </div>
      </div>
    </CreateAdminContext.Provider>
  );
};

export default SettingsLaout;
