import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TextShimmer } from "../../components/shimmer/shimmer";
import InspectionTableWorkSite from "./InductionDisclaimerTableWorksite";
import AreasDefaultSvg from "../../assets/svg/areasDefault";
import { NoSearchTableSvg } from "../../assets/svg/tableDefaul";
import { GetAreaById } from "../../helper/worksite/worksiteDetails.js/areas";
import { TableDefaultPage } from "../../components/default/defaultPage";
import { NoTableOption } from "../../components/noOption/noOption";
import useWindowDimensions from "../../utils/routes/useWindowDimensions";

const AreaTask = ({
  tasks,
  setTasks,
  getInspection,
  setGetInspection,
  inspection,
  setInspection,
  selectedTask,
  setSelectedTask,
}) => {
  const { width } = useWindowDimensions();
  let navigate = useNavigate();
  let { id } = useParams();
  let { area } = useParams();

  let deafaulPage = {
    disclaimer: "No data available for displaying tasks",
    description: "The tasks needs to be added.",
    // buttonText: "Create Tasks",
    svg: <AreasDefaultSvg />,
    // redirect: `/admin/worksite/details/areas/cards/${id}/${area}`,
  };

  let noSearchOption = {
    svg: <NoSearchTableSvg />,
    disclaimer: "There were no search results found",
    description: "Sorry, No Item Matches Your Search Term.",
  };

  const [visibleInspection, setVisibleInspection] = useState(false);
  const handleGetInspection = {
    get: (taskid, type, task) => {
      setSelectedTask(task);
      setVisibleInspection(true);
      navigate(
        `/site-manager/worksite/details/inspection/${id}/${area}/${taskid}/${type}`
      );
    },
    search: async (e) => {
      const params = {
        search: e?.target?.value,
      };
      await handleGetInspection?.getArea(params);
    },
    getArea: async (areaData) => {
      const params = {
        id: area,
        worksite_id: id,
        search: areaData?.search,
      };
      let data = await GetAreaById(params);
      if (data?.status) {
        let allData = [];
        data?.data?.data?.hourly_rate_tasks_estimations?.map((hrt, index) => {
          allData?.push({
            ...hrt,
            task_abbrevation: hrt?.hourly_rate_task?.abbreviation,
            task_name: hrt?.hourly_rate_task?.name,
            type: "Hourly Rate",
          });
        });

        data?.data?.data?.productivity_orders?.map((po, index) => {
          allData?.push({
            ...po,
            task_abbrevation: po?.code,
            task_name: po?.name,
            type: "Productivity Order",
          });
        });

        setGetInspection({
          data: allData,
          extra: { ...data?.extra },
          search: areaData?.search,
          isShimmer: false,
        });
      }
    },
  };

  console.log("getInspection?.data", getInspection?.data);

  useEffect(() => {
    (async () => {
      await handleGetInspection?.getArea();
    })();
  }, []);
  return (
    <>
      <div className="py-4">
        <div className="bg-white">
          {getInspection?.isShimmer ? (
            <div>
              <TextShimmer data={{ gap: 15, line: 20 }} />
            </div>
          ) : visibleInspection ? (
            <InspectionTableWorkSite
              setVisibleInspection={setVisibleInspection}
              selectedTask={selectedTask}
              inspection={inspection}
              setInspection={setInspection}
            />
          ) : (
            <>
              {getInspection?.data?.length <= 0 && !getInspection?.search ? (
                <div className="py-8 h-[70vh]">
                  <TableDefaultPage data={deafaulPage} />
                </div>
              ) : (
                <>
                  <div>
                    {getInspection?.data?.length <= 0 &&
                    getInspection?.search ? (
                      <>
                        <NoTableOption data={noSearchOption} />
                      </>
                    ) : (
                      <div
                        style={{
                          overflowY: width <= 767 ? "scroll" : "visible",
                          maxHeight: width <= 767 ? "65vh" : "auto",
                        }}
                        className=""
                      >
                        <table className="w-full ">
                          <thead className="bg-[#E8F3FF] text-center">
                            <tr className="text-left">
                              <th className=" px-4 py-3">
                                <div className="flex items-center justify-start">
                                  Task Name
                                </div>
                              </th>
                              <th className=" px-4 py-3 ">
                                <div className="flex items-center justify-center">
                                  Task Type
                                </div>
                              </th>
                              <th className=" px-4 py-3 ">
                                <div className="flex items-center justify-center">
                                  Completed Inspection
                                </div>
                              </th>
                              <th className=" px-4 py-3 ">
                                <div className="flex items-center justify-center">
                                  Incompleted Inspection
                                </div>
                              </th>
                              <th className=" px-4 py-3 ">
                                <div className="flex items-center justify-center"></div>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white">
                            {getInspection?.data?.map((item, index) => {
                              let inspectionCompleted =
                                item?.inspections?.filter(
                                  (value) => value.status === 1
                                );
                              let inspectionInCompleted =
                                item?.inspections?.filter(
                                  (value) => value.status === 0
                                );
                              return (
                                <tr
                                  key={index}
                                  className="border-b border-[#F0F0F0]"
                                >
                                  <td className="px-4 py-3">
                                    <div
                                      className="flex items-center justify-start cursor-pointer"
                                      onClick={() =>
                                        handleGetInspection?.get(
                                          item?.id,
                                          item?.type == "Hourly Rate"
                                            ? "hourly"
                                            : "po",
                                          item
                                        )
                                      }
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      <span className="text-sm text-gray-400">
                                        {item?.task_abbrevation}
                                      </span>
                                      &nbsp; &nbsp;
                                      <span> {item?.task_name}</span>
                                    </div>
                                  </td>
                                  <td className="px-4 py-3">
                                    <div className="flex items-center justify-center">
                                      {item?.type}
                                    </div>
                                  </td>
                                  <td className="px-4 py-3">
                                    <div className="flex items-center justify-center">
                                      {inspectionCompleted.length}
                                    </div>
                                  </td>
                                  <td className="px-4 py-3">
                                    <div className="flex items-center justify-center">
                                      {inspectionInCompleted.length}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AreaTask;
