import React from "react";

const AreasDefaultSvg = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="135.15"
        height="115.624"
        viewBox="0 0 135.15 115.624"
      >
        <g fill="#efefef" fillRule="evenodd" data-name="Group 74417">
          <path
            data-name="Path 1441"
            d="M30.354 95.83h74.442c0-1.652-4.255-20.486-4.686-22.5l-4.817-22.637h-10.82v5.809c6.736 0 6.118-.48 6.468 1.456l6.736 32.071H37.489a25.473 25.473 0 0 1 .789-4.222c1.977-8.282 3.506-17.044 5.443-25.448.212-.927.293-1.172.464-2.172.374-2.148-.586-1.684 6.5-1.684v-5.81h-10.82a20.794 20.794 0 0 1-.553 2.612c-.2.952-.366 1.782-.61 2.823-.456 1.993-.814 3.8-1.188 5.671l-4.841 22.608c-.464 2.172-2.311 9.747-2.311 11.414Z"
          />
          <path
            data-name="Path 1442"
            d="M67.57 57.023a30.805 30.805 0 0 1-2.717-3.62l-5.125-7.81c-.871-1.464-1.538-2.758-2.408-4.19-2.343-3.873-6.647-13.31-6.647-19.216 0-8.665 7.631-16.369 15.572-16.369 4.149 0 6.72.114 10.406 2.53 16.792 11 1.586 33.5-6.818 45.674a20.576 20.576 0 0 1-2.27 3.01ZM66.412.01h2.311A21.634 21.634 0 0 1 79.57 3.327c14.758 9.747 11.658 24.244 4.645 38.384l-2.424 4.434a661.667 661.667 0 0 0-2.579 4.288c-1.627 2.742-3.637 5.646-5.516 8.209-1.294 1.757-4.833 6.761-6.11 7.615-1.139-.765-5.386-6.6-6.224-7.77-2.018-2.807-3.588-5.321-5.5-8.225l-5-8.73c-7.151-14.1-9.828-28.8 4.89-38.319A21.671 21.671 0 0 1 66.412 0Z"
          />
          <path
            data-name="Path 1443"
            d="M19.534 55.705c0 1.261 2.14.789 4.491.789-.529 1.1-.968 2.766-1.473 4.068-2.49 6.435-4.955 14.628-7.5 21.006l-2.9 8.445H0v5.809h15.572c.895 0 1.9-3.71 2.408-5.248 2.986-8.966 7.07-19.257 10.17-28.369l4.06-11.52H19.542v5.01Z"
          />
          <path
            data-name="Path 1444"
            d="M119.578 95.83h15.572v-5.809h-12.146l-2.9-8.445c-.529-1.318-.927-2.66-1.473-4.068l-7.5-21.006c.887 0 1.774.016 2.66.024 2.3.016 1.822.472 1.822-5.833h-12.672l15.2 42.607c.366.952.48 2.53 1.432 2.53Z"
          />
          <path
            data-name="Path 1445"
            d="M64.939 22.706a2.655 2.655 0 1 1 5.28 0c0 3.401-5.28 3.401-5.28 0Zm-5.809-1.057c0 3.254.26 4.645 2.571 6.932a7.187 7.187 0 0 0 2.473 1.749 8.137 8.137 0 0 0 9.153-1.619 8.557 8.557 0 0 0 .13-12.415 8.752 8.752 0 0 0-11.618-.122 8.978 8.978 0 0 0-2.709 5.475Z"
          />
          <path data-name="Path 1446" d="M25.343 42.241h16.89v-5.8h-16.89Z" />
          <path data-name="Path 1447" d="M92.918 42.241h16.89v-5.8h-16.89Z" />
          <path
            data-name="Path 1448"
            d="M36.431 114.835c0 .61.187.789.789.789h7.916v-5.809h-8.713v5.012Z"
          />
          <path
            data-name="Path 1449"
            d="M2.636 114.835c0 .61.187.789.789.789h7.916v-5.809H2.628v5.012Z"
          />
          <path
            data-name="Path 1450"
            d="M19.534 114.835c0 .61.187.789.789.789h7.916v-5.809h-8.713v5.012Z"
          />
          <path
            data-name="Path 1451"
            d="M53.321 114.835c0 .61.187.789.789.789h7.916v-5.809h-8.713v5.012Z"
          />
          <path
            data-name="Path 1452"
            d="M87.109 114.835c0 .61.187.789.789.789h7.916v-5.809h-8.713v5.012Z"
          />
          <path
            data-name="Path 1453"
            d="M104.006 114.835c0 .61.187.789.789.789h7.916v-5.809h-8.713v5.012Z"
          />
          <path
            data-name="Path 1454"
            d="M120.898 114.835c0 .61.187.789.789.789h7.911v-5.809h-8.71v5.012Z"
          />
          <path
            data-name="Path 1455"
            d="M70.219 114.835c0 .61.187.789.789.789h7.916v-5.809h-8.713v5.012Z"
          />
        </g>
      </svg>
    </>
  );
};

export default AreasDefaultSvg;
