import React from "react";
import { PayRollService, WorkService } from "../../../config/axiosUrl";
import { ApiAuthorizaion } from "../../../auth/appAuth";

const CraeteEditArea = async (area) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      name: area?.name,
      abbreviation: area?.abbreviation,
      worksite_id: area?.worksite_id,
      isSave: area?.isSave,
      id: area?._id,
    };

    let data = await WorkService?.post(
      "/api/v1/personal/workers/create-edit-area",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const GetAreas = async (areas) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      worksite_id: areas?.worksite_id,
      type: areas?.type ? areas?.type : "All",
      search: areas?.search,
      poStatus:
        areas?.poStatus?.toLowerCase() === "remainig" ||
        areas?.poStatus?.toLowerCase() === "lost"
          ? "all"
          : areas?.poStatus,
      cursor: areas?.cursor,
      id: areas?.id,
      isLost: areas?.poStatus?.toLowerCase() === "lost" ? 1 : 0,
      isRemaining:
        areas?.poStatus?.toLowerCase() === "remainig" ||
        areas?.poStatus?.toLowerCase() === "lost"
          ? 1
          : 0,
    };

    let data = await WorkService?.get("/api/v1/worksite/area/get-areas-data", {
      headers: authorization?.headers,
      params: params,
    });
    return data?.data;
  } catch (error) {
    return error;
  }
};

const CreateHourlyRate = async (worksite) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      ids: worksite?.ids,
      worksite_id: worksite?.worksite_id,
      area_id: worksite?.area_id,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/create-hourly-rate-tasks-estimations?dev=4",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const CreateEditHourlyRate = async (hourlyRateTask) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: hourlyRateTask?.id,
      hourly: hourlyRateTask?.hourly,
      po: hourlyRateTask?.po,
      total: hourlyRateTask?.total,
      remaining: hourlyRateTask?.remaining,
      net_alloc_hours: hourlyRateTask?.net_alloc_hours,
      net_alloc_per: hourlyRateTask?.net_alloc_per,
      reserved: hourlyRateTask?.reserved,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/edit-hourly-rate-tasks-estimations?dev=4",
      params,
      authorization
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};

const DeleteHourlyRateTask = async (hourlyRateTask) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: hourlyRateTask?.id,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/delete-hourly-rate-tasks-estimations?dev=4",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error?.error;
  }
};

const GetAreaById = async (worksite) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: worksite?.id,
      search: worksite?.search,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/get-area-data-by-id?dev=4",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};
const GetAreaByIdTab = async (worksite) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: worksite?.id,
      search: worksite?.search,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/get-area-data-by-id?tab=1",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const CreateEditProductiveOrder = async (area) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: area?.id,
      area_id: area?.area_id,
      worksite_id: area?.worksite_id,
      code: area?.code,
      name: area?.name,
      status: area?.status,
      is_save: area?.is_save,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/add-edit-productivity-orders?dev=5",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const ApproveProductiveOrder = async (productivityOrder) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };
    const params = {
      id: productivityOrder?.id,
      status: productivityOrder?.status,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/approve-productivity-orders",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const UpdateProductiveOrderField = async (productivityOrder) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      productivity_orders_id: productivityOrder?.productivity_orders_id,
      productivity_orders_remaining:
        productivityOrder?.productivity_orders_remaining,
      productivity_orders_time_spent:
        productivityOrder?.productivity_orders_time_spent,
      id: productivityOrder?.id,
      hourly_rate_tasks_estimations_id:
        productivityOrder?.hourly_rate_tasks_estimations_id,
      remaining: productivityOrder?.remaining,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/area/productivity-order-data-update-field",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const UpdateAreaProductiveOrder = async (productivityOrder) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: productivityOrder?.id,
      code: productivityOrder?.code,
      name: productivityOrder?.name,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/area/productivity-order-update-field",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const EditHourlyRateTasks = async (worker) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: worker?.id,
      hourly: worker?.hourly,
      po: worker?.po,
      total: worker?.total,
      remaining: worker?.remaining,
      net_alloc_hours: worker?.net_alloc_hours,
      reserve_per: worker?.reserve_per,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/edit-hourly-rate-tasks-estimations",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};
const DeleteProductiveOrders = async (productivityOrder) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: productivityOrder?.id,
      productivity_orders_data_ids:
        productivityOrder?.productivity_orders_data_ids,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/delete-productivity-order?dev=4",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const DeleteProductiveOrderData = async (productivityOrder) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: productivityOrder?.id,
      hourly_rate_tasks_estimations_id:
        productivityOrder?.hourly_rate_tasks_estimations_id,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/delete-productivity-order-data?dev=4",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const MergeProductiveOrder = async (productivityOrder) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      area_id: productivityOrder?.area_id,
      worksite_id: productivityOrder?.worksite_id,
      code: productivityOrder?.code,
      name: productivityOrder?.name,
      productivity_order_ids: productivityOrder?.productivity_order_ids,
      is_save: productivityOrder?.is_save,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/merge-productivity-orders?dev=4",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const GetInspection = async (inspection) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      cursor: inspection?.cursor,
      skip: inspection?.skip,
      search: inspection?.search,
      type: inspection?.type,
      productivity_orders_id: inspection?.productivity_orders_id,
      hourly_rate_tasks_estimations_id:
        inspection?.hourly_rate_tasks_estimations_id,
    };
    let data = await WorkService?.get(
      "/api/v1/worksite/inspection/get-inspections",
      {
        authorization: authorization?.headers,
        params: params,
      }
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const CreateEditInspection = async (inspection) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: inspection?.id,
      area_id: inspection?.area_id,
      worksite_id: inspection?.worksite_id,
      inspection: inspection?.inspection,
      description: inspection?.description,
      hourly_rate_tasks_estimations_id:
        inspection?.hourly_rate_tasks_estimations_id,
      productivity_orders_id: inspection?.productivity_orders_id,
      isSave: inspection?.isSave,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/inspection/save-edit-inspections?dev=1",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const InspectionStatusChange = async (inspection) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      ids: inspection?.ids,
      status: inspection?.status,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/inspection/change-status",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const InspectionDelete = async (inspection) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: inspection?.id,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/inspection/delete",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const GetIssues = async (issues) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      take: issues?.take,
      previous: issues?.previous,
      cursor: issues?.cursor,
      skip: issues?.skip,
      search: issues?.search,
    };

    let data = await WorkService?.get("/api/v1/worksite/issues/get-issues", {
      authorization: authorization?.headers,
      params: params,
    });

    return data?.data;
  } catch (error) {
    return error;
  }
};

const CreateEditIssues = async (issues) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      area_id: issues?.area_id,
      worksite_id: issues?.worksite_id,
      task_id: issues?.task_id,
      issue: issues?.issue,
      isSave: issues?.isSave,
      id: issues?.id,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/issues/save-edit-issues?dev=2",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const IssuesDelete = async (issue) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: issue?._id,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/issues/delete",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const getTaskAllowance = async (searchValue) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    let data = await PayRollService?.get(
      `/api/v1/setting/payroll/get-task-allowance?cursor=&search=${
        searchValue ? searchValue : ""
      }`,
      {
        authorization,
      }
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};
const deleteTaskAllowance = async (item, selected) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      payroll_task_allowance_id: item?.id,
      worker_id: selected?.worker_id,
      worksite_timesheet_data_id: selected?.worksite_timesheet[0]?._id,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/site-manager/delete-worker-t-a",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};
const assignWorkerPayroll = async (item, selected) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      workerData: [
        {
          worker_id: item,
          payroll_ids: selected,
        },
      ],
    };

    let data = await PayRollService?.post(
      "/api/v1/payroll/setting/assign-worker-payroll-categories",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const assignWorkerPayrollData = async (item, worksite) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    let param = {
      workerData: item?.workerData,
      worksite_id: worksite,
    };

    let data = await PayRollService?.post(
      "/api/v1/payroll/setting/assign-worker-payroll-categories",
      param,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const assignWorkerPayrollEmp = async (dataValue) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    let data = await PayRollService?.post(
      "/api/v1/payroll/setting/assign-worker-payroll-categories",
      dataValue,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const assignWorkerPayrollCat = async (item, work_id) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      worksite_id: work_id?.id,
      pay_id: item,
    };

    let data = await PayRollService?.post(
      "/api/v1/payroll/setting/assign-worksite-payroll-categories",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const assignWorkerPayrollmultiple = async (item, work_id) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };
    const payid = item?.workerData?.map((ele) => ele.payroll_ids).flat();
    const params = {
      worksite_id: work_id?.id,
      pay_ids: payid.filter((item) => item != ""),
    };

    let data = await PayRollService?.post(
      "/api/v1/payroll/setting/assign-worksite-payroll-categories-new",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

const assignWorkerPayrollmultipleWork = async (item, work_id) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };
    // const payid = item?.workerData?.map((ele)=>ele.payroll_ids).flat()
    // console.log(payid)
    const params = {
      worksite_id: work_id?.id,
      pay_ids: item,
    };

    let data = await PayRollService?.post(
      "/api/v1/payroll/setting/assign-worksite-payroll-categories-new",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

export {
  CraeteEditArea,
  GetAreas,
  CreateHourlyRate,
  CreateEditHourlyRate,
  DeleteHourlyRateTask,
  GetAreaById,
  GetAreaByIdTab,
  CreateEditProductiveOrder,
  EditHourlyRateTasks,
  UpdateAreaProductiveOrder,
  UpdateProductiveOrderField,
  ApproveProductiveOrder,
  DeleteProductiveOrders,
  DeleteProductiveOrderData,
  MergeProductiveOrder,
  GetInspection,
  CreateEditInspection,
  InspectionStatusChange,
  InspectionDelete,
  GetIssues,
  CreateEditIssues,
  IssuesDelete,
  getTaskAllowance,
  deleteTaskAllowance,
  assignWorkerPayroll,
  assignWorkerPayrollCat,
  assignWorkerPayrollEmp,
  assignWorkerPayrollmultiple,
  assignWorkerPayrollmultipleWork,
  assignWorkerPayrollData,
};
