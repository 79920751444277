// CustomDefaultPage.js
import React from "react";
import { ArchiveDefault } from "../../../../assets/svg";

const DefaultPage = () => (
  <div style={{ textAlign: "center", padding: "80px" }}>
    <ArchiveDefault style={{ display: "block", margin: "auto" }} />
    <p style={{ fontWeight: "bold", marginTop: 20 }}>No Archives Found</p>
    <p>No item has been archived till now</p>
  </div>
);

export default DefaultPage;
