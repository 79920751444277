import { Button, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { GetAreaById } from "../../../../../../helper/worksite/worksiteDetails.js/areas";
import { useParams } from "react-router-dom";
import { ButtonSpinner } from "../../../../../../components/spinners";

const EditInspectionIssues = ({ setVisibleIssues, issueList, setIssueList, handle, formref }) => {
  const { area } = useParams()
  const [taskList, setTaskList] = useState()

  const handleChange = (name) => {
    setIssueList({
      ...issueList,
      selectedData: {
        ...issueList?.selectedData,
        'task_id': name
      }
    })
  };
  useEffect(() => {
    (async () => {
      const param = {
        id: area
      }
      let res = await GetAreaById(param)
      if (res?.status) {
        let allData = [];
        res?.data?.data?.hourly_rate_tasks_estimations?.map((hrt, index) => {
          allData?.push({
            ...hrt,
            task_abbrevation: hrt?.hourly_rate_task?.abbreviation,
            task_name: hrt?.hourly_rate_task?.name,
            type: "Hourly Rate",
          });
        });

        res?.data?.data?.productivity_orders?.map((po, index) => {
          allData?.push({
            ...po,
            task_abbrevation: po?.code,
            task_name: po?.name,
            type: "Productivity Order",
          });
        });
        setTaskList(allData)
      }
    })()
  }, [])
  const closeModal = () => {
    setVisibleIssues(false);
  };
  return (
    <>
      <div className="modal">
        <div className="modal-content">
          <div className="text-right">
            <button
              className="close-modal"
              onClick={closeModal}
            >
              <FaTimes />
            </button>
          </div>
          <h2 className="text-xl font-bold">{issueList?.selectedData?._id ? "Editing Issue Details" : 'Creating Issue Details'}</h2>

          <div className="modal-body mt-3">
            <form className="mt-4">
              <div className="item">
                <div className="item">
                  <label htmlFor="inspection">Select The Task</label>
                  <Select
                    ref={formref?.current?.["task_id"]}
                    placeholder="Select Task"
                    className={issueList?.selectedData?.errors?.task_id ? "mt-3 w-full border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600" : "mt-3 w-full"}
                    name="task_id"
                    onChange={handleChange}
                    defaultValue={issueList?.selectedData?.task_id}
                    id="task_id"
                  >
                    {taskList?.map(item => (
                      <Select.Option key={item?.id} value={item?.id}>{item?.task_name}</Select.Option>
                    ))}
                  </Select>
                  {issueList?.selectedData?.errors?.task_id && (
                    <div>
                      <p className="pt-2 text-red-600 text-sm">
                        {issueList?.selectedData?.errors?.task_id}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="item mt-5">
                <label htmlFor="description">Reason For Issue </label>
                <TextArea
                  rows={5}
                  type="text"
                  name="issue"
                  id="issue"
                  autoComplete="off"
                  className={
                    issueList?.selectedData?.errors?.issue
                      ? "mt-3 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                      : "mt-3"
                  }
                  ref={formref?.current?.["issue"]}
                  value={issueList?.selectedData?.issue}
                  onChange={handle?.getForm}
                />
                {issueList?.selectedData?.errors?.issue && (
                  <div>
                    <p className="pt-2 text-red-600 text-sm">
                      {issueList?.selectedData?.errors?.issue}
                    </p>
                  </div>
                )}
              </div>
              <div className="item mt-5 text-center">
                <Button className="btn btn-blue bg-blue-disable" onClick={handle?.submit}
                  disabled={issueList?.selectedData?.isLoading}>
                  {issueList?.selectedData?.isLoading ? (
                    <ButtonSpinner />
                  ) : issueList?.selectedData?._id ? 'Save' : 'Create'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditInspectionIssues;
