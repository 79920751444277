import React, { useState, Fragment } from "react";
import CreateSpecificAllowance from "./CreateSpecifcAllowance";
import { Button } from "antd";
import { FaPlus, FaSearch } from "react-icons/fa";
import { PayRollService } from "../../../../../config/axiosUrl";
import { toast } from "react-toastify";
import Confirmation from "../../../../../components/Confirmation";
import DefaulGlobal from "../../../../../components/default/DefaultGloabal";
import Pagination from "../../../../../components/PaginationAPI/Pagination";
import { useForm } from "react-hook-form";
import { Edit, Trash, WorkerAllowanceDefault } from "../../../../../assets/svg";
import { TextShimmer } from "../../../../../components/shimmer/shimmer";

const SpecificAllowance = () => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [specificAllowanceData, setSpecificAllowanceData] = useState({
    data: [],
    pageLoading: true,
  });
  const [deleteModal, setDeleteModal] = useState({
    visible: false,
    current: {},
    isDeleting: false,
  });
  const [isCreating, setIsCreating] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(null);
  const [search, setSearch] = useState("");

  const [reloadData, setReloadData] = useState(false);

  const { handleSubmit, control, reset, setValue } = useForm({
    defaultValues: { name: "" },
  });

  const toggleModal = () => {
    setVisibleModal(!visibleModal);
    if (visibleModal) {
      setIsEdit(false);
      reset();
    }
  };

  const handleEdit = (data) => {
    setIsEdit(true);
    setEditId(data?.id);
    setValue("name", data.name);
    toggleModal();
  };

  const handleDelete = async () => {
    setDeleteModal((prev) => ({ ...prev, isDeleting: true }));
    try {
      const res = await PayRollService.post(
        "api/v1/setting/payroll/delete-specific-allowance",
        { id: deleteModal.current.id }
      );
      if (res.data.status) {
        toast.success(res.data.message);
        setReloadData(!reloadData);
        setDeleteModal({ visible: false, current: {}, isDeleting: false });
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      toast.error("Failed to delete specific allowance");
    } finally {
      setDeleteModal((prev) => ({ ...prev, isDeleting: false }));
    }
  };

  const handleCreateOrUpdate = async (data) => {
    setIsCreating(true);
    const requestData = { ...data, isSave: isEdit ? 0 : 1, id: editId };
    try {
      const res = await PayRollService.post(
        "api/v1/setting/payroll/create-edit-specific-allowance",
        requestData
      );
      if (res.data.status) {
        toast.success(res.data.message);
        setReloadData(!reloadData);
        toggleModal();
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      toast.error("Failed to create specific allowance");
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <div>
      <CreateSpecificAllowance
        open={visibleModal}
        isEdit={isEdit}
        toggleModal={toggleModal}
        onSubmit={handleCreateOrUpdate}
        isSubmitting={isCreating}
        handleSubmit={handleSubmit}
        control={control}
      />
      <Confirmation
        title="Are You Sure You Wish To Delete This Specific Allowance"
        open={deleteModal.visible}
        details={deleteModal.current.name}
        onCancel={() =>
          setDeleteModal((prev) => ({ ...prev, visible: !prev.visible }))
        }
        onConfirm={handleDelete}
        isSubmitting={deleteModal.isDeleting}
      />
      <div className="flex justify-between items-center bg-white p-5">
        <div className="border flex items-center p-1 rounded-md w-full lg:w-1/3 my-2 bg-white">
          <FaSearch className="ml-2" />
          <input
            placeholder="Search by Specific Allowance Name"
            onChange={({ target: { value } }) => setSearch(value)}
            className="h-[31px] bg-transparent text-sm w-full px-2"
          />
        </div>
        <Button
          onClick={toggleModal}
          className="flex justify-center items-center py-3 border h-[42px]"
        >
          Create New
          <div className="bg-orange-500 p-2 rounded-md text-white ml-3">
            <FaPlus />
          </div>
        </Button>
      </div>

      {specificAllowanceData.pageLoading ? (
        <TextShimmer
          data={{
            line: 25,
            gap: 15,
            className: "bg-white",
          }}
        />
      ) : specificAllowanceData.data.length === 0 ? (
        <DefaulGlobal
          DefaultImage={WorkerAllowanceDefault}
          header="No Specific Allowances found"
          description="Create your Specific Allowances now"
          buttonTitle="Create New"
          pageHandler={toggleModal}
        />
      ) : (
        <div className="bg-white">
          <table className="w-full">
            <thead className="bg-[#E8F3FF] text-center">
              <tr className="text-left">
                <th className="px-4 py-3 flex items-center justify-between">
                  <div>Name</div>
                  <div>Actions</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {specificAllowanceData.data.map((item) => (
                <Fragment key={item.id}>
                  <tr className="border-b-2 flex justify-center items-center">
                    <td className="px-4 py-3 w-full">{item.name}</td>
                    <td className="px-5 py-3 flex gap-4">
                      <button
                        onClick={() =>
                          setDeleteModal({ visible: true, current: item })
                        }
                        className="text-black"
                      >
                        <Trash title="Trash" />
                      </button>
                      <button
                        onClick={() => handleEdit(item)}
                        className="text-black"
                      >
                        <Edit title="Edit" />
                      </button>
                    </td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="bg-white">
        <Pagination
          api="/api/v1/setting/payroll/get-specific-allowance"
          apiService={PayRollService}
          getRes={(res) =>
            setSpecificAllowanceData({
              data: res?.data?.data?.data || [],
              pageLoading: false,
            })
          }
          extraParams={{ search }}
          dependecy={[reloadData, search]}
        />
      </div>
    </div>
  );
};

export default SpecificAllowance;
