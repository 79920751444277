import React, { useContext, useState } from "react";
import { Fileurl } from "../../../config/url";
import DefaultImage from "../../../assets/images/Default/defaulCree8.png";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../../layouts/Main";
import moment from "moment";
import useWindowDimensions from "../../../utils/routes/useWindowDimensions";

const Timelines = ({ data }) => {
  const { width } = useWindowDimensions();
  let navigate = useNavigate();
  const [dataStore, setDataStore] = useContext(AppContext);
  const [user, setUser] = useState(
    localStorage.getItem("cree8_user")
      ? JSON.parse(localStorage.getItem("cree8_user"))
      : "{}"
  );
  const isChartValid = (chart) => {
    return (
      chart.remaining > 0 ||
      chart.worked > 0 ||
      chart.reserved > 0 ||
      chart.deficiet > 0 ||
      chart.lost > 0
    );
  };
  const storeContext = (item) => {
    setDataStore(item);
    localStorage.setItem("dataStore", JSON.stringify(item[0]));
  };

  return (
    <div
      className={
        width > 789
          ? "grid lg:grid-cols-2 grid-cols-1 gap-6"
          : "grid sm:grid-cols-21 grid-cols-1 gap-5 p-3 overflow-scroll "
      }
      style={{
        overflowY: width <= 767 ? "scroll" : "visible",
        maxHeight: width <= 767 ? "72vh" : "auto",
      }}
    >
      {data &&
        data.map((item, index) => (
          <div
            className={
              width > 789
                ? "bg-white dash-card rounded-lg "
                : "bg-white dash-card rounded "
            }
            key={index}
          >
            <div className="stat d-flex">
              {item.chart.map((chart, index) =>
                isChartValid(chart) ? (
                  <div className="chart flex items-center" key={index}>
                    {chart.remaining > 0 && (
                      <div
                        style={{ width: chart.remaining + "%" }}
                        className={`chart__item flex justify-center p-2 bg-[#FF922B] text-white`}
                      >
                        {chart.remaining}
                      </div>
                    )}
                    {chart.worked > 0 && (
                      <div
                        style={{ width: chart.worked + "%" }}
                        className={`chart__item p-2 bg-[#40C057] text-white`}
                      >
                        {chart.worked}
                      </div>
                    )}
                    {chart.reserved > 0 && (
                      <div
                        style={{ width: chart.reserved + "%" }}
                        className={`chart__item p-2 bg-[#748FFC] text-white`}
                      >
                        {chart.reserved}
                      </div>
                    )}
                    {chart.deficiet > 0 && (
                      <div
                        style={{ width: chart.deficiet + "%" }}
                        className={`chart__item p-2 bg-[#FFD43B] text-white`}
                      >
                        {chart.deficiet}
                      </div>
                    )}
                    {chart.lost > 0 && (
                      <div
                        style={{ width: chart.lost + "%" }}
                        className={`chart__item p-2 bg-[#F03E3E] text-white`}
                      >
                        {chart.lost}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="chart w-100 bg-[#D5E9FE] items-center justify-center text-center p-1">
                    No data available for Displaying Stats
                  </div>
                )
              )}
            </div>

            <div
              className={
                width > 767
                  ? "chart-details flex mt-3 text-sm justify-center items-center p-3"
                  : "chart-details flex mt-3 text-sm justify-center items-center overflow-x-auto cursor-pointer pl-40 "
              }
            >
              <div className="item mx-3 flex justify-center items-center text-sm">
                <div className="w-4 h-4 bg-[#FF922B] mr-2"></div>
                <p className="text-gray-500">Remaining</p>
              </div>

              <div className="item mx-3 flex justify-center items-center text-sm">
                <div className="w-4 h-4 bg-[#40C057] mr-2"></div>
                <p className="text-gray-500">Worked</p>
              </div>

              <div className="item mx-3 flex justify-center items-center text-sm">
                <div className="w-4 h-4 bg-[#748FFC] mr-2"></div>
                <p className="text-gray-500">Reserved</p>
              </div>

              <div className="item mx-3 flex justify-center items-center text-sm">
                <div className="w-4 h-4 bg-[#FFD43B] mr-2"></div>
                <p className="text-gray-500">Deficit </p>
              </div>

              <div className="item mx-3 flex justify-center items-center text-sm">
                <div className="w-4 h-4 bg-[#F03E3E] mr-2"></div>
                <p className="text-gray-500">Lost</p>
              </div>
            </div>

            <div className="job-deails flex justify-start items-center my-4 px-5">
              <Link
                onClick={() => storeContext(item.jobDetails)}
                to={`/site-manager/worksite/details/timesheet/${item?.id}/1`}
                state={{ data: item }}
                // className="text-xl"
              >
                {item.jobDetails.map((job, index) => (
                  <div className="job flex items-center" key={index}>
                    <div
                      className="job__img flex flex-1"
                      style={{
                        maxWidth: "40%",
                        height: "150px",
                      }}
                    >
                      <img
                        src={Fileurl + job.img}
                        alt=""
                        onError={(e) => {
                          e.target.src = DefaultImage;
                          e.onerror = "";
                        }}
                      />
                    </div>
                    <div className="job__details flex-1 pl-4">
                      <p>
                        <span className="text-[#A1A1A1]">Job Number :</span>
                        &nbsp;
                        <span>{job.jobNumber}</span>
                      </p>

                      <p className="text-2xl mb-3">{job.jobName}</p>
                      <p>
                        <span className="text-[#A1A1A1]">Site Manager:</span>
                        <span>{job.siteManager}</span>
                      </p>
                    </div>
                  </div>
                ))}
              </Link>
            </div>

            <table className="w-full">
              {item.jobDetails.map((job, index) => (
                <tbody key={index}>
                  <tr>
                    <td className="border w-full p-2">
                      <span className="text-[#A1A1A1]">Areas</span>
                    </td>
                    <td className="border w-full p-2 text-right">
                      <span>{job.areas}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="border w-full p-2">
                      <span className="text-[#A1A1A1]">Workers</span>
                    </td>
                    <td className="border w-full p-2 text-right">
                      <span>{job.workers}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="border w-full p-2">
                      <span className="text-[#A1A1A1]">Absent Workers</span>
                    </td>
                    <td className="border w-full p-2 text-right">
                      <span>{job.absentWorkers}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="border w-full p-2">
                      <span className="text-[#A1A1A1]">Project Start Date</span>
                    </td>
                    <td className="border w-full p-2 text-right">
                      <span>{job.projectStartDate}</span>
                    </td>
                  </tr>

                  {user?.role === "admin" && (
                    <tr>
                      <td className="border w-full p-2">
                        <span className="text-[#A1A1A1]">Value</span>
                      </td>
                      <td className="border w-full p-2 text-right min-w-[300px]">
                        <span>{job.projectEndDate}</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              ))}
            </table>

            <div className="productivity p-2 text-center my-3">
              Productivity Order
            </div>

            <table className="w-full">
              {item.productivity.map((productivity, index) => (
                <tbody key={index}>
                  <tr>
                    <td className="border-none p-2 w-1/4 text-center">
                      <span className="text-[#A1A1A1]">Total</span>
                    </td>
                    <td className="border-none w-1/4 p-2 text-center">
                      <span className="text-[#A1A1A1]">Approved</span>
                    </td>
                    <td className="border-none w-1/4 p-2 text-center">
                      <span className="text-[#A1A1A1]">Active</span>
                    </td>
                    <td className="border-none w-1/4 p-2 text-center">
                      <span className="text-[#A1A1A1]">Remaining</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="border-none w-1/4 p-2 text-center">
                      <span>{productivity.total}</span>
                    </td>
                    <td className="border-none w-1/4 p-2 text-center">
                      <span>{productivity.approved}</span>
                    </td>
                    <td className="border-none w-1/4 p-2 text-center">
                      <span>{productivity.active}</span>
                    </td>
                    <td className="border-none w-1/4 p-2 text-center">
                      <span>{productivity.remaining}</span>
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        ))}
    </div>
  );
};

export default Timelines;
