import { Button, Input } from "antd";
import React, { useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { ButtonSpinner } from "../../components/spinners";
import { useNavigate, useParams } from "react-router-dom";

const CreateNewAreaModal = ({ setVisibleAreaModal, data, handle, errors }) => {
  let navigate = useNavigate();
  let { id } = useParams();

  const closeModal = () => {
    navigate(`/admin/worksite/details/areas/${id}`);
    // setVisibleAreaModal(false);
    // navigate();
  };

  useEffect(() => {
    errors?.current?.["name"]?.current?.focus();
  }, []);

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="text-right">
          <button className="close-modal" onClick={closeModal}>
            <FaTimes />
          </button>
        </div>

        <div className="modal-body mt-3">
          <h2 className="text-xl font-bold">Creating New Area</h2>

          <form className="mt-4">
            <div className="item mt-5">
              <label htmlFor="name">Area Name</label>
              <Input
                type="text"
                id="name"
                key={1}
                className={
                  data?.errors?.name
                    ? "mt-3 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                    : "mt-3"
                }
                ref={errors?.current["name"]}
                name="name"
                value={data?.name}
                onChange={handle?.get}
                autoComplete="off"
              />
              {data?.errors?.name && (
                <p className="pt-2 text-red-600 text-sm">
                  {data?.errors?.name}
                </p>
              )}
            </div>
            <div className="item mt-5">
              <label htmlFor="abbreviation">Area Abbreviation</label>
              <Input
                type="text"
                id="abbreviation"
                key={2}
                className={
                  data?.errors?.abbreviation
                    ? "mt-3 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                    : "mt-3"
                }
                ref={errors?.current["abbreviation"]}
                name="abbreviation"
                value={data?.abbreviation}
                onChange={handle?.get}
                autoComplete="off"
              />
              {data?.errors?.abbreviation && (
                <p className="pt-2 text-red-600 text-sm">
                  {data?.errors?.abbreviation}
                </p>
              )}
            </div>
            <div className="item mt-5 text-center">
              <Button
                className="btn btn-blue w-1/5 bg-blue-disable"
                onClick={handle?.submit}
                disabled={data?.isLoading}
              >
                {data?.isLoading ? <ButtonSpinner /> : "Create"}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateNewAreaModal;
