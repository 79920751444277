import React from "react"

const SuccesIcon = () => {
  return (
    <svg id="Group_74667" data-name="Group 74667" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
      <circle id="Ellipse_180" data-name="Ellipse 180" cx="6.5" cy="6.5" r="6.5" fill="#003560" />
      <path id="Path_1536" data-name="Path 1536" d="M146.912,165.49a.556.556,0,1,1,.773.8q-1.8,1.8-3.6,3.6a.559.559,0,0,1-.565.14.6.6,0,0,1-.255-.167l-1.773-1.774a.556.556,0,1,1,.787-.785l1.41,1.409Q145.3,167.1,146.912,165.49Z" transform="translate(-138.128 -161.225)" fill="#fafafa" />
    </svg>
  )
}
export default SuccesIcon;