import moment from "moment";
import React, { Fragment, useMemo } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const GetWeekDayReport = ({ date, parent }) => {
  const currWeek = days[moment(date).weekday()];

  const data = parent.timesheetData[currWeek];
  const navigate = useNavigate();
  const timesheetEntry = parent?.timesheet_ids.find(
    (entry) => entry.day === currWeek
  );

  const timeSheetId = useMemo(() => {
    const timesheet = parent?.worksite_timesheet_data.find((item) => item?._id);
    return timesheet?.worksite_timesheet?._id || "";
  }, []);
  // console.log("parent?.work_site_id, ", timeSheetId, parent?.work_site_id);

  const handleClick = () => {
    if (!timesheetEntry?._id) {
      toast.error("No timesheet entry found for this day."); // Show toast if no ID
    } else {
      navigate(
        `/admin/worksite/add-timesheet-details/${parent?.work_site_id}/${
          timesheetEntry?._id
        }?summary=${true}&workerId=${parent?.user?._id}&workerName=${
          parent?.user?.name
        }`
      );
    }
  };
  return !data ? (
    <td colSpan={3} key={parent?._id + currWeek}>
      <div
        className="flex justify-center items-center cursor-pointer"
        onClick={handleClick}
      >
        <AiOutlinePlusCircle />
      </div>
    </td>
  ) : (
    <Fragment>
      <td>
        {/* N */}
        <p className="pl-2">
          {data?.normal_time ? Number(data.normal_time).toFixed(2) : "-"}
        </p>
      </td>
      {/* OT */}
      <td>{data?.over_time ? Number(data.over_time).toFixed(2) : "-"}</td>
      <td>
        {/* DT */}
        <p className="pr-2">
          {data?.double_time ? Number(data.double_time).toFixed(2) : "-"}
        </p>
      </td>
    </Fragment>
  );
};

export default GetWeekDayReport;
