import { Button } from "antd";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaEdit, FaPlus, FaTrashAlt } from "react-icons/fa";
import EditInspectionIssues from "./editInspectionIssues";
import DeleteInspectionIssues from "./DeleteInspectionIssues";
import {
  CreateEditIssues,
  GetIssues,
  IssuesDelete,
} from "../../../../../../helper/worksite/worksiteDetails.js/areas";
import { TextShimmer } from "../../../../../../components/shimmer/shimmer";
import AreasDefaultSvg from "../../../../../../assets/svg/areasDefault";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import useWindowDimensions from "../../../../../../utils/routes/useWindowDimensions";

const IssuesDetails = ({ setIssueDetails }) => {
  const { width } = useWindowDimensions();
  const { id, area } = useParams();
  let navigate = useNavigate();
  const initialIssues = {
    data: [],
    extra: {},
    selectedData: {
      id: "",
      task_id: "",
      issue: "",
      isSave: "",
      errors: {
        task_id: "",
        issue: "",
      },
      isLoading: false,
    },
    search: "",
    isShimmer: true,
  };
  let ref = {
    inspection: "",
    description: "",
  };
  const [VisibleIssues, setVisibleIssues] = useState(false);
  const [visibleDeleteIssues, setvisibleDeleteIssues] = useState(false);
  const [issueList, setIssueList] = useState(initialIssues);
  let formref = useRef([]);

  Reflect.ownKeys(ref)?.map((value, index) => {
    formref.current[value] = React?.createRef();
  });
  const visibleIssuesModal = (item, index) => {
    setVisibleIssues(true);
    setIssueList({
      ...issueList,
      selectedData: item,
    });
  };
  const visibleDeleteModal = (item, index) => {
    setvisibleDeleteIssues(true);
    setIssueList({
      ...issueList,
      selectedData: item,
    });
  };
  const handleIssue = {
    add: (issue, index) => {
      setVisibleIssues(true);
      setIssueList({
        ...issueList,
        selectedData: {
          ...issueList?.selectedData,
          id: issue?._id,
          isSave: issue?._id ? 0 : 1,
          task_id: issue?.task_id,
          issue: issue?.issue,
        },
      });
    },
    get: async (param) => {
      let data = await GetIssues(param);
      if (data?.status) {
        console.log(data);
        setIssueList({
          ...issueList,
          data: [...data?.data?.data],
          extra: { ...data?.extra },
          search: issueList?.search,
          isShimmer: false,
        });
        return data;
      } else {
        return data;
      }
    },
    getForm: (e) => {
      setIssueList({
        ...issueList,
        selectedData: {
          ...issueList?.selectedData,
          [e?.target?.name]: e?.target?.value,
        },
      });
    },
    submit: async () => {
      if (!issueList?.selectedData?.task_id) {
        setIssueList({
          ...issueList,
          selectedData: {
            ...issueList?.selectedData,
            errors: {
              ...initialIssues?.selectedData?.errors,
              task_id: "Select task",
            },
          },
        });
        formref?.current["task_id"]?.current?.focus();
      } else if (!issueList?.selectedData?.issue) {
        setIssueList({
          ...issueList,
          selectedData: {
            ...issueList?.selectedData,
            errors: {
              ...initialIssues?.selectedData?.errors,
              issue: "Enter Issue Reason",
            },
          },
        });
        formref?.current["issue"]?.current?.focus();
      } else {
        setIssueList({
          ...issueList,
          selectedData: {
            ...issueList?.selectedData,
            isLoading: true,
            errors: {
              ...initialIssues?.selectedData?.errors,
            },
          },
        });
        const params = {
          id: issueList?.selectedData?._id,
          worksite_id: id,
          area_id: area,
          issue: issueList?.selectedData?.issue,
          task_id: issueList?.selectedData?.task_id,
          isSave: issueList?.selectedData?.isSave ? 1 : 0,
        };
        let data = await CreateEditIssues(params);
        if (data?.status) {
          toast?.success(
            issueList?.selectedData?.isSave
              ? "issueList created SuccessFully"
              : "issueList updated SuccessFully"
          );

          let data = await handleIssue?.get(params);
          if (data?.status) {
            setIssueList({
              ...issueList,
              data: [...data?.data?.data],
              extra: { ...data?.extra },
              selectedData: {
                isLoading: false,
              },
            });
            setVisibleIssues(false);
          }
        } else {
          setIssueList({
            ...issueList,
            selectedData: {
              ...issueList?.selectedData,
              isLoading: false,
              errors: {
                ...initialIssues?.selectedData?.errors,
              },
            },
          });
          toast?.error(data?.message);
        }
      }
    },
    fetch: async (e) => {
      if (
        e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
        e.currentTarget.clientHeight
      ) {
        const params = {
          cursor: issueList?.extra?.cursor,
          // type: type,
        };
        console.log(params);
        let data = await handleIssue?.get(params);
        console.log("ggggggg", data);

        if (data?.status) {
          let alldata = [...issueList?.data];
          if (data?.data?.data?.length > 0) {
            data?.data?.data?.map((site, index) => {
              alldata?.push({ ...site });
            });
            setIssueList({
              ...issueList,
              data: alldata,
              extra: data?.extra,
            });
          } else {
            setIssueList({
              ...issueList,
            });
          }
        } else {
          setIssueList({
            ...issueList,
          });
        }
      }
    },
    delete: async () => {
      setIssueList({
        ...issueList,
        selectedData: {
          isLoading: true,
          ...issueList?.selectedData,
        },
      });
      let data = await IssuesDelete(issueList?.selectedData);
      if (data?.status) {
        toast?.success("Deleted Successfully");
        let data = await handleIssue?.get();
        if (data?.status) {
          setIssueList({
            ...issueList,
            data: [...data?.data?.data],
            extra: { ...data?.extra },
            selectedData: {
              isLoading: false,
            },
          });
          setvisibleDeleteIssues(false);
        }
      }
    },
  };
  useEffect(() => {
    (async () => {
      await handleIssue?.get();
    })();
  }, []);

  return (
    <>
      {VisibleIssues && (
        <EditInspectionIssues
          setVisibleIssues={setVisibleIssues}
          issueList={issueList}
          setIssueList={setIssueList}
          handle={handleIssue}
          formref={formref}
        />
      )}
      {visibleDeleteIssues && (
        <DeleteInspectionIssues
          setvisibleDeleteIssues={setvisibleDeleteIssues}
          issueList={issueList}
          setIssueList={setIssueList}
          handle={handleIssue}
          formref={formref}
        />
      )}
      <>
        {issueList?.isShimmer ? (
          <TextShimmer data={{ gap: 15, line: 20 }} />
        ) : (
          <>
            {issueList?.data?.length <= 0 ? (
              <div className="p-5">
                <div className="flex items-center justify-between">
                  <h1 className="font-bold text-2xl">Issues</h1>
                  <Button
                    onClick={handleIssue?.add}
                    className={
                      width > 767
                        ? "flex justify-center items-center py-3 border border-[#111111] h-[42px] ml-2"
                        : "flex justify-center items-center py-3  h-[32px]  ml-2"
                    }
                  >
                    Add Issues
                    <div
                      className={
                        width > 767
                          ? "bg-orange-500 p-2 rounded-md text-white ml-3"
                          : " text-white ml-3 w-[20px] h-[20px]"
                      }
                    >
                      <FaPlus
                        className={
                          width > 767 ? "" : "bg-orange-500 mt-1 rounded-md p-1"
                        }
                      />
                    </div>
                  </Button>
                </div>
                <div className="py-8 h-[35vh]">
                  <div className="h-full flex justify-center items-center flex-col bg-white">
                    <div className="py-2">
                      <AreasDefaultSvg />
                    </div>
                    <div className="">
                      No data available for displaying Issues
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="flex pb-4 items-center justify-between">
                  <div>
                    <div className="flex">
                      <button onClick={() => setIssueDetails(false)}>
                        <FaArrowLeft className="text-[#000] mr-3" />
                      </button>
                      <h1 className="font-bold text-2xl">Issues</h1>
                    </div>
                    <div>
                      <p className="text-gray-300">
                        This Area has{" "}
                        <span className="font-bold text-black">
                          {issueList?.data?.length}
                        </span>{" "}
                        unlogged issues{" "}
                      </p>
                    </div>
                  </div>
                  <div className="flex">
                    <Button
                      onClick={handleIssue?.add}
                      className="flex justify-center items-center py-3 border border-[#111111] h-[42px] ml-2"
                    >
                      Add Issues
                      <div className="bg-orange-500 p-2 rounded-md text-white ml-3">
                        <FaPlus />
                      </div>
                    </Button>
                  </div>
                </div>
                {issueList?.data?.length > 0 && (
                  <div
                    style={{ height: "400px", overflow: "auto" }}
                    onScroll={handleIssue?.fetch}
                    className="scroll-display-none "
                  >
                    <table className="w-full">
                      <thead className="bg-[#E8F3FF] text-center">
                        <tr className="text-left">
                          <th className=" px-4 py-3 w-[200px]">
                            <div className="flex items-center justify-start font-bold">
                              Task Name
                            </div>
                          </th>
                          <th className=" px-4 py-3 w-[200px]">
                            <div className="flex items-center justify-center font-bold">
                              Details
                            </div>
                          </th>
                          <th className=" px-4 py-3 w-[200px]">
                            <div className="flex items-center justify-center font-bold">
                              Actions
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {issueList?.data?.map((item, index) => (
                          <tr className="border-b border-[#F0F0F0]" key={index}>
                            <td className="px-4 py-3">
                              <div className="flex items-center justify-start cursor-pointer">
                                <span className="text-sm text-gray-400"></span>
                                &nbsp; &nbsp;
                                <span>
                                  {item?.hourly_rate_task?.length > 0
                                    ? item?.hourly_rate_task[0]?.name
                                    : item?.productivity_orders?.length > 0
                                    ? item?.productivity_orders[0]?.name
                                    : "-"}
                                </span>
                              </div>
                            </td>
                            <td className="px-4 py-3">
                              <div className="flex items-center justify-center">
                                {item?.issue}
                              </div>
                            </td>
                            <td className="px-4 py-3">
                              <div className="flex items-center justify-center cursor-pointer">
                                <button
                                  className="mx-2"
                                  onClick={() =>
                                    visibleIssuesModal(item, index)
                                  }
                                >
                                  <FaEdit />
                                </button>
                                <button
                                  className="mx-2"
                                  onClick={() => {
                                    visibleDeleteModal(item, index);
                                  }}
                                >
                                  <FaTrashAlt />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </>
    </>
  );
};
export default IssuesDetails;
