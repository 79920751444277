import React, { useState } from "react";
import { Input, Modal, Select } from "antd";
import { Button } from "antd";
import { Controller, useForm } from "react-hook-form";
import { Fragment } from "react";
import InputError from "../../../../components/InputError";
import { useEffect } from "react";
import { PayRollService } from "../../../../config/axiosUrl";

const CipqModal = ({ visible, onCancel, isEditing, editData, onSave }) => {
  const modalTitle = isEditing ? "Editing CIPQ" : "Creating CIPQ";

  const { control, handleSubmit, reset } = useForm({
    defaultValues: isEditing ? editData : {},
  });

  const [payrollCat, setPayrollCat] = useState([]);

  const getPayrollCategories = () => {
    PayRollService.get(
      `/api/v1/payroll/setting/get-payroll-category-data`
    ).then((res) => {
      setPayrollCat(res?.data?.data?.data);
    });
  };
  useEffect(() => {
    getPayrollCategories();
  }, []);

  useEffect(() => {
    if (visible && isEditing) {
      reset(editData);
      console.log("Resetting form with editData:", editData);
    } else {
      reset();
      console.log("Resetting form for new entry");
    }
  }, [visible, isEditing, reset, editData]);

  const onSubmit = (data) => {
    reset();
    onSave(data);
  };

  return (
    <Modal
      title={modalTitle}
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="item mt-5">
          <label htmlFor="name">Name</label>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input type="text" className="mt-3" {...field} />
            )}
          />
        </div>
        <div className="item mt-5">
          <label htmlFor="summary_column">Summary Column</label>
          <Controller
            name="summary_column"
            control={control}
            render={({ field }) => (
              <Input type="text" className="mt-3" {...field} />
            )}
          />
        </div>
        <div className="item mt-5">
          <label htmlFor="rate">Rate</label>
          <Controller
            name="rate"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="number"
                className="mt-3"
                id="rate"
                min="0"
              />
            )}
          />
        </div>
        <Controller
          name="payroll_category_id"
          control={control}
          render={({ field }) => (
            <div className="item mt-5">
              <label htmlFor="payroll_category_id">
                Select Payroll Category
              </label>
              <Select className="mt-3 w-full" {...field}>
                {payrollCat.map((category) => (
                  <Select.Option key={category.id} value={category._id}>
                    {category.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          )}
        />
        <div className="item mt-5">
          <label htmlFor="frequency">Frequency</label>
          <Controller
            name="frequency"
            control={control}
            render={({ field }) => (
              <Select className="mt-3 w-full" {...field}>
                <Select.Option value="1">Per Week</Select.Option>
              </Select>
            )}
          />
        </div>
        <div className="item mt-5 text-center">
          <Button htmlType="submit" className="btn btn-blue">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default CipqModal;
