import React, { useState } from "react";
import SiteManagerWorkersTable from "../../../shared/DataTable/SiteManagerWorkers";
import WorkersTableSiteManager from "../../../shared/DataTable/mobileTables/WorkersTableSiteManager";

const SiteManagerWorkers = () => {
  const [screenSize, setScreenSize] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
  }, []);
  return (
    <div>
      {screenSize < 768 ? (
        <WorkersTableSiteManager />
      ) : (
        <SiteManagerWorkersTable />
      )}
    </div>
  );
};

export default SiteManagerWorkers;
