import React from "react";
import { ToastContainer } from "react-toastify";

const Notify = (notify) => {
  return (
    <>
      <ToastContainer
        closeButton={false}
        position="bottom-right"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default Notify;
