import React, { useEffect, useState } from "react";
import BarChartTimeline from "../Charts/BarChartWorkerTimeline";
import { useParams } from "react-router-dom";
import { GetWorkerTimeline } from "../../../../../helper/worksite/worksiteDetails.js/timesheet";
import { TextShimmer } from "../../../../../components/shimmer/shimmer";

const WorkerTimeline = () => {
  const { id } = useParams();
  const { timesheet } = useParams();

  const initialTimeline = { data: [], isShimmer: true };

  const [timeline, setTimeline] = useState(initialTimeline);

  const handleWorkerTimeline = {
    get: async (getTimeline) => {
      const params = {
        worksheet_id: timesheet,
        date: "2023-06-19",
        worksite_id: id,
      };

      let data = await GetWorkerTimeline(params);
      if (data?.status) {
        setTimeline({
          data: [...data?.data?.data],
          isShimmer: false,
        });
      }
    },
  };

  useEffect(() => {
    (async () => {
      const params = {
        worksheet_id: timesheet,
      };
      let data = await handleWorkerTimeline?.get(params);
    })();
  }, []);

  return (
    <div>
      {timeline?.isShimmer ? (
        <TextShimmer data={{ gap: 15, line: 20 }} />
      ) : (
        <>
          <h1 className="px-8 py-3 text-2xl font-lexend font-semibold">
            Hours Spent across Tasks
          </h1>
          <div className="text-center">
            <span className="flex items-center">
              <p className="rotate-[270deg] text-[#A1A1A1]">Hours</p>
              <BarChartTimeline data={timeline} setData={setTimeline} />
            </span>
            <p className="text-[#A1A1A1]">Area</p>
          </div>
        </>
      )}
    </div>
  );
};

export default WorkerTimeline;
