import { Button, DatePicker, Select, Tabs } from "antd";
import RestoredTable from "../../../../../../shared/DataTable/restoredTable";
import WorkingTable from "../../../../../../shared/DataTable/workingTable";
import CompletedTable from "../../../../../../shared/DataTable/completedTable";
import ConfirmedTable from "../../../../../../shared/DataTable/confirmedTable";
import { FaPlus } from "react-icons/fa";
import { useState, useEffect, useCallback } from "react";
import MultySelectTable from "../../../../../../components/multySelect/multyselectTable";
import { ButtonSpinner } from "../../../../../../components/spinners";
import ClockInModal from "./clockInModal";
import MassAbsenseModal from "./massAbsenseModal";
import MassTransfer from "./transferModal";
import SignofModal from "./signoffModal";
import moment from "moment";
import { useParams } from "react-router-dom";
import {
  PayRollService,
  UserService,
  WorkService,
} from "../../../../../../config/axiosUrl";
import { ApiAuthorizaion } from "../../../../../../auth/appAuth";
import { CancelAll } from "../../../../../../helper/worksite/worksite";
import { toast } from "react-toastify";
import useWindowDimensions from "../../../../../../utils/routes/useWindowDimensions";
const Timesheets = ({ worksite, setWorksite, setWorkerType }) => {
  const { width } = useWindowDimensions();
  const id = useParams();
  const initialMultySelectValue = {
    options: [],
    isMultySelect: false,
    isMultySelectMassAbsense: false,
    isMultySelectMassTransfer: false,
    isMultySelecteMassSignOff: false,
    selectedOption: [],
    isLoading: false,
    isShimmer: true,
    title: "Roster Worker",
    searchBarTitle: "Search by Worker Name",
    buttonTitle: "Add",
  };

  const [multySelect, setMultySelect] = useState(initialMultySelectValue);
  const [loading, setLoading] = useState(false);
  const [clockInModal, setClockInModal] = useState(false);
  const [workerLength, setWorkerLength] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTab, setSelectedTab] = useState("1");
  const [absenseType, setAbsenseType] = useState();
  const [selectedOption, setSelectedOption] = useState(null);

  const [worksite1, setWorksite1] = useState([]);
  // const [selectedWorksites, setSelectedWorksites] = useState([]);

  const apiFunctions = {
    getWorkers: useCallback(() => {
      if (selectedDate) {
        WorkService.get(
          `/api/v1/worksite/get-worksite-data?date=${selectedDate}`
        ).then((res) => {
          setWorksite1(res?.data?.data?.data || []);
        });
      }
    }, [selectedDate]),
  };

  useEffect(() => {
    apiFunctions.getWorkers();
  }, [selectedDate]);
  // const [isAreaShow, setIsAreaShow] = useState(true)
  const items = [
    {
      key: "1",
      label: "Rostered",
      children: (
        <RestoredTable
          work_status={"Rostered"}
          setWorkerLength={setWorkerLength}
          worksite={worksite}
          setWorksite={setWorksite}
          selectedTab={selectedTab}
          setWorkerType={setWorkerType}
        />
      ),
    },
    {
      key: "2",
      label: "Working",
      children: (
        <WorkingTable
          work_status={"Working"}
          worksite={worksite}
          setWorksite={setWorksite}
          selectedTab={selectedTab}
          setWorkerType={setWorkerType}
        />
      ),
    },
    {
      key: "3",
      label: "Completed",
      children: (
        <CompletedTable
          work_status={"Complete"}
          selectedTab={selectedTab}
          setWorkerType={setWorkerType}
          worksite={worksite}
          setWorksite={setWorksite}
        />
      ),
    },
    // {
    //   key: "4",
    //   label: "Confirmed",
    //   children: <ConfirmedTable work_status={'Confirmed'} selectedTab={selectedTab} setWorkerType={setWorkerType} />,
    // },
  ];
  const onChange = (key) => {
    setSelectedTab(key);
    setWorksite({
      ...worksite,
      isShimmer: true,
    });
  };
  const GetWorkers = async (param) => {
    try {
      const authorization = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: ApiAuthorizaion(),
        },
      };
      let data = await UserService?.get(`/api/v1/personal/workers/get-lists`, {
        headers: authorization?.headers,
        params: param,
      });
      return data?.data;
    } catch (error) {
      return error;
    }
  };
  const GetAbsenseType = async () => {
    try {
      const authorization = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: ApiAuthorizaion(),
        },
      };

      let data = await PayRollService?.get(
        `/api/v1/setting/payroll/get-absences-data?type=common`,
        {
          headers: authorization?.headers,
          // params: param
        }
      );
      return data?.data;
    } catch (error) {
      return error;
    }
  };
  const getPayrollCategories = async (e) => {
    const param = { search: e?.target?.value, tab: 1 };
    if (param.search === undefined) {
      setLoading(true);
    }
    let data = await GetWorkers(param);
    if (data?.status) {
      setLoading(false);
      setMultySelect({
        ...multySelect,
        options: [...data?.data?.data],
        extra: data?.extra,
        isShimmer: false,
        isMultySelect: true,
        buttonTitle: "Add",
        isLoading: false,
        isMultySelectMassAbsense: false,
        isMultySelectMassTransfer: false,
        isMultySelecteMassSignOff: false,
        searchBarTitle: "Search by Worker Name",
        selectedOption: [],
        title: "Roster Worker",
      });
    }
  };
  const getPayrollCategoriesMassAbsense = async (e) => {
    const param = {
      search: e?.target?.value,
      tab: 1,
      roasted: 1,
      worksite_id: id?.id,
    };
    let response = await GetAbsenseType();
    if (response?.status) {
      setAbsenseType(response?.data?.data);
    }
    let data = await GetWorkers(param);
    if (data?.status) {
      setLoading(false);
      setMultySelect({
        ...multySelect,
        options: [...data?.data?.data],
        isShimmer: false,
        isMultySelectMassAbsense: true,
        isLoading: false,
        isMultySelectMassTransfer: false,
        isMultySelecteMassSignOff: false,
        selectedOption: [],
      });
    }
  };
  const getPayrollCategoriesMassTransfer = async (e) => {
    const param = {
      search: e?.target?.value,
      tab: 1,
      roasted: 1,
      worksite_id: id?.id,
    };
    let data = await GetWorkers(param);
    if (data?.status) {
      setLoading(false);
      setMultySelect({
        ...multySelect,
        options: [...data?.data?.data],
        isShimmer: false,
        isMultySelectMassTransfer: true,
        isLoading: false,
        isMultySelectMassAbsense: false,
        isMultySelecteMassSignOff: false,
        selectedOption: [],
      });
    }
  };
  const getPayrollCategoriesMassSignOff = async (e) => {
    const param = { search: e?.target?.value };
    let data = await GetWorkers(param);
    if (data?.status) {
      setLoading(false);
      setMultySelect({
        ...multySelect,
        options: [...data?.data?.data],
        isShimmer: false,
        isMultySelecteMassSignOff: true,
      });
    }
  };
  const openModal = () => {
    setMultySelect({
      ...multySelect,
      isMultySelect: true,
    });
  };
  const handleChange = async (value) => {
    if (value == "clockin") {
      setSelectedOption("clockin");
      setClockInModal(true);
    } else if (value == "cancel") {
      let res = await CancelAll(worksite);
      if (res?.status) {
        toast.success("Status change Successfully");
        setSelectedTab(2);
      }
      setSelectedOption(null);
    } else if (value == "absence") {
      setSelectedOption("absence");
      setMultySelect({
        ...multySelect,
        isMultySelectMassAbsense: true,
      });
    } else if (value == "transfer") {
      setSelectedOption("transfer");
      setMultySelect({
        ...multySelect,
        isMultySelectMassTransfer: true,
      });
    } else if (value == "signoff") {
      setSelectedOption("signoff");
      setMultySelect({
        ...multySelect,
        isMultySelecteMassSignOff: true,
      });
    }
  };
  const onClose = () => {
    setClockInModal(false);
    setSelectedOption(null);
  };
  const onCloseAbsense = () => {
    setSelectedOption(null);
    setMultySelect({
      ...multySelect,
      isMultySelectMassAbsense: false,
      isShimmer: true,
      options: [],
    });
  };
  const onCloseTransfer = () => {
    setSelectedOption(null);
    setMultySelect({
      ...multySelect,
      isMultySelectMassTransfer: false,
      isShimmer: true,
      options: [],
    });
  };
  const onCloseSignOff = () => {
    setSelectedOption(null);
    setMultySelect({
      ...multySelect,
      isMultySelecteMassSignOff: false,
      isShimmer: true,
      options: [],
    });
  };
  const onSelectDate = async (date, dateString) => {
    setSelectedDate(dateString);
  };
  return (
    <>
      {/* {isAreaShow ? <TimeSheetArea setIsAreaShow={setIsAreaShow} /> :
        <> */}
      <Tabs
        tabBarExtraContent={
          (selectedTab == "1" || selectedTab == "2") && (
            <div
              className={
                width > 500 ? "flex" : "flex w-[200px] ml-2 overflow-scroll"
              }
            >
              <div className="w-[42px] cursor-pointer">
                <DatePicker
                  type="date"
                  onChange={onSelectDate}
                  allowClear={false}
                  className="border border-[#111111]  w-[42px] h-[42px] mass-opt-date"
                  disabledDate={(current) =>
                    current && current < moment().startOf("day")
                  }
                />
              </div>{" "}
              <Select
                className="px-2 h-[42px] mass-opt"
                placeholder="Mass Options"
                style={{ width: "160px" }}
                onChange={handleChange}
                value={selectedOption}
                options={[
                  {
                    value: "clockin",
                    label: "Mass Clockin",
                    disabled:
                      selectedTab == "2" ||
                      selectedTab == "3" ||
                      selectedTab == "4"
                        ? true
                        : false,
                  },
                  {
                    value: "cancel",
                    label: "Cancel All",
                    disabled:
                      selectedTab == "1" ||
                      selectedTab == "3" ||
                      selectedTab == "4"
                        ? true
                        : false,
                  },
                  { value: "absence", label: "Mass Absence" },
                  {
                    value: "transfer",
                    label: "Mass Transfer",
                    disabled: selectedTab == "2",
                  },
                  // { value: 'signoff', label: 'Mass Sign off', disabled: selectedTab == '1' || selectedTab == '2' || selectedTab == '3' ? true : false },
                ]}
              />
              {selectedTab == "1" ? (
                loading ? (
                  <Button className="py-3 border border-[#111111] h-[42px]">
                    <ButtonSpinner />
                  </Button>
                ) : (
                  <Button
                    onClick={openModal}
                    className="flex justify-center items-center py-3 border border-[#111111] h-[42px]"
                  >
                    Roster Worker{" "}
                    <div className="bg-orange-500 p-2 rounded-md text-white ml-3">
                      <FaPlus />
                    </div>
                  </Button>
                )
              ) : (
                <></>
              )}
            </div>
          )
        }
        className="details-tab child-tab"
        defaultActiveKey={selectedTab}
        items={items}
        onChange={onChange}
      />
      {multySelect?.isMultySelect && (
        <MultySelectTable
          data={multySelect}
          setData={setMultySelect}
          search={getPayrollCategories}
          worksite={worksite}
          setWorksite={setWorksite}
          setWorkerLength={setWorkerLength}
          selectedDate={selectedDate}
        />
      )}
      {clockInModal && (
        <ClockInModal
          isOpen={clockInModal}
          onClose={onClose}
          workerLength={workerLength}
          setWorksite={setWorksite}
          worksite={worksite}
        />
      )}

      {multySelect?.isMultySelectMassAbsense && (
        <MassAbsenseModal
          onClose={onCloseAbsense}
          data={multySelect}
          setData={setMultySelect}
          search={getPayrollCategoriesMassAbsense}
          setWorksite={setWorksite}
          worksite={worksite}
          absenseType={absenseType}
        />
      )}

      {multySelect?.isMultySelectMassTransfer && (
        <MassTransfer
          onClose={onCloseTransfer}
          data={multySelect}
          setData={setMultySelect}
          search={getPayrollCategoriesMassTransfer}
          worksite={worksite}
          setWorksite={setWorksite}
        />
      )}

      {multySelect?.isMultySelecteMassSignOff && (
        <SignofModal
          onClose={onCloseSignOff}
          data={multySelect}
          setData={setMultySelect}
          search={getPayrollCategoriesMassSignOff}
          worksite={worksite}
          setWorksite={setWorksite}
        />
      )}
      {/* </>
      } */}
    </>
  );
};
export default Timesheets;
