import React from "react";
import { ApiAuthorizaion } from "../../../auth/appAuth";
import { WorkService } from "../../../config/axiosUrl";

const AddSiteManager = async (sitemanger) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      ids: sitemanger?.ids,
      worksite_id: sitemanger?.worksite_id,
      deleted_ids: sitemanger?.deleted_ids,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/assign-worksite-manager",
      params,
      authorization
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};

const GetWorksiteData = async (worksite) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: worksite?.id,
      worksite_id: worksite?.worksite_id,
      area_id: worksite?.area_id,
      from: worksite?.from,
      start_date: worksite?.start_date,
      date: worksite?.date,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/get-worksite-data-dashboard?dev=4",
      params,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

export { AddSiteManager, GetWorksiteData };
