import React, { useCallback, useState } from "react";
import { FaEdit, FaSearch, FaTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import Pagination, {
  throttler,
} from "../../../components/PaginationAPI/Pagination";
import { UserService, WorkService } from "../../../config/axiosUrl";
import { TextShimmer } from "../../../components/shimmer/shimmer";
import Confirmation from "../../../components/Confirmation";
import { toast } from "react-toastify";
import { TableDefaultPage } from "../../../components/default/defaultPage";
import { TableDefaultSvg } from "../../../assets/svg/tableDefaul";

const Designations = () => {
  const [records, setRecords] = useState({
    loading: true,
    data: [],
  });
  const [hit, setHit] = useState(true);
  const [search, setSearch] = useState("");

  const [deleteModal, setDeleteModal] = useState({
    data: {},
    open: false,
    isDeleting: false,
  });
  const getData = useCallback((res) => {
    setRecords((prev) => ({
      loading: false,
      data: res?.data?.data?.data,
    }));
  }, []);

  let deafaulPage = {
    disclaimer: "No Worker Designations Found",
    description: "Create Your First Worker Designations",
    buttonText: "Create New",
    svg: <TableDefaultSvg />,
    redirect: "/admin/settings/create-designations-template",
  };

  const toggleDelete = useCallback((item) => {
    setDeleteModal((prev) => ({
      ...prev,
      data: item || {},
      open: !prev.open,
    }));
  }, []);

  const deleteQA = useCallback(
    async (id) => {
      try {
        setDeleteModal((prev) => ({ ...prev, isDeleting: true }));

        const res = await UserService.post(
          "/api/v1/personal/workers/worker-designation/delete-worker-designation",
          { id }
        );

        if (res?.data?.status) {
          toast.success(res?.data?.message);

          // Update state to remove the deleted item
          setRecords((prev) => ({
            ...prev,
            data: prev.data.filter((item) => item.id !== id),
          }));

          toggleDelete(); // Close the modal
        } else {
          toast.error(res?.data?.message);
        }
      } catch {
        toast.error("Error Deleting Item");
      } finally {
        setDeleteModal((prev) => ({ ...prev, isDeleting: false }));
      }
    },
    [toggleDelete]
  );

  return (
    <div className="flex">
      <Confirmation
        open={deleteModal.open}
        onCancel={() => toggleDelete()}
        title="Are you sure want to delete this designation?"
        onConfirm={() => deleteQA(deleteModal.data?.id)}
        details={deleteModal.data?.name}
        isSubmitting={deleteModal.isDeleting}
      />
      <div className="bg-white mx-auto my-8 w-[80%]">
        {records.loading ? (
          <TextShimmer
            data={{
              line: 20,
              gap: 10,
            }}
          />
        ) : (
          <div>
            <div className="border border-[#111111] flex items-center p-1 rounded-md w-full lg:w-1/3 m-4 bg-white">
              <FaSearch className="ml-2" />
              <input
                placeholder="Search by Designation Name"
                className="h-[31px] bg-transparent text-sm w-full px-2"
                onChange={({ target: { value } }) => {
                  throttler(setSearch)(value);
                }}
              />
            </div>
            <table className="w-full">
              <thead className="bg-[#E8F3FF] text-center">
                <tr className="text-left">
                  <th className="px-4 py-3">
                    <div className="flex items-center justify-start">
                      Designation Name
                    </div>
                  </th>
                  <th className="px-4 py-3">
                    <div className="flex items-center justify-center">
                      Actions
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {records.data.length > 0 ? (
                  records.data.map((item) => (
                    <tr className="border-b border-[#F0F0F0]" key={item._id}>
                      <td className="px-4 py-3">
                        <div className="flex items-center justify-start">
                          {item.name}
                        </div>
                      </td>
                      <td className="px-4 py-3">
                        <div className="flex items-center justify-center">
                          <Link
                            to={`/admin/settings/edit-designations-template/${item._id}`}
                            state={{ designation: item }} // Pass the designation data
                          >
                            <button className="mx-2">
                              <FaEdit title="Edit" />
                            </button>
                          </Link>

                          <button
                            className="mx-2"
                            onClick={() => toggleDelete(item)}
                          >
                            <FaTrashAlt title="Delete" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2" className="text-center py-4">
                      <TableDefaultPage data={deafaulPage} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
        <Pagination
          api={
            "/api/v1/personal/workers/worker-designation/get-worker-designation"
          }
          apiService={UserService}
          getRes={getData}
          dependecy={[hit, search]}
          extraParams={{ search }}
        />
      </div>
    </div>
  );
};

export default Designations;
