import React from 'react'

const RosterDefaultImage = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="71.732" height="94.146" viewBox="0 0 71.732 94.146">
      <g id="Group_74728" data-name="Group 74728" transform="translate(-754 -526.817)">
        <path id="Subtraction_1" data-name="Subtraction 1" d="M3050.732,8805.146H2979v-8.965a22.415,22.415,0,0,1,22.416-22.416h26.9a22.415,22.415,0,0,1,22.416,22.416v8.964Zm-35.867-40.349a26.813,26.813,0,1,1,10.471-2.113A26.731,26.731,0,0,1,3014.865,8764.8Zm-10.364-30.614a4.5,4.5,0,1,0,0,9h21a4.5,4.5,0,1,0,0-9Z" transform="translate(-2225 -8184.184)" fill="#efefef" />
      </g>
    </svg>
  )
}
export default RosterDefaultImage
