import Header from "./Tabs/Header";
import AllowanceRoutes from "./Tabs/AllowanceRoutes";

const Allowances = () => (
  <div className="p-6">
    <Header />
    <AllowanceRoutes />
  </div>
);

export default Allowances;
