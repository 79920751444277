import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useParams } from "react-router-dom";
import {
  ChangeWorkerStatusConfirm,
  ChangeWorkerStatusTerminate,
  GetWorkersType,
} from "../../helper/worksite/worksite";
import { TextShimmer } from "../../components/shimmer/shimmer";
import { TableDefaultPage } from "../../components/default/defaultPage";
import { Fileurl } from "../../config/url";
import DefaultCree8Img from "../../assets/images/Default/defaulCree8.png";
import { Button, Modal, Popover } from "antd";
import { toast } from "react-toastify";
import CompletedWorkerDefaultImg from "../../assets/svg/completedWorkerDefaultImg";
import TransferModal from "./transferModal";
import { ButtonSpinner } from "../../components/spinners";
import CreatingWorkShiftModal from "./CreatingWorkShiftModal";
import SplitShiftModal from "./splitShiftModal";
import TaskAllowanceModal from "./taskAllowanceModal";
import { formatTime } from "./timeUtils";
const CompletedTable = ({
  work_status,
  selectedTab,
  setWorkerType,
  worksite,
  setWorksite,
}) => {
  console.log("the completed worker site is", worksite);
  let id = useParams();
  let deafaulPage = {
    disclaimer: "No worker has completed the work",
    redirect: "",
    svg: <CompletedWorkerDefaultImg />,
    tab: true,
  };
  const [selectedItem, setSelectedItem] = useState();
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [openTerminateModal, setOpenTerminateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [assignTaskModal, setAssignTask] = useState(false);
  const [isSplit, setIsSplit] = useState(false);
  const [taskAllowance, setTaskAllowance] = useState(false);
  const [selectedTaskArr, setSelectedTaskArr] = useState([]);
  const [taskAllowData, setTaskAllowData] = useState([]);
  const [displayTextWor, setDisplayTextWor] = useState(false);
  // console.log("the completed text", displayTextWor);
  useEffect(() => {
    setDisplayTextWor(false);
  }, []);

  const handleWorksite = {
    getWorkers: async (work_status) => {
      let data = await GetWorkersType({ ...worksite, type: work_status });
      if (data?.status) {
        setWorksite({
          ...worksite,
          data: data?.data?.data,
          extra: data?.extra,
          worker_status: work_status,
          isShimmer: false,
          cursor: "",
        });
        return data;
      } else {
        setWorksite({
          ...worksite,
          isShimmer: false,
        });
        return data;
      }
    },
  };
  const saveChanges = async () => {
    setIsLoading(true);
    let data = await ChangeWorkerStatusTerminate(selectedItem, worksite);
    if (data?.status) {
      toast.success(data?.message ? data?.message : "Terminate Successfully");
      setOpenTerminateModal(false);
      const param = { type: work_status, id: id?.id };
      const res = await GetWorkersType(param);
      if (res?.status) {
        setWorksite({
          ...worksite,
          data: res?.data?.data,
        });
      }
    }
  };
  const transferWorker = async () => {
    setOpenTransferModal(true);
  };
  const terminateWorker = async () => {
    setOpenTerminateModal(true);
  };
  const confirmWorker = async () => {
    let data = await ChangeWorkerStatusConfirm(selectedItem, worksite);
    if (data?.status) {
      toast.success("Status change successfully");
      handleWorksite?.getWorkers(work_status);
    }
  };
  const splitShift = () => {
    setIsSplit(true);
  };
  const closeSplitShift = () => {
    setIsSplit(false);
  };
  const taskAllowanceOpen = () => {
    setTaskAllowance(true);
  };
  const closeTaskAllowance = () => {
    setTaskAllowance(false);
  };
  useEffect(() => {
    if (selectedTab == "3") {
      setWorkerType("Complete");
      handleWorksite?.getWorkers("Complete");
    }
  }, [selectedTab]);
  const assignTaskWorker = async () => {
    setAssignTask(true);
  };
  const content = (
    <div>
      {/* <p className="px-4 py-2 cursor-pointer border-b-2" onClick={transferWorker}>Transfer</p> */}
      <p className="px-4 py-2 cursor-pointer" onClick={assignTaskWorker}>
        Confirm work
      </p>
    </div>
  );
  const confirmedContent = (
    <div>
      <p className="px-4 py-2 cursor-pointer" onClick={terminateWorker}>
        Terminate
      </p>
    </div>
  );
  const onCloseTransefer = () => {
    setOpenTransferModal(false);
  };
  const onClose = () => {
    setOpenTerminateModal(false);
  };

  const closeAssignTaskWorker = async () => {
    setAssignTask(false);
  };

  let hourlyRateTask = worksite?.data?.reduce((accumulator, item) => {
    if (item.hourly_rate_task && item.hourly_rate_task.length > 0) {
      accumulator.push(...item.hourly_rate_task.map((value) => value.name));
    }
    return accumulator;
  }, []);

  let productivityOrders = worksite?.data?.reduce((accumulator, item) => {
    if (item.productivity_orders && item.productivity_orders.length > 0) {
      accumulator.push(...item.productivity_orders.map((value) => value.name));
    }
    return accumulator;
  }, []);

  let finalWork;
  if (hourlyRateTask && productivityOrders) {
    finalWork = hourlyRateTask.concat(productivityOrders).join(", ");
  }
  return (
    <>
      {worksite?.isShimmer ? (
        <div className="py-6 px-4 ">
          <TextShimmer data={{ gap: 15, line: 20 }} />
        </div>
      ) : worksite?.data?.length > 0 ? (
        <div className="relative">
          {/* <div className="absolute top-[-65px] w-1/2 right-0 flex justify-end items-center gap-4">
        <Select defaultValue="Mass Options" className="border-black">
          <Select.Option value="Mass Options">Mass Options</Select.Option>
        </Select>
        <Button className="flex justify-center items-center p-1 pl-3 border border-[#d4d3d3]">
          Roaster Worker{" "}
          <div className="bg-orange-500 p-1 rounded-md text-white ml-3">
            <FaPlus />
          </div>
        </Button>
      </div> */}
          <table className="w-full">
            <thead className="bg-[#E8F3FF] text-center">
              <tr className="text-left">
                <th className=" px-4 py-3 ">
                  <div className="flex items-center justify-start">
                    Worker Name
                  </div>
                </th>
                <th className=" px-4 py-3 ">
                  <div className="flex items-center justify-center"></div>
                </th>
                <th className=" px-4 py-3 ">
                  <div className="flex items-center justify-center">Action</div>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {worksite?.data?.length > 0 &&
                worksite?.data?.map((item, index) => (
                  <tr key={index} className="border-b border-[#F0F0F0]">
                    <td className="px-4 py-3">
                      <div className="flex items-center justify-start">
                        <div className="relative text-center mr-[10px]">
                          <img
                            src={
                              item?.users_profile_details?.data
                                ? Fileurl + item?.users_profile_details?.data
                                : DefaultCree8Img
                            }
                            alt=""
                            className="w-[50px] h-[50px] rounded mx-auto"
                          />
                          {item?.split_shifts?.length > 0 &&
                            item?.timesheet_status != "Confirmed" && (
                              <span className="text-[11px] text-[#FFA200] bg-[#FFA20021] border border-[#FFA200] px-[6px] py-[2px] relative top-[-15px]">
                                Split Shift
                              </span>
                            )}
                          {item?.timesheet_status == "Confirmed" && (
                            <span className="text-[11px] text-[#41C700] bg-[#D9F4CC] border border-[#41C700] px-[6px] py-[2px] relative top-[-15px]">
                              confirmed
                            </span>
                          )}
                        </div>
                        <div>
                          <p className="font-medium">
                            {item?.users.length > 0 ? item.users[0].name : "-"}
                          </p>
                          <p className="text-[10px] text-[#707070]">
                            {finalWork ? `Work: ${finalWork}` : ""}
                            {/* {item.work.map((item, index) => (
                        <span key={index} className="text-[#0F4C7D]">
                          {item}
                        </span>
                      ))} */}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-4 py-3">
                      <div className="flex items-center justify-center">
                        <div className="text-center mx-5">
                          <p className="text-lg">Started</p>
                          <p className="text-lg text-[#A1A1A1]">
                            {item?.started && formatTime(item?.started)}
                          </p>
                        </div>
                        <div className="text-center mx-5">
                          <p className="text-lg">Finished</p>
                          <p className="text-lg text-[#A1A1A1]">
                            {/* {item?.finished} */}
                            {item?.finished && formatTime(item?.finished)}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-4 py-3">
                      {item?.timesheet_status == "Confirmed" ? (
                        <Popover
                          placement="left"
                          content={confirmedContent}
                          trigger="click"
                        >
                          <FaPlus
                            className="text-sm border-2 p-1 border-black w-[25px] h-[25px] flex justify-center items-center rounded-full cursor-pointer marginAuto"
                            onClick={() => setSelectedItem(item)}
                          />
                        </Popover>
                      ) : (
                        <Popover
                          placement="left"
                          content={content}
                          trigger="click"
                        >
                          <FaPlus
                            className="text-sm border-2 p-1 border-black w-[25px] h-[25px] flex justify-center items-center rounded-full cursor-pointer marginAuto"
                            onClick={() => (
                              setSelectedItem(item), setDisplayTextWor(false)
                            )}
                          />
                        </Popover>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <TableDefaultPage data={deafaulPage} />
      )}
      <TransferModal
        isOpen={openTransferModal}
        onClose={onCloseTransefer}
        worksite={worksite}
        setWorksite={setWorksite}
        selectedItem={selectedItem}
      />
      <Modal
        title="Are You Sure To Terminate This Worker"
        className="clockin-modal"
        open={openTerminateModal}
        onCancel={onClose}
        footer={[
          <Button
            key="save"
            onClick={saveChanges}
            className="btn btn-blue bg-blue-disable"
          >
            {isLoading ? <ButtonSpinner /> : "Terminate"}
          </Button>,
        ]}
      >
        <p className="mt-7">
          Name :{" "}
          <span className="text-[#A1A1A1] ml-8">
            {selectedItem?.users.length > 0 ? selectedItem.users[0].name : "-"}
          </span>
        </p>
      </Modal>
      {assignTaskModal && (
        <CreatingWorkShiftModal
          assignTaskModal={assignTaskModal}
          closeAssignTaskWorker={closeAssignTaskWorker}
          selectedItem={selectedItem}
          isComplted={true}
          handleWorksite={handleWorksite}
          shiftOpen={splitShift}
          isSplit={isSplit}
          displayTextWor={displayTextWor}
          taskAllowanceOpen={taskAllowanceOpen}
          selectedTaskArr={selectedTaskArr}
          setSelectedTaskArr={setSelectedTaskArr}
          taskAllowData={taskAllowData}
          setTaskAllowData={setTaskAllowData}
        />
      )}
      {isSplit && (
        <SplitShiftModal
          setDisplayTextWor={setDisplayTextWor}
          closeModal={closeSplitShift}
          selectedItem={selectedItem}
          getWorkersList={() => handleWorksite?.getWorkers(work_status)}
        />
      )}
      {taskAllowance && (
        <TaskAllowanceModal
          closeModal={closeTaskAllowance}
          selectedTaskArr={selectedTaskArr}
          setSelectedTaskArr={setSelectedTaskArr}
          setTaskAllowData={setTaskAllowData}
          taskAllowData={taskAllowData}
        />
      )}
    </>
  );
};

export default CompletedTable;
