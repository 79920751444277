import { Button, Checkbox, Input, Select } from "antd";
import React from "react";
import { ButtonSpinner } from "../../../../../components/spinners";

const absenceTypes = [
  {
    name: "RDO",
    value: 1,
  },
  {
    name: "PH Public Holiday",
    value: 2,
  },
  {
    name: "PL Personal Leave(Sick)",
    value: 3,
  },
  {
    name: "AL Anual Leave",
    value: 4,
  },
  {
    name: "LWP Leave Without Pay",
    value: 5,
  },
];

const AbsenceForm = ({ data, setData, handle, errors }) => {
  return (
    <div>
      <form>
        <div className="">
          <label className="block font-normal">Name</label>
          <Input
            id="name"
            className={
              data?.errors?.name
                ? "mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "mt-4"
            }
            name="name"
            ref={errors?.current["name"]}
            value={data?.name}
            onChange={handle?.get}
          />
          {data?.errors?.name && (
            <p className="pt-2 text-red-600 text-sm">{data?.errors?.name}</p>
          )}
        </div>
        <div className="mt-5">
          <label className="block font-normal">Absence Accrued Name</label>
          <Input
            id="absence-accrued-name"
            className={
              data?.errors?.absence_accrued_name
                ? "mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "mt-4"
            }
            name="absence_accrued_name"
            ref={errors?.current["absence_accrued_name"]}
            value={data?.absence_accrued_name}
            onChange={handle?.get}
          />
          {data?.errors?.absence_accrued_name && (
            <p className="pt-2 text-red-600 text-sm">
              {data?.errors?.absence_accrued_name}
            </p>
          )}
        </div>
        <div className="mt-5">
          <label className="block font-normal">Absence Taken Name</label>
          <Input
            id="absence-taken-name"
            className={
              data?.errors?.absence_taken_name
                ? "mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "mt-4"
            }
            name="absence_taken_name"
            ref={errors?.current["absence_taken_name"]}
            value={data?.absence_taken_name}
            onChange={handle?.get}
          />
          {data?.errors?.absence_taken_name && (
            <p className="pt-2 text-red-600 text-sm">
              {data?.errors?.absence_taken_name}
            </p>
          )}
        </div>
        <div className="mt-5">
          <label className="block font-normal">Absence Type</label>
          {/* <Input
            id="absence-taken-name"
            className={
              data?.errors?.absence_taken_name
                ? "mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "mt-4"
            }
            name="absence_taken_name"
            ref={errors?.current["absence_taken_name"]}
            value={data?.absence_taken_name}
            onChange={handle?.get}
          /> */}
          <Select
            className="w-full"
            value={data.type}
            onChange={(value) => {
              handle.get({
                target: {
                  name: "type",
                  value,
                },
              });
            }}
          >
            {absenceTypes.map((item) => (
              <Select.Option value={item.value}>{item.name}</Select.Option>
            ))}{" "}
          </Select>
          {data?.errors?.absence_taken_name && (
            <p className="pt-2 text-red-600 text-sm">
              {data?.errors?.absence_taken_name}
            </p>
          )}
        </div>

        <div className="mt-5">
          <label className="block font-normal">Default Duration</label>
          <Input
            type="text"
            className={
              data?.errors?.default_duration
                ? "mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "mt-4"
            }
            name="default_duration"
            ref={errors?.current["default_duration"]}
            value={data?.default_duration}
            onWheel={(e) => e.target.blur()}
            onChange={handle?.getNumbers}
          />
          {data?.errors?.default_duration && (
            <p className="pt-2 text-red-600 text-sm">
              {data?.errors?.default_duration}
            </p>
          )}
        </div>
        {/* <div className="mt-5">
          <label className="block font-normal">Default Duration</label>
          <Input
            type="text"
            className={
              data?.errors?.default_duration
                ? "mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "mt-4"
            }
            name="default_duration"
            ref={errors?.current["default_duration"]}
            value={data?.default_duration}
            onWheel={(e) => e.target.blur()}
            onChange={handle?.getNumbers}
          />
          {data?.errors?.default_duration && (
            <p className="pt-2 text-red-600 text-sm">
              {data?.errors?.default_duration}
            </p>
          )}
        </div> */}
        <div className="mt-5">
          <label className="block font-normal">Accrual Method</label>
          <Input
            className={
              data?.errors?.accrual_method
                ? "mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "mt-4"
            }
            name="accrual_method"
            ref={errors?.current["accrual_method"]}
            value={data?.accrual_method}
            onChange={handle?.get}
          />
          {data?.errors?.accrual_method && (
            <p className="pt-2 text-red-600 text-sm">
              {data?.errors?.accrual_method}
            </p>
          )}
        </div>
        <div className="mt-5">
          <label className="block font-normal">Required Weekday Hours</label>
          <Input
            type="text"
            className={
              data?.errors?.required_weekday_hours
                ? "mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "mt-4"
            }
            name="required_weekday_hours"
            ref={errors?.current["required_weekday_hours"]}
            value={data?.required_weekday_hours}
            onWheel={(e) => e.target.blur()}
            onChange={handle?.getNumbers}
          />
          {data?.errors?.required_weekday_hours && (
            <p className="pt-2 text-red-600 text-sm">
              {data?.errors?.required_weekday_hours}
            </p>
          )}
        </div>
        <div className="mt-5">
          <label className="block font-normal">Required Weekend Hours</label>
          <Input
            type="text"
            className={
              data?.errors?.required_weekend_hours
                ? "mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "mt-4"
            }
            name="required_weekend_hours"
            ref={errors?.current["required_weekend_hours"]}
            value={data?.required_weekend_hours}
            onWheel={(e) => e.target.blur()}
            onChange={handle?.getNumbers}
          />
          {data?.errors?.required_weekend_hours && (
            <p className="pt-2 text-red-600 text-sm">
              {data?.errors?.required_weekend_hours}
            </p>
          )}
        </div>
        <div className="mt-5">
          <label className="block font-normal">Daily Accrual</label>
          <Input
            className={
              data?.errors?.daily_accrual
                ? "mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "mt-4"
            }
            name="daily_accrual"
            ref={errors?.current["daily_accrual"]}
            value={data?.daily_accrual}
            onChange={handle?.get}
          />
          {data?.errors?.daily_accrual && (
            <p className="pt-2 text-red-600 text-sm">
              {data?.errors?.daily_accrual}
            </p>
          )}
        </div>
        <div className="mt-5">
          <label className="block font-normal">Summary Column</label>
          <Input
            className={
              data?.errors?.summary_column
                ? "mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "mt-4"
            }
            name="summary_column"
            ref={errors?.current["summary_column"]}
            value={data?.summary_column}
            onChange={handle?.get}
          />
          {data?.errors?.summary_column && (
            <p className="pt-2 text-red-600 text-sm">
              {data?.errors?.summary_column}
            </p>
          )}
        </div>
        <div className="mt-5">
          <div className="flex mb-2 justify-start items-center">
            <Checkbox
              id="c1"
              name="export_taken"
              checked={Boolean(data?.export_taken)}
              onChange={handle?.check}
            />
            <label className="ml-2" htmlFor="c1">
              Export Taken
            </label>
          </div>
          <div className="flex mb-2 justify-start items-center">
            <Checkbox
              id="c2"
              name="export_accrued"
              checked={Boolean(data?.export_accrued)}
              onChange={handle?.check}
            />
            <label className="ml-2" htmlFor="c2">
              Export Accrued
            </label>
          </div>
          <div className="flex mb-2 justify-start items-center">
            <Checkbox
              id="c3"
              name="track_as_entitlement"
              checked={Boolean(data?.track_as_entitlement)}
              onChange={handle?.check}
            />
            <label className="ml-2" htmlFor="c3">
              Track As Entitlement
            </label>
          </div>
          <div className="flex mb-2 justify-start items-center">
            <Checkbox
              id="c4"
              name="exclude_from_shift_cost"
              checked={Boolean(data?.exclude_from_shift_cost)}
              onChange={handle?.check}
            />
            <label className="ml-2" htmlFor="c4">
              Exclude From Shift Cost
            </label>
          </div>
          <div className="flex mb-2 justify-start items-center">
            <Checkbox
              id="c5"
              name="accrue_when_absent"
              checked={Boolean(data?.accrue_when_absent)}
              onChange={handle?.check}
            />
            <label className="ml-2" htmlFor="c5">
              Accrue When Absent
            </label>
          </div>
          <div className="flex mb-2 justify-start items-center">
            <Checkbox
              id="c6"
              name="export_without_job_number"
              checked={Boolean(data?.export_without_job_number)}
              onChange={handle?.check}
            />
            <label htmlFor="c6" className="ml-2">
              Export Without Job Number
            </label>
          </div>
        </div>

        <div className="mt-8 text-right">
          <Button
            type="button"
            className="btn btn-blue w-1/5 bg-blue-disable"
            disabled={data?.isLoading}
            onClick={handle?.submit}
          >
            {data?.isLoading ? <ButtonSpinner /> : data?.id ? "Update" : "Save"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AbsenceForm;
