import React from "react";
import { WorkService } from "../../../config/axiosUrl";
import { ApiAuthorizaion } from "../../../auth/appAuth";

const GetOverview = async (overviews) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      worksite_id: overviews?.worksite_id,
      date: overviews?.date,
    };

    let data = await WorkService?.post(
      "/api/v1/worksite/overview-data",
      params,
      authorization
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};
const GetOverviewTask = async (overviews) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };
    let data = await WorkService?.get(
      `/api/v1/worksite/task/task-overview?worksite_id=${overviews?.worksite_id}&from=${overviews?.from}&to=${overviews?.to}`,
      authorization
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};

export { GetOverview, GetOverviewTask };
