import React, { Fragment, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Select } from "antd";
import AllowanceTable from "./AllowanceTable";

const options = [
  {
    label: "Site Allowance",
    key: "site-allowance",
  },
  {
    label: "Worker Allowance",
    key: "worker-allowance",
  },
  {
    label: "Task Allowance",
    key: "task-allowance",
  },
  {
    label: "Payroll Category Allowance",
    key: "payroll-category-allowance",
  },
];

const api = {
  "site-allowance": {
    get: "api/v1/payroll/setting/get-archive-worksites-site-allowance",
    reactive: "api/v1/payroll/setting/reactive-worksites-site-allowance-by-id",
    path: "/admin/settings/allowances/site-allowance",
  },
  "worker-allowance": {
    get: "api/v1/payroll/setting/get-archive-workers-allowance",
    reactive: "api/v1/payroll/setting/reactive-workers-allowance-by-id",
    path: "/admin/settings/allowances/worker-allowance",
  },
  "task-allowance": {
    get: "api/v1/payroll/setting/get-archive-task-allowance",
    reactive: "api/v1/payroll/setting/reactive-task-allowance-by-id",
    path: "/admin/settings/allowances/task-allowance",
  },
  "payroll-category-allowance": {
    get: "api/v1/payroll/setting/get-archive-payroll-allowance",
    reactive: "api/v1/payroll/setting/reactive-payroll-allowance-by-id",
    path: "/admin/settings/allowances/payroll-category-allowance",
  },
};

const Allowances = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState("site-allowance");
  console.log(currentPage);
  return (
    <Fragment>
      <div className="p-4 flex justify-between">
        <div className="border border-[#111111] flex items-center p-1 rounded-md w-full lg:w-1/3 my-2 bg-white">
          <FaSearch className="ml-2" />
          <input
            placeholder={`Search by ${
              options.find((item) => item.key === currentPage).label
            }`}
            className="h-[31px] bg-transparent text-sm w-full px-2"
            onChange={({ target: { value } }) => setSearch(value)}
          />
        </div>
        <div className="border border-black h-fit rounded-sm">
          <Select
            bordered={false}
            placeholder="Payroll Category Allowances"
            className="min-w-[200px]"
            value={currentPage}
            onChange={setCurrentPage}
          >
            {options.map((item) => (
              <Select.Option value={item.key}>{item.label}</Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <AllowanceTable
        getAPI={api[currentPage].get}
        reactiveAPI={api[currentPage].reactive}
        label={options.find((item) => item.key === currentPage).label}
        path={api[currentPage].path}
        search={search}
      />
    </Fragment>
  );
};

export default Allowances;
