import { Switch } from "antd";
import React, { useState } from "react";
import { FaCircle, FaInfoCircle } from "react-icons/fa";
import img from "../../../assets/images/Admin/immg.png";
import { Link } from "react-router-dom";
import { ButtonSpinner } from "../../../components/spinners";
import DefaultCree8Img from "../../../assets/images/Default/defaulCree8.png";
import { Fileurl } from "../../../config/url";

const WorksiteCards = ({ data, setData, handle }) => {
  return (
    <>
      {/* scroll-display-none */}
      <div
        className="h-[70vh] w-100 overflow-y-scroll scroll-display-none fixed"
        onScroll={handle?.fetch}
      >
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
          {data?.data?.map((item, index) => (
            <div
              className="bg-white p-7 rounded-lg border border-gray-200"
              key={index}
            >
              <div className="flex justify-between items-center">
                <button className="flex justify-center items-center border px-3 py-2 rounded-md border-green-500">
                  <span className="text-green-500 flex items-center">
                    <FaCircle className="mr-2" />{" "}
                    {item.ontime ? item.ontime : "On Time"}
                  </span>
                  <FaInfoCircle className="ml-2 text-xs" />
                </button>

                {/*    <span className="text-red-500 flex items-center">
                    {" "}
                    <FaCircle className="mr-2" /> On Time
                  </span> */}

                {data?.isLoadingId == item?.id ? (
                  <ButtonSpinner />
                ) : (
                  <Switch
                    className={
                      item?.status == 1
                        ? "switch-bg-color-active"
                        : "switch-bg-color-in-active "
                    }
                    checked={item?.status == 0 ? false : true}
                    onChange={(checked) =>
                      handle?.changeStatus(checked, item?.id)
                    }
                  />
                )}
              </div>

              <Link
                to={`/admin/worksite/details/overviews/${item?.id}`}
                state={{ data: item }}
                className="text-xl"
              >
                <div className="text-center my-4 ">
                  <div>
                    <img
                      src={
                        item?.picture
                          ? Fileurl + item?.picture
                          : DefaultCree8Img
                      }
                      alt="worksite"
                      className="mx-auto mb-3 circular-image"
                    />
                  </div>

                  {item?.name ? item?.name : "N/A"}

                  <table className="mt-5 w-full">
                    <tbody>
                      <tr>
                        <td>
                          <p className="text-gray-500 text-sm">
                            {" "}
                            {item?.worksite_managers?.length == 1
                              ? "Site Manager"
                              : item?.worksite_managers?.length > 1
                              ? "Site Managers"
                              : "Site Manager"}
                          </p>
                        </td>
                        <td>
                          <p className="text-gray-500 text-sm">Cost</p>
                        </td>
                        <td>
                          <p className="text-gray-500 text-sm">Job Number</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="">
                            {item?.worksite_managers?.length > 0
                              ? item?.worksite_managers?.length
                              : "N/A"}
                          </p>
                        </td>
                        <td>
                          <p className="">
                            $
                            {item?.project_cost
                              ? Number(item?.project_cost).toLocaleString(
                                  "en-AU"
                                )
                              : "0"}
                          </p>
                        </td>
                        <td>
                          <p className="">
                            {item?.job_no ? item?.job_no : "N/A"}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <hr className="my-4" />

                  <table className="w-full tttable">
                    <tbody>
                      <tr>
                        <td>
                          <p className="text-gray-500 text-sm">Rostered</p>
                        </td>
                        <td>
                          <p className="text-gray-500 text-sm">Working</p>
                        </td>
                        <td>
                          <p className="text-gray-500 text-sm">Finished</p>
                        </td>
                        <td>
                          <p className="text-gray-500 text-sm">Absent</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="">
                            {item?.rostered ? item?.rostered : "0"}
                          </p>
                        </td>
                        <td>
                          <p className="">
                            {item.working ? item.working : "0"}
                          </p>
                        </td>
                        <td>
                          <p className="">
                            {item.finished ? item.finished : "0"}
                          </p>
                        </td>
                        <td>
                          <p className="">{item.absent ? item.absent : "0"}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Link>
            </div>
          ))}
        </div>
        {data?.isFetchLoader && (
          <div className="py-5">
            <ButtonSpinner />
          </div>
        )}
      </div>
    </>
  );
};

export default WorksiteCards;
