import React from 'react'

const AdminPayroll = () => {
    return (
        <div className='p-6'>
            Admin Payroll
        </div>
    )
}

export default AdminPayroll