import React from "react";
import Stacked from "./stackedChart";
import LineChartInfo from "./LineChartInfo";

const Line = ({ data, setData }) => {
  return (
    <div className="lg:flex justify-between items-center">
      <div className="lg:w-9/12">
        <Stacked data={data} setData={setData} />
      </div>
      <div className="lg:w-3/12">
        <LineChartInfo data={data} setData={setData} />
      </div>
    </div>
  );
};

export default Line;
