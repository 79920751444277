import React, { useState } from 'react'
import { FaSearch } from "react-icons/fa";
import { Input } from "antd";

const HeaderSearch = ({ placeHolder, handle, value }) => {
    return (
        <div className="border py-1 px-4 rounded-md flex w-full justify-between items-center bg-white">
            <FaSearch />
            <Input
                placeholder={placeHolder}
                className="border-none outline-none bg-transparent p-0 ml-3"
                onChange={handle}
                value={value}
            />
        </div>
    )
}
export default HeaderSearch