import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineWarning } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import AreaDetails from "./areaDetails";
import { GetAreas } from "../../../../../../helper/worksite/worksiteDetails.js/areas";
import { TableDefaultPage } from "../../../../../../components/default/defaultPage";
import AreasDefaultSvg from "../../../../../../assets/svg/areasDefault";
import { NoSearchTableSvg } from "../../../../../../assets/svg/tableDefaul";
import { TextShimmer } from "../../../../../../components/shimmer/shimmer";
import IssuesDetails from "./issuesDetails";

const Areas = ({ tasks, setTasks, areas, setAreas, getInspection, setGetInspection, inspection, setInspection, setIssueDetails, isIssueDetails, details, setDetails }) => {
  let { id } = useParams();
  let deafaulPage = {
    disclaimer: "No areas Found",
    svg: <AreasDefaultSvg />,
  };
  let noSearchOption = {
    svg: <NoSearchTableSvg />,
    disclaimer: "There were no search results found",
    description: "Sorry, No Item Matches Your Search Term.",
  };

  const handleDetails = () => {
    setDetails(true);
  };
  const handleAreas = {
    getAllAreas: async (areasData) => {
      const params = {
        worksite_id: id,
        search: areasData?.search,
      };

      let getData = await GetAreas(params);
      if (getData?.status) {
        setAreas({
          ...areas,
          data: [...getData?.data?.data],
          extra: { ...getData?.extra },
          search: areasData?.search,
          isShimmer: false,
        });
      }
    },
  }
  const getAreaDetails = async () => {
    await handleAreas?.getAllAreas()
  }
  useEffect(() => {
    getAreaDetails()
  }, [])


  return (
    <>
      <div>
        {isIssueDetails ? <IssuesDetails setIssueDetails={setIssueDetails} /> : details ? (
          <AreaDetails tasks={tasks} setTasks={setTasks} getInspection={getInspection} setGetInspection={setGetInspection} inspection={inspection} setInspection={setInspection} setIssueDetails={setIssueDetails} />
        ) : (<>
          {areas?.isShimmer ? (
            <div className="p-4">
              <TextShimmer data={{ gap: 15, line: 15 }} />
            </div>
          ) : (
            <>
              {areas?.data?.length <= 0 && !areas?.search ? (
                <div className="py-8 px-4 h-[70vh]">
                  <TableDefaultPage data={deafaulPage} />
                </div>
              ) : (
                <>
                  <div className="pt-10">
                    <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5">
                      {areas?.data?.length > 0 && areas?.data?.map((areas) => (
                        <Link
                          to={`/site-manager/worksite/details/task/${id}/${areas._id}/1`}
                          key={areas?._id}
                          onClick={handleDetails}
                        >
                          <Card className="rounded-md border-[#E8E8E8]">
                            <p className="text-sm">{areas?.abbreviation
                              ? areas?.abbreviation
                              : "N/A"}</p>
                            <p className="text-xl mt-2 mb-4">  {areas?.name ? areas?.name : "N/A"}</p>
                            <p>{areas?.tasks} Tasks</p>
                            {areas?.tasks_without_alloc > 0 ? (
                              <p className="my-2 text-[#FE0C0C] flex justify-start items-center text-xs">
                                <AiOutlineWarning /> &nbsp;
                                {areas?.tasks_without_alloc
                                  ? areas?.tasks_without_alloc
                                  : ""}{" "}
                                Tasks Without Allocation
                              </p>
                            ) : (
                              <p className="my-4"></p>
                            )}
                            <p>{areas?.hours ? areas?.hours : "N/A"}{" "} Hours</p>
                            <p className="text-xs text-[#A1A1A1]">
                              Last 7 days hour worked Hours
                            </p>
                            <div className="grid lg:grid-cols-3 gap-4 mt-5">
                              <div className="text-center">
                                <p className="text-xs text-[#A1A1A1]">Worked</p>
                                <p className="text-sm">{areas?.worked}</p>
                              </div>
                              <div className="text-center">
                                <p className="text-xs text-[#A1A1A1]">Remaining</p>
                                <p className="text-sm">{areas?.remaining}</p>
                              </div>
                              <div className="text-center">
                                <p className="text-xs text-[#A1A1A1]">Allocated</p>
                                <p className="text-sm">{areas?.allocated}</p>
                              </div>
                            </div>

                            <div className="text-center p-2 w-full bg-[#D5E9FE] mt-5 mb-3">
                              <p className="text-xs">Productivity Order</p>
                            </div>

                            <div className="grid grid-cols-4">
                              <div className="text-center">
                                <p className="text-xs text-[#A1A1A1]">Total</p>
                                {areas?.total ? areas?.total : "0"}
                              </div>
                              <div className="text-center">
                                <p className="text-xs text-[#A1A1A1]">Approved</p>
                                {areas?.approved
                                  ? areas?.approved
                                  : "0"}
                              </div>
                              <div className="text-center">
                                <p className="text-xs text-[#A1A1A1]">Active</p>
                                {areas?.active
                                  ? areas?.active
                                  : "N/A"}
                              </div>
                              <div className="text-center">
                                <p className="text-xs text-[#A1A1A1]">Remaining</p>
                                <p className="text-sm">
                                  {areas?.remaining
                                    ? areas?.remaining
                                    : "0"}
                                </p>
                              </div>
                            </div>
                          </Card>
                        </Link>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </>)}
        </>)}
      </div>
    </>
  );
};

export default Areas;
