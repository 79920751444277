import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

function CustomToolTip({ active, payload, label }) {
  return (
    <div className="bg-[#F2F2F2] rounded-sm p-4 drop-shadow-lg shadow-lg">
      {payload.map((chartItem) => (
        <div className="flex flex-col gap-1">
          <p className="font-lexend font-bold text-sm">
            {chartItem.payload.name}
          </p>
          <p className="font-lexend text-sm">
            Workers: {chartItem.payload.workers}
          </p>
        </div>
      ))}
    </div>
  );
}

class CustomizedYAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={5}
          dx={-20}
          fontSize={14}
          textAnchor="middle"
          fill="#A1A1A1"
          fontFamily='"Lexend", sans-serif'
          transform="rotate(270)"
        >
          {payload.value}
        </text>
      </g>
    );
  }
}

class CustomizedXAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props;

    return (
      <g margin={10} transform={`translate(${x},${y})`}>
        <text
          x={-10}
          y={0}
          dy={5}
          dx={-20}
          fontSize={14}
          textAnchor="middle"
          fill="#A1A1A1"
          fontFamily='"Lexend", sans-serif'
          transform="rotate(270)"
        >
          {payload.value}
        </text>
      </g>
    );
  }
}

const BarChartWorkerBreakdown = ({ data }) => {
  const getData = [
    {
      time: "",
      hours: 0,
    },
  ];

  return (
    <ResponsiveContainer className={"barC"} width="100%" height="99.5%">
      <BarChart
        width={500}
        height={300}
        data={data?.data?.length > 0 ? data?.data : getData}
        margin={{
          top: 20,
          right: 30,
          left: 0,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="time"
          height={80}
          tickLine={false}
          axisLine={false}
          tick={<CustomizedXAxisTick />}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={<CustomizedYAxisTick />}
        />
        <Tooltip fill="transparent" content={<CustomToolTip />} />
        <Bar dataKey="workers" barSize={20} stackId="workers" fill="#FFA500" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartWorkerBreakdown;
