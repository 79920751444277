import { Collapse, Select } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FaAngleDown, FaAngleUp, FaSearch } from "react-icons/fa";
import { AiOutlineCheck } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { WorkService } from "../../../config/axiosUrl";
import { BiPlusCircle } from "react-icons/bi";
import InfiniteScroll from "react-infinite-scroll-component";
import { ButtonSpinner } from "../../../components/spinners";
import { throttler } from "../../../components/PaginationAPI/Pagination";
import DownloadQAReport from "../Worksite/DownloadQAReport";
import { toast } from "react-toastify";

const { Panel } = Collapse;

const QADetails = () => {
  const { worksite_id = "", area_id = "" } = useParams();
  const filterOptions = useMemo(
    () => [
      {
        value: "",
        label: "All",
      },
      {
        value: "productivity",
        label: "Productivity Orders",
      },
      {
        value: "hourly",
        label: "Hourly",
      },
    ],
    []
  );
  const [details, setDetails] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [quFilterType, setQaFilterType] = useState("");
  const [query, setQuery] = useState("");

  const getDetails = useCallback(() => {
    let cursor = details?.[details.length]?._id || "";

    WorkService.get(
      `/api/v1/worksite/quality-assurance/get-assigned-quality-assurance?area_id=${area_id}&worksite_id=${worksite_id}&search=${query}&cursor=${cursor}&type=${quFilterType}`
    ).then((res) => {
      let data = res?.data?.data?.data;

      data = data.map((item) => {
        item.totalAssurance = item?.quality_assurance_details?.length;
        item.completedAssurance = item?.quality_assurance_details.reduce(
          (acc, item) => {
            if (item.status) {
              return acc + 1;
            }
            return acc;
          },
          0
        );
        let currCons = item?.["productivity_orders"]?.length
          ? "productivity_orders"
          : "hourly_rate_task";
        item.current = item?.[currCons]?.[0];

        item.currType =
          currCons === "productivity_orders"
            ? "Productivity Order"
            : "Hourly Task";

        item.currentName = item.current?.name;

        return item;
      });

      setTotalItems(res?.data?.extra?.totalItems || 0);
      setDetails((prev) => data);
    });
  }, [area_id, worksite_id, quFilterType, query]);

  const toggleStatus = useCallback((id, childIndex, parentIndex) => {
    WorkService.post("/api/v1/worksite/quality-assurance/change-status", {
      id,
    }).then((res) => {
      if (res?.data?.status) {
        setDetails((prev) => {
          const currentQA = prev[parentIndex];
          currentQA.quality_assurance_details[childIndex].status =
            currentQA.quality_assurance_details[childIndex].status === 0
              ? 1
              : 0;
          prev[parentIndex] = currentQA;
          getDetails();
          return [...prev];
        });
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.message);
      }
    });
  }, []);

  const throttledGetDetails = useCallback(throttler(setQuery, 300), []);

  useEffect(() => {
    getDetails();
  }, [getDetails, quFilterType, query]);

  return (
    <div className="m-8">
      <div className="bg-white p-5 flex justify-between items-center">
        <div className="border border-[#111111] flex items-center p-1 rounded-md w-full lg:w-1/3 my-2 bg-white">
          <FaSearch className="ml-2" />
          <input
            placeholder="Search by Task or Site Allowance"
            className="h-[31px] bg-transparent text-sm w-full px-2"
            onChange={({ target: { value } }) => {
              throttledGetDetails(value);
            }}
          />
        </div>
        <div className="flex gap-5">
          <div className="flex items-center gap-1 border border-[#111111] h-[42px] p-3 rounded-md min-w-[250px]">
            <p className="text-sm text-[#A1A1A1]">Show:</p>
            <Select
              bordered={false}
              className="w-full"
              value={quFilterType}
              onChange={setQaFilterType}
            >
              {filterOptions.map((item) => (
                <Select.Option value={item.value}>{item.label}</Select.Option>
              ))}
            </Select>
          </div>
          {/* <Button className="flex justify-center items-center py-3 border border-[#111111] h-[42px]">
            Download QA Report
          </Button> */}
          <DownloadQAReport type="details" />
          {/* <Button className="flex justify-center items-center py-3 border border-[#111111] h-[42px]">
            Print
          </Button> */}
        </div>
      </div>

      <InfiniteScroll
        className=""
        dataLength={totalItems}
        next={getDetails}
        hasMore={totalItems !== details.length}
        loader={
          <div>
            <ButtonSpinner />
          </div>
        }
        height={"auto"}
        endMessage={<div></div>}
        // scrollableTarget="scrollableDiv"
      >
        {details.map((item, index) => {
          return (
            <div key={item._id} className="bg-white">
              <Collapse
                className="border-none p-0 my-[2px] "
                ghost
                accordion
                expandIcon={({ isActive }) =>
                  isActive ? <FaAngleUp /> : <FaAngleDown />
                }
              >
                <Panel
                  ghost
                  header={
                    <span className="inline-flex gap-8 p-4">
                      <p className="font-semibold text-lg">{`#${index + 1}`}</p>
                      <p className="font-semibold text-lg">
                        {item?.currentName}
                      </p>
                      <p className="font-semibold text-lg">{item.currType}</p>
                    </span>
                  }
                  style={{
                    margin: "5px",
                  }}
                  className="border-none p-0 m-[0px] "
                >
                  <span className="flex justify-between">
                    <p className="text-[#FE650C] font-semibold text-lg">
                      {item?.quality_assurance?.[0]?.name}
                    </p>
                    <p className="flex gap-1 text-[#A1A1A1]">
                      <p className="font-semibold text-black">
                        {item.completedAssurance}
                      </p>
                      Out of
                      <p className="font-semibold text-black">
                        {item.totalAssurance}
                      </p>
                      Quality Assurance Completed
                    </p>
                  </span>
                  <table className="text-center my-5 font-sm w-full">
                    <thead className="timesheet-detailed-table-head-container">
                      <tr className="timesheet-detailed-table-main-head">
                        <th>
                          <div className="font-semibold">Status</div>
                        </th>
                        <th>
                          <div className=" font-semibold text-center">Name</div>
                        </th>
                        <th>
                          <div className=" font-semibold">Approval</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="timesheet-detailed-table-body">
                      {item?.assigned_quality_assurance_details?.map(
                        (item, cIndex) => (
                          <tr className="border-b border-b-[#E9E9E9] ">
                            <td className="">
                              {item.status ? "Completed" : "Incomplete"}
                            </td>
                            <td>{item?.quality_assurance_details?.name}</td>
                            <td className="flex justify-center cursor-pointer">
                              <div
                                onClick={() =>
                                  toggleStatus(item._id, cIndex, index)
                                }
                              >
                                {item.status ? (
                                  <div
                                    className="bg-[#41C700] rounded-full w-fit p-[3px]
                        "
                                  >
                                    <AiOutlineCheck fill="white" />
                                  </div>
                                ) : (
                                  <BiPlusCircle />
                                )}
                              </div>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </Panel>
              </Collapse>
            </div>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

export default QADetails;
