import React from "react";
import { Route, Routes } from "react-router-dom";
import WorkerDetails from "./WorkerDetails";
import TerminatedWorker from "./TerminatedWorker";
import ActiveWorker from "./ActiveWorker";

const ProfileRoutes = (props) => {
  return (
    <Routes>
      <Route path="active" element={<ActiveWorker {...props} />} />
      <Route path="terminated" element={<TerminatedWorker {...props} />} />
      <Route path="worker-details" element={<WorkerDetails {...props} />} />
    </Routes>
  );
};

export default ProfileRoutes;
