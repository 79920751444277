import React, { useEffect, useRef, useState } from "react";
import logo from "../../../assets/images/Login/logo.png";
import {
  ApiAuthorizaion,
  LoggedInUser,
  setAuthUser,
  setLoggedInUser,
} from "../../../auth/appAuth";
import { ButtonSpinner } from "../../../components/spinners";
import { UserService } from "../../../config/axiosUrl";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../utils/routes/useWindowDimensions";

const Login = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const initialvalue = {
    email: "",
    password: "",
    error: {
      email: "",
      password: "",
    },
    isLoading: false,
  };
  const [login, setLogin] = useState(initialvalue);
  let email = useRef();
  let password = useRef();
  const handleLogin = {
    getLogin: (e) => {
      setLogin({
        ...login,
        [e?.target?.name]: e?.target?.value,
      });
    },
    emailValidate: (email) => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },
    submitLogin: async () => {
      if (!login?.email) {
        setLogin({
          ...login,
          error: {
            email: "Enter email",
            password: "",
          },
        });
        email.current.focus();
      } else if (!handleLogin?.emailValidate(login?.email)) {
        setLogin({
          ...login,
          error: {
            email: "Enter a valid email",
            password: "",
          },
        });
        email.current.focus();
      } else if (!login?.password) {
        setLogin({
          ...login,
          error: {
            email: "",
            password: "Enter password",
          },
        });
        password.current.focus();
      } else {
        setLogin({
          ...login,
          error: {
            email: "",
            password: "",
          },
          isLoading: true,
        });
        const params = {
          email: login?.email,
          password: login?.password,
        };
        let loginData = await UserService?.post(
          "/api/v1/auth/panel-login",
          params
        );
        if (loginData?.data?.status) {
          setLogin(initialvalue);
          console.log("the token", loginData?.data?.data?.token);

          UserService.get("/api/v1/auth/get-user-profile", {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-type": "Application/json",
              Authorization: loginData?.data?.data?.token,
            },
          }).then((res) => {
            setAuthUser(res?.data?.data?.data[0]);
            setLoggedInUser(loginData?.data?.data?.token);
            window.location.reload();
          });
          toast.success(loginData?.data?.message);
          navigate("/site-manager/dashboard");
        } else {
          toast.error(loginData?.data?.message);
          setLogin({
            ...login,
            error: {
              email: "",
              password: loginData?.message,
              isLoading: false,
            },
          });
        }
      }
    },
  };
  useEffect(() => {
    email.current.focus();
    setLogin({
      user: LoggedInUser(),
    });
    if (LoggedInUser() !== null) {
      navigate("/site-manager/dashboard");
    }
  }, [LoggedInUser()]);
  return (
    <div
      className={
        width > 767
          ? "site-manager-login md:min-h-screen bg-[#0F4C7D] text-white flex justify-center items-center h-screen"
          : "site-manager-login md:min-h-screen bg-[#FFF] text-white flex justify-center items-center h-screen"
      }
    >
      <div className="flex justify-between items-center w-full">
        <div className="lg:w-1/2 w-full text-center md:block hidden mr-4">
          <h2 className="font-medium text-3xl mb-[15px]">
            Building your dreams, one brick at a time
          </h2>
          <p>
            With 20 years of experience in the industry, We have built a solid{" "}
            <br />
            reputation for delivering high-quality projects on time.
          </p>
        </div>
        <div className="lg:w-1/2 w-full text-center md:mr-4">
          <div className="bg-white py-[40px] px-[30px] w-full">
            <div className="text-center">
              <img src={logo} alt="logo" className="mx-auto mb-[24px]" />
              <h2 className="text-[22px] text-black font-semibold">
                Welcome to AM8 Onsite
              </h2>
              <p className="text-[10px] text-[#a1a1a1]">
                Please enter your credentials to access your account.
              </p>
            </div>

            <form className="mt-[40px] text-left">
              <div className="mb-[20px]">
                <label
                  htmlFor="email"
                  className="block text-sm text-black mb-[10px]"
                >
                  E-mail
                </label>
                <input
                  autoComplete="off"
                  type="text"
                  name="email"
                  value={login?.email}
                  ref={email}
                  onChange={handleLogin?.getLogin}
                  id="email"
                  className={
                    login?.error?.email
                      ? "w-full border border-[#a1a1a1] rounded-[5px] py-[15px] px-[15px] text-[#a1a1a1] focus:outline-none border-rose-600 hover:border-rose-600"
                      : "w-full border border-[#a1a1a1] rounded-[5px] py-[15px] px-[15px] text-[#a1a1a1] focus:outline-none"
                  }
                  placeholder="mail@gmail.com"
                />
                {login?.error?.email && (
                  <p className="pt-2 text-red-600 text-sm">
                    {login?.error?.email}
                  </p>
                )}
              </div>
              <div className="mb-[20px]">
                <label
                  autoComplete="off"
                  htmlFor="password"
                  className="block text-sm text-black mb-[10px]"
                >
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  className={
                    login?.error?.password
                      ? "w-full border border-[#a1a1a1] rounded-[5px] py-[15px] px-[15px] text-[#a1a1a1] focus:outline-none border-rose-600 hover:border-rose-600"
                      : "w-full border border-[#a1a1a1] rounded-[5px] py-[15px] px-[15px] text-[#a1a1a1] focus:outline-none"
                  }
                  placeholder="Password"
                  ref={password}
                  value={login?.password}
                  onChange={handleLogin?.getLogin}
                  onKeyPress={(e) =>
                    e?.key == "Enter" && handleLogin?.submitLogin()
                  }
                />
                {login?.error?.password && (
                  <p className="pt-2 text-red-600 text-sm">
                    {login?.error?.password}
                  </p>
                )}
              </div>
              <div className="mb-[20px] text-right">
                <a
                  className="text-[#111] text-sm"
                  onClick={() => navigate("/reset-password")}
                >
                  Forgot Password?
                </a>
              </div>

              <div>
                <button
                  type="button"
                  className="bg-[#0F4C7D] text-white w-full py-[15px] rounded-[5px]"
                  onClick={handleLogin?.submitLogin}
                >
                  {login?.isLoading ? <ButtonSpinner /> : "Login"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
