import React from "react";

const ImageCancelSvg = ({ data }) => {
  let width = data?.width ? data?.width : "20";
  let height = data?.height ? data?.height : "20";

  return (
    <>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 512 512"
      >
        <path d="M232.5 1.1C124.6 11.1 34.3 88.2 8.1 192.7-26.6 330.6 55.9 469.5 193 504c105.4 26.5 215.5-15.6 275.7-105.5 57.6-86 57.6-198.9 0-285-18-26.9-43.3-52.2-70.2-70.2-48.3-32.4-108.1-47.6-166-42.2zm-57.3 145.5c1.8.9 20.7 19 42.1 40.2l38.7 38.6 38.8-38.6c21.3-21.2 40.3-39.4 42.2-40.4 2.2-1.1 5.7-1.8 9.5-1.9 12.1 0 21 8.9 21 21-.1 3.8-.8 7.3-1.9 9.5-1 1.9-19.2 20.9-40.4 42.2L286.6 256l38.6 38.7c21.2 21.4 39.4 40.4 40.4 42.3 1.1 2.2 1.8 5.7 1.9 9.5 0 12.1-8.9 21-21 21-3.8-.1-7.3-.8-9.5-1.9-1.9-1-20.9-19.2-42.2-40.4L256 286.5l-38.7 38.7c-21.4 21.2-40.4 39.4-42.3 40.4-2.2 1.1-5.7 1.8-9.5 1.9-12.1 0-21-8.9-21-21 .1-3.8.8-7.3 1.9-9.5 1-1.9 19.2-20.9 40.4-42.3l38.6-38.7-38.6-38.8c-21.2-21.3-39.4-40.3-40.4-42.2-1.1-2.2-1.8-5.7-1.9-9.5 0-9.7 5.8-17.5 15.3-20.6 3.7-1.2 11.4-.4 15.4 1.7z" />
      </svg>
    </>
  );
};

export { ImageCancelSvg };
