import { Button, Modal } from 'antd'
import React, { useState } from 'react'
import { AiOutlineInfoCircle } from "react-icons/ai";
import { ButtonSpinner } from '../../components/spinners'

const ApproveModal = ({ openApproveModal, onCloseApproveModal, approveWorker, isLoading }) => {
    return (
        <Modal title={`Approve shift details`} className="clockin-modal" open={openApproveModal} onCancel={onCloseApproveModal} footer={[
            <Button key="save" onClick={approveWorker} className="btn btn-blue mt-3 text-[14px] bg-blue-disable">
                {isLoading ? <ButtonSpinner /> :'Continue'}
            </Button>]}>
            <div>
                <span>This action is not reversible, Do you wish to continue?</span>
                <div className="w-full text-center font-[500] items-center flex justify-start mt-5">
                    <AiOutlineInfoCircle className=" text-[#ff0000] mr-1 text-[20px]" />
                    <p className='text-[#ff0000] text-[11px]'>Once the shift details have been approved, no adjustments can be made.
                    </p>
                </div>
            </div>
        </Modal>
    )
}

export default ApproveModal