import React from "react";
import { WorkService } from "../../../config/axiosUrl";
import { ApiAuthorizaion } from "../../../auth/appAuth";

const GetAllProductivityOrders = async (productivityOrder) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      worksite_id: productivityOrder?.worksite_id,
      type: productivityOrder?.type,
      cursor: productivityOrder?.cursor,
      skip: productivityOrder?.skip,
      search: productivityOrder?.search,
      area_id: productivityOrder?.area_id,
      status: productivityOrder?.status,
    };

    let data = await WorkService?.get("/api/v1/worksite/task/get-all-task", {
      headers: authorization?.headers,
      params: params,
    });
    return data?.data;
  } catch (error) {
    return error;
  }
};

const ExportCsv = async (productivityOrder) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      worksite_id: productivityOrder?.worksite_id,
      type: productivityOrder?.type,
      poStatus: productivityOrder?.poStatus,
    };

    let data = await WorkService?.get(
      "api/v1/worksite/area/get-areas-data-csv",
      {
        headers: authorization?.headers,
        params: params,
      }
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};

export { GetAllProductivityOrders, ExportCsv };
