import { Button, Checkbox, Input, Select } from "antd";
import React from "react";
import { TextShimmer } from "../../../../../components/shimmer/shimmer";
import { ButtonSpinner } from "../../../../../components/spinners";

const PayrollCategoryForm = ({ data, setData, handle, errors }) => {
  return (
    <form className="mt-4">
      <div className="lg:flex justify-between gap-4 items-center">
        <div className="lg:w-1/2 w-full">
          <label className="mb-2">Payroll Category</label>
          <Input
            type="text"
            id="name"
            className={
              data?.errors?.name
                ? "mt-3 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "mt-3"
            }
            ref={errors?.current["name"]}
            name="name"
            value={data?.name}
            onChange={handle?.get}
          />
          {data?.errors?.name && (
            <p className="pt-2 text-red-600 text-sm">{data?.errors?.name}</p>
          )}
        </div>
        <div className="lg:w-1/2 w-full">
          <label className="mb-2">Employement Status</label>
          <Select
            showSearch
            placeholder="Select Category"
            optionFilterProp="children"
            className="mt-3"
            ref={errors?.current["employement_status_id"]}
            status={data?.errors?.employement_status_id ? "error" : ""}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={data?.employee_status_options}
            value={data?.employement_status_id}
            onChange={(e) => handle?.getCategory(e, "employement_status_id")}
            onSearch={handle?.getEmployeeStatus}
          />
          {data?.errors?.employement_status_id && (
            <p className="pt-2 text-red-600 text-sm">
              {data?.errors?.employement_status_id}
            </p>
          )}
        </div>
      </div>
      <div className="lg:flex mt-3 justify-between gap-4 items-center">
        <div className="lg:w-1/2 w-full">
          <label className="mb-2">Hourly Rate Name</label>
          <Input
            type="text"
            id="hourly-rate-name"
            className={
              data?.errors?.hourly_rate_name
                ? "mt-3 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "mt-3"
            }
            ref={errors?.current["hourly_rate_name"]}
            name="hourly_rate_name"
            value={data?.hourly_rate_name}
            onChange={handle?.get}
          />
          {data?.errors?.hourly_rate_name && (
            <p className="pt-2 text-red-600 text-sm">
              {data?.errors?.hourly_rate_name}
            </p>
          )}
        </div>
        <div className="lg:w-1/2 w-full">
          <label htmlFor="hourly-rate" className="mb-2">
            Hourly Rate
          </label>
          <Input
            type="text"
            id="hourly-rate"
            className={
              data?.errors?.hourly_rate
                ? "mt-3 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "mt-3"
            }
            ref={errors?.current["hourly_rate"]}
            name="hourly_rate"
            value={data?.hourly_rate}
            onChange={handle?.get}
            onInput={(e) => {
              const value = e.target.value;
              if (value < 0) {
                e.target.value = 0;
              }
            }}
          />
          {data?.errors?.hourly_rate && (
            <p className="pt-2 text-red-600 text-sm">
              {data?.errors?.hourly_rate}
            </p>
          )}
        </div>
      </div>
      <div className="lg:flex mt-3 justify-between gap-4 items-center">
        <div className="lg:w-1/2 w-full">
          <label htmlFor="overtime-rate-name" className="mb-2">
            Overtime Rate Name
          </label>
          <Input
            type="text"
            id="overtime-rate-name"
            className={
              data?.errors?.overtime_rate_name
                ? "mt-3 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "mt-3"
            }
            ref={errors?.current["overtime_rate_name"]}
            name="overtime_rate_name"
            value={data?.overtime_rate_name}
            onChange={handle?.get}
          />
          {data?.errors?.overtime_rate_name && (
            <p className="pt-2 text-red-600 text-sm">
              {data?.errors?.overtime_rate_name}
            </p>
          )}
        </div>
        <div className="lg:w-1/2 w-full">
          <label htmlFor="overtime-rate" className="mb-2">
            Overtime Rate
          </label>
          <Input
            type="text"
            id="overtime-rate"
            className={
              data?.errors?.overtime_rate
                ? "mt-3 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "mt-3"
            }
            ref={errors?.current["overtime_rate"]}
            name="overtime_rate"
            value={data?.overtime_rate}
            onChange={handle?.get}
            onInput={(e) => {
              const value = e.target.value;
              if (value < 0) {
                e.target.value = 0;
              }
            }}
          />
          {data?.errors?.overtime_rate && (
            <p className="pt-2 text-red-600 text-sm">
              {data?.errors?.overtime_rate}
            </p>
          )}
        </div>
      </div>
      <div className="lg:flex mt-3 justify-between gap-4 items-center">
        <div className="lg:w-1/2 w-full">
          <label className="mb-2">Double Time Rate Name</label>
          <Input
            type="text"
            id="double_time_rate_name"
            className={
              data?.errors?.double_time_rate_name
                ? "mt-3 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "mt-3"
            }
            ref={errors?.current["double_time_rate_name"]}
            name="double_time_rate_name"
            value={data?.double_time_rate_name}
            onChange={handle?.get}
          />
          {data?.errors?.double_time_rate_name && (
            <p className="pt-2 text-red-600 text-sm">
              {data?.errors?.double_time_rate_name}
            </p>
          )}
        </div>
        <div className="lg:w-1/2 w-full">
          <label className="mb-2">Double Time Rate</label>
          <Input
            type="text"
            id="double_time_rate"
            className={
              data?.errors?.double_time_rate
                ? "mt-3 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "mt-3"
            }
            ref={errors?.current["double_time_rate"]}
            name="double_time_rate"
            value={data?.double_time_rate}
            onChange={handle?.get}
            onInput={(e) => {
              const value = e.target.value;
              if (value < 0) {
                e.target.value = 0;
              }
            }}
          />
          {data?.errors?.double_time_rate && (
            <p className="pt-2 text-red-600 text-sm">
              {data?.errors?.double_time_rate}
            </p>
          )}
        </div>
      </div>

      <div className="flex justify-start items-center mt-4">
        <Checkbox
          id="cost"
          name="is_cost_work_as_all_inclusive"
          onChange={handle?.check}
          checked={Boolean(data?.is_cost_work_as_all_inclusive)}
        />
        <label className="ml-2 text-sm text-blue" htmlFor="cost">
          Cost work as All Inclusive
        </label>
      </div>

      <div className="w-full mt-5">
        <label className="mb-2">Select Agreement</label>
        <Select
          showSearch
          placeholder="Select Category"
          optionFilterProp="children"
          className="mt-3 w-full"
          ref={errors?.current["agreement_id"]}
          status={data?.errors?.agreement_id ? "error" : ""}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={data?.agreement_options}
          value={data?.agreement_id}
          onChange={(e) => handle?.getCategory(e, "agreement_id")}
          onSearch={handle?.getAgreement}
        />
        {data?.errors?.agreement_id && (
          <p className="pt-2 text-red-600 text-sm">
            {data?.errors?.agreement_id}
          </p>
        )}
      </div>

      <div className="item mt-5 text-center">
        <Button
          className="btn btn-blue w-1/5 bg-blue-disable"
          disabled={data?.isLoading}
          onClick={handle?.submit}
        >
          {data?.isLoading ? <ButtonSpinner /> : data?.id ? "Update" : "Save"}
        </Button>
      </div>
    </form>
  );
};

export default PayrollCategoryForm;
