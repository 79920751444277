import { Button } from "antd";
import React, { useState } from "react";
import { ButtonSpinner } from "../../components/spinners";
import { useNavigate, useLocation, useParams } from "react-router-dom";

const TabBtns = ({ data, setData, handle, singleUser }) => {
  const navigate = useNavigate();
  const path = useLocation();
  const { type } = useParams();
  const [activeButton, setActiveButton] = useState(type);

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
    if (!singleUser) {
      handle?.getType(buttonType);
    }
  };

  return (
    <div className="tab-buttons flex justify-start items-center">
      {data?.status !== 3 && (
        <Button
          className={`flex justify-center items-center p-0 mr-3 h-[42px] w-32 ${
            activeButton === 1 ? "btn-blue" : ""
          }`}
          onClick={() => handleButtonClick(1)}
          disabled={data?.isTabLoading}
        >
          {singleUser ? (
            <span
              className={
                data?.status == 1
                  ? "block w-full py-3 lg:px-5 h-full btn-blue btn"
                  : "block w-full py-3 lg:px-5 h-full "
              }
            >
              {data?.status == 1 && data?.isLoading ? (
                <ButtonSpinner />
              ) : (
                "Active"
              )}
            </span>
          ) : (
            <span
              className={
                data?.pagination?.type == 1
                  ? "block w-full py-3 lg:px-5 h-full btn-blue btn"
                  : "block w-full py-3 lg:px-5 h-full "
              }
            >
              {data?.pagination?.type == 1 && data?.isTabLoading ? (
                <ButtonSpinner />
              ) : (
                "Active"
              )}
            </span>
          )}
        </Button>
      )}
      {data?.status !== 1 && (
        <Button
          className={`flex justify-center items-center p-0 mr-3 h-[42px] w-32 ${
            activeButton === 3 ? "btn-blue" : ""
          }`}
          onClick={() => handleButtonClick(3)}
          disabled={data?.isTabLoading}
        >
          {singleUser ? (
            <span
              className={
                data?.status == 3
                  ? "block w-full py-3 lg:px-5 h-full btn-blue btn"
                  : "block w-full py-3 lg:px-5 h-full "
              }
            >
              {data?.status == 3 && data?.isLoading ? (
                <ButtonSpinner />
              ) : (
                "Terminated"
              )}
            </span>
          ) : (
            <span
              className={
                data?.pagination?.type == 3
                  ? "block w-full py-3 lg:px-5 h-full btn-blue btn"
                  : "block w-full py-3 lg:px-5 h-full "
              }
            >
              {data?.pagination?.type == 3 && data?.isTabLoading ? (
                <ButtonSpinner />
              ) : (
                "Terminated"
              )}
            </span>
          )}
        </Button>
      )}

      {data?.status !== 3 && !singleUser ? (
        <Button
          className={`flex justify-center items-center p-0 mr-3 h-[42px] w-32 ${
            activeButton === "ALL" ? "btn-blue" : ""
          }`}
          onClick={() => handleButtonClick("ALL")}
          disabled={data?.isTabLoading}
        >
          <span
            className={
              data?.pagination?.type == "ALL"
                ? "block w-full py-3 lg:px-5 h-full btn-blue btn"
                : "block w-full py-3 lg:px-5 h-full "
            }
          >
            {data?.pagination?.type == "ALL" && data?.isTabLoading ? (
              <ButtonSpinner />
            ) : (
              "All"
            )}
          </span>
        </Button>
      ) : (
        <Button
          className={`flex justify-center items-center p-0 mr-3 h-[42px] w-42 ${
            path?.pathname?.includes(
              "admin/personnel/workers/details/worker_details"
            )
              ? "btn-blue"
              : ""
          }`}
          onClick={() => {
            navigate(
              `/admin/personnel/workers/details/worker_details/${data?.id}`
            );
          }}
          disabled={data?.isTabLoading}
        >
          <span className="block w-full py-3 lg:px-5 h-full">
            {data?.pagination?.type === "details" && data?.isTabLoading ? (
              <ButtonSpinner />
            ) : (
              "Worker Details"
            )}
          </span>
        </Button>
      )}
    </div>
  );
};

export default TabBtns;
