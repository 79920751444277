import { useState, useCallback, useEffect } from "react";
import { UserService } from "../../config/axiosUrl";
import { useParams, useNavigate } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import CodeTableLogs from "./CodeTableLogs";

const AdminHistoryTable = () => {
  const { user_id } = useParams();
  const [adminHistory, setAdminHistory] = useState([]);
  const [selectedLog, setSelectedLog] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  let navigate = useNavigate();
  const apiFunctions = {
    getAdminHistory: useCallback(() => {
      UserService.post(
        `/api/v1/administrators/history?user_id=${user_id}`
      ).then((res) => {
        setAdminHistory(res?.data?.data?.data || []);
        console.log("the admin is the ", res?.data?.data?.data);
      });
    }, []),
  };

  useEffect(() => {
    apiFunctions.getAdminHistory();
  }, []);

  const handleInfoClick = (log) => {
    setSelectedLog(log);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setSelectedLog(null);
    setIsModalVisible(false);
  };

  const truncateText = (text, maxWords) => {
    const words = text.split(" ");
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(" ") + " ...";
    }
    return text;
  };

  return (
    <>
      <div
        className="cursor-pointer"
        onClick={() => navigate("/admin/settings/users/admin")}
      >
        <div className="flex items-center ml-5 mb-5">
          <div>
            <div className="pt-1">
              <FaArrowLeft />
            </div>
          </div>
          <div>
            <div className="px-2">Return to Administrators</div>
          </div>
        </div>
      </div>
      {adminHistory.length === 0 ? (
        <CodeTableLogs className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-200" />
      ) : (
        <table className="w-full">
          <thead className="bg-[#E8F3FF] text-center">
            <tr className="text-left">
              <th className="px-12 py-3">
                <div className="flex items-center justify-center">Date</div>
              </th>
              <th className="px-12 py-3">
                <div className="flex items-center justify-center">Logs</div>
              </th>
            </tr>
          </thead>

          <tbody className="bg-white">
            {adminHistory?.map((item, index) => (
              <React.Fragment key={index}>
                <tr className="border-b border-[#F0F0F0]">
                  <td className="px-4 py-3">
                    <div className="flex items-center justify-center">
                      {item.created_at
                        ? new Date(item.created_at).toLocaleDateString()
                        : ""}
                    </div>
                  </td>
                  <td className="px-4 py-3">
                    <div className="flex items-center justify-center">
                      <button
                        onClick={() => handleInfoClick(item.log)}
                        className="bg-transparent border-none outline-none"
                      >
                        <div
                          style={{
                            maxHeight: "70px",
                            overflowY: "auto",
                            width: "770px",
                          }}
                        >
                          {truncateText(item.log, 15)}{" "}
                        </div>
                      </button>
                    </div>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      )}
      <Modal
        title="Log Information"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        centered
      >
        <div>{selectedLog}</div>
      </Modal>
    </>
  );
};

export default AdminHistoryTable;
