import React from "react";

const CameraSvg = ({ data }) => {
  let width = data?.width ? data?.width : "38.214";
  let height = data?.height ? data?.height : "33.363";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 38.214 33.363"
    >
      <path
        id="_5e22b7e4e6c3f59ce415dd8e5104d203"
        data-name="5e22b7e4e6c3f59ce415dd8e5104d203"
        d="M28.421,4H31.3A6.926,6.926,0,0,1,38.2,10.909V26.454A6.926,6.926,0,0,1,31.3,33.363H6.9A6.926,6.926,0,0,1-.01,26.454V10.909A6.926,6.926,0,0,1,6.9,4H9.773a5.411,5.411,0,0,1,1.455-2.28A6,6,0,0,1,15.041,0h8.111a6,6,0,0,1,3.813,1.72A5.416,5.416,0,0,1,28.421,4ZM19.1,10.262a8.421,8.421,0,1,1-5.954,2.466A8.395,8.395,0,0,1,19.1,10.262Zm4.122,4.3a5.831,5.831,0,1,0,1.707,4.122A5.811,5.811,0,0,0,23.219,14.56ZM31.3,6.591H27.323a1.3,1.3,0,0,1-1.3-1.3,2.462,2.462,0,0,0-.853-1.714,3.29,3.29,0,0,0-2.022-.991H15.042a3.29,3.29,0,0,0-2.022.991A2.462,2.462,0,0,0,12.167,5.3a1.3,1.3,0,0,1-1.3,1.3H6.9a4.333,4.333,0,0,0-4.318,4.318V26.454A4.334,4.334,0,0,0,6.9,30.772H31.3a4.333,4.333,0,0,0,4.318-4.318V10.909A4.334,4.334,0,0,0,31.3,6.591Z"
        transform="translate(0.01 0)"
        fill="#b4b4b4"
      />
    </svg>
  );
};

export { CameraSvg };
