import { Button, Card, Progress } from "antd";
import React, { useEffect, useState } from "react";
import { FaEdit, FaTimes } from "react-icons/fa";
import { Edit } from "../../assets/svg";
import TimespentEditModal from "./TimespentEditModal";
import { ApiAuthorizaion } from "../../auth/appAuth";
import { WorkService } from "../../config/axiosUrl";

const TimespentModal = ({ close, id, hours, handle }) => {
  const initialModal = {
    isModal: false,
    modal_selected_data: {},
    modalTitle: "",
    item: "",
    isModalLoading: false,
  };

  const [modal, setModal] = useState(initialModal);
  const [timeSpent, setTimeSpent] = useState("");

  const timespentBreakdown = () => {
    WorkService.get(`/api/v1/worksite/get-task-estimation?task_id=${id}`).then(
      (response) => {
        setTimeSpent(response?.data?.data);
      }
    );
  };

  useEffect(() => {
    timespentBreakdown();
  }, [id]);

  const handleProductiveOrder = {
    openTimespentEdit: (item) => {
      setModal({
        ...modal,
        isModal: true,
        data: item,
        isModalLoading: false,
      });
    },

    closeModal: () => {
      setModal(initialModal);
    },
  };
  return (
    <div className="modal">
      <div className="modal-content">
        <div className="text-right">
          <div className="flex justify-between text-md font-medium">
            {timeSpent?.workers_data?.[0]?.task_name}
            <button className="close-modal" onClick={close}>
              <FaTimes />
            </button>
          </div>
          <div className="flex justify-between text-xs mt-5 mb-5 gap-2">
            <Card
              className={`border-l-8 w-full p-2 flex-grow`}
              style={{ borderColor: "#FFD43B" }}
            >
              <p className="text-sm text-left">Worked</p>
              <div className="flex mt-2 justify-between items-center">
                <p className="text-xs text-black">
                  {/* {timeSpent?.worked.toFixed(3)} &nbsp;Hours */}
                  {timeSpent?.worked !== undefined
                    ? timeSpent.worked.toFixed(3)
                    : ""}{" "}
                  &nbsp;Hours
                </p>
              </div>
            </Card>
            <Card
              className={`border-l-8 w-full p-2 flex-grow`}
              style={{ borderColor: "#F03E3E" }}
            >
              <p className="text-sm text-left">Exceeded</p>
              <div className="flex mt-2 justify-between items-center">
                <p className="text-xs text-black">
                  {/* {timeSpent?.lost.toFixed(3)} &nbsp;Hours */}
                  {timeSpent?.lost !== undefined
                    ? timeSpent.lost.toFixed(3)
                    : "0.000"}
                </p>
              </div>
            </Card>
          </div>

          <table className="w-full zoom-80">
            <thead className="bg-[#E8F3FF] text-center">
              <tr className="text-left">
                <th className=" px-4 py-3">
                  <div className="flex items-center justify-center">
                    Worker Name{" "}
                  </div>
                </th>
                <th className=" px-4 py-3 ">
                  <div className="flex items-center justify-center">Date</div>
                </th>
                <th className=" px-4 py-3 ">
                  <div className="flex items-center justify-center">
                    Duration
                  </div>
                </th>
              </tr>
            </thead>
            {timeSpent?.workers_data?.map((item) => (
              <tbody className="bg-white">
                <tr className="border-b border-[#F0F0F0]">
                  <td className="text-lg px-4 py-3 border border-white   border-b-[#F0F0F0] border-t-[#F0F0F0]">
                    <div className="flex items-center justify-center  break-all">
                      {item?.name}
                    </div>
                  </td>
                  <td className="text-lg px-4 py-3 border border-white   border-b-[#F0F0F0] border-t-[#F0F0F0]">
                    <div className="flex items-center justify-center  break-all">
                      {item?.created_at}
                    </div>
                  </td>
                  <td className="text-lg px-4 py-3 border border-white   border-b-[#F0F0F0] border-t-[#F0F0F0]">
                    <div className="flex items-center justify-center  break-all ">
                      {item?.spent} hrs &nbsp; &nbsp;
                      <div
                        style={{
                          paddingInline: "2px",
                          // backgroundColor: "red",

                          transform: "scale(1.5)",
                        }}
                      >
                        <Progress
                          className="w-auto h-auto"
                          strokeColor={"green"}
                          success={{
                            percent: 101,
                          }}
                          status="normal"
                          strokeWidth={10}
                          type="circle"
                          format={() => (
                            <span style={{ fontSize: "9px" }}>
                              {((item.spent / hours) * 100).toFixed(1)}%
                            </span>
                          )}
                        />
                      </div>
                      &nbsp; &nbsp; &nbsp;{" "}
                      <div>
                        <FaEdit
                          style={{ fontSize: "21px", color: "#353935" }}
                          onClick={() =>
                            handleProductiveOrder.openTimespentEdit(item)
                          }
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>

          <div className="flex justify-between text-xs mt-5">
            <div>
              Productivity Order :{" "}
              {timeSpent.worked !== undefined
                ? timeSpent.worked.toFixed(3)
                : "0.000"}
              /{hours}
            </div>

            <div>
              Exceeded Estimation :{" "}
              {timeSpent?.lost !== undefined
                ? timeSpent.lost.toFixed(3)
                : "0.000"}{" "}
              &nbsp;hrs
            </div>
          </div>
        </div>
      </div>
      {modal?.isModal && (
        <TimespentEditModal
          close={handleProductiveOrder?.closeModal}
          data={modal?.data}
          timespentBreakdown={timespentBreakdown}
          handle={handle}
        />
      )}
    </div>
  );
};

export default TimespentModal;
