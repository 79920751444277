import { useState, useCallback, useEffect } from "react";
import { WorkService } from "../../../config/axiosUrl";
import { Checkbox } from "antd";
const Area = ({ filterWorkers, filter }) => {
  const [areas, setAreas] = useState([]);
  // const [selectedAreas, setSelectedAreas] = useState([]);

  // console.log("the areas are", selectedAreas);

  const apiFunctions = {
    getArea: useCallback(() => {
      WorkService.get("/api/v1/worksite/area/get-areas-data").then((res) => {
        setAreas(res?.data?.data?.data || []);
      });
    }, []),
  };

  useEffect(() => {
    apiFunctions.getArea();
  }, []);

  // const handleCheckboxChange = (areaId) => {
  //   if (selectedAreas.includes(areaId)) {
  //     setSelectedAreas(selectedAreas.filter((id) => id !== areaId));
  //   } else {
  //     setSelectedAreas([...selectedAreas, areaId]);
  //   }
  // };
  return (
    <div className=" bg-white p-30">
      {areas.map((area, index) => (
        <div
          key={index}
          className="flex justify-between items-center border-b border-[#E8E8E8] py-2 fs-12"
        >
          {area?.name}
          <Checkbox
            onChange={() => filterWorkers("Areas", area._id)}
            checked={filter.includes(area._id)}
          />
        </div>
      ))}
    </div>
  );
};

export default Area;
