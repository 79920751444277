import React from 'react'

const NotesIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.075" height="14.537" viewBox="0 0 11.075 14.537">
  <g id="_000000ff" data-name="#000000ff" transform="translate(-60.954)">
    <path id="Path_1275" data-name="Path 1275" d="M61.318,0h8.626a.435.435,0,0,1,.38.424c0,.436,0,.872,0,1.308.426,0,.852,0,1.278,0a.431.431,0,0,1,.426.452v11.9a.446.446,0,0,1-.363.455H63.028a.44.44,0,0,1-.367-.424c0-.427,0-.853,0-1.28-.407,0-.814,0-1.221,0a.505.505,0,0,1-.257-.053.434.434,0,0,1-.225-.4q0-5.864,0-11.728a.827.827,0,0,1,.063-.447.469.469,0,0,1,.3-.205m.489.852v11.13h7.666V.852H61.808m8.518,1.732q0,4.9,0,9.8a.434.434,0,0,1-.242.41.771.771,0,0,1-.352.044h-6.22v.852h7.666V2.584Z" transform="translate(0)"/>
    <path id="Path_1276" data-name="Path 1276" d="M121.333,90.98a1.966,1.966,0,0,1,.277-.008h2.331a.433.433,0,0,1,.411.245.424.424,0,0,1-.069.466.441.441,0,0,1-.344.141c-.842,0-1.685,0-2.527,0a.425.425,0,0,1-.079-.843Z" transform="translate(-58.328 -88.388)"/>
    <path id="Path_1277" data-name="Path 1277" d="M121.339,150.979a1.693,1.693,0,0,1,.252-.009q2.47,0,4.94,0a.425.425,0,0,1,.182.808.781.781,0,0,1-.354.043h-4.741a.81.81,0,0,1-.4-.051.425.425,0,0,1,.118-.793Z" transform="translate(-58.335 -146.684)"/>
    <path id="Path_1278" data-name="Path 1278" d="M121.318,210.977a1.643,1.643,0,0,1,.251-.009q2.47,0,4.94,0a.425.425,0,0,1,.182.808.777.777,0,0,1-.352.043h-4.914a.434.434,0,0,1-.419-.262A.427.427,0,0,1,121.318,210.977Z" transform="translate(-58.312 -204.978)"/>
    <path id="Path_1279" data-name="Path 1279" d="M121.316,270.979a1.666,1.666,0,0,1,.25-.009q2.471,0,4.942,0a.425.425,0,0,1,.182.808.782.782,0,0,1-.354.043h-4.911a.434.434,0,0,1-.42-.263A.427.427,0,0,1,121.316,270.979Z" transform="translate(-58.311 -263.277)"/>
    <path id="Path_1280" data-name="Path 1280" d="M241.344,330.989a.833.833,0,0,1,.138-.009c.548,0,1.1,0,1.644,0a.425.425,0,1,1,0,.851c-.557,0-1.115,0-1.672,0a.437.437,0,0,1-.391-.2A.427.427,0,0,1,241.344,330.989Z" transform="translate(-174.931 -321.583)"/>
  </g>
</svg>
  )
}
export default NotesIcon
