import { createBrowserRouter } from "react-router-dom";

import Login from "../../pages/Admin/Login/Login";
import AdminLayout from "../../layouts/Admin";
import Dashboard from "../../pages/Admin/Dashboard/Dashboard";
import Worksite from "../../pages/Admin/Worksite/Worksite";
import CreateNewWorksite from "../../pages/Admin/Worksite/CreateNewWorksite/CreateNewWorksite";
import WorksiteDetails from "../../pages/Admin/Worksite/WorksiteDetails/WorksiteDetails";
import Workers from "../../pages/Admin/Personnel/Workers/Workers";
import WorkersDetails from "../../pages/Admin/Personnel/Workers/WorkersDetails";
import SiteManagers from "../../pages/Admin/Personnel/SiteManagers/SiteManagers";
import SiteManagerHistory from "../../pages/Admin/Personnel/SiteManagers/SiteManagerHistory";
import EditSiteManagerHistory from "../../pages/Admin/Personnel/SiteManagers/EditSiteManagerHistory";
import Settings from "../../pages/Admin/Settings/Settings";
import SettingsLaout from "../../layouts/SettingsLayout";
import WorkersLayout from "../../layouts/Workers";
import WorkersDashboard from "../../pages/Workers/WorkersDashboard";
import Companies from "../../pages/Admin/Companies/Companies";
import Email from "../../pages/Admin/Emails/Email";
import DisclaimerConfigurations from "../../pages/Admin/DisclaimerConfigurations/DisclaimerConfigurations";
import EditDisclaimer from "../../pages/Admin/DisclaimerConfigurations/EditDisclaimer";
import Categories from "../../pages/Admin/Settings/Categories/Categories";
import CreateNewEmail from "../../pages/Admin/Emails/CreateNewEmail";
import AdminAccount from "../../pages/Admin/Settings/AdministratorAccounts/AdminAccount";
import AdminHistory from "../../pages/Admin/Settings/AdministratorAccounts/AdminHistory";
import EditAdminHistory from "../../pages/Admin/Settings/AdministratorAccounts/EditAdminHistory";
import Payroll from "../../pages/Admin/Settings/Payroll/Agreements/Agreements";
import CreateAgreement from "../../pages/Admin/Settings/Payroll/Agreements/CreateAgreement";
import EmployementStatus from "../../pages/Admin/Settings/Payroll/EmployementStatus/EmployementStatus";
import ForgetPassword from "../../pages/Admin/Login/ForgetPassword";
import Search from "../../pages/Admin/Search/Search";
import Absences from "../../pages/Admin/Settings/Payroll/Absences/Absences";
import CreateAbsence from "../../pages/Admin/Settings/Payroll/Absences/CreateAbsence";
import PayrollCategories from "../../pages/Admin/Settings/Payroll/Categories/Categories";
import OTP from "../../pages/Admin/Login/OTP";
import SetNewPasword from "../../pages/Admin/Login/SetNewPassword";
import AdminPayroll from "../../pages/Admin/Payroll/Payroll";
import Productivity from "../../pages/Admin/Payroll/Productivity";
import Notes from "../../pages/Admin/Payroll/Notes";
import Allowances from "../../pages/Admin/Payroll/Allowences/Allowance";
import AreaCardDetails from "../../components/areaCardDetails/AareaCardDetails";
import SiteManagerLayout from "../../layouts/SiteManagerLayout";
import SiteManagerDashboard from "../../layouts/SiteManagerDashboard";
import Timeline from "../../pages/Site-manager/Dashboard";
import SiteManagerWorksites from "../../pages/Site-manager/worksites";
import SiteManagerWorkers from "../../pages/Site-manager/workers";
import WorksiteTimehseets from "../../pages/Site-manager/worksites/worksite-timesheets";
import Induction from "../../pages/Induction";
import Areas from "../../pages/Admin/Worksite/WorksiteDetails/Tabpans/Areas";
import Tasks from "../../pages/Admin/Worksite/WorksiteDetails/Tabpans/Tasks";
import Timesheet from "../../pages/Admin/Worksite/WorksiteDetails/Tabpans/Timesheet";
import OverViews from "../../pages/Admin/Worksite/WorksiteDetails/Tabpans/Overviews";
import SingleWorkersDetails from "../../pages/Admin/Personnel/Workers/SingleWorkerDetails";
import MergeCreate from "../../components/areaCardDetails/mergeCards/mergeCreate";
import MergeHistory from "../../components/areaCardDetails/mergeCards/mergeHistory";
import TimesheetDetails from "../../pages/Admin/Worksite/WorksiteDetails/WorksiteComponents/TimesheetDetails";
import TimesheetDetailsSubsection from "../../pages/Admin/Worksite/WorksiteDetails/WorksiteComponents/TimesheetDetailsSubsection";
import AddTimesheetDetails from "../../pages/Admin/Worksite/WorksiteDetails/WorksiteComponents/AddTimesheetDetails";
import TimesheetDetailedView from "../../pages/Admin/Worksite/WorksiteDetails/WorksiteComponents/TimesheetDetailedView";
import ProductivityOrders from "../../pages/Admin/Worksite/WorksiteDetails/Tabpans/ProductivityOrders";
import EditTaskAllowance from "../../pages/Admin/Payroll/Allowences/AllowenceItems/EditWorkerAllowance";
import EditPayrollCategory from "../../pages/Admin/Payroll/Allowences/AllowenceItems/EditPayrollCategory";
import Entitlement from "../../pages/Admin/Payroll/Entitlement/Entitlement";
import Summary from "../../pages/Admin/Settings/Payroll/Summary/Summary";
import TimesheetPayroll from "../../pages/Admin/Settings/Payroll/Timesheet/Timesheet";
import InspectionTable from "../../shared/DataTable/inspectionTable";
// import AreaDetails from "../../pages/Site-manager/worksites/worksite-timesheets/detailsTab/tabpanes/areaDetails";
// import AreaTask from "../../shared/DataTable/areaDetails1Table";
import GetInspectionTable from "../../shared/DataTable/getInspectionTable";
import Archive from "../../pages/Admin/Archive/Archive";
import CreateTerminationEmail from "../../pages/Admin/Emails/CreateTerminationEmail";
import QA from "../../pages/Admin/QA/QA";
import CreateQA from "../../pages/Admin/QA/CreateQA";
import QADetails from "../../pages/Admin/QA/QADetails";
import EmployerExpenses from "../../pages/Admin/Expenses/EmployerExpenses";
import EmployeeExpenses from "../../pages/Admin/Expenses/EmployeeExpenses";
import WorkerProfile from "../../pages/Admin/Personnel/Workers/WorkerPage/WorkerProfile";
import AdminHistoryTable from "../../shared/DataTable/AdminHistoryTable";
import Terminated from "../../pages/Admin/Personnel/Workers/Terminated";
import SummarySave from "../../pages/Admin/Settings/Payroll/Summary/SummarySave";
import ExpensesFront from "../../pages/Admin/Expenses/ExpensesItem2/ExpensesFront";
import Superannuation from "../../pages/Admin/Expenses/ExpensesItem/Superannuation";
import Superannuation2 from "../../pages/Admin/Expenses/ExpensesItem2/Superannuation2";
import Othersettings from "../../pages/Admin/OtherSettings/Othersetting";
import ThankyouPage from "../../pages/Induction/ThankyouPage";
import Designations from "../../pages/Admin/Designation/Designations";
import CreateDesignation from "../../pages/Admin/Designation/CreateDesignations";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/induction",
    element: <Induction />,
  },
  {
    path: "/thankyou",
    element: <ThankyouPage />,
  },
  {
    path: "/reset-password",
    element: <ForgetPassword />,
  },
  {
    path: "/reset-password/otp",
    element: <OTP />,
  },
  {
    path: "/reset-password/new",
    element: <SetNewPasword />,
  },
  // search page
  {
    path: "/search",
    element: <Search />,
  },
  {
    path: "/admin",
    element: <AdminLayout />,
    children: [
      {
        path: "/admin",
        element: <Dashboard />,
      },
      {
        path: "/admin/dashboard",
        element: <Dashboard />,
      },

      // worksite
      {
        path: "/admin/worksite",
        element: <Worksite />,
      },
      {
        path: "/admin/worksite/get-inspection/:id/:area",
        element: <GetInspectionTable />,
      },
      {
        path: "/admin/worksite/inspection/:id/:area/:taskid/:type",
        element: <InspectionTable />,
      },
      {
        path: "/admin/worksite/details/*",
        element: <WorksiteDetails />,
        children: [
          {
            path: "overviews/:id",
            element: <OverViews />,
          },
          {
            path: "areas/:id",
            element: <Areas />,
          },
          {
            path: "areas/cards/:id/:area",
            element: <AreaCardDetails />,
          },
          {
            path: "areas/cards/merge/:id/:area",
            element: <MergeCreate />,
          },
          {
            path: "areas/cards/merge/history/:id/:area",
            element: <MergeHistory />,
          },
          {
            path: "tasks/:id",
            element: <Tasks />,
          },
          {
            path: "timesheet/:id",
            element: <Timesheet />,
          },
          {
            path: "timesheet/:id/:timesheet/:routePath",
            element: <TimesheetDetails />,
          },
          {
            path: "timesheet/:id/:timesheet/timesheet-details",
            element: <TimesheetDetailsSubsection />,
          },
          {
            path: "productivity-orders/:id",
            element: <ProductivityOrders />,
          },
        ],
      },
      {
        path: "/admin/worksite/quality-assurance/:worksite_id/:area_id",
        element: <QADetails />,
      },
      {
        path: "/admin/worksite/add-timesheet-details/:id/:timesheet",
        element: <AddTimesheetDetails />,
      },
      {
        path: "/admin/worksite/edit-timesheet-details/:id/:timesheet/:shift",
        element: <AddTimesheetDetails />,
      },
      {
        path: "/admin/worksite/add-timesheet-detailss/:id/:date",
        element: <SummarySave />,
      },

      /*   {
        path: "/admin/worksite/details/card",
        element: <AreaCardDetails />,
      }, */
      {
        path: "/admin/worksite/create",
        element: <CreateNewWorksite />,
      },
      {
        path: "/admin/worksite/edit/:id",
        // element: <EditWorksite />,
        element: <CreateNewWorksite />,
      },

      // admin payroll
      {
        path: "/admin/payroll",
        element: <AdminPayroll />,
      },
      {
        path: "/admin/productivity",
        element: <Productivity />,
      },
      {
        path: "/admin/summary/:memory?",
        element: <Summary />,
      },
      // {
      //   path: "/admin/allowances",
      //   element: <Allowances />,
      // },
      {
        path: "/admin/timesheets",
        element: <TimesheetPayroll />,
      },
      {
        path: "/admin/notes",
        element: <Notes />,
      },

      // personnel - workers
      {
        path: "/admin/personnel/workers/:page/:cursor/:precursor/:type",
        element: <Workers />,
      },
      {
        path: "/admin/personnel/workers/details/active/:id",
        element: <WorkersDetails />,
      },
      {
        path: "/admin/personnel/workers/details/terminated/:id",
        element: <Terminated />,
      },
      {
        path: "/admin/personnel/workers/details/terminated/:id/:logID?",
        element: <Terminated />,
      },
      {
        path: "/admin/personnel/workers/details/worker_details/:id",
        element: <SingleWorkersDetails />,
      },

      //Failed REFACTOR wroker profile use the routes defined before
      {
        path: "/admin/personnel/workers/profile/:id/*",
        element: <WorkerProfile />,
      },

      // personnel - site managers
      {
        path: "/admin/personnel/site-managers",
        element: <SiteManagers />,
      },
      {
        path: "/admin/personnel/site-managers/:page/:cursor",
        element: <SiteManagers />,
      },
      {
        path: "/admin/personnel/site-managers/history/:site_manager_id?",
        element: <SiteManagerHistory />,
      },
      {
        path: "/admin/personnel/site-managers/edit-history",
        element: <EditSiteManagerHistory />,
      },
      // other settings
      {
        path: "/admin/personnel/othersettings",
        element: <Othersettings />,
      },
    ],
  },

  {
    path: "/timesheet-summary-view/:id/:memory?",
    element: <TimesheetDetailedView />,
  },

  // admin settings
  {
    path: "/admin/settings",
    element: <SettingsLaout />,
    children: [
      {
        path: "/admin/settings",
        element: <Settings />,
      },
      {
        path: "/admin/settings/hourly-rate-tasks",
        element: <Settings />,
      },
      {
        path: "/admin/settings/categories",
        element: <Categories />,
      },

      // induction - companies
      {
        path: "/admin/settings/induction/companies",
        element: <Companies />,
      },

      // induction - emails
      {
        path: "/admin/settings/induction/emails/*",
        element: <Email />,
      },
      {
        path: "/admin/settings/induction/emails/induction/edit/:id",
        element: <CreateNewEmail />,
      },
      {
        path: "/admin/settings/induction/emails/induction/create",
        element: <CreateNewEmail />,
      },
      {
        path: "/admin/settings/induction/emails/termination/edit/:id",
        element: <CreateTerminationEmail />,
      },
      {
        path: "/admin/settings/induction/emails/termination/create",
        element: <CreateTerminationEmail />,
      },

      // induction - disclaimer
      {
        path: "/admin/settings/induction/disclaimer",
        element: <DisclaimerConfigurations />,
      },
      {
        path: "/admin/settings/induction/disclaimer/create",
        element: <EditDisclaimer />,
      },
      {
        path: "/admin/settings/induction/disclaimer/edit/:id",
        element: <EditDisclaimer />,
      },

      // user accounts - admin
      {
        path: "/admin/settings/users/admin",
        element: <AdminAccount />,
      },
      {
        path: "/admin/settings/users/admin/history/:user_id?",
        element: <AdminHistory />,
      },
      {
        path: "/admin/settings/users/admin/history/edit",
        element: <EditAdminHistory />,
      },

      // payroll - agreements
      {
        path: "/admin/settings/payroll/agreements",
        element: <Payroll />,
      },
      {
        path: "/admin/settings/payroll/agreements/create",
        element: <CreateAgreement />,
      },
      {
        path: "/admin/settings/payroll/agreements/edit/:id/:cursor",
        element: <CreateAgreement />,
      },

      // payroll - employement status
      {
        path: "/admin/settings/payroll/employment-status",
        element: <EmployementStatus />,
      },

      // payroll - absence
      {
        path: "/admin/settings/payroll/absences/:page/:cursor",
        element: <Absences />,
      },
      {
        path: "/admin/settings/payroll/absences/create",
        element: <CreateAbsence />,
      },
      {
        path: "/admin/settings/payroll/absences/edit/:id",
        element: <CreateAbsence />,
        // element: <EditAbsence />,
      },

      // payroll - categories
      {
        path: "/admin/settings/payroll/categories/:page/:cursor",
        element: <PayrollCategories />,
      },

      //  user accounts - admin
      {
        path: "/admin/settings/users/admin",
        element: <AdminAccount />,
      },
      // {
      //   path: "/admin/settings/users/admin/history",
      //   element: <AdminHistory />,
      // },
      // {
      //   path: "/admin/settings/users/admin/history/edit",
      //   element: <EditAdminHistory />,
      // },

      // payroll - agreements
      {
        path: "/admin/settings/payroll/agreements",
        element: <Payroll />,
      },
      {
        path: "/admin/settings/payroll/agreements/create",
        element: <CreateAgreement />,
      },
      {
        path: "/admin/settings/payroll/agreements/edit/:id/:cursor",
        element: <CreateAgreement />,
      },

      // payroll - employement status
      {
        path: "/admin/settings/payroll/employment-status",
        element: <EmployementStatus />,
      },

      // payroll - absence
      {
        path: "/admin/settings/payroll/absences/:page/:cursor",
        element: <Absences />,
      },
      {
        path: "/admin/settings/payroll/absences/create",
        element: <CreateAbsence />,
      },
      {
        path: "/admin/settings/payroll/absences/edit/:id",
        element: <CreateAbsence />,
        // element: <EditAbsence />,
      },

      // payroll - categories
      {
        path: "/admin/settings/payroll/categories/:page/:cursor",
        element: <PayrollCategories />,
      },
      {
        path: "/admin/settings/allowances/*",
        element: <Allowances />,
      },
      {
        path: "/admin/settings/edit-worker-allowance/:id",
        element: <EditTaskAllowance />,
      },
      {
        path: "/admin/settings/payroll-category/:id?",
        element: <EditPayrollCategory />,
      },
      {
        path: "/admin/settings/entitlement/*",
        element: <Entitlement />,
      },
      {
        path: "/admin/settings/archives/*",
        element: <Archive />,
      },
      {
        path: "/admin/settings/quality-assurance/*",
        element: <QA />,
      },
      {
        path: "/admin/settings/create-qa-template",
        element: <CreateQA />,
      },
      {
        path: "/admin/settings/edit-qa-template/:id",
        element: <CreateQA />,
      },
      {
        path: "/admin/settings/employee-designations/*",
        element: <Designations />,
      },
      {
        path: "/admin/settings/create-designations-template",
        element: <CreateDesignation />,
      },
      {
        path: "/admin/settings/edit-designations-template/:id",
        element: <CreateDesignation />,
      },

      {
        path: "/admin/settings/employer-expenses/*",
        element: <EmployerExpenses />,
      },
      {
        path: "/admin/settings/employee-expenses/ExpensesFront",
        element: <ExpensesFront />,
      },
      {
        path: "/admin/settings/employee-expenses/*",
        element: <EmployeeExpenses />,
      },
    ],
  },

  // workers dashboard
  {
    path: "/workers",
    element: <WorkersLayout />,
    children: [
      {
        path: "/workers",
        element: <WorkersDashboard />,
      },
      {
        path: "/workers/dashboard",
        element: <WorkersDashboard />,
      },
    ],
  },

  // site manager panel
  {
    path: "/site-manager/login",
    element: <SiteManagerLayout />,
  },
  {
    path: "/site-manager",
    element: <SiteManagerDashboard />,
    children: [
      {
        path: "/site-manager",
        element: <Timeline />,
      },
      {
        path: "/site-manager/dashboard",
        element: <Timeline />,
      },
      {
        path: "/site-manager/worksite",
        element: <SiteManagerWorksites />,
      },
      {
        path: "/site-manager/worksite/details/timesheet/:id/:page",
        element: <WorksiteTimehseets />,
      },
    ],
  },
  {
    path: "/site-manager",
    element: <SiteManagerDashboard />,
    children: [
      {
        path: "/site-manager/worksite/details/areas/:id",
        element: <WorksiteTimehseets />,
      },
      {
        path: "/site-manager/worksite/details/task/:id/:area/:page",
        element: <WorksiteTimehseets />,
      },
      {
        path: "/site-manager/worksite/details/issues/:id/:area",
        element: <WorksiteTimehseets />,
      },
      {
        path: "/site-manager/areas/cards/:id/:area",
        element: <AreaCardDetails />,
      },
      {
        path: "/site-manager/worksite/details/inspection/:id/:area/:taskid/:type",
        element: <WorksiteTimehseets />,
      },
      {
        path: "/site-manager/worksite/details/productivity-orders/:id",
        element: <WorksiteTimehseets />,
      },
      {
        path: "/site-manager/worksite/details/productivitys/:id/:area",
        element: <WorksiteTimehseets />,
      },
      {
        path: "/site-manager/worksite/details/overviews/:id/:area",
        element: <WorksiteTimehseets />,
      },
      {
        path: "/site-manager/worksite/details/shift-details/:id",
        element: <WorksiteTimehseets />,
      },
    ],
  },
  {
    path: "/site-manager/workers",
    element: <SiteManagerDashboard />,
    children: [
      {
        path: "/site-manager/workers/:page",
        element: <SiteManagerWorkers />,
      },
      {
        path: "/site-manager/workers/1/details/:id",
        element: <WorkersDetails />,
      },
      {
        path: "/site-manager/workers/1/details/worker_details/:id",
        element: <SingleWorkersDetails />,
      },
      {
        path: "/site-manager/workers/1/details/terminated/:id/:logID?",
        element: <Terminated />,
      },
    ],
  },
  {
    path: "/site-manager/account",
    element: <WorkersDashboard />,
  },
  {
    path: "*",
    element: "404 Not Found",
  },
]);

export default router;
