import { Button, Form, Modal, Select, TimePicker } from "antd";
import React, { useState } from "react";
import { ButtonSpinner } from "../../../../../../components/spinners";
import {
  GetWorkersType,
  MassClockIn,
} from "../../../../../../helper/worksite/worksite";
import { useParams } from "react-router-dom";

const ClockInModal = ({
  isOpen,
  onClose,
  workerLength,
  setWorksite,
  worksite,
}) => {
  const id = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const onChange = (time, timeString) => {
    setWorksite({
      ...worksite,
      started_time: timeString,
    });
  };
  const onChangeEndTime = (time, timeString) => {
    setWorksite({
      ...worksite,
      finished_time: timeString,
    });
  };
  const onChangeBreak = () => {};
  const saveChanges = async () => {
    setIsLoading(true);
    setWorksite({
      ...worksite,
      is_mass_clock_in: 1,
    });
    const response = await MassClockIn(0, worksite, id?.id);
    if (response?.status) {
      onClose();
      const param = { type: "Rostered", id: id?.id };
      const res = await GetWorkersType(param);
      if (res?.status) {
        setWorksite({
          ...worksite,
          data: res?.data?.data,
        });
      }
    }
  };
  return (
    <Modal
      title="Mass Clockin"
      className="clockin-modal"
      open={isOpen}
      onCancel={onClose}
      footer={[
        <Button
          key="save"
          onClick={saveChanges}
          className="btn btn-blue bg-blue-disable"
          disabled={worksite?.started_time == undefined ? true : false}
        >
          {isLoading ? <ButtonSpinner /> : "Save Changes"}
        </Button>,
      ]}
    >
      <p className="text-[#A1A1A1]">
        Clocking In <span className="text-[#000]">{workerLength}</span> Workers
      </p>
      <Form layout="vertical" className="mt-4">
        <Form.Item label="Start Time">
          <TimePicker
            onChange={onChange}
            placeholder="Start Time"
            className="time-picker ant-btn-link"
          />
        </Form.Item>
        <Form.Item label="End Time">
          <TimePicker
            onChange={onChangeEndTime}
            placeholder="End Time"
            className="time-picker"
          />
        </Form.Item>
        <Form.Item label="Break">
          <Select defaultValue={"yes"} onChange={onChangeBreak}>
            <Select.Option value={"yes"}>Yes</Select.Option>
            <Select.Option value={"no"}>No</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default ClockInModal;
