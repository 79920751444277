import React from 'react'

const CameraICon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.777" height="20.608" viewBox="0 0 23.777 20.608">
      <g id="Group_75055" data-name="Group 75055" transform="translate(-750 -482)">
        <path id="Path_1281" data-name="Path 1281" d="M22.763,49.792a2.9,2.9,0,0,1,2.6-1.8c1.635-.006,3.27,0,4.906,0a3.028,3.028,0,0,1,.955.138,2.893,2.893,0,0,1,1.824,1.74c.152.428.288.862.435,1.292q1.712,0,3.425,0a2.9,2.9,0,0,1,2.872,2.872q0,5.82,0,11.641A2.9,2.9,0,0,1,36.854,68.6H19.318a5.046,5.046,0,0,1-.9-.043A2.9,2.9,0,0,1,16,65.678q0-5.822,0-11.643a2.9,2.9,0,0,1,2.874-2.873H22.3c.158-.456.3-.918.466-1.37m2.373-.18a1.312,1.312,0,0,0-.934.849c-.2.579-.385,1.16-.581,1.738a.794.794,0,0,1-.733.546c-1.356.009-2.712,0-4.067.006a1.313,1.313,0,0,0-1.234,1.283q0,5.821,0,11.643a1.318,1.318,0,0,0,.8,1.231,1.81,1.81,0,0,0,.788.106q8.869,0,17.738,0a1.314,1.314,0,0,0,1.281-1.284q.007-5.724,0-11.447a1.959,1.959,0,0,0-.054-.591,1.313,1.313,0,0,0-.892-.89,2.391,2.391,0,0,0-.691-.053H32.941a.8.8,0,0,1-.773-.514c-.162-.466-.313-.937-.471-1.4a2.4,2.4,0,0,0-.319-.735,1.311,1.311,0,0,0-1.062-.516q-2.4,0-4.806,0A1.626,1.626,0,0,0,25.137,49.613Z" transform="translate(734 434.008)" />
        <path id="Path_1282" data-name="Path 1282" d="M141.264,160.049a5.943,5.943,0,1,1-3.748,1.93,5.947,5.947,0,0,1,3.748-1.93m.1,1.586a4.359,4.359,0,1,0,2.659.5A4.342,4.342,0,0,0,141.366,161.635Z" transform="translate(619.953 327.537)" />
        <path id="Path_1283" data-name="Path 1283" d="M400.62,175.954a.792.792,0,1,1-.653.685A.8.8,0,0,1,400.62,175.954Z" transform="translate(369.059 312.397)" />
      </g>
    </svg>
  )
}
export default CameraICon