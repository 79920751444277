import React, { useEffect } from "react";
import { useState } from "react";
import {
  FaArrowLeft,
  FaArrowRight,
  FaEdit,
  FaSearch,
  FaTrashAlt,
} from "react-icons/fa";
import EditInspection from "../Modal/EditInspection";
import DeleteInspection from "../Modal/DeleteInspection";
import { Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { GetAreaById } from "../../helper/worksite/worksiteDetails.js/areas";
import { TableDefaultPage } from "../../components/default/defaultPage";
import { NoTableOption } from "../../components/noOption/noOption";
import AreasDefaultSvg from "../../assets/svg/areasDefault";
import { NoSearchTableSvg } from "../../assets/svg/tableDefaul";
import { TextShimmer } from "../../components/shimmer/shimmer";

const GetInspectionTable = () => {
  let navigate = useNavigate();
  let { id } = useParams();
  let { area } = useParams();

  const initialGetInspection = {
    data: [],
    extra: {},
    search: "",
    isShimmer: true,
  };

  let deafaulPage = {
    disclaimer: "No data available for displaying tasks",
    description: "The tasks needs to be added.",
    buttonText: "Create Tasks",
    svg: <AreasDefaultSvg />,
    redirect: `/admin/worksite/details/areas/cards/${id}/${area}`,
  };

  let noSearchOption = {
    svg: <NoSearchTableSvg />,
    disclaimer: "There were no search results found",
    description: "Sorry, No Item Matches Your Search Term.",
  };

  const [getInspection, setGetInspection] = useState(initialGetInspection);

  const handleGetInspection = {
    back: () => {
      navigate(`/admin/worksite/details/areas/cards/${id}/${area}`);
    },
    get: (taskid, type) => {
      navigate(`/admin/worksite/inspection/${id}/${area}/${taskid}/${type}`);
    },
    search: async (e) => {
      const params = {
        search: e?.target?.value,
      };
      let data = await handleGetInspection?.getArea(params);
    },
    getArea: async (areaData) => {
      const params = {
        id: area,
        worksite_id: id,
        search: areaData?.search,
      };
      let data = await GetAreaById(params);
      if (data?.status) {
        console.log(data);
        let allData = [];
        data?.data?.data?.hourly_rate_tasks_estimations?.map((hrt, index) => {
          allData?.push({
            ...hrt,
            task_abbrevation: hrt?.hourly_rate_task?.abbreviation,
            task_name: hrt?.hourly_rate_task?.name,
            type: "Hourly Rate",
          });
        });

        data?.data?.data?.productivity_orders?.map((po, index) => {
          allData?.push({
            ...po,
            task_abbrevation: po?.code,
            task_name: po?.name,
            type: "Productivity Order",
          });
        });

        setGetInspection({
          data: allData,
          extra: { ...data?.extra },
          search: areaData?.search,
          isShimmer: false,
        });
      }
    },
  };

  useEffect(() => {
    (async () => {
      let data = await handleGetInspection?.getArea();
    })();
  }, []);

  return (
    <>
      <div className="p-6">
        <div className="bg-white">
          {getInspection?.isShimmer ? (
            <div>
              <TextShimmer data={{ gap: 15, line: 20 }} />
            </div>
          ) : (
            <>
              {getInspection?.data?.length <= 0 && !getInspection?.search ? (
                <div className="py-8 h-[70vh]">
                  <TableDefaultPage data={deafaulPage} />
                </div>
              ) : (
                <>
                  <div className="p-4">
                    <div className="pb-4 cursor-pointer">
                      <div className="flex" onClick={handleGetInspection?.back}>
                        <div className="pt-1 pr-2">
                          <FaArrowLeft />
                        </div>
                        <div>Back to tasks</div>
                      </div>
                    </div>

                    <div className="border border-[#111111] flex items-center p-1 rounded-md w-full lg:w-1/2">
                      <FaSearch className="ml-2" />{" "}
                      <input
                        placeholder="Search by Hourly Rate Task, Productivity Order"
                        className="h-[31px] bg-transparent text-sm w-full px-2"
                        name="search"
                        autoComplete="off"
                        onChange={handleGetInspection?.search}
                      />
                    </div>
                  </div>
                  <div>
                    {getInspection?.data?.length <= 0 &&
                    getInspection?.search ? (
                      <>
                        <NoTableOption data={noSearchOption} />
                      </>
                    ) : (
                      <>
                        <table className="w-full">
                          <thead className="bg-[#E8F3FF] text-center">
                            <tr className="text-left">
                              <th className=" px-4 py-3">
                                <div className="flex items-center justify-start">
                                  Task Name
                                </div>
                              </th>
                              <th className=" px-4 py-3 ">
                                <div className="flex items-center justify-center">
                                  Task Type
                                </div>
                              </th>
                              <th className=" px-4 py-3 ">
                                <div className="flex items-center justify-center">
                                  Completed Inspection
                                </div>
                              </th>
                              <th className=" px-4 py-3 ">
                                <div className="flex items-center justify-center">
                                  Incompleted Inspection
                                </div>
                              </th>
                              <th className=" px-4 py-3 ">
                                <div className="flex items-center justify-center"></div>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white">
                            {getInspection?.data?.map((item, index) => (
                              <tr
                                key={index}
                                className="border-b border-[#F0F0F0]"
                              >
                                <td className="px-4 py-3">
                                  <div className="flex items-center justify-start">
                                    <span className="text-sm text-gray-400">
                                      {item?.task_abbrevation}
                                    </span>
                                    &nbsp; &nbsp;
                                    <span> {item?.task_name}</span>
                                  </div>
                                </td>
                                <td className="px-4 py-3">
                                  <div className="flex items-center justify-center">
                                    {item?.type}
                                  </div>
                                </td>
                                <td className="px-4 py-3">
                                  <div className="flex items-center justify-center">
                                    0
                                  </div>
                                </td>
                                <td className="px-4 py-3">
                                  <div className="flex items-center justify-center">
                                    0
                                  </div>
                                </td>
                                <td className="px-4 py-3">
                                  <div className="flex items-center justify-center cursor-pointer">
                                    <div
                                      className="bg-orange-500 p-2 rounded-md text-white ml-3"
                                      onClick={() =>
                                        handleGetInspection?.get(
                                          item?.id,
                                          item?.type == "Hourly Rate"
                                            ? "hourly"
                                            : "po"
                                        )
                                      }
                                    >
                                      <FaArrowRight />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default GetInspectionTable;
