import * as Services from "../config/axiosUrl";

export const AUTH_SESSION_KEY = "cree8_auth";
export const Auth_USER_KEY = "cree8_user";

const setLoggedInUser = (user) => {
  localStorage.setItem(AUTH_SESSION_KEY, user);
};

const setAuthUser = (user) => {
  localStorage.setItem(Auth_USER_KEY, JSON.stringify(user));
};

const LoggedInUser = () => {
  let user = localStorage.getItem(AUTH_SESSION_KEY);
  return user;
};

const Authuser = () => {
  let user = localStorage.getItem(Auth_USER_KEY);
  return JSON.parse(user);
};

const ApiAuthorizaion = () => {
  let user = localStorage.getItem(AUTH_SESSION_KEY);
  let auth = "JWT " + user;
  return auth;
};

const setAuthorization = (token) => {
  let user = "";

  if (token) {
    user = token;
  } else {
    user = localStorage.getItem(AUTH_SESSION_KEY);
  }

  if (user) {
    const allServices = Object.keys(Services);
    allServices.forEach((item) => {
      Services[item].interceptors.request.use((config) => {
        config.headers = {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: "JWT " + user,
        };

        return config;
      });
    });
  }
};

export {
  setLoggedInUser,
  LoggedInUser,
  setAuthUser,
  Authuser,
  ApiAuthorizaion,
  setAuthorization,
};
