import { DatePicker, Select } from "antd";
import React, { useState, useEffect } from "react";
import WorksiteCards from "./WorksiteCards";
import {
  ChangeWorksiteStatus,
  GetWorkSite,
} from "../../../helper/worksite/worksite";
import { toast } from "react-toastify";
import { TextShimmer } from "../../../components/shimmer/shimmer";
import moment from "moment";
import useWindowDimensions from "../../../utils/routes/useWindowDimensions";
const SiteManagerWorksite = ({ worksite, setWorksite }) => {
  const { width } = useWindowDimensions();
  const [selectedDate, setSelectedDate] = useState();
  const handleWorksite = {
    changeStatus: async (checked, id) => {
      setWorksite({
        ...worksite,
        isStatusLoading: true,
        isLoadingId: id,
      });
      const params = {
        id: id,
      };
      let data = await ChangeWorksiteStatus(params);
      if (data?.status) {
        let getData = await GetWorkSite();
        if (getData?.status) {
          setWorksite({
            ...worksite,
            data: [...getData?.data?.data],
            isLoadingId: "",
          });
          toast?.success(data?.message);
        }
      } else {
        toast?.error(data?.message);
      }
    },
    fetch: async (e) => {
      if (
        e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
        e.currentTarget.clientHeight
      ) {
        const params = {
          cursor: worksite?.extra?.cursor,
        };
        let data = await GetWorkSite(params);
        if (data?.status) {
          let alldata = [...worksite?.data];

          if (data?.data?.data?.length > 0) {
            data?.data?.data?.map((site, index) => {
              alldata?.push({ ...site });
            });

            setWorksite({
              ...worksite,
              data: alldata,
              isFetchLoader: false,
              extra: data?.extra,
            });
          } else {
            setWorksite({
              ...worksite,
              isFetchLoader: false,
            });
          }
        } else {
          setWorksite({
            ...worksite,
            isFetchLoader: false,
          });
        }
      }
    },
  };
  useEffect(() => {
    setWorksite({
      ...worksite,
      isShimmer: true,
    });
    (async () => {
      let data = await GetWorkSite();
      if (data?.status) {
        setWorksite({
          ...worksite,
          data: [...data?.data?.data],
          isShimmer: false,
          extra: data?.extra,
          isFetchLoader: false,
          cursor: "",
        });
      } else {
        setWorksite({
          ...worksite,
          isShimmer: false,
        });
      }
    })();
  }, []);
  const handleChange = async (e) => {
    const params = {
      type: e?.target?.value,
    };
    let data = await GetWorkSite(params);
    if (data?.status) {
      setWorksite({
        ...worksite,
        data: [...data?.data?.data],
        isShimmer: false,
        extra: data?.extra,
        isFetchLoader: false,
        cursor: "",
      });
    } else {
      setWorksite({
        ...worksite,
        isShimmer: false,
      });
    }
  };
  const onSelectDate = async (date, dateString) => {
    setSelectedDate(dateString);
    const params = {
      date: dateString,
    };
    let data = await GetWorkSite(params);
    if (data?.status) {
      setWorksite({
        ...worksite,
        data: [...data?.data?.data],
        isShimmer: false,
        extra: data?.extra,
        isFetchLoader: false,
        cursor: "",
      });
    } else {
      setWorksite({
        ...worksite,
        isShimmer: false,
      });
    }
  };
  return worksite?.isShimmer ? (
    <div className="bg-white p-4 rounded-lg">
      <TextShimmer data={{ gap: 15, line: 19 }} />
    </div>
  ) : (
    <div className="">
      <div
        className={
          width > 767
            ? "flex justify-between items-center my-5"
            : "flex justify-between items-center my-5 p-3"
        }
      >
        <div className="flex items-center gap-2">
          <div className="border bg-white py-1 px-3 gap-2 flex rounded justify-between items-center">
            <span className="text-xs">Show: </span>
            <select
              className="border-none outline-none bg-transparent p-0 ml-3"
              onChange={handleChange}
            >
              <option value="all">All</option>
              <option value={0}>Active</option>
              <option value={1}>Archived</option>
            </select>
          </div>
        </div>
        <div className="border bg-white w-[150px] h-[31px] gap-2 flex rounded justify-center items-center">
          <DatePicker type="date" onChange={onSelectDate} allowClear={true} />
        </div>
        {/* {selectedDate && <p className="text-right text-gray-400 text-sm">
            Showing Data of:{" "}
            <span className="font-bold text-black">{moment(selectedDate).format('DD MMM YYYY')}</span>
          </p>} */}
      </div>

      <WorksiteCards
        data={worksite}
        setData={setWorksite}
        handle={handleWorksite}
      />
    </div>
  );
};

export default SiteManagerWorksite;
