import { Button } from "antd";
import React, { useEffect } from "react";
import { ButtonSpinner } from "../../../components/spinners";

const WorkerDetails = ({ data, errors, setData, handle }) => {
  useEffect(() => {
    setTimeout(() => {
      errors?.current["name"]?.current?.focus();
    }, 100);
  }, []);

  const addMoreExperaince = () => {
    const workers_experiences = {
      trade: "",
      year: "",
    };
    data?.workers_experiences.push(workers_experiences);
    setData({ ...data });
  };

  const addMoreEmployment = () => {
    const workers_previous_employement_details = {
      company: "",
      job: "",
      duties: "",
      duration: "",
    };
    data?.workers_previous_employement_details.push(
      workers_previous_employement_details
    );
    setData({ ...data });
  };

  return (
    <div className="p-5">
      <div className="bg-white p-5 rounded">
        <h2 className="text-lg font-bold">
          <label></label>
          Worker Details
        </h2>
        <div>
          <label htmlFor="name" className="mt-4 block">
            Name
          </label>
          <input
            type="text"
            id="name"
            className={
              data?.errors?.name
                ? "mt-5 h-[40px] rounded p-2 w-full border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "mt-5 h-[40px] border rounded p-2 w-full"
            }
            name="name"
            ref={errors?.current["name"]}
            value={data?.name}
            disabled={data?.name !== ""}
            onChange={handle?.get}
          />
          {data?.errors?.name && (
            <p className="pt-2 text-red-600 text-sm">{data?.errors?.name}</p>
          )}
        </div>

        <div className="flex lg:flex-row flex-col justify-between items-center gap-4">
          <div className="w-full">
            <label htmlFor="gender" className="mt-4 block">
              Gender
            </label>
            <select
              id="gender"
              className={
                data?.errors?.gender
                  ? "mt-5 h-[40px] rounded p-2 w-full border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                  : "mt-5 h-[40px] border rounded p-2 w-full"
              }
              ref={errors?.current["gender"]}
              name="gender"
              value={data?.gender}
              onChange={handle?.get}
            >
              <option value={""}>Select</option>
              <option value={"male"}>Male</option>
              <option value={"female"}>Female</option>
              <option value={"other"}>Other</option>
            </select>
            {data?.errors?.gender && (
              <p className="pt-2 text-red-600 text-sm">
                {data?.errors?.gender}
              </p>
            )}
          </div>
          <div className="w-full">
            <label id="dob" className="mt-4 block">
              Date of Birth
            </label>
            <input
              type="date"
              id="dob"
              className={
                data?.errors?.dob
                  ? "mt-5 h-[40px]  rounded p-2 w-full border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                  : "mt-5 h-[40px] border rounded p-2 w-full"
              }
              ref={errors?.current["dob"]}
              name="dob"
              value={data?.dob}
              onChange={handle?.get}
            />
            {data?.errors?.dob && (
              <p className="pt-2 text-red-600 text-sm">{data?.errors?.dob}</p>
            )}
          </div>
        </div>
        <div className="mt-4">
          <label id="address" className="mt-4 block">
            Address
          </label>
          <input
            type="text"
            id="address"
            className={
              data?.errors?.address
                ? "mt-5 h-[40px]  rounded p-2 w-full border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "mt-5 h-[40px] border rounded p-2 w-full"
            }
            ref={errors?.current["address"]}
            name="address"
            value={data?.address}
            onChange={handle?.get}
          />
          {data?.errors?.address && (
            <p className="pt-2 text-red-600 text-sm">{data?.errors?.address}</p>
          )}
        </div>

        <div className="flex lg:flex-row flex-col justify-between items-center gap-4">
          <div className="w-full">
            <label id="city" className="mt-4 block">
              City
            </label>
            <input
              type="text"
              id="city"
              className={
                data?.errors?.city
                  ? "mt-5 h-[40px]  rounded p-2 w-full border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                  : "mt-5 h-[40px] border rounded p-2 w-full"
              }
              ref={errors?.current["city"]}
              name="city"
              value={data?.city}
              onChange={handle?.get}
            />
            {data?.errors?.city && (
              <p className="pt-2 text-red-600 text-sm">{data?.errors?.city}</p>
            )}
          </div>
          <div className="w-full">
            <label htmlFor="post-code" className="mt-4 block">
              Post Code
            </label>
            <input
              type="text"
              id="post-code"
              className={
                data?.errors?.post_code
                  ? "mt-5 h-[40px]  rounded p-2 w-full border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                  : "mt-5 h-[40px] border rounded p-2 w-full"
              }
              ref={errors?.current["post_code"]}
              name="post_code"
              value={data?.post_code}
              onChange={handle?.get}
            />
            {data?.errors?.post_code && (
              <p className="pt-2 text-red-600 text-sm">
                {data?.errors?.post_code}
              </p>
            )}
          </div>
        </div>
        <div className="flex lg:flex-row flex-col justify-between items-center gap-4">
          <div className="w-full">
            <label htmlFor="mobile" className="mt-4 block">
              Mobile
            </label>
            <input
              type="text"
              id="mobile"
              className={
                data?.errors?.mobile
                  ? "mt-5 h-[40px]  rounded p-2 w-full border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                  : "mt-5 h-[40px] border rounded p-2 w-full"
              }
              ref={errors?.current["mobile"]}
              name="mobile"
              value={data?.mobile}
              onChange={handle?.get}
            />
            {data?.errors?.mobile && (
              <p className="pt-2 text-red-600 text-sm">
                {data?.errors?.mobile}
              </p>
            )}
          </div>
          <div className="w-full">
            <label htmlFor="driver-licence" className="mt-4 block">
              Driver License
            </label>
            <input
              type="text"
              id="driver-licence"
              className={
                data?.errors?.driver_licence
                  ? "mt-5 h-[40px]  rounded p-2 w-full border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                  : "mt-5 h-[40px] border rounded p-2 w-full"
              }
              ref={errors?.current["driver_licence"]}
              name="driver_licence"
              value={data?.driver_licence}
              onChange={handle?.get}
            />
            {data?.errors?.driver_licence && (
              <p className="pt-2 text-red-600 text-sm">
                {data?.errors?.driver_licence}
              </p>
            )}
          </div>
        </div>
        <div className="flex lg:flex-row flex-col justify-between items-center gap-4">
          <div className="w-full">
            <label htmlFor="shirt-size" className="mt-4 block">
              Shirt Size
            </label>
            <input
              type="text"
              id="shirt-size"
              className={
                data?.errors?.shirt_size
                  ? "mt-5 h-[40px]  rounded p-2 w-full border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                  : "mt-5 h-[40px] border rounded p-2 w-full"
              }
              ref={errors?.current["shirt_size"]}
              name="shirt_size"
              value={data?.shirt_size}
              onChange={handle?.get}
            />
            {data?.errors?.shirt_size && (
              <p className="pt-2 text-red-600 text-sm">
                {data?.errors?.shirt_size}
              </p>
            )}
          </div>
          <div className="w-full">
            <label htmlFor="pant-size" className="mt-4 block">
              Pant Size
            </label>
            <input
              type="text"
              id="pant-size"
              className={
                data?.errors?.pant_size
                  ? "mt-5 h-[40px]  rounded p-2 w-full border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                  : "mt-5 h-[40px] border rounded p-2 w-full"
              }
              ref={errors?.current["pant_size"]}
              name="pant_size"
              value={data?.pant_size}
              onChange={handle?.get}
            />
            {data?.errors?.pant_size && (
              <p className="pt-2 text-red-600 text-sm">
                {data?.errors?.pant_size}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="bg-white p-5 rounded mt-6">
        <div className="flex lg:flex-row flex-col justify-between items-center gap-4">
          <div className="w-full">
            <label htmlFor="super-fund-name" className="mt-4 block">
              Super Fund Name
            </label>
            <input
              type="text"
              id="super-fund-name"
              className={
                data?.errors?.super_fund_name
                  ? "mt-5 h-[40px]  rounded p-2 w-full border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                  : "mt-5 h-[40px] border rounded p-2 w-full"
              }
              ref={errors?.current["super_fund_name"]}
              name="super_fund_name"
              value={data?.super_fund_name}
              onChange={handle?.get}
              autoComplete={"off"}
            />
            {data?.errors?.super_fund_name && (
              <p className="pt-2 text-red-600 text-sm">
                {data?.errors?.super_fund_name}
              </p>
            )}
          </div>
          <div className="w-full">
            <label htmlFor="super-fund-number" className="mt-4 block">
              Super Fund Number
            </label>
            <input
              type="text"
              id="super-fund-number"
              className={
                data?.errors?.super_fund_number
                  ? "mt-5 h-[40px]  rounded p-2 w-full border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                  : "mt-5 h-[40px] border rounded p-2 w-full"
              }
              ref={errors?.current["super_fund_number"]}
              name="super_fund_number"
              value={data?.super_fund_number}
              onChange={handle?.get}
              autoComplete={"off"}
            />
            {data?.errors?.super_fund_number && (
              <p className="pt-2 text-red-600 text-sm">
                {data?.errors?.super_fund_number}
              </p>
            )}
          </div>
        </div>
        <div className="w-full">
          <label htmlFor="tax-file-number" className="mt-4 block">
            Tax File Number
          </label>
          <input
            type="text"
            id="tax-file-number"
            className={
              data?.errors?.tax_file_number
                ? "mt-5 h-[40px]  rounded p-2 w-full border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "mt-5 h-[40px] border rounded p-2 w-full"
            }
            ref={errors?.current["tax_file_number"]}
            name="tax_file_number"
            value={data?.tax_file_number}
            onChange={handle?.get}
            autoComplete={"off"}
          />
          {data?.errors?.tax_file_number && (
            <p className="pt-2 text-red-600 text-sm">
              {data?.errors?.tax_file_number}
            </p>
          )}
        </div>
        <div className="flex lg:flex-row flex-col justify-between items-center gap-4">
          <div className="w-full">
            <label htmlFor="bert" className="mt-4 block">
              BERT
            </label>
            <input
              type="text"
              id="bert"
              className={
                data?.errors?.bert
                  ? "mt-5 h-[40px]  rounded p-2 w-full border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                  : "mt-5 h-[40px] border rounded p-2 w-full"
              }
              ref={errors?.current["bert"]}
              name="bert"
              value={data?.bert}
              onChange={handle?.get}
              autoComplete={"off"}
            />
            {data?.errors?.bert && (
              <p className="pt-2 text-red-600 text-sm">{data?.errors?.bert}</p>
            )}
          </div>
          <div className="w-full">
            <label htmlFor="qLeave" className="mt-4 block">
              QLeave
            </label>
            <input
              type="text"
              id="qLeave"
              className={
                data?.errors?.qLeave
                  ? "mt-5 h-[40px]  rounded p-2 w-full border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                  : "mt-5 h-[40px] border rounded p-2 w-full"
              }
              ref={errors?.current["qLeave"]}
              name="qLeave"
              value={data?.qLeave}
              onChange={handle?.get}
              autoComplete={"off"}
            />
            {data?.errors?.qLeave && (
              <p className="pt-2 text-red-600 text-sm">
                {data?.errors?.qLeave}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="bg-white p-5 rounded mt-6">
        <h2 className="text-lg font-bold mt-6">Bank</h2>
        <div className="flex lg:flex-row flex-col justify-between items-center gap-4">
          <div className="w-full">
            <label htmlFor="bank_bsb" className="mt-4 block">
              BSB
            </label>
            <input
              id="bank_bsb"
              className={
                data?.errors?.bank_bsb
                  ? "mt-5 h-[40px]  rounded p-2 w-full border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                  : "mt-5 h-[40px] border rounded p-2 w-full"
              }
              ref={errors?.current["bank_bsb"]}
              name="bank_bsb"
              value={data?.bank_bsb}
              onChange={handle?.get}
              autoComplete={"off"}
            />
            {data?.errors?.bank_bsb && (
              <p className="pt-2 text-red-600 text-sm">
                {data?.errors?.bank_bsb}
              </p>
            )}
          </div>
          <div className="w-full">
            <label htmlFor="bank_account_number" className="mt-4 block">
              Account
            </label>
            <input
              id="bank_account_number"
              className={
                data?.errors?.bank_account_number
                  ? "mt-5 h-[40px]  rounded p-2 w-full border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                  : "mt-5 h-[40px] border rounded p-2 w-full"
              }
              ref={errors?.current["bank_account_number"]}
              name="bank_account_number"
              value={data?.bank_account_number}
              onChange={handle?.get}
              autoComplete={"off"}
            />
            {data?.errors?.bank_account_number && (
              <p className="pt-2 text-red-600 text-sm">
                {data?.errors?.bank_account_number}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="bg-white p-5 rounded mt-6">
        <h2 className="text-lg font-bold mt-6">Emergency Contacts</h2>
        <div className="flex lg:flex-row flex-col justify-between items-center gap-4">
          <div className="w-full">
            <label htmlFor="c_name" className="mt-4 block">
              Name
            </label>
            <input
              type="text"
              id="c_name"
              className={
                data?.errors?.c_name
                  ? "mt-5 h-[40px]  rounded p-2 w-full border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                  : "mt-5 h-[40px] border rounded p-2 w-full"
              }
              ref={errors?.current["c_name"]}
              name="c_name"
              value={data?.c_name}
              onChange={handle?.get}
              autoComplete={"off"}
            />
            {data?.errors?.c_name && (
              <p className="pt-2 text-red-600 text-sm">
                {data?.errors?.c_name}
              </p>
            )}
          </div>
          <div className="w-full">
            <label htmlFor="c_mobile" className="mt-4 block">
              Ph. Number
            </label>
            <input
              type="text"
              id="c_mobile"
              className={
                data?.errors?.c_mobile
                  ? "mt-5 h-[40px]  rounded p-2 w-full border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                  : "mt-5 h-[40px] border rounded p-2 w-full"
              }
              ref={errors?.current["c_mobile"]}
              name="c_mobile"
              value={data?.c_mobile}
              onChange={handle?.get}
              autoComplete={"off"}
            />
            {data?.errors?.c_mobile && (
              <p className="pt-2 text-red-600 text-sm">
                {data?.errors?.c_mobile}
              </p>
            )}
          </div>
        </div>
        <div className="flex lg:flex-row flex-col justify-between items-center gap-4">
          <div className="w-full">
            <label id="c_existing_injuries" className="mt-4 block">
              Existing Injuries
            </label>
            <select
              id="c_existing_injuries"
              className="mt-5 h-[40px] border rounded p-2 w-full"
              name="c_existing_injuries"
              value={data?.c_existing_injuries}
              onChange={handle?.get}
              autoComplete={"off"}
            >
              <option value={0}>No</option>
              <option value={1}>Yes</option>
            </select>
          </div>

          <div className="w-full">
            <label htmlFor="c_medicle_condition" className="mt-4 block">
              Medical Condition
            </label>
            <input
              type="text"
              id="c_medicle_condition"
              className={
                data?.errors?.c_medicle_condition
                  ? "mt-5 h-[40px]  rounded p-2 w-full border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                  : "mt-5 h-[40px] border rounded p-2 w-full"
              }
              ref={errors?.current["c_medicle_condition"]}
              name="c_medicle_condition"
              value={data?.c_medicle_condition}
              onChange={handle?.get}
              autoComplete={"off"}
            />
            {data?.errors?.c_medicle_condition && (
              <p className="pt-2 text-red-600 text-sm">
                {data?.errors?.c_medicle_condition}
              </p>
            )}
          </div>
        </div>
        {data?.c_existing_injuries == 1 && (
          <div className="w-full">
            <label id="c_existing_injuries_text" className="mt-4 block">
              Injuries Details
            </label>

            <input
              type="text"
              id="c_existing_injuries_text"
              className="mt-5 h-[40px] border rounded p-2 w-full"
              name="c_existing_injuries_text"
              value={data?.c_existing_injuries_text}
              onChange={handle?.get}
              autoComplete={"off"}
            />
          </div>
        )}
        <div className="lg:w-1/2">
          <label
            htmlFor="c_has_ever_made_a_worker_compensation_claim"
            className="mt-4 block"
          >
            Have You ever made a worker's compensation claim?
          </label>
          <select
            id="c_has_ever_made_a_worker_compensation_claim"
            className="mt-5 h-[40px] border rounded p-2 w-full"
            name="c_has_ever_made_a_worker_compensation_claim"
            value={data?.c_has_ever_made_a_worker_compensation_claim}
            onChange={handle?.get}
            autoComplete={"off"}
          >
            <option value={0}>No</option>
            <option value={1}>Yes</option>
          </select>
        </div>
        <div className="lg:w-1/2">
          <label htmlFor="c_blood_type" className="mt-4 block">
            Blood Type
          </label>
          <select
            id="c_blood_type"
            className={
              data?.errors?.c_blood_type
                ? "mt-5 h-[40px] rounded p-2 w-full border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "mt-5 h-[40px] border rounded p-2 w-full"
            }
            ref={errors?.current["c_blood_type"]}
            name="c_blood_type"
            value={data?.c_blood_type}
            onChange={handle?.get}
            autoComplete={"off"}
          >
            <option value="">Select</option>
            <option value={"A+"}>A+</option>
            <option value={"A-"}>A-</option>
            <option value={"B+"}>B+</option>
            <option value={"B-"}>B-</option>
            <option value={"O+"}>O+</option>
            <option value={"O-"}>O-</option>
            <option value={"AB+"}>AB+</option>
            <option value={"AB-"}>AB-</option>
          </select>
          {data?.errors?.c_blood_type && (
            <p className="pt-2 text-red-600 text-sm">
              {data?.errors?.c_blood_type}
            </p>
          )}
        </div>
        <div className="lg:w-1/2">
          <label htmlFor="c_is_subcontractor" className="mt-4 block">
            Are you a Subcontractor ?
          </label>
          <select
            id="c_is_subcontractor"
            className={
              data?.errors?.c_is_subcontractor
                ? "mt-5 h-[40px] rounded p-2 w-full border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                : "mt-5 h-[40px] border rounded p-2 w-full"
            }
            ref={errors?.current["c_is_subcontractor"]}
            name="c_is_subcontractor"
            value={data?.c_is_subcontractor}
            onChange={handle?.get}
            autoComplete={"off"}
          >
            <option value={"0"}>No</option>
            <option value={"1"}>Yes</option>
          </select>
          {data?.errors?.c_is_subcontractor && (
            <p className="pt-2 text-red-600 text-sm">
              {data?.errors?.c_is_subcontractor}
            </p>
          )}
        </div>
      </div>
      {data?.workers_experiences?.map((work_exp, index) => {
        const tcert = `trade_certificate_${index}`;
        return (
          <div key={index}>
            <div className="bg-white p-5 rounded mt-6">
              <h2 className="text-lg font-bold mt-6">
                Experience {index > 0 ? " # " + (index + 1) : ""}
              </h2>
              <div className="lg:w-1/2">
                <label htmlFor={tcert} className="mt-4 block">
                  Trade Certificate
                </label>

                <div className="w-full">
                  <input
                    type="file"
                    id={tcert}
                    name={tcert}
                    className="mt-5 h-[40px] border rounded p-2 w-full hidden"
                    onChange={handle?.getFiles}
                  />
                  <label
                    htmlFor={tcert}
                    className="mt-5 h-12 border  rounded w-full text-center block px-5 py-3"
                  >
                    Choose File
                  </label>
                  <div className="text-xs mt-1">
                    {data[tcert]?.name ? (
                      data[tcert]?.name
                    ) : (
                      <p className="text-sm mt-2">
                        *Copy of certificate must be provided
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex lg:flex-row flex-col justify-between items-center gap-4">
                <div className="w-full">
                  <label htmlFor={`pant-size${index}`} className="mt-4 block">
                    Trade
                  </label>
                  <input
                    type="text"
                    id={`pant-size${index}`}
                    className="mt-5 h-[40px] border rounded p-2 w-full"
                    /*  className={
                      data?.errors?.trade
                        ? "mt-5 h-[40px]  rounded p-2 w-full border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                        : "mt-5 h-[40px] border rounded p-2 w-full"
                    }
                    ref={errors?.current["trade"]} */
                    name="trade"
                    value={data?.[index]?.trade}
                    onChange={(e) => handle?.getTrade(e, index)}
                    autoComplete={"off"}
                  />
                  {data?.errors?.trade && (
                    <p className="pt-2 text-red-600 text-sm">
                      {data?.errors?.trade}
                    </p>
                  )}
                </div>
                <div className="w-full">
                  <label htmlFor={`year-${index}`} className="mt-4 block">
                    Year in Trade
                  </label>
                  <input
                    type="text"
                    id={`year-${index}`}
                    className="mt-5 h-[40px] border rounded p-2 w-full"
                    /*   className={
                      data?.errors?.year
                        ? "mt-5 h-[40px]  rounded p-2 w-full border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                        : "mt-5 h-[40px] border rounded p-2 w-full"
                    } */
                    ref={errors?.current["year"]}
                    name="year"
                    value={data?.[index]?.year}
                    onChange={(e) => handle?.getTradeYear(e, index)}
                    autoComplete={"off"}
                  />
                  {data?.errors?.year && (
                    <p className="pt-2 text-red-600 text-sm">
                      {data?.errors?.year}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {data?.workers_experiences?.length <= 4 && (
        <div className="flex mt-4 justify-end">
          <Button
            className="btn btn-blue w-1/4 bg-blue-disable h-11"
            onClick={addMoreExperaince}
          >
            Add More
          </Button>
        </div>
      )}
      {data?.workers_previous_employement_details?.map((prev, index) => {
        return (
          <div key={index}>
            <div className="bg-white p-5 rounded mt-6">
              <h2 className="text-lg font-bold mt-6">
                Previous Employment Details{" "}
                {index > 0 ? " # " + (index + 1) : ""}
              </h2>
              <div className="w-full">
                <label htmlFor={`company-${index}`} className="mt-4 block">
                  Company
                </label>
                <input
                  type="text"
                  id={`company-${index}`}
                  className="mt-5 h-[40px] border rounded p-2 w-full"
                  /*   className={
                    data?.errors?.company
                      ? "mt-5 h-[40px]  rounded p-2 w-full border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                      : "mt-5 h-[40px] border rounded p-2 w-full"
                  }
                  ref={errors?.current["company"]} */
                  name="company"
                  value={data?.[index]?.company}
                  onChange={(e) => handle?.getPrevCompany(e, index)}
                  autoComplete={"off"}
                />
                {data?.errors?.company && (
                  <p className="pt-2 text-red-600 text-sm">
                    {data?.errors?.company}
                  </p>
                )}
              </div>
              <div className="w-full">
                <label htmlFor={`job-${index}`} className="mt-4 block">
                  Job
                </label>
                <input
                  type="text"
                  id={`job-${index}`}
                  className="mt-5 h-[40px] border rounded p-2 w-full"
                  /*className={
                    data?.errors?.job
                      ? "mt-5 h-[40px]  rounded p-2 w-full border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                      : "mt-5 h-[40px] border rounded p-2 w-full"
                  }
                  ref={errors?.current["job"]} */
                  name="job"
                  value={data?.[index]?.job}
                  onChange={(e) => handle?.getJob(e, index)}
                  autoComplete={"off"}
                />
                {data?.errors?.job && (
                  <p className="pt-2 text-red-600 text-sm">
                    {data?.errors?.job}
                  </p>
                )}
              </div>
              <div className="w-full">
                <label htmlFor={`duties-${index}`} className="mt-4 block">
                  Duties
                </label>
                <input
                  id={`duties-${index}`}
                  className="mt-5 h-[40px] border rounded p-2 w-full"
                  /* className={
                    data?.errors?.duties
                      ? "mt-5 h-[40px]  rounded p-2 w-full border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                      : "mt-5 h-[40px] border rounded p-2 w-full"
                  } */
                  ref={errors?.current["duties"]}
                  name="duties"
                  value={data?.[index]?.duties}
                  onChange={(e) => handle?.getDuties(e, index)}
                  autoComplete={"off"}
                />
                {data?.errors?.duties && (
                  <p className="pt-2 text-red-600 text-sm">
                    {data?.errors?.duties}
                  </p>
                )}
              </div>
              <div className="w-full">
                <label htmlFor={`duration-${index}`} className="mt-4 block">
                  Duration
                </label>
                <input
                  id="duration"
                  className="mt-5 h-[40px] border rounded p-2 w-full"
                  /*  className={
                    data?.errors?.duration
                      ? "mt-5 h-[40px]  rounded p-2 w-full border border-rose-600 hover:border-rose-600 focus:border-rose-600"
                      : "mt-5 h-[40px] border rounded p-2 w-full"
                  }
                  ref={errors?.current["duration"]} */
                  name="duration"
                  value={data?.[index]?.duration}
                  onChange={(e) => handle?.getDuration(e, index)}
                  autoComplete={"off"}
                />
                {data?.errors?.duration && (
                  <p className="pt-2 text-red-600 text-sm">
                    {data?.errors?.duration}
                  </p>
                )}
              </div>
            </div>
          </div>
        );
      })}
      {data?.workers_previous_employement_details?.length <= 4 && (
        <div className="flex mt-4 justify-end">
          <Button
            className="btn btn-blue w-1/4 bg-blue-disable h-11"
            onClick={addMoreEmployment}
          >
            Add More
          </Button>
        </div>
      )}
      <div className="bg-white p-5 rounded mt-6">
        <div className="lg:w-1/2">
          <label htmlFor="is_have_working_visa" className="mt-4 block">
            Do you Posses a working visa ?
          </label>
          <select
            id="is_have_working_visa"
            className="mt-5 h-[40px] border rounded p-2 w-full"
            name="is_have_working_visa"
            value={data?.is_have_working_visa}
            onChange={handle?.get}
            autoComplete={"off"}
          >
            <option value={"0"}>No</option>
            <option value={"1"}>Yes</option>
          </select>
        </div>
        {data?.is_have_working_visa == 1 && (
          <div className="w-full">
            <label id="is_have_working_visa" className="mt-4 block">
              Visa Details
            </label>

            <input
              type="text"
              id="is_have_working_visa_text"
              className="mt-5 h-[40px] border rounded p-2 w-full"
              name="is_have_working_visa_text"
              value={data?.is_have_working_visa_text}
              onChange={handle?.get}
              autoComplete={"off"}
            />
          </div>
        )}
        <h2 className="text-lg font-bold mt-6">Additional Documents</h2>

        <div className="flex lg:flex-row flex-col justify-between items-center gap-4">
          <div className="w-full">
            <label className="mt-4 block">Photo of Licence</label>
            <input
              type="file"
              id="f1"
              className="mt-5 h-12 border rounded p-2 w-full hidden"
              name="licence_file"
              onChange={handle?.getFiles}
              ref={errors?.current["licence_file"]}
            />
            <label
              htmlFor="f1"
              className={
                data?.errors?.licence_file && !data?.licence_file?.name
                  ? "mt-5 h-12 border  rounded w-full text-center block px-5 py-3 border-rose-600 text-rose-600"
                  : "mt-5 h-12 border  rounded w-full text-center block px-5 py-3"
              }
              // className="mt-5 h-12 border rounded w-full text-center block px-5 py-3"
            >
              Choose File
            </label>
            <div
              className={
                data?.errors?.licence_file && !data?.licence_file?.name
                  ? "text-xs mt-1 text-rose-600"
                  : "text-xs mt-1"
              }
            >
              {data?.licence_file?.name
                ? data?.licence_file?.name
                : "*No file chosen"}
            </div>
          </div>
          <div className="w-full">
            <label className="mt-4 block">Photo of Blue Card</label>
            <input
              type="file"
              id="f2"
              name="blue_card_file"
              className="mt-5 h-[40px] border rounded p-2 w-full hidden"
              onChange={handle?.getFiles}
            />
            <label
              htmlFor="f2"
              className={
                data?.errors?.blue_card_file && !data?.blue_card_file?.name
                  ? "mt-5 h-12 border  rounded w-full text-center block px-5 py-3 border-rose-600 text-rose-600"
                  : "mt-5 h-12 border  rounded w-full text-center block px-5 py-3"
              }
            >
              Choose File
            </label>
            <div
              className={
                data?.errors?.blue_card_file && !data?.blue_card_file?.name
                  ? "text-xs mt-1 text-rose-600"
                  : "text-xs mt-1"
              }
            >
              {data?.blue_card_file?.name
                ? data?.blue_card_file?.name
                : "*No file chosen"}
            </div>
          </div>
        </div>

        <h2 className="mt-6 font-bold text-xl mb-2">
          By Clicking OK you certify that to the best of your knowledge that the
          above details are correct.
        </h2>
        <p>
          You acknowledge that you have read and understand the contents of this
          SAFETY INDUCTION HANDBOOK and agree to abide by the Company Work Place
          Health & Safety Policy, General Work Place Health & Safety Procedures,
          Company Rules, Company Policies, Administration Procedures, Code of
          Conduct and Ethics, Confidentiality Agreement and Site requirements of
          CREE8 and to comply with your obligations to the Workplace Health and
          Safety Act in the interests of safety to yourself, fellow workers and
          the general public.
        </p>

        <div className="text-right mt-6 flex justify-end items-center gap-4">
          {/* <Button className="bg-[#fff] border border-[#0F4C7D] text-[#0F4C7D] h-11  w-1/5 rounded">
            Print
          </Button> */}
          <Button
            className="btn btn-blue w-1/4 bg-blue-disable h-11"
            onClick={handle?.submit}
            disabled={data?.isLoading}
          >
            {data?.isLoading ? <ButtonSpinner /> : "Agree and Submit"}
          </Button>
        </div>
      </div>{" "}
    </div>
  );
};

export default WorkerDetails;
