import { Button, Modal, Select, Space, InputNumber } from "antd";
import React, { useState, useEffect } from "react";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import { ButtonSpinner } from "../../components/spinners";
import TextArea from "antd/es/input/TextArea";
import { AiOutlineInfoCircle } from "react-icons/ai";
import {
  GetAreaById,
  GetAreaByIdTab,
  GetAreas,
  deleteTaskAllowance,
} from "../../helper/worksite/worksiteDetails.js/areas";
import { useParams } from "react-router-dom";
import { confirmWork, getConfirmWork } from "../../helper/worksite/worksite";
import { toast } from "react-toastify";
import { Trash } from "../../assets/svg";
import useWindowDimensions from "../../utils/routes/useWindowDimensions";

const CreatingWorkShiftModal = ({
  assignTaskModal,
  closeAssignTaskWorker,
  selectedItem,
  isComplted,
  handleWorksite,
  isShift,
  displayTextWor,

  fetchShiftDetails,
  shiftOpen,
  taskAllowanceOpen,
  selectedTaskArr,
  setSelectedTaskArr,
  taskAllowData,
  setTaskAllowData,
  workingTable,
  handle,
}) => {
  const { width } = useWindowDimensions();
  const id = useParams();
  const [notesOpen, setNotesOpen] = useState(false);
  const [taskArray, setTaskArr] = useState({
    worksite_timesheet_workdone: [],
    task_allowances: [],
    break: "yes",
  });
  const [areaData, setAreaData] = useState();
  const [taskData, setTaskData] = useState();
  const [selectedArea, setSelectedArea] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [taskallArr, setTaskAllArr] = useState({
    worksite_timesheet_workdone: [],
    task_allowances: [],
  });
  const [isMax, setIsMax] = useState(0);
  console.log("taskallArr", taskallArr);
  const [finishedTime, setFinishedTime] = useState(0);
  const [saveDisable, setSaveDisable] = useState(false);
  const areaIds = taskallArr.worksite_timesheet_workdone.map(
    (item) => item.area_id
  );
  const taskIds = taskallArr.worksite_timesheet_workdone.map(
    (item) => item.task_id
  );
  const hasAllAreaIds = areaIds.every(
    (areaId) => areaId !== undefined && areaId !== null && areaId !== ""
  );
  const hasAllTaskIds = taskIds.every(
    (taskId) => taskId !== undefined && taskId !== null && taskId !== ""
  );
  useEffect(() => {
    if (
      hasAllAreaIds &&
      hasAllTaskIds &&
      areaIds.length > 0 &&
      taskIds.length > 0
    ) {
      setSaveDisable(true);
    } else {
      setSaveDisable(false);
    }
  }, [areaIds, taskIds]);

  const [display, setDisplay] = useState(false);
  const setMaxValue = () => {
    if (taskArray?.started && taskArray?.finished) {
      let [startedHour, startedMin] = taskArray?.started?.split(":");
      let [finishedHour, finishedMin] = taskArray?.finished?.split(":");
      let remainHours, remainMinutes;
      if (parseInt(startedMin) > parseInt(finishedMin)) {
        remainHours = parseInt(finishedHour) - parseInt(startedHour) - 1;
        remainMinutes = parseInt(finishedMin) + 60 - parseInt(startedMin);
      } else {
        remainHours = parseInt(finishedHour) - parseInt(startedHour);
        remainMinutes = parseInt(finishedMin) - parseInt(startedMin);
      }
      const totalMinutes =
        parseInt(remainHours, 10) * 60 + parseInt(remainMinutes, 10);
      setIsMax(
        Number(
          `${Math.floor(totalMinutes / 60)}.${
            ((totalMinutes % 60) / 6).toFixed(1) * 10
          }`
        )
      );
    } else {
      console.error("Invalid taskArray values:", taskArray);
    }
  };
  useEffect(() => {
    setMaxValue();
  }, [finishedTime]);

  const toggleNotes = () => {
    setNotesOpen(!notesOpen);
  };
  const handleTimeChange = (time) => {
    setTaskArr({ ...taskArray, started: time?.target?.value });
    setTaskAllArr({ ...taskallArr, started: time?.target?.value });
  };
  const handleTimeChangeFinished = (time) => {
    setFinishedTime(time?.target?.value);
    setTaskArr({ ...taskArray, finished: time?.target?.value });
    setTaskAllArr({ ...taskallArr, finished: time?.target?.value });
  };

  const handleChange = (value, index) => {
    let allPrev = [...taskArray?.worksite_timesheet_workdone];
    allPrev[index].area_id = value;
    fetchTaskList(value);
    setSelectedArea(value);
    setTaskArr({ ...taskArray, worksite_timesheet_workdone: allPrev });
    setTaskAllArr({ ...taskallArr, worksite_timesheet_workdone: allPrev });
  };
  const handleChangeBreak = (value) => {
    setTaskArr({ ...taskArray, break: value });
    setTaskAllArr({ ...taskallArr, break: value });
  };
  const addNotes = (e) => {
    setTaskArr({ ...taskArray, notes: e?.target?.value });
    setTaskAllArr({ ...taskallArr, notes: e?.target?.value });
  };
  const handleChangeTask = (value, index) => {
    let allPrev = [...taskArray?.worksite_timesheet_workdone];
    allPrev[index].task_id = value;
    taskData.forEach((ele) => {
      if (ele?.id == value) {
        setTaskAllArr({ ...taskallArr, [index]: ele });
      }
    });
    setTaskArr({ ...taskArray, worksite_timesheet_workdone: allPrev });
  };
  const onChangeWorked = (e, index) => {
    if (e > 0) {
      let allPrev = [...taskArray?.worksite_timesheet_workdone];
      allPrev[index].spent = e;
      if (e <= isMax) {
        const totalSpent = taskArray?.worksite_timesheet_workdone?.reduce(
          (total, item) => Number(total) + Number(item.spent),
          0
        );
        const remaining = isMax - totalSpent;
        const incrementPerItem =
          Number(remaining) /
          Number(taskArray?.worksite_timesheet_workdone?.length - 1);

        taskArray?.worksite_timesheet_workdone?.forEach((item, indexValue) => {
          if (Number(indexValue !== index)) {
            item.spent = Number(item.spent) + incrementPerItem;
          }
          item.spent = item.spent?.toFixed(1);
        });
      } else {
        taskArray?.worksite_timesheet_workdone?.forEach((item, indexValue) => {
          if (Number(indexValue !== index)) {
            item.spent = `00.00`;
          }
        });
      }
      setTaskArr({ ...taskArray, worksite_timesheet_workdone: allPrev });
      setTaskAllArr({ ...taskallArr, worksite_timesheet_workdone: allPrev });
    }
  };
  const deleteTask = (index) => {
    let deleteArr = taskArray?.worksite_timesheet_workdone.splice(index, 1);
    if (isMax) {
      taskArray?.worksite_timesheet_workdone.map((item) => {
        item.spent = (
          isMax / taskArray?.worksite_timesheet_workdone.length
        ).toFixed(2);
        return item;
      });
    }
    setTaskArr({
      ...taskArray,
      worksite_timesheet_workdone: taskArray?.worksite_timesheet_workdone,
    });
    setTaskAllArr({
      ...taskallArr,
      worksite_timesheet_workdone: taskArray?.worksite_timesheet_workdone,
    });
  };

  const deleteTaskAllow = async (item) => {
    let det = taskArray?.task_allowances?.splice(item, 1);
    var filtered = taskAllowData.filter(function (el) {
      return el.id != item?.id;
    });
    setTaskAllowData(filtered);
    let res = await deleteTaskAllowance(item, selectedItem);
    if (res?.status) {
      setTaskArr({ ...taskArray, task_allowances: taskArray?.task_allowances });
      toast?.success("Task allowance deleted successfully");
    }
  };
  const assignTask = async () => {
    const task = {
      id: "",
      area_id: "",
      task_id: "",
      spent: "",
      hour: "",
      minute: "",
      max: "",
    };
    taskArray?.worksite_timesheet_workdone.push(task);
    setTaskArr({ ...taskArray });
    setTaskAllArr({ ...taskallArr });
    let [startedHour, startedMin] = taskArray?.started?.split(":");
    let [finishedHour, finishedMin] = taskArray?.finished?.split(":");
    let remainHours, remainMinutes;
    if (parseInt(startedMin) > parseInt(finishedMin)) {
      remainHours = parseInt(finishedHour) - parseInt(startedHour) - 1;
      remainMinutes = parseInt(finishedMin) + 60 - parseInt(startedMin);
    } else {
      remainHours = parseInt(finishedHour) - parseInt(startedHour);
      remainMinutes = parseInt(finishedMin) - parseInt(startedMin);
    }
    if (taskArray?.worksite_timesheet_workdone.length) {
      taskArray?.worksite_timesheet_workdone.map((item) => {
        item.max = `${remainHours}.${remainMinutes}`;
        if (remainHours >= 0) {
          const totalMinutes =
            parseInt(remainHours, 10) * 60 + parseInt(remainMinutes, 10);
          setIsMax(
            Number(
              `${Math.floor(totalMinutes / 60)}.${
                ((totalMinutes % 60) / 6).toFixed(1) * 10
              }`
            )
          );
          const dividedMinutes =
            totalMinutes / taskArray?.worksite_timesheet_workdone.length;
          item.hour = Math.floor(dividedMinutes / 60);
          item.minute = ((dividedMinutes % 60) / 6).toFixed(1) * 10;
          item.spent = `${Math.floor(dividedMinutes / 60)}.${
            ((dividedMinutes % 60) / 6).toFixed(1) * 10
          }`;
        } else {
          item.spent = "00.00";
        }
      });
    }
    const param = { worksite_id: id?.id };
    const data = await GetAreas(param);
    if (data?.status) {
      setAreaData(data?.data?.data);
    }
  };
  const fetchTaskList = async (areaId) => {
    const param = {
      id: areaId,
    };
    const data = await GetAreaByIdTab(param);
    if (data?.status) {
      let allData = [];
      data?.data?.data?.hourly_rate_tasks_estimations?.map((hrt, index) => {
        allData?.push({
          ...hrt,
          task_abbrevation: hrt?.hourly_rate_task?.abbreviation,
          task_name: hrt?.hourly_rate_task?.name,
          type: "Hourly Rate",
        });
      });

      data?.data?.data?.productivity_orders?.map((po, index) => {
        allData?.push({
          ...po,
          task_abbrevation: po?.code,
          task_name: po?.name,
          type: "Productivity Order",
        });
      });
      setTaskData(allData);
    }
  };
  const SaveTask = async () => {
    setIsLoading(true);
    let res = await confirmWork(taskArray, selectedItem, id?.id, selectedArea);
    if (res?.status) {
      setIsLoading(false);
      closeAssignTaskWorker();
      toast.success("Work Saved Successfully");
      if (workingTable) {
        handle();
      }
      if (isShift) {
        fetchShiftDetails();
      }
    }
  };
  const signOffTask = async () => {
    setIsLoading(true);
    let res = await confirmWork(
      taskArray,
      selectedItem,
      id?.id,
      selectedArea,
      "timesheet_status"
    );
    if (res?.status) {
      setIsLoading(false);
      closeAssignTaskWorker();
      toast.success("Work Saved Successfully");
      handleWorksite?.getWorkers("Complete");
    }
  };
  useEffect(() => {
    (async () => {
      let res = await getConfirmWork(selectedItem);
      if (res?.status) {
        if (res?.data?.data?.length > 0) {
          var startTime = `${parseFloat(res?.data?.data[0]?.started).toFixed(
            2
          )}`;
          startTime = ("0" + startTime).slice(-5).replace(".", ":");
          var finishTime = `${parseFloat(res?.data?.data[0]?.finished).toFixed(
            2
          )}`;
          finishTime = ("0" + finishTime).slice(-5).replace(".", ":");
          setTaskArr({
            ...taskArray,
            started: startTime,
            finished: finishTime,
          });
          setTaskAllArr({
            ...taskallArr,
            started: startTime,
            finished: finishTime,
          });
          if (isComplted) {
            if (res?.data?.data[0]?.notes !== "") {
              setNotesOpen(true);
            }
            res?.data?.data[0]?.works?.map((work, index) => {
              fetchTaskList(work?.area_id);
            });
            const param = { worksite_id: id?.id };
            const data = await GetAreas(param);
            if (data?.status) {
              setAreaData(data?.data?.data);
            }
            setTaskArr({
              ...taskArray,
              started: startTime,
              finished: finishTime,
              break: res?.data?.data[0]?.break,
              notes: res?.data?.data[0]?.notes,
              worksite_timesheet_workdone: res?.data?.data[0]?.works,
            });
            console.log("the databdhddd", res?.data?.data[0]?.works);

            let tTasks = {};
            if (res?.data?.data?.[0]?.works?.length > 0) {
              for (
                let index = 0;
                index < res?.data?.data?.[0]?.works?.length;
                index++
              ) {
                const element = res?.data?.data?.[0]?.works?.[index];

                if (element.hourly_rate_tasks_estimations.length > 0) {
                  tTasks[index] = element.hourly_rate_tasks_estimations[0];
                }
              }
              console.log(
                "============================dasdasdasdasdasds =",
                tTasks
              );
            }
            setTaskAllArr({
              ...taskallArr,
              started: startTime,
              finished: finishTime,
              break: res?.data?.data[0]?.break,
              notes: res?.data?.data[0]?.notes,
              worksite_timesheet_workdone: res?.data?.data[0]?.works,
              ...tTasks,
            });
          }
        } else {
          setTaskArr({ ...taskArray, started: "00:00", finished: "00:00" });
          setTaskAllArr({ ...taskallArr, started: "00:00", finished: "00:00" });
        }
      }
    })();
  }, []);
  useEffect(() => {
    setTaskArr({ ...taskArray, task_allowances: selectedTaskArr });
    setTaskAllArr({ ...taskallArr, task_allowances: selectedTaskArr });
  }, [taskAllowData]);

  return (
    <>
      <Modal
        className="clockin-modal p-0 "
        contentClassName="paading -0"
        open={assignTaskModal}
        onCancel={closeAssignTaskWorker}
        width={width > 767 ? "50%" : "100%"}
        height={width > 767 ? "50%" : "100%"}
        footer={null}
      >
        <div className={"text-[24px] font-bold mt-3"}>Creating Work Shift</div>
        <div className="font-bold mt-8 mb-2 ">Shift Length</div>
        {width > 767 ? (
          <div>
            {" "}
            <section className=" grid grid-cols-3 mb-2 gap-2">
              <div>Started</div>
              <div>Finished</div>
              <div>Break</div>
            </section>
            <section className=" grid grid-cols-3 gap-2">
              <div>
                {/* <TimePicker
                            className="mass-absent-opt h-8"
                            onChange={handleTimeChange}
                            value={dayjs(taskArray?.started, 'HH:mm')}
                            format={'HH:mm'}
                        /> */}
                <input
                  type="time"
                  onChange={handleTimeChange}
                  value={taskArray?.started}
                  className="mass-absent-opt h-8 border w-full rounded"
                  placeholder="Start time"
                />
              </div>
              <div>
                {/* <TimePicker className="mass-absent-opt h-8" onChange={handleTimeChangeFinished} value={dayjs(taskArray?.finished == '0' ? '00:00' : taskArray?.finished, 'HH:mm')}
                            format={'HH:mm'} /> */}
                <input
                  type="time"
                  onChange={handleTimeChangeFinished}
                  value={taskArray?.finished}
                  className="mass-absent-opt h-8 border w-full rounded"
                  placeholder="Finish time"
                />
              </div>
              <div>
                <Space direction="vertical" className="w-full">
                  <div>
                    <Select
                      className="assign-work w-10"
                      onChange={handleChangeBreak}
                      style={{ width: "100%" }}
                      value={taskArray?.break}
                    >
                      <Select.Option value={"yes"}>Yes</Select.Option>
                      <Select.Option value={"no"}>No</Select.Option>
                    </Select>
                  </div>
                </Space>
              </div>
            </section>
          </div>
        ) : (
          <section className=" grid grid-rows-3 mb-2 gap-2">
            <div>Started</div>
            <div>
              {/* <TimePicker
                            className="mass-absent-opt h-8"
                            onChange={handleTimeChange}
                            value={dayjs(taskArray?.started, 'HH:mm')}
                            format={'HH:mm'}
                        /> */}
              <input
                type="time"
                onChange={handleTimeChange}
                value={taskArray?.started}
                className="mass-absent-opt h-8 border w-full rounded"
                placeholder="Start time"
              />
            </div>
            <div>Finished</div>
            <div>
              {/* <TimePicker className="mass-absent-opt h-8" onChange={handleTimeChangeFinished} value={dayjs(taskArray?.finished == '0' ? '00:00' : taskArray?.finished, 'HH:mm')}
                            format={'HH:mm'} /> */}
              <input
                type="time"
                onChange={handleTimeChangeFinished}
                value={taskArray?.finished}
                className="mass-absent-opt h-8 border w-full rounded"
                placeholder="Finish time"
              />
            </div>
            <div>Break</div>

            <div>
              <Space direction="vertical" className="w-full">
                <div>
                  <Select
                    className="assign-work w-5"
                    onChange={handleChangeBreak}
                    style={{ width: "100%" }}
                    value={taskArray?.break}
                  >
                    <Select.Option value={"yes"}>Yes</Select.Option>
                    <Select.Option value={"no"}>No</Select.Option>
                  </Select>
                </div>
              </Space>
            </div>
          </section>
        )}

        <section className="flex justify-between mt-4">
          <div>Paid Time : {isNaN(isMax) ? 0 : isMax} hrs</div>
          <div>Time Assigned : {isNaN(isMax) ? 0 : isMax} hrs</div>
        </section>
        <section className="flex justify-between px-6 mt-4 h-9 bg-[#E8F3FF] items-center">
          <div className="text-black font-[500]">Payroll Notes</div>
          <div className="flex justify-center items-center">
            <FaPlus className="text-sm  p-1 w-[20px] h-[20px] cursor-pointer marginAuto text-[#0F4C7D]" />
            <span
              className="text-[#0F4C7D] text-xs cursor-pointer"
              onClick={toggleNotes}
            >
              Add
            </span>
          </div>
        </section>
        {notesOpen && (
          <TextArea
            className="mt-5"
            rows={4}
            onChange={(e) => addNotes(e)}
            value={taskArray?.notes}
          />
        )}
        <section className="h-16 bg-[#004175] mt-9 flex items-center justify-between  px-5">
          <div
            className={
              width > 767
                ? "text-[18px] text-[#FFFFFF] "
                : "text-[12px] text-[#FFFFFF] "
            }
          >
            Task Details for the Shifts
          </div>
          <div>
            <Button
              className={
                width > 767
                  ? "flex justify-center items-center py-3 border  text-white h-[42px] w-[132px] rounded-sm"
                  : "flex justify-center items-center py-2 border-none text-white h-[33px] w-[92px] text-xs"
              }
              onClick={assignTask}
            >
              Assign Task
              <div
                className={
                  width > 767
                    ? "bg-orange-500 p-2 rounded-md text-white ml-2"
                    : "bg-orange-500 p-2 rounded-md text-white ml-2 text-xs"
                }
              >
                <FaPlus />
              </div>
            </Button>
          </div>
        </section>
        {taskArray?.worksite_timesheet_workdone?.length > 0 && (
          <>
            {taskArray?.worksite_timesheet_workdone?.map((item, index) => (
              <div key={index}>
                {width < 767 ? (
                  <>
                    <div className="mt-5">Select Area</div>
                    <Select
                      className="assign-work"
                      onChange={(e) => handleChange(e, index)}
                      style={{ width: "100%" }}
                      value={item?.area_id}
                    >
                      {areaData?.length > 0 &&
                        areaData?.map((area, index) => (
                          <Select.Option value={area?._id} key={index}>
                            {area?.name}
                          </Select.Option>
                        ))}
                    </Select>

                    <div className="">Select Task</div>
                    <Select
                      className="assign-work"
                      onChange={(e) => handleChangeTask(e, index)}
                      style={{ width: "100%" }}
                      value={item?.task_id}
                    >
                      {taskData?.length > 0 &&
                        taskData?.map((task, index) => (
                          <Select.Option value={task?.id} key={index}>
                            {task?.task_name}
                          </Select.Option>
                        ))}
                    </Select>

                    <div>Time Worked</div>
                    <section className="grid grid-cols-3 items-center">
                      <InputNumber
                        onChange={(e) => onChangeWorked(e, index)}
                        max={isMax}
                        className="h-8 col-span-2"
                        value={item?.spent}
                      />
                      <button
                        className="mx-2"
                        onClick={() => deleteTask(index)}
                      >
                        <FaTrashAlt />
                      </button>
                    </section>
                  </>
                ) : (
                  <div key={index}>
                    <section className="grid grid-cols-3 gap-2 mt-9 mb-1">
                      <div className="">Select Area</div>
                      <div className="">Select Task</div>
                      <div> Time Worked</div>
                    </section>
                    <section className="grid grid-cols-3 gap-2">
                      <Select
                        className="assign-work"
                        onChange={(e) => handleChange(e, index)}
                        style={{ width: "100%" }}
                        value={item?.area_id}
                      >
                        {areaData?.length > 0 &&
                          areaData?.map((area, index) => (
                            <Select.Option value={area?._id} key={index}>
                              {area?.name}
                            </Select.Option>
                          ))}
                      </Select>

                      <div className="">
                        <Select
                          className="assign-work"
                          onChange={(e) => handleChangeTask(e, index)}
                          style={{ width: "100%" }}
                          value={item?.task_id}
                        >
                          {taskData?.length > 0 &&
                            taskData?.map((task, index) => (
                              <Select.Option value={task?.id} key={index}>
                                {task?.task_name}
                              </Select.Option>
                            ))}
                        </Select>
                      </div>
                      <div className="grid grid-cols-3  items-center">
                        <InputNumber
                          onChange={(e) => onChangeWorked(e, index)}
                          max={isMax}
                          className="h-8 col-span-2"
                          value={item?.spent}
                        />
                        <button
                          className="mx-2"
                          onClick={() => deleteTask(index)}
                        >
                          <FaTrashAlt />
                        </button>
                      </div>
                    </section>
                    <section className="flex justify-between mt-5 border-b-2 pb-7  ">
                      {(
                        taskallArr[index]?.total
                          ? taskallArr[index]?.total
                          : taskallArr[index]?.remaining
                      ) ? (
                        <div>
                          Total Allocated Time :{" "}
                          {taskallArr[index]?.total
                            ? taskallArr[index]?.total
                            : taskallArr[index]?.remaining}{" "}
                          hrs
                        </div>
                      ) : (
                        <div>Total Allocated Time :0 hrs </div>
                      )}

                      {taskallArr[index]?.po > taskallArr[index]?.total && (
                        <div style={{ color: "red" }}>
                          Time Overallocation Time :{" "}
                          {taskallArr[index]?.po - taskallArr[index]?.total} hrs
                        </div>
                      )}

                      {(
                        taskallArr[index]?.po
                          ? taskallArr[index]?.po
                          : taskallArr[index]?.time_spent
                      ) ? (
                        <div>
                          Total worked Time :{" "}
                          {taskallArr[index]?.po
                            ? taskallArr[index]?.po
                            : taskallArr[index]?.time_spent}{" "}
                          hrs
                        </div>
                      ) : (
                        <div>Total worked Time : 0 hrs</div>
                      )}
                    </section>

                    {/* <SelectTask />
                                  <SelectTask /> */}
                  </div>
                )}
              </div>
            ))}
            <div className="mt-8 mb-8" />
            {isComplted && taskArray?.task_allowances?.length > 0 && (
              <div>
                <p className="bg-[#E8F3FF] font-[500] p-2">Task Allowance</p>
                <div className="mb-6">
                  {taskAllowData?.map((item, i) => (
                    <div key={i}>
                      <div className="flex justify-between m-2">
                        <p>{item?.name}</p>
                        <p
                          className="cursor-pointer"
                          onClick={() => deleteTaskAllow(item)}
                        >
                          <Trash />
                        </p>
                      </div>
                      <hr />
                    </div>
                  ))}
                </div>
              </div>
            )}

            <section className=" flex justify-between mb-4">
              <div className="">
                <Button
                  className="btn btn-blue bg-blue-disable w-32  ml-3 h-9 rounded-sm"
                  onClick={shiftOpen}
                >
                  Split Shift
                </Button>
                {isComplted && (
                  <Button
                    className="btn btn-blue bg-blue-disable ml-3 h-9 rounded-sm"
                    onClick={taskAllowanceOpen}
                  >
                    Add Task Allowance
                  </Button>
                )}
              </div>
              {isComplted ? (
                <Button
                  key="save"
                  className="btn btn-blue bg-blue-disable w-32 h-9 rounded-sm"
                  onClick={signOffTask}
                >
                  Sign-Off
                </Button>
              ) : (
                <Button
                  key="save"
                  disabled={!saveDisable}
                  className="btn btn-blue bg-blue-disable w-32 h-9 rounded-sm"
                  onClick={SaveTask}
                >
                  {isLoading ? <ButtonSpinner /> : "Save"}
                </Button>
              )}
            </section>
            {!displayTextWor && (
              <div className="w-full text-center bg-[#E8F3FF] font-[500] items-center flex justify-center">
                <AiOutlineInfoCircle className="text-[#F4511E] mr-1 text-[20px]" />
                <p>
                  This shift is marked as a split shift so it receives paid
                  breaks as per usual.
                </p>
              </div>
            )}
            {displayTextWor && (
              <div className="w-full text-center bg-[#E8F3FF] font-[500] items-center flex justify-center">
                <AiOutlineInfoCircle className="text-[#F4511E] mr-1 text-[20px]" />
                <p>This split shift is marked successfully</p>
              </div>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default CreatingWorkShiftModal;
