import React, { useState, useCallback, useEffect } from "react";
import { SettingService } from "../../../config/axiosUrl";
import { Checkbox } from "antd";

const Companies = ({ filterWorkers, filter }) => {
  const [companies, setCompanies] = useState([]);
  // const [selectedCompanies, setSelectedCompanies] = useState([]);

  const apiFunctions = {
    getWorkers: useCallback(() => {
      SettingService.get("api/v1/setting/company/get-company-data").then(
        (res) => {
          setCompanies(res?.data?.data?.data || []);
        }
      );
    }, []),
  };

  useEffect(() => {
    apiFunctions.getWorkers();
  }, []);

  // const handleCheckboxChange = (companyId) => {
  //   if (selectedCompanies.includes(companyId)) {
  //     setSelectedCompanies(selectedCompanies.filter((id) => id !== companyId));
  //   } else {
  //     setSelectedCompanies([...selectedCompanies, companyId]);
  //   }
  // };

  return (
    <div className=" bg-white p-30">
      {companies.map((company, index) => (
        <div
          key={index}
          className="flex justify-between items-center border-b border-[#E8E8E8] py-2 fs-12"
        >
          {company?.name}
          <Checkbox
            className="ml-3"
            onChange={() => filterWorkers("Companies", company.id)}
            checked={filter.includes(company.id)}
          />
        </div>
      ))}
    </div>
  );
};

export default Companies;
