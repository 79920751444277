import React, { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { Card, DatePicker } from "antd";
import { TableDefaultPage } from "../../../../../components/default/defaultPage";
import { useParams } from "react-router-dom";
import { TextShimmer } from "../../../../../components/shimmer/shimmer";
import OverviewDefaultSvg from "../../../../../assets/svg/overviewDefaultSvg";
import { GetOverviewTask } from "../../../../../helper/worksite/worksiteDetails.js/overview";
import StackedWorksite from "../Charts/stackedChartWorksite";
import { toast } from "react-toastify";
import useWindowDimensions from "../../../../../utils/routes/useWindowDimensions";

const OverViewsWorksite = ({ selectedTask, overview, setOverView }) => {
  const { width } = useWindowDimensions();
  let { id } = useParams();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  let deafaulPage = {
    disclaimer: "No data available for Displaying Graphs and stats",
    description:
      "Before estimations can be created, the worksite needs to have areas added.",
    buttonText: "Create Area",
    svg: <OverviewDefaultSvg />,
    redirect: `/admin/worksite/details/areas-create/${id}`,
  };

  const handleOverview = {
    getOverview: async () => {
      const params = {
        worksite_id: id,
        from: startDate ? startDate : "",
        to: endDate ? endDate : "",
      };

      let data = await GetOverviewTask(params);
      if (data?.status) {
        setOverView({
          ...overview,
          data: data?.data?.data,
          isShimmer: false,
        });
      }
    },
  };

  useEffect(() => {
    (async () => {
      if (!(new Date(startDate) >= new Date(endDate))) {
        let data = await handleOverview?.getOverview();
      }
    })();
    if (new Date(startDate) >= new Date(endDate) && startDate && endDate) {
      toast.error("Invalid start date");
    }
  }, [startDate, endDate]);
  const onChangeStartDate = (date, dateString) => {
    setStartDate(dateString);
  };
  const onChangeEndDate = (date, dateString) => {
    setEndDate(dateString);
  };

  return (
    <>
      <div className="pt-2 ">
        <div className={width > 767 ? "flex" : "flex bigText ml-3"}>
          <div className="font-bold">Common Fire Walls</div>
          <span className="ml-2 mr-2 font-bold">&gt;</span>
          <div>
            <p className="font-bold">{selectedTask?.task_name}</p>
          </div>
        </div>
        <div
          className="pt-4"
          style={{
            overflowY: width <= 767 ? "scroll" : "visible",
            maxHeight: width <= 767 ? "50vh" : "auto",
          }}
        >
          {overview?.isShimmer ? (
            <TextShimmer data={{ gap: 15, line: 20 }} />
          ) : (
            <>
              {overview?.data?.overview?.length <= 0 ? (
                <div className="py-8 h-[70vh]">
                  <TableDefaultPage data={deafaulPage} />
                </div>
              ) : (
                <div>
                  <Card bordered={false} style={{ boxShadow: "none" }}>
                    <div className="flex justify-between items-center">
                      <h2 className="tex-3xl font-bold">Task Overview</h2>
                      <div className="flex items-center">
                        <button className="mr-2">
                          <DatePicker
                            onChange={onChangeStartDate}
                            format={"YYYY-MM-DD"}
                          />
                        </button>
                        <FaArrowRight className="text-[#000] mr-3" />
                        <button>
                          <DatePicker
                            onChange={onChangeEndDate}
                            format={"YYYY-MM-DD"}
                          />
                        </button>
                      </div>
                    </div>
                    <div className="lg:w-9/12 ">
                      {
                        <StackedWorksite
                          data={overview}
                          setData={setOverView}
                        />
                      }
                    </div>
                  </Card>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default OverViewsWorksite;
