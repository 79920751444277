import React from "react";
import ArchiveHeader from "./ArchiveHeader";
import ArchiveRoutes from "./ArchiveRoutes";

const Archive = () => {
  return (
    <div className="p-6">
      <ArchiveHeader />
      <ArchiveRoutes />
    </div>
  );
};

export default Archive;
