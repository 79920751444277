import { Progress } from "antd";
import React, { useState } from "react";
import LogCauseModal from "../Modal/LogCauseModal";
import { FaEdit } from "react-icons/fa";

const TabTasksTable = ({
  data,
  setData,
  handle,
  visibleModal,
  setVisibleModal,
  log,
  selectedTab,
}) => {
  const openModal = (item) => {
    console.log(item);

    setVisibleModal(true);
    setData({
      ...data,
      selectedItem: item,
      log: item?.log_cause?.[0]?.log,
      errors: {
        ...data?.errors,
        log: "",
      },
    });
  };
  return (
    <>
      {visibleModal && (
        <LogCauseModal
          data={data}
          setData={setData}
          handle={handle}
          setVisibleModal={setVisibleModal}
        />
      )}

      <table className="w-full">
        <thead className="bg-[#E8F3FF] text-center">
          <tr className="text-left">
            <th className=" px-4 py-3 "></th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">Task</div>
            </th>
            <th className=" px-4 py-3 "></th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">Area</div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">
                {selectedTab == "2" ? "Cause" : "Actions"}
              </div>
            </th>
            {selectedTab == "2" && <th className=" px-4 py-3 "></th>}
          </tr>
        </thead>
        <tbody className="bg-white">
          {data?.data?.map((item, index) => (
            <>
              <tr key={index} className="border-b border-[#F0F0F0]">
                <td className="px-4 py-3 text-center">
                  <div className="flex items-center justify-center">
                    <p className="text-xs text-gray-400">
                      {item?.hourly_rate_task?.abbreviation}
                    </p>
                  </div>
                </td>
                <td className="px-4 py-3 text-center">
                  <div className="flex items-center justify-center">
                    <p className="mx-3 font-bold text-lg">
                      {item?.hourly_rate_task?.name}
                    </p>
                  </div>
                </td>
                <td className="px-4 py-3 text-center">
                  <div className="flex items-center justify-center">
                    {selectedTab == "2" ? (
                      <Progress
                        className="w-auto h-auto"
                        strokeColor={item?.warning ? "red" : "green"}
                        success={{
                          percent: 101,
                        }}
                        status="normal"
                        strokeWidth={8}
                        type="circle"
                        percent={item?.spent}
                      />
                    ) : (
                      <Progress
                        status="normal"
                        className="w-auto h-auto"
                        strokeColor={item?.warning ? "red" : "green"}
                        strokeWidth={8}
                        type="circle"
                        percent={item?.spent}
                      />
                    )}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex items-center justify-center">
                    {item?.areas?.name}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex items-center justify-center">
                    {selectedTab == "2" ? (
                      <>
                        <div className="flex items-center justify-center">
                          {item?.log_cause?.[0]?.log}
                        </div>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={() => {
                            openModal(item);
                          }}
                          className={
                            item?.log_cause?.length > 0
                              ? "px-3 py-1 text-sm text-green-700 underline rounded-md"
                              : "px-3 py-1 text-sm text-blue-700 underline rounded-md"
                          }
                        >
                          Log Cause
                        </button>
                      </>
                    )}
                  </div>
                </td>
                {selectedTab == "2" && (
                  <td>
                    <div
                      className="pl-2"
                      onClick={() => {
                        openModal(item);
                      }}
                    >
                      <FaEdit />
                    </div>
                  </td>
                )}
              </tr>
              {/*  ) : (
                <></>
              )} */}
            </>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default TabTasksTable;
