import React, { Fragment, useCallback, useEffect } from "react";
import CreateNewSiteAllowance from "./CreateNewSiteAllowanceModal";
import { Button } from "antd";
import { FaPlus, FaSearch } from "react-icons/fa";
import SiteAllowanceTable from "./SiteAllowanceTable";
import { useState } from "react";
import { useMemo } from "react";
import { PayRollService } from "../../../../../config/axiosUrl";
import { GetWorkSite } from "../../../../../helper/worksite/worksite";
import PopupSelector from "./PopupSelector";
import Pagination from "../../../../../components/PaginationAPI/Pagination";
import { TextShimmer } from "../../../../../components/shimmer/shimmer";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import DefaulGlobal from "../../../../../components/default/DefaultGloabal";
import { SiteAllowanceDefault } from "../../../../../assets/svg";
import Confirmation from "../../../../../components/Confirmation";

const SiteAllowence = () => {
  const [visibleModal, setVisibleModal] = useState(false);

  const [workSites, setWorkSites] = useState({
    isLoading: true,
    modalOpen: false,
    currentID: "",
    worksites: [],
    selected: [],
    allLoaded: false,
  });

  const [allSites, setAllSites] = useState({
    data: [],
    loading: true,
    search: "",
  });

  const [submitting, setSubmitting] = useState({
    assign: false,
    allowance: false,
    delete: false,
  });

  const [currentEdit, setCurrentEdit] = useState({
    selected: {},
    selectedIndex: -1,
  });

  const [onArchive, setArchive] = useState({
    visible: false,
    data: {},
    isSubmitting: false,
    reget: false,
  });

  const toggleModal = () => {
    setVisibleModal((prev) => !prev);
    pageFunctions.resetForm();
  };

  function onEdit(workSite, index) {
    setCurrentEdit({
      selected: workSite,
      selectedIndex: index,
    });
    setVisibleModal((prev) => !prev);
  }

  const pageFunctions = {
    onAssign: useMemo(
      () => (item) => {
        setWorkSites((prev) => ({
          ...prev,
          modalOpen: true,
          isLoading: false,
          currentID: item,
          selected:
            item?.payroll_site_allowance_data?.map(
              (item) => item?.worksite_id
            ) || [],
        }));
      },
      []
    ),
    getWorkSite: useMemo(
      () => async (search, cursor) => {
        const worksite = await GetWorkSite({ search, cursor });

        setWorkSites((prev) => {
          let filtered = search ? worksite?.data?.data : [];
          if (!search) {
            const ids = [];
            const sites = prev.worksites.concat(worksite?.data?.data);

            sites.forEach((item) => {
              if (!ids.includes(item.id)) {
                ids.push(item.id);
                filtered.push(item);
              }
            });
          }

          return {
            ...prev,
            isLoading: false,
            worksites: filtered,
            allLoaded: worksite.extra.totalItems <= filtered.length,
          };
        });
      },
      []
    ),
    closeSelector: useMemo(
      () => () => {
        setWorkSites((prev) => ({
          ...prev,
          isLoading: true,
          modalOpen: false,
          currentID: "",
          selected: [],
        }));
      },
      []
    ),
    onSelect: (id) => {
      setWorkSites((prev) => {
        let selected = workSites.selected;

        if (selected.includes(id)) {
          selected.splice(selected.indexOf(id), 1);
        } else {
          selected.push(id);
        }

        return { ...prev, selected };
      });
    },
    resetForm: function () {
      setCurrentEdit({
        selected: {},
        selectedIndex: -1,
      });
      reset();
    },
  };

  const { handleSubmit, control, reset, watch } = useForm({
    values: currentEdit.selected,
  });

  const apiFunctions = {
    get: useMemo(
      () =>
        function (res) {
          setAllSites((prev) => ({
            ...prev,
            data: res?.data?.data?.data || [],
            loading: false,
          }));
        },
      []
    ),
    save: async function (data) {
      await new Promise((resolve) => {
        setSubmitting((prev) => ({
          ...prev,
          allowance: true,
        }));

        resolve(true);
      });

      PayRollService.post(
        "/api/v1/setting/payroll/edit-create-site-allowance",
        {
          ...data,
          isSave: data?.id ? 0 : 1,
        }
      )
        .then((res) => {
          if (res?.data?.status) {
            toggleModal();

            let apiRes = res?.data?.data?.data;
            let data = [...allSites?.data];
            let index = data.findIndex((item) => item.id === apiRes.id);

            if (index > -1) {
              let { payroll_site_allowance_data, ...currentEdit } = data[index];

              currentEdit = apiRes;
              data[index] = {
                payroll_site_allowance_data,
                ...currentEdit,
              };

              setAllSites((prev) => ({
                ...prev,
                data,
              }));
            }
            window.location.reload();
            pageFunctions.resetForm();
          }

          toast[res?.data?.status ? "success" : "error"](res?.data?.message);

          setSubmitting((prev) => ({
            ...prev,
            allowance: false,
          }));
        })
        .catch((err) => {
          toast.error("Failed to save data");

          setSubmitting((prev) => ({
            ...prev,
            allowance: false,
          }));
        });
    },
    delete: async function (state) {
      setAllSites((prev) => ({
        ...prev,
        data: state,
      }));
    },
    assign: useMemo(
      () => async () => {
        await new Promise((resolve) => {
          setSubmitting((prev) => ({
            ...prev,
            assign: true,
          }));
          resolve(true);
        });

        PayRollService.post("/api/v1/setting/payroll/assign-worksites", {
          ids: workSites.selected,
          deleted_ids: [],
          payroll_site_allowance_id: workSites?.currentID?.id,
        })
          .then((res) => {
            if (res?.data?.status) {
              toast.success(res?.data?.message);
              setSubmitting((prev) => ({
                ...prev,
                assign: false,
              }));
              pageFunctions.closeSelector();
            } else {
              toast.error(res?.data?.message);
            }
            let data = allSites.data;

            let index = workSites?.currentID?.index;
            console.log(res);
            data[index] = res?.data?.data?.data;

            setAllSites((prev) => ({
              ...prev,
              data,
            }));
          })
          .catch((err) => {
            setSubmitting((prev) => ({
              ...prev,
              assign: false,
            }));
          });
      },
      [workSites.selected, workSites?.currentID]
    ),
    deleteSiteAllowance: useCallback((data) => {
      setAllSites((prev) => ({
        ...prev,
        data,
      }));
    }, []),
  };

  const arciveAgreements = useCallback(async () => {
    await new Promise((resolve) => {
      setArchive((prev) => ({
        ...prev,
        isSubmitting: true,
      }));
      resolve(true);
    });
    PayRollService.post(
      "api/v1/payroll/setting/archive-worksites-site-allowance-by-id",
      {
        id: onArchive.data?.id || "",
      }
    )
      .then((res) => {
        if (res?.data?.status) {
          setArchive((prev) => ({
            ...prev,
            isSubmitting: false,
            visible: false,
            data: {},
            reget: !prev.reget,
          }));
          toast.success(res?.data?.message);
        } else {
          setArchive((prev) => ({
            ...prev,
            isSubmitting: false,
          }));
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        setArchive((prev) => ({
          ...prev,
          isSubmitting: false,
        }));
        toast.error("Failed to Archive Agreements");
      });
  }, [onArchive]);

  const onArchiveClick = useCallback((data) => {
    setArchive((prev) => ({
      ...prev,
      visible: !!data,
      data: data || {},
    }));
  }, []);

  useEffect(() => {
    pageFunctions.getWorkSite();
  }, []);

  return (
    <div>
      <Confirmation
        open={onArchive.visible}
        title={`Are you sure want to archive this Site Allowance`}
        details={onArchive.data?.name}
        onConfirm={arciveAgreements}
        isSubmitting={onArchive.isSubmitting}
        onCancel={() => onArchiveClick()}
        buttonText="Archive"
      />
      {allSites.loading ? (
        <TextShimmer
          data={{
            line: 25,
            gap: 15,
            className: "bg-white",
          }}
        />
      ) : (
        <Fragment>
          <CreateNewSiteAllowance
            toggleModal={toggleModal}
            visible={visibleModal}
            save={apiFunctions.save}
            data={currentEdit.selected}
            handleSubmit={handleSubmit}
            control={control}
            isEdit={currentEdit.selected}
            isSubmitting={submitting.allowance}
            watch={watch}
          />
          <PopupSelector
            title="Available Worksites"
            allLoaded={workSites.allLoaded}
            loading={workSites.isLoading}
            isSubmitting={submitting.assign}
            visible={workSites.modalOpen}
            onHide={pageFunctions.closeSelector}
            onSearch={pageFunctions.getWorkSite}
            data={workSites.worksites}
            onSubmit={apiFunctions.assign}
            onCheck={pageFunctions.onSelect}
            selected={workSites.selected}
            searchPlaceholder="Search for worksite"
            onScrollEnd={(search) =>
              pageFunctions.getWorkSite(
                search,
                workSites.worksites[workSites.worksites.length - 1].id
              )
            }
          />
          <div className="flex justify-between items-center bg-white p-5">
            <div className="border border-[#111111] flex items-center p-1 rounded-md w-full lg:w-1/3 my-2 bg-white">
              <FaSearch className="ml-2" />
              <input
                placeholder="Search by Site Allowance Name"
                className="h-[31px] bg-transparent text-sm w-full px-2"
                onChange={({ target: { value } }) => {
                  setAllSites((prev) => ({
                    ...prev,
                    search: value,
                  }));
                }}
              />
            </div>
            <Button
              onClick={toggleModal}
              className="flex justify-center items-center py-3 border border-[#111111] h-[42px]"
            >
              Create New
              <div className="bg-orange-500 p-2 rounded-md text-white ml-3">
                <FaPlus />
              </div>
            </Button>
          </div>

          <div className="bg-white">
            <SiteAllowanceTable
              data={allSites.data || []}
              toggleModal={onEdit}
              deleteRecord={apiFunctions.delete}
              onAssign={pageFunctions.onAssign}
              allSites={allSites}
              onArchive={onArchiveClick}
            />
          </div>
          {!allSites?.data?.length && (
            <DefaulGlobal
              DefaultImage={SiteAllowanceDefault}
              header="No Worker Allowances found"
              description={"Create your Worker Allowances now"}
              buttonTitle="Create New"
              pageHandler={toggleModal}
            />
          )}
        </Fragment>
      )}
      <div className="bg-white">
        <Pagination
          extraParams={{ search: allSites.search }}
          api="/api/v1/setting/payroll/get-worksites-site-allowance"
          apiService={PayRollService}
          callType="post"
          dependecy={[allSites.search, onArchive.reget]}
          getRes={apiFunctions.get}
        />
      </div>
    </div>
  );
};

export default SiteAllowence;
