import React, { useCallback, useState, useEffect } from "react";
import { FaEdit, FaSearch, FaTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import OthersettingModal from "./OthersettingModal";
import { SettingService } from "../../../config/axiosUrl";
import { Button } from "antd";
import * as XLSX from "xlsx";

const Othersettings = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedData1, setSelectedData1] = useState(null);
  const [names, setNames] = useState(null);
  const [worksitenames, setWorksiteName] = useState(null);
  const [empStatusData, setEmpStatusData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };
  const openModal = (employeeData, employeeData1, names, worksitename) => {
    setSelectedData(employeeData);
    setSelectedData1(employeeData1);
    setNames(names);
    setWorksiteName(worksitename);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const EmpReportStatus = () => {
    SettingService.get(
      `/api/v1/setting/employment-status-report?search=${searchQuery}`
    ).then((res) => {
      setEmpStatusData(res?.data?.data?.data);
    });
  };

  useEffect(() => {
    EmpReportStatus();
  }, [searchQuery]);

  const handleUpdateButtonClick = () => {
    EmpReportStatus(); // Call the function to update
  };

  const handleEmployeeStatus = () => {
    if (empStatusData && empStatusData.length > 0) {
      const titleterminated = ["Employement Status Reports"];
      const headerRow = [
        "Employee Name",
        "Card Id",
        "Date Of Birth",
        "Joined Date",
        "Email",
        "Employed as",
        "Designation",
      ];
      const subHeaderRow = ["", "", ""];
      const dataRows = [];

      empStatusData.forEach((item) => {
        item.employment_status.forEach((worksiteData) => {
          item.users.forEach((userdata, index) => {
            const Name = userdata.name;
            const date = new Date(userdata.created_at).toLocaleDateString(
              "en-GB"
            );
            const cardId = userdata.employee_id;
            const email = userdata.email;
            const emp = worksiteData.name;
            const dob = item?.dob
              ? new Date(item.dob).toLocaleDateString("en-GB")
              : "N/A";
            const designation = item?.worker_designation?.[0]?.name;
            dataRows.push([Name, cardId, dob, date, email, emp, designation]);
          });
        });
      });

      const workbook = XLSX.utils.book_new();
      const columnWidths = [
        { wch: 20 }, // Emp. Co./Last Name
        { wch: 20 }, // Emp. First Name
        { wch: 15 },
        { wch: 15 }, // Emp. Co./Last Name
        { wch: 20 }, // Emp. First Name
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ];
      const worksheet = XLSX.utils.aoa_to_sheet([
        titleterminated,
        subHeaderRow,
        headerRow,
        ...dataRows,
      ]);
      worksheet["!cols"] = columnWidths;
      XLSX.utils.book_append_sheet(workbook, worksheet, "EmpStatusReport");

      XLSX.writeFile(workbook, "empstatusreport.xlsx");
    } else {
      console.error("retrived is undefined or empty");
    }
  };
  return (
    <div className="p-6">
      <div className="pt-4 bg-white">
        <div>
          <div className="bg-white p-4">
            <div className="flex items-center justify-between">
              <div className="border border-[#111111] flex items-center p-1 rounded-md w-full lg:w-1/3 m-4 bg-white">
                <FaSearch className="ml-2" />
                <input
                  placeholder="Search by Worker Name , card id"
                  className="h-[31px] bg-transparent text-sm w-full px-2"
                  value={searchQuery}
                  onChange={handleSearch}
                />
              </div>
              <div className="ml-4 ">
                <Button
                  className="border border-[#001C33]"
                  onClick={handleEmployeeStatus}
                >
                  Export To Excel
                </Button>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto">
            <div className="min-w-full">
              <table className="min-w-full bg-white">
                <thead className="bg-[#E8F3FF] text-center">
                  <tr className="text-left">
                    <th className=" px-4 py-3 ">
                      <div className="flex items-center justify-start">
                        Employee Name
                      </div>
                    </th>
                    <th className=" px-4 py-3 ">
                      <div className="flex items-center justify-center">
                        Card Id
                      </div>
                    </th>
                    <th className=" px-4 py-3 ">
                      <div className="flex items-center justify-center">
                        Date Of Birth
                      </div>
                    </th>
                    <th className=" px-4 py-3 ">
                      <div className="flex items-center justify-center">
                        Joined Date
                      </div>
                    </th>
                    <th className=" px-4 py-3 ">
                      <div className="flex items-center justify-center">
                        Email
                      </div>
                    </th>
                    <th className=" px-4 py-3 ">
                      <div className="flex items-center justify-center">
                        Employed As
                      </div>
                    </th>
                    <th className=" px-4 py-3 ">
                      <div className="flex items-center justify-center">
                        Designation
                      </div>
                    </th>
                    <th className=" px-4 py-3 ">
                      <div className="flex items-center justify-center">
                        Action
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {empStatusData?.map((item, index) => (
                    <React.Fragment key={index}>
                      {item?.users?.map((items, index) => (
                        <React.Fragment key={index}>
                          {item?.employment_status?.map((names, index) => (
                            <React.Fragment key={index}>
                              {item?.worksites?.map((worksitename, index) => (
                                <tr
                                  key={index}
                                  className="border-b border-[#F0F0F0]"
                                >
                                  <td className="px-4 py-3">
                                    <div className="flex items-center justify-start">
                                      {items?.name}
                                    </div>
                                  </td>
                                  <td className="px-4 py-3">
                                    <div className="flex items-center justify-center">
                                      {items?.employee_id}
                                    </div>
                                  </td>
                                  <td className="px-4 py-3">
                                    <div className="flex items-center justify-center">
                                      {item?.dob
                                        ? new Date(
                                            item?.dob
                                          ).toLocaleDateString()
                                        : "N/A"}
                                    </div>
                                  </td>
                                  <td className="px-4 py-3">
                                    <div className="flex items-center justify-center">
                                      {new Date(
                                        items?.created_at
                                      ).toLocaleDateString()}
                                    </div>
                                  </td>
                                  <td className="px-4 py-3">
                                    <div className="flex items-center justify-center">
                                      {items?.email}
                                    </div>
                                  </td>
                                  <td className="px-4 py-3">
                                    <div className="flex items-center justify-center">
                                      {names?.name}
                                    </div>
                                  </td>
                                  <td className="px-4 py-3">
                                    <div className="flex items-center justify-center">
                                      {item?.worker_designation?.[0]?.name}
                                    </div>
                                  </td>
                                  <td className="px-4 py-3">
                                    <div className="flex items-center justify-center">
                                      <button
                                        className="mx-2"
                                        onClick={() =>
                                          openModal(
                                            item,
                                            items,
                                            names,
                                            worksitename
                                          )
                                        }
                                      >
                                        <FaEdit />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <OthersettingModal
            visible={isModalOpen}
            onClose={closeModal}
            data={selectedData}
            selectedData1={selectedData1}
            names={names}
            worksitenames={worksitenames}
            setEmpStatusData={setEmpStatusData}
            onUpdateButtonClick={handleUpdateButtonClick}
            EmpReportStatus={EmpReportStatus}
          />
          {/* <div className="bg-white">
            <Pagination
              api="/api/v1/setting/payroll/get-workers-allowance"
              apiService={PayRollService}
              getRes={apiFunctions.get}
              extraParams={{ search }}
              dependecy={[getAllowance, search, onArchive.reget]}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Othersettings;
