import { Input } from "antd";
import React from "react";

const CreateAgreementTable = ({
  data,
  handle,
  mon_to_thr,
  friday,
  saturday,
  sunday,
  late_and_early,
}) => {
  return (
    <div>
      <table className="w-full mt-4 agtable">
        <thead className="bg-[#E8F3FF] text-center">
          <tr className="text-left">
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-start">Time</div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">Mon to Thr</div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">Friday</div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">Saturday</div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">Sunday</div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">
                Late and Early
              </div>
            </th>
          </tr>
        </thead>

        <tbody className="bg-white">
          {data?.hours?.map((value, index) => {
            return (
              <tr className="text-left">
                <td className=" px-4 py-3 ">
                  <div className="flex items-center justify-start">
                    {value?.time}
                  </div>
                </td>
                <td className=" px-4 py-3 ">
                  <div className="flex items-center justify-start">
                    {data?.hours?.[index]?.mon_to_thr == "All Remaining" ? (
                      <select
                        className={
                          data?.errors?.mon_to_thr &&
                          data?.selectedErrorIndex == index
                            ? "w-full border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                            : "w-full"
                        }
                        name="mon_to_thr"
                        onChange={(e) => handle?.getHour(e, index)}
                      >
                        <option value="All Remaining">All Remaining</option>
                        <option value="Custom">Custom</option>
                      </select>
                    ) : (
                      <Input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        className={
                          data?.errors?.mon_to_thr &&
                          data?.selectedErrorIndex == index
                            ? "border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                            : ""
                        }
                        disabled={index > 1}
                        ref={mon_to_thr?.current?.[index]}
                        name="mon_to_thr"
                        value={data?.hours?.[index]?.mon_to_thr}
                        onChange={(e) => handle?.getHour(e, index)}
                        onInput={(e) => {
                          const value = e.target.value;
                          if (value < 0) {
                            e.target.value = 0;
                          }
                        }}
                      />
                    )}
                  </div>
                  <div>
                    {data?.errors?.mon_to_thr &&
                      data?.selectedErrorIndex == index && (
                        <p className="pt-2 text-red-600 text-sm">
                          {data?.errors?.mon_to_thr}
                        </p>
                      )}
                  </div>
                </td>
                <td className=" px-4 py-3 ">
                  <div className="flex items-center justify-start">
                    {data?.hours?.[index]?.friday == "All Remaining" ? (
                      <select
                        className={
                          data?.errors?.friday
                            ? "w-full border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                            : "w-full"
                        }
                      >
                        <option value="All Remaining">All Remaining</option>
                      </select>
                    ) : (
                      <Input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        className={
                          data?.errors?.friday &&
                          data?.selectedErrorIndex == index
                            ? "border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                            : ""
                        }
                        ref={friday?.current?.[index]}
                        name="friday"
                        disabled={index > 1}
                        value={data?.hours?.[index]?.friday}
                        onChange={(e) => handle?.getHour(e, index)}
                        onInput={(e) => {
                          const value = e.target.value;
                          if (value < 0) {
                            e.target.value = 0;
                          }
                        }}
                      />
                    )}
                  </div>
                  <div>
                    {data?.errors?.friday &&
                      data?.selectedErrorIndex == index && (
                        <p className="pt-2 text-red-600 text-sm">
                          {data?.errors?.friday}
                        </p>
                      )}
                  </div>
                </td>
                <td className=" px-4 py-3 ">
                  <div className="flex items-center justify-start">
                    {data?.hours?.[index]?.saturday == "All Remaining" ? (
                      <select
                        className={
                          data?.errors?.saturday
                            ? "w-full border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                            : "w-full"
                        }
                      >
                        <option value="All Remaining">All Remaining</option>
                      </select>
                    ) : (
                      <Input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        className={
                          data?.errors?.saturday &&
                          data?.selectedErrorIndex == index
                            ? "border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                            : ""
                        }
                        disabled={index > 1}
                        ref={saturday?.current?.[index]}
                        name="saturday"
                        value={data?.hours?.[index]?.saturday}
                        onChange={(e) => handle?.getHour(e, index)}
                        onInput={(e) => {
                          const value = e.target.value;
                          if (value < 0) {
                            e.target.value = 0;
                          }
                        }}
                      />
                    )}
                  </div>
                  <div>
                    {data?.errors?.saturday &&
                      data?.selectedErrorIndex == index && (
                        <p className="pt-2 text-red-600 text-sm">
                          {data?.errors?.saturday}
                        </p>
                      )}
                  </div>
                </td>
                <td className=" px-4 py-3 ">
                  <div className="flex items-center justify-start">
                    {data?.hours?.[index]?.sunday == "All Remaining" ? (
                      <select
                        className={
                          data?.errors?.sunday
                            ? "w-full border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                            : "w-full"
                        }
                      >
                        <option value="All Remaining">All Remaining</option>
                      </select>
                    ) : (
                      <Input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        className={
                          data?.errors?.sunday &&
                          data?.selectedErrorIndex == index
                            ? "border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                            : ""
                        }
                        ref={sunday?.current?.[index]}
                        name="sunday"
                        disabled={index > 1}
                        value={data?.hours?.[index]?.sunday}
                        onChange={(e) => handle?.getHour(e, index)}
                        onInput={(e) => {
                          const value = e.target.value;
                          if (value < 0) {
                            e.target.value = 0;
                          }
                        }}
                      />
                    )}
                  </div>
                  <div>
                    {data?.errors?.sunday &&
                      data?.selectedErrorIndex == index && (
                        <p className="pt-2 text-red-600 text-sm">
                          {data?.errors?.sunday}
                        </p>
                      )}
                  </div>
                </td>
                <td className=" px-4 py-3 ">
                  <div className="flex items-center justify-start">
                    {data?.hours?.[index]?.late_and_early == "All Remaining" ? (
                      <select
                        className={
                          data?.errors?.late_and_early
                            ? "w-full border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                            : "w-full"
                        }
                      >
                        <option value="All Remaining">All Remaining</option>
                      </select>
                    ) : index > 0 ? (
                      <Input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        className={
                          data?.errors?.late_and_early &&
                          data?.selectedErrorIndex == index
                            ? "border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                            : ""
                        }
                        ref={late_and_early?.current?.[index]}
                        name="late_and_early"
                        value={data?.hours?.[index]?.late_and_early}
                        disabled={index > 1}
                        onChange={(e) => handle?.getHour(e, index)}
                        onInput={(e) => {
                          const value = e.target.value;
                          if (value < 0) {
                            e.target.value = 0;
                          }
                        }}
                      />
                    ) : (
                      "-"
                    )}
                  </div>
                  <div>
                    {data?.errors?.late_and_early &&
                      data?.selectedErrorIndex == index && (
                        <p className="pt-2 text-red-600 text-sm">
                          {data?.errors?.late_and_early}
                        </p>
                      )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CreateAgreementTable;
