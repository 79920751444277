import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  AddPayrollCategory,
  TerminateRevokeWorker,
} from "../../../../../helper/personnel/workers";
import { toast } from "react-toastify";
import { SearchData } from "../../../../../helper/global/global";
import { PayRollService } from "../../../../../config/axiosUrl";
import MultySelect from "../../../../../components/multySelect/multyselect";
import { TextShimmer } from "../../../../../components/shimmer/shimmer";
import { Button } from "antd";
import { FaArrowLeft, FaEdit, FaPlus } from "react-icons/fa";
import { Fileurl } from "../../../../../config/url";
import EditProfileModal from "../../../../../shared/Modal/EditProfileModal";
import moment from "moment";
import { ButtonSpinner } from "../../../../../components/spinners";
import EditWorkersDetails from "./../EditWorkerDetails";
import DefaultCree8Img from "../../../../../assets/images/Default/defaulCree8.png";
import useWindowDimensions from "../../../../../utils/routes/useWindowDimensions";

const WorkerDetails = ({ data, hitAPI }) => {
  const { width } = useWindowDimensions();
  const { id } = useParams();
  const navigate = useNavigate();

  const [modalVisible, setModalVisible] = React.useState(false);

  const EditModalVisible = () => {
    setModalVisible(true);
  };

  const initialvalues = {
    isShimmer: true,
    isLoading: false,
    selectedPayrollCategory: [],
  };

  const initialMultySelectValue = {
    options: [],
    isMultySelect: false,
    selectedOption: [],
    isLoading: false,
    isShimmer: true,
    title: "Available Payroll Category ",
    searchBarTitle: "Search by Payroll Category Name",
    buttonTitle: "Add",
  };

  const [worker, setWorker] = useState(initialvalues);
  const [multySelect, setMultySelect] = useState(initialMultySelectValue);

  const handleWorker = {
    getWorker: async () => {
      //   const params = {
      //     id: id,
      //   };
      //   let data = await GetWorkersById(params);
      let allSelectedOptions = [];
      data?.data?.data?.workers_payroll_category?.map((item, index) => {
        return allSelectedOptions?.push(item?.payroll_category?.id);
      });

      if (data?.status) {
        setWorker({
          ...worker,
          ...data?.data?.data,
          isShimmer: false,
        });
        setMultySelect({
          ...multySelect,
          selectedOption: allSelectedOptions,
        });
      }
    },
    getPayrollCategories: async (e) => {
      if (!e?.target?.value) {
        setMultySelect({
          ...multySelect,
          isShimmer: true,
          isMultySelect: true,
        });
      }

      const params = {
        value: e?.target?.value,
        api: "/api/v1/payroll/setting/get-payroll-category-data",
        service: PayRollService,
      };
      let data = await SearchData(params);
      if (data?.status) {
        setMultySelect({
          ...multySelect,
          options: [...data?.data?.data],
          isShimmer: false,
          isMultySelect: true,
        });
      }
    },
    terminateRevoke: async (status) => {
      setWorker({
        ...worker,
        isLoading: true,
      });
      const params = {
        id: id,
        status: status,
      };

      let data = await TerminateRevokeWorker(params);
      if (data?.status) {
        toast?.success(data?.message);
        setWorker({
          ...worker,
          status: status,
          isLoading: false,
        });
      } else {
        toast?.error(data?.message);
      }
    },
    addPayrollCategoties: async () => {
      setMultySelect({
        ...multySelect,
        isLoading: true,
      });

      const params = {
        ids: multySelect?.selectedOption,
        worker_id: worker?.id,
      };

      let getData = await AddPayrollCategory(params);
      if (getData?.status) {
        // hitAPI(params);
        hitAPI();
        toast?.success(getData?.message);
        setMultySelect(initialMultySelectValue);
      } else {
        toast?.error(getData?.message);
        setMultySelect({
          ...multySelect,
          isLoading: false,
        });
      }
    },
    getType: async (type) => {
      const params = {
        type: type,
      };
      hitAPI();
      // await handleWorker?.getWorker(params);
    },
  };
  const img = {
    display: "block",
    width: 120,
    height: 120,
    borderRadius: "10px",
  };

  useEffect(() => {
    if (data) {
      handleWorker?.getWorker();
    }
  }, [data]);

  return (
    <>
      {worker?.isShimmer ? (
        <>
          <div className="p-6">
            <div className="data-card p-4 my-3  bg-white">
              <TextShimmer data={{ gap: 15, line: 22 }} />
            </div>
          </div>
        </>
      ) : (
        <>
          {multySelect?.isMultySelect && (
            <MultySelect
              data={multySelect}
              setData={setMultySelect}
              search={handleWorker?.getPayrollCategories}
              submit={handleWorker?.addPayrollCategoties}
            />
          )}

          <div className="data-card  my-3">
            <div className="tab-contents">
              <div className="details mt-5 bg-white p-4">
                <div>
                  {modalVisible && (
                    <EditProfileModal
                      worker={worker}
                      setWorker={setWorker}
                      setModalVisible={setModalVisible}
                      hitAPI={hitAPI}
                    />
                  )}
                  <div className="profile pb-4 border-b-2 border-[#E9E9E9]">
                    <div>
                      <div
                        className="cursor-pointer  pb-4"
                        onClick={() =>
                          navigate("/admin/personnel/workers/1/1/1/1", {
                            replace: true,
                          })
                        }
                      >
                        <div className="flex">
                          <div>
                            <div>
                              <div className="pt-1">
                                <FaArrowLeft />
                              </div>
                            </div>
                          </div>
                          <div>
                            <div>
                              <div className="px-2">Return to Workers </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-start items-center">
                      <div className="dp">
                        <img
                          src={
                            worker?.users_profile_details?.data
                              ? Fileurl + worker?.users_profile_details?.data
                              : DefaultCree8Img
                          }
                          className="mr-10"
                          style={img}
                          alt=""
                        />
                      </div>
                      <div className="contents p-5 pl-10">
                        <div className="flex w-full justify-between items-center">
                          <div className="profile-details w-full">
                            <div className="flex justify-start items-center">
                              <p className="fs-22 mr-5">{worker?.name}</p>
                              {worker?.status === 1 ? (
                                <span className="fs-10 ml-3 text-[#41C700] border rounded-md px-2 py-1 border-[#41C700] bg-[#42c70033]">
                                  Active
                                </span>
                              ) : worker?.status === 3 ? (
                                <span className="fs-10 ml-3 text-[#FF0000] border rounded-md px-2 py-1 border-[#FF0000] bg-[#FF000033]">
                                  Terminated
                                </span>
                              ) : (
                                ""
                              )}
                              <button
                                className="px-3"
                                onClick={EditModalVisible}
                              >
                                <FaEdit />
                              </button>
                            </div>

                            <div className="flex justify-between w-full items-center mt-3">
                              <div className="lg:w-1/3 md:w-1/2 w-full text-left">
                                <p className="fs-14 text-[#A1A1A1]">Email</p>
                                <p className="fs-14">
                                  {worker?.email ? worker?.email : "N/A"}
                                </p>
                              </div>
                              {worker?.status == 1 ? (
                                <div className="lg:w-1/4 md:w-1/2 w-full text-left">
                                  <p className="fs-14 text-[#A1A1A1]">
                                    Card ID
                                  </p>
                                  <p className="fs-14">
                                    {worker?.employee_id
                                      ? worker?.employee_id
                                      : "N/A"}
                                  </p>
                                </div>
                              ) : (
                                <div className="lg:w-1/4 md:w-1/2 w-full text-left">
                                  <p className="fs-14 text-[#A1A1A1]">
                                    Employee Number
                                  </p>
                                  <p className="fs-14">
                                    {worker?.employee_id
                                      ? worker?.employee_id
                                      : "N/A"}
                                  </p>
                                </div>
                              )}
                              {worker?.status == 1 ? (
                                <div className="lg:w-1/4 md:w-1/2 w-full text-left">
                                  <p className="fs-14 text-[#A1A1A1]">
                                    Joined Date
                                  </p>
                                  <p className="fs-14">
                                    {worker?.created_at
                                      ? moment(worker?.created_at)?.format(
                                          "MMMM  D, YYYY"
                                        )
                                      : "N/A"}
                                  </p>
                                </div>
                              ) : (
                                <div className="lg:w-1/4 md:w-1/2 w-full text-left"></div>
                              )}
                              {worker?.status == 1 ? (
                                <div className="lg:w-1/4 md:w-1/2 w-full text-left">
                                  <p className="fs-14 text-[#A1A1A1]">
                                    Employed As
                                  </p>
                                  <p className="fs-14">N/A</p>
                                </div>
                              ) : (
                                <div className="lg:w-1/4 md:w-1/2 w-full text-left"></div>
                              )}
                            </div>
                          </div>

                          <Button
                            onClick={() =>
                              handleWorker?.terminateRevoke(
                                worker?.status === 1
                                  ? 3
                                  : worker?.status === 3
                                  ? 1
                                  : ""
                              )
                            }
                            disabled={worker?.isLoading}
                            className="flex justify-center items-center py-3 border border-[#111111] h-[42px] w-1/5"
                          >
                            {worker?.isLoading ? (
                              <ButtonSpinner />
                            ) : !worker?.isLoading && worker?.status === 1 ? (
                              <>Terminate</>
                            ) : !worker?.isLoading && worker?.status === 3 ? (
                              <>Revoke</>
                            ) : (
                              ""
                            )}
                            {!worker?.isLoading && (
                              <div className="bg-orange-500 p-2 rounded-md text-white ml-3">
                                <FaPlus />
                              </div>
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Worker Full Details */}
                  <div className="worker-full-details mt-5">
                    <div className="flex justify-between items-center">
                      <p className="fs-18 weight-bold">Worker Details</p>
                    </div>

                    <div className="flex justify-between w-full items-center mt-4 mb-6">
                      <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">Gender</p>

                        <EditWorkersDetails
                          value={worker?.worker_details[0]?.gender}
                          fieldName={"gender"}
                          workerId={worker?.worker_details[0]?.id}
                          collectionKey={"worker_details"}
                          handleWorker={hitAPI}
                          type={"genderRadio"}
                        />
                      </div>
                      <div className="lg:w-1/4 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">Date of Birth</p>

                        <EditWorkersDetails
                          value={worker?.worker_details[0]?.dob}
                          fieldName={"dob"}
                          workerId={worker?.worker_details[0]?.id}
                          collectionKey={"worker_details"}
                          handleWorker={hitAPI}
                          inputField={"dob"}
                        />
                      </div>
                      <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">Phone Number</p>
                        <EditWorkersDetails
                          value={worker?.worker_details[0]?.mobile}
                          fieldName={"mobile"}
                          workerId={worker?.worker_details[0]?.id}
                          collectionKey={"worker_details"}
                          handleWorker={hitAPI}
                        />
                      </div>
                      <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">Blood Type</p>
                        {/* <p className="fs-14 text-capitalize">
                            {worker?.workers_emergency_contacts[
                              worker?.workers_emergency_contacts?.length - 1
                            ]?.blood_type
                              ? worker?.workers_emergency_contacts[
                                worker?.workers_emergency_contacts?.length - 1
                              ]?.blood_type
                              : "-"}
                          </p> */}
                        <EditWorkersDetails
                          value={
                            worker?.workers_emergency_contacts[
                              worker?.workers_emergency_contacts?.length - 1
                            ]?.blood_type
                          }
                          fieldName={"blood_type"}
                          workerId={
                            worker?.workers_emergency_contacts[
                              worker?.workers_emergency_contacts?.length - 1
                            ]?.id
                          }
                          collectionKey={"workers_emergency_contacts"}
                          handleWorker={hitAPI}
                          type={"bloodType"}
                        />
                      </div>
                      <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">Shirt Size </p>
                        <EditWorkersDetails
                          value={worker?.worker_details[0]?.shirt_size}
                          fieldName={"shirt_size"}
                          workerId={worker?.worker_details[0]?.id}
                          collectionKey={"worker_details"}
                          handleWorker={hitAPI}
                        />
                        {/* <p className="fs-14">
                            {worker?.worker_details[0]?.shirt_size
                              ? worker?.worker_details[0]?.shirt_size
                              : "-"}
                          </p> */}
                      </div>
                      <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">Pants Size</p>
                        {/* <p className="fs-14">
                            {worker?.worker_details[0]?.pant_size
                              ? worker?.worker_details[0]?.pant_size
                              : "-"}
                          </p> */}
                        <EditWorkersDetails
                          value={worker?.worker_details[0]?.pant_size}
                          fieldName={"pant_size"}
                          workerId={worker?.worker_details[0]?.id}
                          collectionKey={"worker_details"}
                          handleWorker={hitAPI}
                        />
                      </div>
                    </div>
                    <div className="flex justify-between w-full items-center pt-4 pb-6 border-t-2 border-[#E9E9E9]">
                      <div className="lg:w-1/4 md:w-1/1 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">Address</p>
                        {/* <p className="fs-14 text-capitalize">
                            {worker?.worker_details[0]?.address
                              ? worker?.worker_details[0]?.address
                              : "-"}
                          </p> */}
                        <EditWorkersDetails
                          value={worker?.worker_details[0]?.address}
                          fieldName={"address"}
                          workerId={worker?.worker_details[0]?.id}
                          collectionKey={"worker_details"}
                          handleWorker={hitAPI}
                        />
                        <p className="fs-14 text-capitalize">
                          {/* {worker?.worker_details[0]?.mobile
                              ? worker?.worker_details[0]?.mobile
                              : "-"} */}
                          <EditWorkersDetails
                            value={worker?.worker_details[0]?.mobile}
                            fieldName={"mobile"}
                            workerId={worker?.worker_details[0]?.id}
                            collectionKey={"worker_details"}
                            handleWorker={hitAPI}
                          />
                        </p>
                      </div>
                      <div className="lg:w-1/4 md:w-1/2 w-full text-left"></div>
                      <div className="lg:w-1/4 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">City</p>
                        {/* <p className="fs-14 text-capitalize">
                            {worker?.worker_details[0]?.city
                              ? worker?.worker_details[0]?.city
                              : "-"}
                          </p> */}
                        <EditWorkersDetails
                          value={worker?.worker_details[0]?.city}
                          fieldName={"city"}
                          workerId={worker?.worker_details[0]?.id}
                          collectionKey={"worker_details"}
                          handleWorker={hitAPI}
                        />
                      </div>

                      <div className="lg:w-1/4 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">Post Code</p>
                        {/* <p className="fs-14">
                            {worker?.worker_details[0]?.post_code
                              ? worker?.worker_details[0]?.post_code
                              : "-"}
                          </p> */}
                        <EditWorkersDetails
                          value={worker?.worker_details[0]?.post_code}
                          fieldName={"post_code"}
                          workerId={worker?.worker_details[0]?.id}
                          collectionKey={"worker_details"}
                          handleWorker={hitAPI}
                        />
                      </div>
                    </div>
                    <div className="flex justify-between w-full items-center pt-4 pb-6 border-t-2 border-[#E9E9E9]">
                      <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">Super Fund Name</p>
                        {/* <p className="fs-14 text-capitalize">
                            {worker?.worker_details[0]?.super_fund_name
                              ? worker?.worker_details[0]?.super_fund_name
                              : "-"}
                          </p> */}
                        <EditWorkersDetails
                          value={worker?.worker_details[0]?.super_fund_name}
                          fieldName={"super_fund_name"}
                          workerId={worker?.worker_details[0]?.id}
                          collectionKey={"worker_details"}
                          handleWorker={hitAPI}
                        />
                      </div>

                      <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">
                          Super Fund Number
                        </p>
                        {/* <p className="fs-14">
                            {worker?.worker_details[0]?.super_fund_number
                              ? worker?.worker_details[0]?.super_fund_number
                              : "-"}
                          </p> */}
                        <EditWorkersDetails
                          value={worker?.worker_details[0]?.super_fund_number}
                          fieldName={"super_fund_number"}
                          workerId={worker?.worker_details[0]?.id}
                          collectionKey={"worker_details"}
                          handleWorker={hitAPI}
                        />
                      </div>

                      <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">Tax File Number</p>
                        {/* <p className="fs-14">
                            {worker?.worker_details[0]?.tax_file_number
                              ? worker?.worker_details[0]?.tax_file_number
                              : "-"}
                          </p> */}
                        <EditWorkersDetails
                          value={worker?.worker_details[0]?.tax_file_number}
                          fieldName={"tax_file_number"}
                          workerId={worker?.worker_details[0]?.id}
                          collectionKey={"worker_details"}
                          handleWorker={hitAPI}
                        />
                      </div>
                      <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">BERT</p>
                        {/* <p className="fs-14 text-capitalize">
                            {worker?.worker_details[0]?.bert
                              ? worker?.worker_details[0]?.bert
                              : "-"}
                          </p> */}
                        <EditWorkersDetails
                          value={worker?.worker_details[0]?.bert}
                          fieldName={"bert"}
                          workerId={worker?.worker_details[0]?.id}
                          collectionKey={"worker_details"}
                          handleWorker={hitAPI}
                        />
                      </div>
                      <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">QLeave</p>
                        {/* <p className="fs-14 text-capitalize">
                            {worker?.worker_details[0]?.qLeave
                              ? worker?.worker_details[0]?.qLeave
                              : "-"}
                          </p> */}
                        <EditWorkersDetails
                          value={worker?.worker_details[0]?.qLeave}
                          fieldName={"qLeave"}
                          workerId={worker?.worker_details[0]?.id}
                          collectionKey={"worker_details"}
                          handleWorker={hitAPI}
                        />
                      </div>
                    </div>
                    <div className="flex justify-between w-full items-center pt-4 pb-6 border-t-2 border-[#E9E9E9]">
                      <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">BSB</p>
                        {/* <p className="fs-14 text-capitalize">
                            {worker?.worker_details[0]?.bank_bsb
                              ? worker?.worker_details[0]?.bank_bsb
                              : "-"}
                          </p> */}
                        <EditWorkersDetails
                          value={worker?.worker_details[0]?.bank_bsb}
                          fieldName={"bank_bsb"}
                          workerId={worker?.worker_details[0]?.id}
                          collectionKey={"worker_details"}
                          handleWorker={hitAPI}
                        />
                      </div>

                      <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">Account</p>
                        {/* <p className="fs-14 text-capitalize">
                            {worker?.worker_details[0]?.bank_account_number
                              ? worker?.worker_details[0]?.bank_account_number
                              : "-"}
                          </p> */}
                        <EditWorkersDetails
                          value={worker?.worker_details[0]?.bank_account_number}
                          fieldName={"bank_account_number"}
                          workerId={worker?.worker_details[0]?.id}
                          collectionKey={"worker_details"}
                          handleWorker={hitAPI}
                        />
                      </div>

                      <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">Drivers Licence</p>
                        {/* <p className="fs-14 text-capitalize">
                            {worker?.worker_details[0]?.driver_licence
                              ? worker?.worker_details[0]?.driver_licence
                              : "-"}
                          </p> */}
                        <EditWorkersDetails
                          value={worker?.worker_details[0]?.driver_licence}
                          fieldName={"driver_licence"}
                          workerId={worker?.worker_details[0]?.id}
                          collectionKey={"worker_details"}
                          handleWorker={hitAPI}
                        />
                      </div>
                      <div className="lg:w-1/5 md:w-1/2 w-full text-left"></div>
                      <div className="lg:w-1/5 md:w-1/2 w-full text-left"></div>
                    </div>
                    <p className="fs-17 weight-bold pt-4 pb-6 border-t-2 border-[#E9E9E9]">
                      Emergency Contacts And Medical conditions
                    </p>
                    <div className="flex justify-between w-full items-center">
                      <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">Name</p>
                        {/* <p className="fs-14 text-capitalize">
                            {worker?.workers_emergency_contacts[
                              worker?.workers_emergency_contacts?.length - 1
                            ]?.name
                              ? worker?.workers_emergency_contacts[
                                worker?.workers_emergency_contacts?.length - 1
                              ]?.name
                              : "-"}
                          </p> */}
                        <EditWorkersDetails
                          value={
                            worker?.workers_emergency_contacts[
                              worker?.workers_emergency_contacts?.length - 1
                            ]?.name
                          }
                          fieldName={"name"}
                          workerId={
                            worker?.workers_emergency_contacts[
                              worker?.workers_emergency_contacts?.length - 1
                            ]?.id
                          }
                          collectionKey={"workers_emergency_contacts"}
                          handleWorker={hitAPI}
                        />
                      </div>

                      <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">Phone Number</p>
                        {/* <p className="fs-14">
                            {worker?.workers_emergency_contacts[
                              worker?.workers_emergency_contacts?.length - 1
                            ]?.mobile
                              ? worker?.workers_emergency_contacts[
                                worker?.workers_emergency_contacts?.length - 1
                              ]?.mobile
                              : "-"}
                          </p> */}
                        <EditWorkersDetails
                          value={
                            worker?.workers_emergency_contacts[
                              worker?.workers_emergency_contacts?.length - 1
                            ]?.mobile
                          }
                          fieldName={"mobile"}
                          workerId={
                            worker?.workers_emergency_contacts[
                              worker?.workers_emergency_contacts?.length - 1
                            ]?.id
                          }
                          collectionKey={"workers_emergency_contacts"}
                          handleWorker={hitAPI}
                        />
                      </div>

                      <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">
                          Existing Injuries
                        </p>
                        {/* <p className="fs-14">
                            {worker?.workers_emergency_contacts[
                              worker?.workers_emergency_contacts?.length - 1
                            ]?.existing_injuries
                              ? worker?.workers_emergency_contacts[
                                worker?.workers_emergency_contacts?.length - 1
                              ]?.existing_injuries == 0
                                ? "No"
                                : "Yes"
                              : "No"}
                          </p> */}
                        <EditWorkersDetails
                          value={
                            worker?.workers_emergency_contacts[
                              worker?.workers_emergency_contacts?.length - 1
                            ]?.existing_injuries
                          }
                          fieldName={"existing_injuries"}
                          workerId={
                            worker?.workers_emergency_contacts[
                              worker?.workers_emergency_contacts?.length - 1
                            ]?.id
                          }
                          collectionKey={"workers_emergency_contacts"}
                          handleWorker={hitAPI}
                          type={"yesNo"}
                        />
                      </div>
                      <div className="lg:w-1/5 md:w-1/2 w-full text-left"></div>
                      <div className="lg:w-1/5 md:w-1/2 w-full text-left"></div>
                    </div>
                    <div className="flex justify-between w-full items-center pt-5 pb-6 ">
                      <div className="lg:w-1/2 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">
                          Medical Condition Description
                        </p>
                        {/* <p className="fs-14 text-capitalize">
                            {worker?.workers_emergency_contacts[
                              worker?.workers_emergency_contacts?.length - 1
                            ]?.medicle_condition
                              ? worker?.workers_emergency_contacts[
                                worker?.workers_emergency_contacts?.length - 1
                              ]?.medicle_condition
                              : "-"}
                          </p> */}
                        <EditWorkersDetails
                          value={
                            worker?.workers_emergency_contacts[
                              worker?.workers_emergency_contacts?.length - 1
                            ]?.medicle_condition
                          }
                          fieldName={"medicle_condition"}
                          workerId={
                            worker?.workers_emergency_contacts[
                              worker?.workers_emergency_contacts?.length - 1
                            ]?.id
                          }
                          collectionKey={"workers_emergency_contacts"}
                          handleWorker={hitAPI}
                        />
                      </div>

                      {worker?.workers_emergency_contacts[
                        worker?.workers_emergency_contacts?.length - 1
                      ]?.existing_injuries == 1 && (
                        <div className="lg:w-1/2 md:w-1/2 w-full text-left">
                          <p className="fs-14 text-[#A1A1A1]">
                            Injuries Description
                          </p>
                          {/* <p className="fs-14 text-capitalize">
                                {worker?.worker_details[0]?.injuries_desc
                                  ? worker?.worker_details[0]?.injuries_desc
                                  : "-"}
                              </p> */}
                          <EditWorkersDetails
                            value={worker?.worker_details[0]?.injuries_desc}
                            fieldName={"injuries_desc"}
                            workerId={worker?.worker_details[0]?.id}
                            collectionKey={"worker_details"}
                            handleWorker={hitAPI}
                          />
                        </div>
                      )}
                    </div>
                    <div className="flex justify-between w-full items-center pt-4 pb-6 border-t-2 border-[#E9E9E9]">
                      <div className="lg:w-1/3 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">
                          Are you a Subcontractor ?
                        </p>
                        {/* <p className="fs-14">
                            {worker?.workers_emergency_contacts[
                              worker?.workers_emergency_contacts?.length - 1
                            ]?.is_subcontractor
                              ? worker?.workers_emergency_contacts[
                                worker?.workers_emergency_contacts?.length - 1
                              ]?.is_subcontractor === 0
                                ? "No"
                                : "Yes"
                              : "No"}
                          </p> */}
                        <EditWorkersDetails
                          value={
                            worker?.workers_emergency_contacts[
                              worker?.workers_emergency_contacts?.length - 1
                            ]?.is_subcontractor
                          }
                          fieldName={"is_subcontractor"}
                          workerId={
                            worker?.workers_emergency_contacts[
                              worker?.workers_emergency_contacts?.length - 1
                            ]?.id
                          }
                          collectionKey={"workers_emergency_contacts"}
                          handleWorker={hitAPI}
                          type={"yesNo"}
                        />
                      </div>

                      <div className="lg:w-1/3 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">
                          Have You ever made a worker's compensation claim?
                        </p>
                        {/* <p className="fs-14">
                            {worker?.workers_emergency_contacts[
                              worker?.workers_emergency_contacts?.length - 1
                            ]?.has_ever_made_a_worker_compensation_claim
                              ? worker?.workers_emergency_contacts[
                                worker?.workers_emergency_contacts?.length - 1
                              ]?.has_ever_made_a_worker_compensation_claim ===
                                0
                                ? "No"
                                : "Yes"
                              : "No"}
                          </p> */}
                        <EditWorkersDetails
                          value={
                            worker?.workers_emergency_contacts[
                              worker?.workers_emergency_contacts?.length - 1
                            ]?.has_ever_made_a_worker_compensation_claim
                          }
                          fieldName={
                            "has_ever_made_a_worker_compensation_claim"
                          }
                          workerId={
                            worker?.workers_emergency_contacts[
                              worker?.workers_emergency_contacts?.length - 1
                            ]?.id
                          }
                          collectionKey={"workers_emergency_contacts"}
                          handleWorker={hitAPI}
                          type={"yesNo"}
                        />
                      </div>
                      <div className="lg:w-1/3 md:w-1/2 w-full text-left"></div>
                    </div>
                    {worker?.workers_experiences?.length > 0 && (
                      <>
                        <p className="fs-17 weight-bold pt-4 pb-6 border-t-2 border-[#E9E9E9]">
                          Experience
                        </p>
                        {worker?.workers_experiences?.length > 0 &&
                          worker?.workers_experiences.map((exp, index) => {
                            return (
                              <div
                                className="flex justify-between w-full items-center mb-5"
                                key={index}
                              >
                                {/* {exp?.trade_certificate === "" &&<div className="lg:w-1/5 md:w-1/2 w-full text-left">
                                    <p className="fs-14 text-[#A1A1A1]">
                                      Trade Certificate
                                    </p>
                                    <p className="fs-14">
                                      {exp?.trade_certificate === ""
                                        ? "No"
                                        : "Yes"}
                                    </p>
                                    <EditWorkersDetails value={exp?.trade_certificate} fieldName={'trade_certificate'} workerId={exp?.id} collectionKey={'workers_experiences'} handleWorker={hitAPI} type={'yesNo'} />
                                  </div>} */}

                                <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                                  <p className="fs-14 text-[#A1A1A1]">
                                    Photo of Trade Certificate
                                  </p>
                                  {/* <p className="fs-14">
                                        {exp?.trade_certificate !== "" ? (
                                          <a className="text-decoration-underline"
                                            href={
                                              Fileurl + exp?.trade_certificate
                                            }
                                            target="_blank"
                                          >
                                            View
                                          </a>
                                        ) : (
                                          "N / A"
                                        )}
                                      </p> */}
                                  <EditWorkersDetails
                                    value={exp?.trade_certificate}
                                    fieldName={"trade_certificate"}
                                    workerId={exp?.id}
                                    collectionKey={"workers_experiences"}
                                    handleWorker={hitAPI}
                                    type={"inputFile"}
                                  />
                                </div>

                                <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                                  <p className="fs-14 text-[#A1A1A1]">Trade</p>
                                  {/* <p className="fs-14 text-capitalize">
                                      {exp?.trade ? exp?.trade : "-"}
                                    </p> */}
                                  <EditWorkersDetails
                                    value={exp?.trade}
                                    fieldName={"trade"}
                                    workerId={exp?.id}
                                    collectionKey={"workers_experiences"}
                                    handleWorker={hitAPI}
                                  />
                                </div>
                                <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                                  <p className="fs-14 text-[#A1A1A1]">
                                    Year in Trade
                                  </p>
                                  {/* <p className="fs-14">
                                      {exp?.year ? exp?.year : "-"}
                                    </p> */}
                                  <EditWorkersDetails
                                    value={exp?.year}
                                    fieldName={"year"}
                                    workerId={exp?.id}
                                    collectionKey={"workers_experiences"}
                                    handleWorker={hitAPI}
                                  />
                                </div>
                                <div className="lg:w-1/5 md:w-1/2 w-full text-left"></div>
                              </div>
                            );
                          })}
                      </>
                    )}
                    {worker?.workers_previous_employment_details?.length >
                      0 && (
                      <>
                        <p className="fs-17 weight-bold pt-4 pb-6 border-t-2 border-[#E9E9E9]">
                          Previous Employment Details
                        </p>
                        {worker?.workers_previous_employment_details?.length >
                          0 &&
                          worker?.workers_previous_employment_details?.map(
                            (emp, index) => {
                              return (
                                <div
                                  className="flex justify-between w-full items-center mb-5"
                                  key={index}
                                >
                                  <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                                    <p className="fs-14 text-[#A1A1A1]">
                                      Company
                                    </p>
                                    {/* <p className="fs-14">
                                          {emp?.company ? emp?.company : "-"}
                                        </p> */}
                                    <EditWorkersDetails
                                      value={emp?.company}
                                      fieldName={"company"}
                                      workerId={emp?.id}
                                      collectionKey={
                                        "workers_previous_employment_details"
                                      }
                                      handleWorker={hitAPI}
                                    />
                                  </div>

                                  <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                                    <p className="fs-14 text-[#A1A1A1]">Job</p>
                                    {/* <p className="fs-14">
                                          {emp?.job ? emp?.job : "-"}
                                        </p> */}
                                    <EditWorkersDetails
                                      value={emp?.job}
                                      fieldName={"job"}
                                      workerId={emp?.id}
                                      collectionKey={
                                        "workers_previous_employment_details"
                                      }
                                      handleWorker={hitAPI}
                                    />
                                  </div>

                                  <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                                    <p className="fs-14 text-[#A1A1A1]">
                                      Duties
                                    </p>
                                    {/* <p className="fs-14">
                                          {emp?.duties ? emp?.duties : "-"}
                                        </p> */}
                                    <EditWorkersDetails
                                      value={emp?.duties}
                                      fieldName={"duties"}
                                      workerId={emp?.id}
                                      collectionKey={
                                        "workers_previous_employment_details"
                                      }
                                      handleWorker={hitAPI}
                                    />
                                  </div>
                                  <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                                    <p className="fs-14 text-[#A1A1A1]">
                                      Duration
                                    </p>
                                    {/* <p className="fs-14">
                                          {emp?.duration ? emp?.duration : "-"}
                                        </p> */}
                                    <EditWorkersDetails
                                      value={emp?.duration}
                                      fieldName={"duration"}
                                      workerId={emp?.id}
                                      collectionKey={
                                        "workers_previous_employment_details"
                                      }
                                      handleWorker={hitAPI}
                                    />
                                  </div>
                                  <div className="lg:w-1/5 md:w-1/2 w-full text-left"></div>
                                </div>
                              );
                            }
                          )}
                      </>
                    )}

                    <div className="flex justify-between w-full items-center pt-4 pb-6 border-t-2 border-[#E9E9E9]">
                      <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">
                          Do you Posses a working visa ?
                        </p>
                        {/*<p className="fs-14">
                            {worker?.worker_details[0]?.is_have_working_visa ===
                              0
                              ? "No"
                              : "Yes"}
                          </p> */}
                        <EditWorkersDetails
                          value={
                            worker?.worker_details[0]?.is_have_working_visa
                          }
                          fieldName={"is_have_working_visa"}
                          workerId={worker?.worker_details[0]?.id}
                          collectionKey={"worker_details"}
                          handleWorker={hitAPI}
                          type={"yesNo"}
                        />
                      </div>

                      {worker?.worker_details[0]?.is_have_working_visa ===
                        1 && (
                        <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                          <p className="fs-14 text-[#A1A1A1]">Visa Details</p>
                          {/* <p className="fs-14 text-capitalize">
                                {worker?.worker_details[0]?.visa_details
                                  ? worker?.worker_details[0]?.visa_details
                                  : "-"}
                              </p> */}
                          <EditWorkersDetails
                            value={worker?.worker_details[0]?.visa_details}
                            fieldName={"visa_details"}
                            workerId={worker?.worker_details[0]?.id}
                            collectionKey={"worker_details"}
                            handleWorker={hitAPI}
                          />
                        </div>
                      )}

                      <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">Photo of Licence</p>
                        {/* <p className="fs-14">
                            {worker?.worker_details[0]?.license_file ? (
                              <a className="text-decoration-underline"
                                href={
                                  Fileurl +
                                  worker?.worker_details[0]?.license_file
                                }
                                target="_blank"
                              >
                                View
                              </a>
                            ) : (
                              "N/A"
                            )}
                          </p> */}
                        <EditWorkersDetails
                          value={worker?.worker_details[0]?.license_file}
                          fieldName={"license_file"}
                          workerId={worker?.worker_details[0]?.id}
                          collectionKey={"worker_details"}
                          handleWorker={hitAPI}
                          type={"inputFile"}
                        />
                      </div>

                      <div className="lg:w-1/5 md:w-1/2 w-full text-left">
                        <p className="fs-14 text-[#A1A1A1]">
                          Photo of Blue Card
                        </p>
                        {/* <p className="fs-14">
                            {worker?.worker_details[0]?.blue_card_file ? (
                              <a className="text-decoration-underline"
                                href={
                                  Fileurl +
                                  worker?.worker_details[0]?.blue_card_file
                                }
                                target="_blank"
                              >
                                View
                              </a>
                            ) : (
                              "N/A"
                            )}
                          </p> */}
                        <EditWorkersDetails
                          value={worker?.worker_details[0]?.blue_card_file}
                          fieldName={"blue_card_file"}
                          workerId={worker?.worker_details[0]?.id}
                          collectionKey={"worker_details"}
                          handleWorker={hitAPI}
                          type={"inputFile"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default WorkerDetails;
