import React from "react";
import { Input, Modal, Select } from "antd";
import { Button } from "antd";
import { DatePicker } from "antd";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { useState } from "react";
const SiteDonationModal = ({
  visible,
  onCancel,
  isEditing,
  editData,
  onSave,
}) => {
  const [selectedDate, setSelectedDate] = useState();
  const [selectedDate2, setSelectedDate2] = useState();

  const modalTitle = isEditing
    ? "Editing Site Donation"
    : "Creating Site Donation";

  const { control, handleSubmit, reset } = useForm({
    defaultValues: isEditing ? editData : {},
  });

  useEffect(() => {
    if (visible && isEditing) {
      reset(editData);
    } else {
      reset();
    }
  }, [visible, isEditing, reset, editData]);

  const onSubmit = (data) => {
    reset();

    onSave(data);
  };

  return (
    <Modal
      title={modalTitle}
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="item mt-5">
          <label htmlFor="name">Name</label>
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <Input {...field} type="text" className="mt-3" />
            )}
          />
        </div>
        <div className="item mt-5">
          <label htmlFor="donation">Donate</label>
          <Controller
            control={control}
            name="donation"
            render={({ field }) => (
              <Input {...field} type="text" className="mt-3" />
            )}
          />
        </div>
        <div className="item mt-5">
          <label htmlFor="reason">Reason</label>
          <Controller
            control={control}
            name="reason"
            render={({ field }) => (
              <Input {...field} type="text" className="mt-3" />
            )}
          />
        </div>
        <div className="mt-5 flex justify-between items-center">
          <div>
            <label htmlFor="from">From</label>
            <Controller
              control={control}
              name="from"
              render={({ field }) => (
                <Input {...field} type="date" className="mt-3" id="from" />
              )}
            />
          </div>
          <div>
            <label htmlFor="to">To</label>
            <Controller
              control={control}
              name="to"
              render={({ field }) => (
                <Input {...field} type="date" className="mt-3" id="to" />
              )}
            />
          </div>
        </div>
        <div className="item mt-3">
          <div className="flex justify-start items-center mt-3">
            <Controller
              name="export_to_summary"
              control={control}
              defaultValue={0}
              render={({ field }) => (
                <input
                  type="checkbox"
                  id="export_to_summary"
                  checked={field.value}
                  {...field}
                />
              )}
            />
            <label htmlFor="export_to_summary" className="ml-2">
              Export to Summary
            </label>
          </div>
        </div>
        <div className="item mt-5 text-center">
          <Button htmlType="submit" className="btn btn-blue">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default SiteDonationModal;
