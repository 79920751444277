import { Button } from "antd";
import React, { useState } from "react";
import { FaMinus, FaPlus, FaTable, FaTrash } from "react-icons/fa";
import MultySelect from "../../components/multySelect/multyselect";
import { AddProductiveOrderTask } from "../../helper/worksite/worksite";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  ApproveProductiveOrder,
  DeleteProductiveOrderData,
  DeleteProductiveOrders,
  UpdateAreaProductiveOrder,
  UpdateProductiveOrderField,
} from "../../helper/worksite/worksiteDetails.js/areas";
import DeleteModal from "../../components/Modal/deleteModal";
import TimespentModal from "../Modal/TimespentModal";

const ProductivityOrdersTable = ({ data, setData, handle }) => {
  const [focusedIndex, setFocusedIndex] = useState(null);

  const initialMultySelectValue = {
    options: [],
    isMultySelect: false,
    selectedOption: [],
    isLoading: false,
    isShimmer: true,
    title: "Available Hourly Rate Tasks",
    searchBarTitle: "Search by Hourly Rate Tasks",
    buttonTitle: "Add",
  };
  const initialModal = {
    isModal: false,
    modal_selected_data: {},
    modalTitle: "",
    isModalLoading: false,
  };
  const initialModal2 = {
    isModal: false,
    modal_selected_data: {},
    modalTitle: "",
    isModalLoading: false,
    id: "",
    totalhours: "",
  };

  let { id } = useParams();
  const [modal, setModal] = useState(initialModal);
  const [modaltimespent, setModaltimespent] = useState(initialModal2);
  const [openRow, setOpenRow] = useState(null);
  const [selectedId, setSelectedId] = useState();
  const [multySelect, setMultySelect] = useState(initialMultySelectValue);

  useEffect(() => {
    const handleEnterKeyPress = (e) => {
      if (e.key === "Enter" && focusedIndex !== null) {
        const inputElement = document.activeElement;
        inputElement.blur();
      }
    };

    document.addEventListener("keydown", handleEnterKeyPress);

    return () => {
      document.removeEventListener("keydown", handleEnterKeyPress);
    };
  }, [focusedIndex]);

  const handleProductiveOrder = {
    openPOModal: (item, index) => {
      let productivity_orders_id = [];
      item?.productivity_orders_data?.map((po, ind) => {
        productivity_orders_id?.push(po?.id);
      });

      let data = {
        id: item?.id,
        name: item?.name,
        productivity_orders_id: productivity_orders_id,
        type: "Parent",
      };

      setModal({
        ...modal,
        isModal: true,
        modal_selected_data: data,
        modalTitle: "Are you sure you wish to delete these Productive Order?",
        isModalLoading: false,
      });
    },
    openTimespent: (id, hours) => {
      setModaltimespent({
        ...modal,
        isModal: true,
        id: id,
        isModalLoading: false,
        totalhours: hours,
      });
    },

    openPODataModal: (item, task, index) => {
      let taskName =
        task?.hourly_rate_tasks_estimations?.hourly_rate_task?.abbreviation +
        " " +
        task?.hourly_rate_tasks_estimations?.hourly_rate_task?.name;

      let data = {
        id: task?.id,
        hourly_rate_tasks_estimations_id:
          task?.hourly_rate_tasks_estimations_id,
        po_id: item?.id,
        name: taskName,
        type: "Child",
      };

      setModal({
        ...modal,
        isModal: true,
        modal_selected_data: data,
        modalTitle:
          "Are you sure you wish to delete these Productive Order Data?",
        isModalLoading: false,
      });
    },
    closeModal: () => {
      setModal(initialModal);
    },
    closeModal2: () => {
      setModaltimespent(initialModal2);
    },
    modalAction: async () => {
      setModal({
        ...modal,
        isModalLoading: true,
      });

      console.log(modal?.modal_selected_data);
      if (modal?.modal_selected_data?.type == "Parent") {
        const params = {
          id: modal?.modal_selected_data?.id,
          productivity_orders_data_ids:
            modal?.modal_selected_data?.productivity_orders_id,
        };

        let getData = await DeleteProductiveOrders(params);
        if (getData?.status) {
          let areaData = await handle?.getAreaById(params);
          if (areaData?.status) {
            setModal(initialModal);
            toast?.success(data?.message);
          } else {
            setModal({
              ...modal,
              isModalLoading: false,
            });
          }
        } else {
          toast?.error(getData?.message);
          setModal({
            ...modal,
            isModalLoading: false,
          });
        }
      } else if (modal?.modal_selected_data?.type == "Child") {
        const params = {
          id: modal?.modal_selected_data?.id,
          hourly_rate_tasks_estimations_id:
            modal?.modal_selected_data?.hourly_rate_tasks_estimations_id,
        };

        let getData = await DeleteProductiveOrderData(params);
        if (getData?.status) {
          let areaData = await handle?.getAreaById(params);
          if (areaData?.status) {
            setModal(initialModal);
            toast?.success(data?.message);
          } else {
            setModal({
              ...modal,
              isModalLoading: false,
            });
          }
        } else {
          toast?.error(getData?.message);
          setModal({
            ...modal,
            isModalLoading: false,
          });
        }
      }
    },
    getCode: (e, index) => {
      let allData = [...data?.productivity_orders];
      allData[index].code = e?.target?.value;

      console.log(allData[index]);

      setData({
        ...data,
        productivity_orders: allData,
      });
    },
    getName: (e, index) => {
      let allData = [...data?.productivity_orders];
      allData[index].name = e?.target?.value;

      setData({
        ...data,
        productivity_orders: allData,
      });
    },
    save: async (index) => {
      let allData = [...data?.productivity_orders];

      const params = {
        id: allData[index]?.id,
        code: allData[index]?.code,
        name: allData[index]?.name,
        time_spent: allData[index]?.TimeSpend,
        remaining: allData[index]?.Remaining,
      };

      let getData = await UpdateAreaProductiveOrder(params);
      if (!getData?.status) {
        toast?.error(data?.message);
      }
    },
    approve: async (item, status, index) => {
      const params = {
        id: item?.id,
        status: status,
      };

      let allData = [...data?.productivity_orders];
      allData[index].status = status;

      setData({
        ...data,
        productivity_orders: allData,
      });

      let getData = await ApproveProductiveOrder(params);
      if (!getData?.status) {
        toast?.error(data?.message);
      }
    },
    getRemaining: async (e, item, task, index, ind) => {
      if (e?.target?.value >= 0) {
        let allData = [...data?.productivity_orders];
        allData[index].productivity_orders_data[ind].remaining =
          e?.target?.value;

        setData({
          ...data,
          productivity_orders: allData,
        });
      }
    },
    saveRemaining: async (task, item) => {
      console.log(task, item);

      setData({
        ...data,
        animate_pulse: true,
      });

      let productivity_orders_remaining =
        item?.productivity_orders_data?.reduce(
          (total, currentValue) =>
            (total = parseFloat(total) + parseFloat(currentValue?.remaining)),
          0
        );

      console.log(
        productivity_orders_remaining,
        "productivity_orders_remaining"
      );

      const params = {
        productivity_orders_id: item?.id,
        productivity_orders_remaining: productivity_orders_remaining,
        // productivity_orders_time_spent: productivity_orders_remaining,
        productivity_orders_time_spent: 0,
        id: task?.id,
        hourly_rate_tasks_estimations_id:
          task?.hourly_rate_tasks_estimations_id,
        remaining: task?.remaining,
      };

      let getData = await UpdateProductiveOrderField(params);

      if (getData?.status) {
        let areaData = await handle?.getAreaById();
        if (!areaData?.status) {
          toast?.error(getData?.message);
        }
      } else {
        toast?.error(getData?.message);
      }
    },
  };

  const openrow = (id) => {
    setOpenRow(id);
    setSelectedId();
  };

  const closerow = () => {
    setOpenRow(null);
  };

  const openMultytask = (id, productivity_orders_data) => {
    setSelectedId(id);
    let hourlyRateTask = [...data?.hourly_rate_tasks_estimations];

    let hrtOptions = [];
    hourlyRateTask?.map((hrt, index) => {
      hrtOptions?.push({
        id: hrt?.id,
        name:
          hrt?.hourly_rate_task?.abbreviation +
          " " +
          hrt?.hourly_rate_task?.name +
          " ( " +
          hrt?.hourly_rate_task?.categories?.name +
          " ) ",
      });
    });

    let selOptions = [];

    productivity_orders_data?.map((po, ind) =>
      selOptions?.push(po?.hourly_rate_tasks_estimations?.id)
    );

    console.log(selOptions, "selOptions", productivity_orders_data);

    setMultySelect({
      ...multySelect,
      isMultySelect: true,
      options: hrtOptions,
      isShimmer: false,
      selectedOption: selOptions,
    });
  };

  const searchtask = () => {};

  const addTask = async () => {
    console.log(multySelect, "addTask", id);

    setMultySelect({
      ...multySelect,
      isMultySelect: true,
      isLoading: true,
    });

    const params = {
      id: "",
      worksite_id: id,
      productivity_orders_id: selectedId,
      hourly_rate_tasks_estimations_ids: multySelect?.selectedOption,
      alloc_hours: "",
      time_spent: "",
      is_save: 1,
    };

    let getData = await AddProductiveOrderTask(params);

    if (getData?.status) {
      let getArea = await handle?.getAreaById();

      if (getArea?.status) {
        if (!getData?.data?.data || getData?.data?.data.length === 0) {
          // Show toast notification for empty data
          toast?.error("Productivity is already been added.");
        } else {
          toast?.success(data?.message);
        }

        setMultySelect({
          ...multySelect,
          isMultySelect: false,
          isLoading: false,
        });
      }
    } else {
      toast?.error(data?.message);

      setMultySelect({
        ...multySelect,
        isLoading: false,
      });
    }
  };

  return (
    <>
      {multySelect?.isMultySelect && (
        <MultySelect
          data={multySelect}
          setData={setMultySelect}
          search={searchtask}
          submit={addTask}
        />
      )}

      <table className="w-full zoom-80">
        <thead className="bg-[#E8F3FF] text-center">
          <tr className="text-left">
            <th className=" px-4 py-3">
              <div className="flex items-center justify-center">Code</div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">Name</div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">Tasks</div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">Time Spent</div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">Remaining</div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">Status</div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">...</div>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {data?.productivity_orders?.map((item, index) => (
            <>
              <tr key={index} className="border-b border-[#F0F0F0]">
                <td className="px-4 py-3">
                  <div className="flex items-center border justify-center text-sm">
                    <input
                      type="text"
                      name="code"
                      className="w-24 p-1"
                      disabled={data?.animate_pulse}
                      value={item?.code}
                      onChange={(e) => handleProductiveOrder?.getCode(e, index)}
                      onBlur={() => handleProductiveOrder?.save(index)}
                    />
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex items-center border justify-center text-sm">
                    <input
                      type="text"
                      name="name"
                      className="w-44 p-1"
                      disabled={data?.animate_pulse}
                      value={item?.name}
                      onChange={(e) => handleProductiveOrder?.getName(e, index)}
                      onBlur={() => handleProductiveOrder?.save(index)}
                    />
                  </div>
                </td>
                <td className="text-lg px-4 py-3 border border-white  border-r-[#F0F0F0] border-b-[#F0F0F0] border-t-[#F0F0F0]">
                  <div className="flex items-center justify-center  break-all">
                    {item?.productivity_orders_data?.map((task, ind) => {
                      return (
                        <>
                          {ind > 0 && " , "}
                          {
                            task?.hourly_rate_tasks_estimations
                              ?.hourly_rate_task?.abbreviation
                          }
                        </>
                      );
                    })}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div
                    className="flex items-center justify-center  break-all"
                    title={`Time Spent Value: ${
                      item?.time_spent.toFixed(2) || 0
                    }`}
                    onClick={() => {
                      if (item?.time_spent > item?.remaining) {
                        handleProductiveOrder.openTimespent(
                          item.id,
                          item.remaining
                        );
                      }
                    }}
                  >
                    {/* ww  0&nbsp; */}
                    {item?.time_spent ? item?.time_spent.toFixed(2) : 0} /
                    <span
                      className="text-slate-500"
                      title={`Remaining Value: ${
                        item?.remaining.toFixed(2) || 0
                      }`}
                      sss
                    >
                      {/* {item?.time_spent ? item?.time_spent : 0} */}
                      {item?.remaining ? item?.remaining.toFixed(2) : 0}
                    </span>
                  </div>
                </td>
                <td className="px-4 py-3  border border-white  border-r-[#F0F0F0] border-b-[#F0F0F0] border-t-[#F0F0F0]">
                  <div
                    className="flex items-center justify-center  break-all"
                    title={`Remaining value minus time spent`}
                  >
                    {/* {item?.remaining ? item?.remaining : 0}&nbsp;hrs */}
                    {(
                      item?.remaining?.toFixed(1) - item?.time_spent?.toFixed(2)
                    ).toFixed(2)}
                    &nbsp;hrs
                  </div>
                </td>
                <td className="py-3">
                  <div className="flex items-center justify-center  break-all ">
                    {item?.status == 0
                      ? "Active"
                      : item?.status == 1
                      ? "Approved"
                      : ""}
                  </div>
                </td>
                <td className="px-3 py-3">
                  <div className=" flex items-center justify-center">
                    <Button
                      className="border border-black "
                      onClick={() =>
                        handleProductiveOrder?.approve(
                          item,
                          item?.status == 0
                            ? "1"
                            : item?.status == 1
                            ? "0"
                            : "0",
                          index
                        )
                      }
                    >
                      {item?.status == 0
                        ? "Approve"
                        : item?.status == 1
                        ? "Revoke"
                        : ""}
                    </Button>

                    <Button className="flex justify-center items-center py-3 border-none">
                      <div
                        className="bg-orange-500 p-2 rounded-md text-white ml-3"
                        onClick={
                          openRow == index ? closerow : () => openrow(index)
                        }
                      >
                        {openRow == index ? <FaMinus /> : <FaPlus />}
                      </div>
                      <div>
                        {item?.productivity_orders_data?.length <= 0 && (
                          <Button
                            className="text-[#0F4C7D] border-none pr-0"
                            onClick={() =>
                              handleProductiveOrder?.openPOModal(item, index)
                            }
                            disabled={data?.animate_pulse}
                          >
                            <FaTrash />
                          </Button>
                        )}
                      </div>
                    </Button>
                  </div>
                </td>
              </tr>

              {openRow == index && (
                <>
                  {item?.productivity_orders_data?.map((task, ind) => {
                    return (
                      <tr className="border-b border-[#F0F0F0]">
                        <td></td>
                        <td
                          colSpan={2}
                          className="py-3 px-4 border border-white  border-r-[#F0F0F0] border-b-[#F0F0F0] border-t-[#F0F0F0]"
                        >
                          {
                            task?.hourly_rate_tasks_estimations
                              ?.hourly_rate_task?.abbreviation
                          }
                          &nbsp;&nbsp;
                          {/* {
                            task?.hourly_rate_tasks_estimations
                              ?.hourly_rate_task?.name
                          } */}
                        </td>

                        <td
                          colSpan={2}
                          className="py-3 px-4  border border-white  border-r-[#F0F0F0] border-b-[#F0F0F0] border-t-[#F0F0F0]"
                        >
                          <div className="flex">
                            <div className="flex items-center justify-center text-sm border">
                              <input
                                type="remaining"
                                className="w-24"
                                value={task?.remaining}
                                disabled={data?.animate_pulse}
                                onChange={(e) =>
                                  handleProductiveOrder?.getRemaining(
                                    e,
                                    item,
                                    task,
                                    index,
                                    ind
                                  )
                                }
                                onBlur={() =>
                                  handleProductiveOrder?.saveRemaining(
                                    task,
                                    item
                                  )
                                }
                                onFocus={() => setFocusedIndex(index)}
                              />
                            </div>
                            {task?.remaining <= 0 && (
                              <div>
                                <Button
                                  className="text-[#0F4C7D] border-none pr-0"
                                  onClick={() =>
                                    handleProductiveOrder?.openPODataModal(
                                      item,
                                      task,
                                      index
                                    )
                                  }
                                  disabled={data?.animate_pulse}
                                >
                                  <FaTrash />
                                </Button>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}

                  <tr className="border-b border-[#F0F0F0]">
                    <td colSpan={8} className="py-3 text-right">
                      <div>
                        <Button
                          className="btn-light ml-auto mr-3 text-black h-[40px] px-4  flex "
                          onClick={() =>
                            openMultytask(
                              item?.id,
                              item?.productivity_orders_data
                            )
                          }
                        >
                          <div className="pt-1"> Add Task </div>
                          <div className="bg-orange-500 p-2 rounded-md text-white ml-3">
                            <FaPlus />
                          </div>
                        </Button>
                      </div>
                    </td>
                  </tr>
                </>
              )}
            </>
          ))}
        </tbody>
      </table>

      {modal?.isModal && (
        <DeleteModal
          setData={setModal}
          data={modal}
          action={handleProductiveOrder?.modalAction}
          close={handleProductiveOrder?.closeModal}
        />
      )}
      {modaltimespent?.isModal && (
        <TimespentModal
          close={handleProductiveOrder?.closeModal2}
          id={modaltimespent.id}
          hours={modaltimespent.totalhours}
          handle={handle}
        />
      )}
    </>
  );
};

export default ProductivityOrdersTable;
