import { Input, Modal, Select } from "antd";
import React, { Fragment, useMemo } from "react";
import { Controller } from "react-hook-form";
import { FaTimes } from "react-icons/fa";
import { Spinner } from "../../../../../assets/svg";
import InputError from "../../../../../components/InputError";

const CreateTaskAllowance = ({
  visible,
  toggleModal,
  isSubmitting,
  onSubmit,
  control,
}) => {
  const options = useMemo(() => ["Rate", "Hour", "Days"], []);

  return (
    <Modal
      open={visible}
      footer={[]}
      onCancel={toggleModal}
      centered
      closeIcon={<FaTimes className="text-black" />}
      className="min-w-fit"
      destroyOnClose
      maskClosable={false}
    >
      <div className="modal-body mt-3">
        <h2 className="text-xl font-bold">Edit Task Allowance</h2>

        <form className="mt-4" onSubmit={onSubmit}>
          <div className="item mt-5">
            <label htmlFor="name">Summary Column</label>
            <Controller
              name="summary"
              control={control}
              rules={{
                required: "Please enter summary column",
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Fragment>
                  <Input
                    type="text"
                    className="mt-3"
                    value={value}
                    onChange={onChange}
                    id="name"
                    autoComplete="off"
                  />
                  <InputError message={error?.message} />
                </Fragment>
              )}
            />
          </div>
          <span className="flex gap-4">
            <div className="item mt-5 flex-1">
              <label htmlFor="name">Hourly Rate Name</label>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: "Please enter hourly rate name",
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <Fragment>
                    <Input
                      type="text"
                      className="mt-3"
                      autoComplete="off"
                      value={value}
                      onChange={onChange}
                    />
                    <InputError message={error?.message} />
                  </Fragment>
                )}
              />
            </div>
            <div className="item mt-5 flex-1">
              <label htmlFor="name">Hourly Rate</label>
              <Controller
                name="hourly_rate"
                rules={{
                  required: "Please enter hourly rate",
                  min: {
                    value: 0,
                    message: "Hourly rate cannot me less than 0",
                  },
                }}
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <Fragment>
                    <Input
                      type="number"
                      className="mt-3"
                      name="name"
                      id="name"
                      autoComplete="off"
                      value={value}
                      onChange={onChange}
                      onWheel={(e) => {
                        e.target.blur();
                      }}
                    />
                    <InputError message={error?.message} />
                  </Fragment>
                )}
              />
            </div>
          </span>
          <span className="flex gap-4">
            <div className="item mt-5 flex-1">
              <label htmlFor="name">Export As</label>
              <Controller
                name="export_as"
                control={control}
                rules={{
                  required: "Please select a value",
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <Fragment>
                    <div className="border border-[#d9d9d9] rounded-lg mt-3">
                      <Select
                        className="w-full"
                        style={{ width: 260 }}
                        bordered={false}
                        value={value}
                        onChange={onChange}
                      >
                        {options.map((item) => (
                          <Select.Option value={item} key={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                    <InputError message={error?.message} />
                  </Fragment>
                )}
              />
            </div>
            <div className="item mt-5 flex-1">
              <label htmlFor="name">Display In Summery As</label>
              <Controller
                name="display_in_summary_as"
                control={control}
                rules={{
                  required: "Please select a value",
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <Fragment>
                    <div className="border border-[#d9d9d9] rounded-lg mt-3">
                      <Select
                        className="w-full"
                        style={{ width: 260 }}
                        bordered={false}
                        value={value}
                        onChange={onChange}
                      >
                        {options.map((item) => (
                          <Select.Option value={item} key={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                    <InputError message={error?.message} />
                  </Fragment>
                )}
              />
            </div>
          </span>

          <div className="item mt-5 text-center">
            <button
              className="min-w-[100px] py-2 px-4 rounded-lg btn btn-blue"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? <Spinner /> : "Save Changes"}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CreateTaskAllowance;
