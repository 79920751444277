import { Button } from "antd";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TableDefaultSvg } from "../../assets/svg/tableDefaul";
import CreateNewTaskModal from "../../shared/Modal/CreateNewTaskModal";
import CreateNewCategoryModal from "../../shared/Modal/CreateNewCategoryModal";
import CreateAdminDetailsModal from "../../shared/Modal/CreateAdminDetailsModal";
import AddNewSiteManagerModal from "../../shared/Modal/AddNewSiteManagerModal";
import CreateNewCompanyModal from "../../shared/Modal/CreateNewCompanyModal";
import CreateEmployementStatusModal from "../../shared/Modal/CreateEmpoyementStatusModal";
import CreatePayrollCategoryModal from "../../shared/Modal/CreatePayrollCategory";
import AddNewWorkerModal from "../../shared/Modal/AddNewWorkerModal";
import CreateNewAreaModal from "../../shared/Modal/CreateNewAreaModal";

const TableDefaultPage = ({ data }) => {
  let location = useLocation();

  let navigate = useNavigate();

  const initialvalues = {
    disclaimer: data?.disclaimer,
    description: data?.description,
    buttonText: data?.buttonText,
    svg: data?.svg,
    redirect: data?.redirect,
    data: data?.data,
    setData: data?.setData,
    handle: data?.handle,
    errors: data?.errors,
    tab: data?.tab
  };

  const [tableDefault, setTableDefault] = useState(initialvalues);
  const [visibleCreate, setVisibleCreate] = React.useState(false);
  const [createCategory, setCreateCategory] = React.useState(false);
  const [createAdmin, setCreateAdmin] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [createCompany, setCreateCompany] = React.useState(false);
  const [createEmployementStatus, setCreateEmployementStatus] = useState(false);
  const [CreatePayrollCategory, setCreatePayrollCategory] = useState(false);
  const [visibleWorkerModal, setVisibleWorkerModal] = useState(false);
  const [visibleAreaModal, setVisibleAreaModal] = useState(false);

  const handleTableDefault = {
    redirect: () => {
      if (location?.pathname == "/admin/settings/hourly-rate-tasks") {
        setVisibleCreate(true);
      } else if (location?.pathname == "/admin/settings/categories") {
        setCreateCategory(true);
      } else if (location?.pathname == "/admin/settings/users/admin") {
        setCreateAdmin(true);
      } else if (location?.pathname == "/admin/personnel/site-managers") {
        setVisible(true);
      } else if (location?.pathname == "/admin/settings/induction/companies") {
        setCreateCompany(true);
      } else if (
        location?.pathname == "/admin/settings/payroll/employment-status"
      ) {
        setCreateEmployementStatus(true);
      } else if (
        location?.pathname?.includes("/admin/settings/payroll/categories")
      ) {
        setCreatePayrollCategory(true);
      } else if (location?.pathname?.includes("/admin/personnel/workers")) {
        setVisibleWorkerModal(true);
      } /*  else if (
        location?.pathname?.includes("/admin/worksite/details/areas/")
      ) {
        setVisibleAreaModal(true);
      } */ else {
        navigate(data?.redirect);
      }
    },
  };

  return (
    <>
      {/*   {visibleAreaModal && (
        <CreateNewAreaModal
          setVisibleAreaModal={setVisibleAreaModal}
          data={tableDefault?.data}
          handle={tableDefault?.handle}
          errors={tableDefault?.errors}
        />
      )} */}

      {visibleCreate && (
        <CreateNewTaskModal setVisibleCreate={setVisibleCreate} />
      )}

      {createCategory && (
        <CreateNewCategoryModal setCreateCategory={setCreateCategory} />
      )}

      {createAdmin && (
        <CreateAdminDetailsModal setCreateAdmin={setCreateAdmin} />
      )}

      {createCompany && (
        <CreateNewCompanyModal setCreateCompany={setCreateCompany} />
      )}

      {visible && (
        <AddNewSiteManagerModal
          setVisible={setVisible}
          data={tableDefault?.data}
          setData={tableDefault?.setData}
        />
      )}

      {createEmployementStatus && (
        <CreateEmployementStatusModal
          setCreateEmployementStatus={setCreateEmployementStatus}
        />
      )}

      {CreatePayrollCategory && (
        <CreatePayrollCategoryModal
          setCreatePayrollCategory={setCreatePayrollCategory}
        />
      )}

      {visibleWorkerModal && (
        <AddNewWorkerModal setVisibleWorkerModal={setVisibleWorkerModal} />
      )}

      <div className={tableDefault?.tab ? "h-full flex justify-center items-center flex-col bg-white py-8" : "h-full flex justify-center items-center flex-col bg-white"}>
        <div className="py-2">{tableDefault?.svg}</div>
        <div className="">{tableDefault?.disclaimer}</div>
        <div className="text-gray-300 text-base py-2">
          {tableDefault?.description}
        </div>
        <div className="pt-2">
          {data?.buttonText && (
            <Button className="btn-blue" onClick={handleTableDefault?.redirect}>
              {data?.buttonText}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export { TableDefaultPage };
