import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import {
  ChangeWorkerStatus,
  ChangeWorkerStatusTerminate,
  GetWorkersType,
  MarkPresent,
} from "../../helper/worksite/worksite";
import { Fileurl } from "../../config/url";
import DefaultCree8Img from "../../assets/images/Default/defaulCree8.png";
import { TextShimmer } from "../../components/shimmer/shimmer";
import { ButtonSpinner } from "../../components/spinners";
import { toast } from "react-toastify";
import { TableDefaultPage } from "../../components/default/defaultPage";
import { Button, Modal, Popover } from "antd";
import TransferModal from "./transferModal";
import { useParams } from "react-router-dom";
import RosterDefaultImage from "../../assets/svg/rosterWorkerDefaultImg";
import AbsentModal from "./absentModal";
import { ApiAuthorizaion } from "../../auth/appAuth";
import { PayRollService } from "../../config/axiosUrl";
import CreatingWorkShiftModal from "./CreatingWorkShiftModal";
import TextArea from "antd/es/input/TextArea";
import { TablePagination } from "../../components/pagination/pagination";

const RestoredTable = ({
  work_status,
  setWorkerLength,
  worksite,
  setWorksite,
  selectedTab,
  setWorkerType,
}) => {
  let deafaulPage = {
    disclaimer: "There are no Worker listed on the roster",
    redirect: "",
    svg: <RosterDefaultImage />,
    tab: true,
  };
  const id = useParams();
  const [timerId, setTimerId] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [openTerminateModal, setOpenTerminateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [absenteModal, setAbsentModal] = useState(false);
  const [absenseType, setAbsenseType] = useState();
  const [terminationReason, setTerminationReason] = useState("");
  const saveChanges = async () => {
    setIsLoading(true);
    let data = await ChangeWorkerStatusTerminate(
      selectedItem,
      worksite,
      terminationReason
    );
    if (data?.status) {
      toast.success(data?.message ? data?.message : "Terminates Successfuly");
      setOpenTerminateModal(false);
      const param = { type: work_status, id: id?.id };
      const res = await GetWorkersType(param);
      if (res?.status) {
        setWorksite({
          ...worksite,
          data: res?.data?.data,
        });
      }
    }
  };
  const handleWorksite = {
    getWorkers: async (work_status) => {
      let data = await GetWorkersType({ ...worksite, type: work_status });
      if (data?.status) {
        setWorksite({
          ...worksite,
          data: data?.data?.data,
          extra: data?.extra,
          worker_status: work_status,
          isShimmer: false,
          cursor: "",
        });
        setWorkerLength(data?.data?.data.length);
        return data;
      } else {
        setWorksite({
          ...worksite,
          isShimmer: false,
        });
        return data;
      }
    },
  };
  const startTimer = async (item) => {
    setIsLoader(true);
    setTimerId(item?._id);
    let data = await ChangeWorkerStatus(item, worksite);
    if (data?.status) {
      toast.success("Status change Successfully");
      handleWorksite?.getWorkers(work_status);
      setIsLoader(false);
    }
  };
  const transferWorker = async () => {
    setOpenTransferModal(true);
  };
  const terminateWorker = async () => {
    setOpenTerminateModal(true);
  };
  const GetAbsenseType = async () => {
    try {
      const authorization = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: ApiAuthorizaion(),
        },
      };

      let data = await PayRollService?.get(
        `/api/v1/setting/payroll/get-absences-data?type=common`,
        {
          headers: authorization?.headers,
          // params: param
        }
      );
      return data?.data;
    } catch (error) {
      return error;
    }
  };
  const absentWorker = async () => {
    setAbsentModal(true);
    let response = await GetAbsenseType();
    if (response?.status) {
      setAbsenseType(response?.data?.data);
    }
  };
  const presentWorker = async () => {
    let res = await MarkPresent(worksite, selectedItem);
    if (res?.status) {
      toast.success("Status change Successfully");
      handleWorksite?.getWorkers(work_status);
      setIsLoader(false);
    }
  };
  useEffect(() => {
    if (selectedTab == "1") {
      setWorkerType("Rostered");
      handleWorksite?.getWorkers(work_status);
    }
  }, [selectedTab]);

  const content = (
    <div>
      <p
        className="px-4 py-2 cursor-pointer border-b-2"
        onClick={transferWorker}
      >
        Transfer
      </p>
      <p
        className="px-4 py-2 cursor-pointer border-b-2"
        onClick={terminateWorker}
      >
        Terminate
      </p>
      <p
        className="px-4 py-2 cursor-pointer"
        onClick={selectedItem?.is_absence == 1 ? presentWorker : absentWorker}
      >
        {selectedItem?.is_absence == 1 ? "Mark Present" : "Mark Absent"}
      </p>
      {/* <p className="px-4 py-2 cursor-pointer" onClick={()=>assignTaskWorker()}>Assign Task</p> */}
    </div>
  );

  const onCloseTransefer = () => {
    setOpenTransferModal(false);
  };
  const onClose = () => {
    setOpenTerminateModal(false);
  };
  const onCloseAbsentModal = () => {
    setAbsentModal(false);
  };
  let hourlyRateTask = worksite?.data?.reduce((accumulator, item) => {
    if (item.hourly_rate_task && item.hourly_rate_task.length > 0) {
      accumulator.push(...item.hourly_rate_task.map((value) => value.name));
    }
    return accumulator;
  }, []);

  let productivityOrders = worksite?.data?.reduce((accumulator, item) => {
    if (item.productivity_orders && item.productivity_orders.length > 0) {
      accumulator.push(...item.productivity_orders.map((value) => value.name));
    }
    return accumulator;
  }, []);

  let finalWork;
  if (hourlyRateTask && productivityOrders) {
    finalWork = hourlyRateTask.concat(productivityOrders).join(", ");
  }

  return (
    <>
      {worksite?.isShimmer ? (
        <div className="py-6 px-4 ">
          <TextShimmer data={{ gap: 15, line: 20 }} />
        </div>
      ) : worksite?.data?.length > 0 ? (
        <table className="w-full">
          <thead className="bg-[#E8F3FF] text-center">
            <tr className="text-left">
              <th className=" px-4 py-3 ">
                <div className="flex items-center justify-start">
                  Worker Name
                </div>
              </th>
              <th className=" px-4 py-3 ">
                <div className="flex items-center justify-center"></div>
              </th>
              <th className=" px-4 py-3 ">
                <div className="flex items-center justify-center">Action</div>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {worksite?.data?.length > 0 &&
              worksite?.data?.map((item, index) => (
                <tr key={index} className="border-b border-[#F0F0F0]">
                  <td className="px-4 py-3">
                    <div className="flex items-center justify-start">
                      <img
                        src={
                          item?.users_profile_details?.data
                            ? Fileurl + item?.users_profile_details?.data
                            : DefaultCree8Img
                        }
                        alt=""
                        className="w-[50px] h-[50px] rounded mr-[10px]"
                      />
                      <div>
                        <p className="font-medium">
                          {item?.users.length > 0 ? item.users[0].name : "-"}
                        </p>
                        <p className="text-[10px] text-[#707070]">
                          {finalWork ? `Work: ${finalWork}` : ""}
                          {/* {item.work.map((item, index) => (
                      <span key={index} className="text-[#0F4C7D]">
                        {item}
                      </span>
                    ))} */}
                          {/* <span className="text-[#0F4C7D]">
                        {item?.role}
                      </span> */}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="px-4 py-3">
                    {isLoader && item?._id === timerId ? (
                      <ButtonSpinner />
                    ) : (
                      <div className="flex items-center justify-center">
                        {item?.is_absence == 1 ? (
                          <p>Absent</p>
                        ) : (
                          <button onClick={() => startTimer(item)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="34.441"
                              height="34.694"
                              viewBox="0 0 34.441 34.694"
                            >
                              <path
                                id="Path_1408"
                                data-name="Path 1408"
                                d="M16.851,0h.674a17.163,17.163,0,0,1,7.547,1.909,17.356,17.356,0,0,1,8.145,9.014,17.62,17.62,0,0,1,1.224,6.05v.682a17.39,17.39,0,0,1-7.352,13.9,17.14,17.14,0,0,1-9.562,3.135h-.675a17.168,17.168,0,0,1-9.5-3.136A17.387,17.387,0,0,1,0,17.656v-.678A17.513,17.513,0,0,1,1.9,9.434,17.213,17.213,0,0,1,16.851,0M12.517,9.8q0,7.546,0,15.09Q19,21.117,25.488,17.347,19,13.574,12.517,9.8Z"
                                fill="#51ff8b"
                              />
                            </svg>
                            Start
                          </button>
                        )}
                      </div>
                    )}
                  </td>
                  <td className="px-4 py-3">
                    <Popover placement="left" content={content} trigger="click">
                      <FaPlus
                        className="text-sm border-2 p-1 border-black w-[25px] h-[25px] flex justify-center items-center rounded-full cursor-pointer marginAuto"
                        onClick={() => setSelectedItem(item)}
                      />
                    </Popover>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <TableDefaultPage data={deafaulPage} />
      )}
      <TransferModal
        isOpen={openTransferModal}
        onClose={onCloseTransefer}
        worksite={worksite}
        setWorksite={setWorksite}
        selectedItem={selectedItem}
      />
      <Modal
        title={`Terminating ${selectedItem?.users[0]?.name}`}
        className="clockin-modal termination-modal"
        open={openTerminateModal}
        onCancel={onClose}
        footer={[
          <Button
            key="save"
            onClick={saveChanges}
            className="btn btn-blue bg-blue-disable"
          >
            {isLoading ? <ButtonSpinner /> : "Confirm Termination"}
          </Button>,
        ]}
      >
        <label>Enter the Reason For termination</label>
        <TextArea
          placeholder="Worker contract ended today"
          rows={4}
          allowClear
          onChange={(e) => setTerminationReason(e?.target?.value)}
          value={terminationReason}
        />
      </Modal>
      {absenteModal && (
        <AbsentModal
          onCloseAbsentModal={onCloseAbsentModal}
          openAbsentModal={absenteModal}
          absenseType={absenseType}
          worksite={worksite}
          setWorksite={setWorksite}
          selectedItem={selectedItem}
          workStatus={"Rostered"}
        />
      )}
      <TablePagination />
    </>
  );
};

export default RestoredTable;
