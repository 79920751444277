import React from "react";

const WorkerDetailsInduction = ({ worker, setWorker, handle }) => {
  return (
    <div className="pt-5">
      <div className="pt-5">
        <div className="fs-22 ">Worker Details</div>
      </div>

      <div className="flex justify-between w-full items-center mt-5">
        <div className="lg:w-1/4 md:w-1/2 w-full text-left">
          <p className="fs-14 text-[#A1A1A1]">Gender</p>
          <p className="fs-14">
            {worker?.worker_details?.[0]?.gender
              ? worker?.worker_details?.[0]?.gender
              : "N/A"}
          </p>
        </div>
        <div className="lg:w-1/4 md:w-1/2 w-full text-left">
          <p className="fs-14 text-[#A1A1A1]">Date of Birth</p>
          <p className="fs-14">
            {worker?.worker_details?.[0]?.dob
              ? worker?.worker_details?.[0]?.dob
              : "N/A"}
          </p>
        </div>
        <div className="lg:w-1/4 md:w-1/2 w-full text-left">
          <p className="fs-14 text-[#A1A1A1]">Phone Number</p>
          <p className="fs-14">
            {worker?.worker_details?.[0]?.mobile
              ? worker?.worker_details?.[0]?.mobile
              : "N/A"}{" "}
          </p>
        </div>
        <div className="lg:w-1/4 md:w-1/2 w-full text-left">
          <p className="fs-14 text-[#A1A1A1]">Blood Type</p>
          <p className="fs-14">-</p>
        </div>
        <div className="lg:w-1/4 md:w-1/2 w-full text-left">
          <p className="fs-14 text-[#A1A1A1]">Shirt Size</p>
          <p className="fs-14">
            {" "}
            {worker?.worker_details?.[0]?.shirt_size
              ? worker?.worker_details?.[0]?.shirt_size
              : "N/A"}
          </p>
        </div>
        <div className="lg:w-1/4 md:w-1/2 w-full text-left">
          <p className="fs-14 text-[#A1A1A1]">Pants Size</p>
          <p className="fs-14">
            {" "}
            {worker?.worker_details?.[0]?.pant_size
              ? worker?.worker_details?.[0]?.pant_size
              : "N/A"}
          </p>
        </div>
      </div>

      <div className="profile py-4 border-b-2 border-[#E9E9E9]" />

      <div>
        <div className="pt-5">
          <div className="flex justify-between w-full items-center mt-5">
            <div className="lg:w-1/4 md:w-1/2 w-full text-left">
              <p className="fs-14 text-[#A1A1A1]">Address</p>
              <p className="fs-14">
                {worker?.worker_details?.[0]?.address
                  ? worker?.worker_details?.[0]?.address
                  : "N/A"}
              </p>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full text-left">
              <p className="fs-14 text-[#A1A1A1]">City</p>
              <p className="fs-14">
                {worker?.worker_details?.[0]?.city
                  ? worker?.worker_details?.[0]?.city
                  : "N/A"}
              </p>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full text-left">
              <p className="fs-14 text-[#A1A1A1]">Post Code</p>
              <p className="fs-14">
                {worker?.worker_details?.[0]?.post_code
                  ? worker?.worker_details?.[0]?.post_code
                  : "N/A"}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="profile py-4 border-b-2 border-[#E9E9E9]" />

      <div>
        <div className="pt-5">
          <div className="flex justify-between w-full items-center mt-5">
            <div className="lg:w-1/4 md:w-1/2 w-full text-left">
              <p className="fs-14 text-[#A1A1A1]">Super Fund Name</p>
              <p className="fs-14">
                {worker?.worker_details?.[0]?.super_fund_name
                  ? worker?.worker_details?.[0]?.super_fund_name
                  : "N/A"}
              </p>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full text-left">
              <p className="fs-14 text-[#A1A1A1]">Aus Superfund</p>
              <p className="fs-14">
                {worker?.worker_details?.[0]?.super_fund_number
                  ? worker?.worker_details?.[0]?.super_fund_number
                  : "N/A"}
              </p>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full text-left">
              <p className="fs-14 text-[#A1A1A1]">Tax File Number</p>
              <p className="fs-14">
                {worker?.worker_details?.[0]?.tax_file_number
                  ? worker?.worker_details?.[0]?.tax_file_number
                  : "N/A"}
              </p>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full text-left">
              <p className="fs-14 text-[#A1A1A1]">BERT</p>
              <p className="fs-14">
                {worker?.worker_details?.[0]?.bert
                  ? worker?.worker_details?.[0]?.bert
                  : "N/A"}
              </p>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full text-left">
              <p className="fs-14 text-[#A1A1A1]">QLeave</p>
              <p className="fs-14">
                {worker?.worker_details?.[0]?.qLeave
                  ? worker?.worker_details?.[0]?.qLeave
                  : "N/A"}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="pt-5">
          <div className="flex  w-full items-center mt-5">
            <div className="lg:w-1/4 md:w-1/2 w-full text-left">
              <p className="fs-14 text-[#A1A1A1]">BSB</p>
              <p className="fs-14">
                {worker?.worker_details?.[0]?.bank_bsb
                  ? worker?.worker_details?.[0]?.bank_bsb
                  : "N/A"}
              </p>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full text-left">
              <p className="fs-14 text-[#A1A1A1]">Account</p>
              <p className="fs-14">
                {worker?.worker_details?.[0]?.bank_account_number
                  ? worker?.worker_details?.[0]?.bank_account_number
                  : "N/A"}
              </p>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full text-left">
              <p className="fs-14 text-[#A1A1A1]">Drivers Licence</p>
              <p className="fs-14">
                {worker?.worker_details?.[0]?.license_file
                  ? worker?.worker_details?.[0]?.license_file
                  : "N/A"}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="profile py-4 border-b-2 border-[#E9E9E9]" />

      <div className="mt-5">
        <div className="pt-5">Emergency Contacts And Medical conditions</div>
      </div>

      <div>
        <div className="pt-5">
          <div className="flex justify-between w-full items-center mt-5">
            <div className="lg:w-1/4 md:w-1/2 w-full text-left">
              <p className="fs-14 text-[#A1A1A1]">Name</p>
              <p className="fs-14">N/A</p>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full text-left">
              <p className="fs-14 text-[#A1A1A1]">Phone Number</p>
              <p className="fs-14">N/A</p>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full text-left">
              <p className="fs-14 text-[#A1A1A1]">Existing Injuries</p>
              <p className="fs-14">N/A</p>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full text-left">
              <p className="fs-14 text-[#A1A1A1]">Medical Condition</p>
              <p className="fs-14">N/A</p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="pt-5">
          <div className="flex  w-full items-center mt-5">
            <div className="lg:w-1/4 md:w-1/2 w-full text-left">
              <p className="fs-14 text-[#A1A1A1]">Are you a Subcontractor ?</p>
              <p className="fs-14">N/A</p>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full text-left">
              <p className="fs-14 text-[#A1A1A1]">
                Have You ever made a worker's compensation claim?
              </p>
              <p className="fs-14">N/A</p>
            </div>
          </div>
        </div>
      </div>

      <div className="profile py-4 border-b-2 border-[#E9E9E9]" />

      <div className="mt-5">
        <div className="pt-5">Experience</div>
      </div>

      <div>
        <div className="pt-5">
          <div className="flex justify-between w-full items-center mt-5">
            <div className="lg:w-1/4 md:w-1/2 w-full text-left">
              <p className="fs-14 text-[#A1A1A1]">Trade Certificate</p>
              <p className="fs-14">N/A</p>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full text-left">
              <p className="fs-14 text-[#A1A1A1]">Photo of Trade Certificate</p>
              <p className="fs-14">
                <u>View</u>
              </p>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full text-left">
              <p className="fs-14 text-[#A1A1A1]">Trade</p>
              <p className="fs-14">N/A</p>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full text-left">
              <p className="fs-14 text-[#A1A1A1]">Year in Trade</p>
              <p className="fs-14">N/A</p>
            </div>
          </div>
        </div>
      </div>

      <div className="profile py-4 border-b-2 border-[#E9E9E9]" />

      <div className="mt-5">
        <div className="pt-5">Previous Employment Details</div>
      </div>

      <div>
        <div className="pt-5">
          <div className="flex justify-between w-full items-center mt-5">
            <div className="lg:w-1/4 md:w-1/2 w-full text-left">
              <p className="fs-14 text-[#A1A1A1]">Company</p>
              <p className="fs-14">N/A</p>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full text-left">
              <p className="fs-14 text-[#A1A1A1]">Job</p>
              <p className="fs-14">N/A</p>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full text-left">
              <p className="fs-14 text-[#A1A1A1]">Duties</p>
              <p className="fs-14">N/A</p>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full text-left">
              <p className="fs-14 text-[#A1A1A1]">Duration</p>
              <p className="fs-14">N/A</p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="pt-5">
          <div className="flex w-full items-center mt-5">
            <div className="lg:w-1/4 md:w-1/2 w-full text-left">
              <p className="fs-14 text-[#A1A1A1]">
                Do you Posses a working visa ?
              </p>
              <p className="fs-14">N/A</p>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full text-left">
              <p className="fs-14 text-[#A1A1A1]">Photo of Licence</p>
              <p className="fs-14">
                <u>view</u>
              </p>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full text-left">
              <p className="fs-14 text-[#A1A1A1]">Photo of Blue Card</p>
              <p className="fs-14">
                <u>view</u>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkerDetailsInduction;
