import { Button, Tabs } from "antd";
import AreaTask from "../../../../../../shared/DataTable/areaDetails1Table";
import OverflowTable from "../../../../../../shared/DataTable/OverflowTable";
import Productivity from "./productivity";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../../../layouts/Main";
import OverViewsWorksite from "../../../../../Admin/Worksite/WorksiteDetails/Tabpans/OverviewsWorksite";
import IssueIcon from "../../../../../../assets/svg/issuesIconSvg";
import useWindowDimensions from "../../../../../../utils/routes/useWindowDimensions";

const AreaDetails = ({
  tasks,
  setTasks,
  getInspection,
  setGetInspection,
  inspection,
  setInspection,
  setIssueDetails,
}) => {
  const { width } = useWindowDimensions();
  const initialvalue = {
    isShimmer: true,
    data: [],
  };
  const navigate = useNavigate();
  const { area } = useParams();
  let path = useLocation();
  const userData = localStorage.getItem("dataStore");
  const [dataStore, setDataStore] = useContext(AppContext);
  const [selectedTask, setSelectedTask] = useState();
  const [overview, setOverView] = useState(initialvalue);
  const [areaId, setAreaId] = useState();
  const [selectedKey, setSelectedKey] = useState("1");
  useEffect(() => {
    setDataStore(JSON.parse(userData));
    setAreaId(area);
  }, []);
  useEffect(() => {
    if (path?.pathname?.includes("/site-manager/worksite/details/task")) {
      setSelectedKey("1");
    } else if (
      path?.pathname?.includes("/site-manager/worksite/details/productivitys")
    ) {
      setSelectedKey("2");
    } else if (
      path?.pathname?.includes("/site-manager/worksite/details/overviews")
    ) {
      setSelectedKey("3");
    }
  }, [window.location.pathname]);

  const onChange = (key) => {
    console.log(key);
    setSelectedKey(key);
    setIssueDetails(false);
    if (key == "1") {
      navigate(
        `/site-manager/worksite/details/task/${dataStore?.id}/${area}/1`
      );
    }
    if (key == "2") {
      navigate(
        `/site-manager/worksite/details/productivitys/${dataStore?.id}/${area}/`
      );
    }
    if (key == "3") {
      navigate(
        `/site-manager/worksite/details/overviews/${dataStore?.id}/${area}/`
      );
    }
  };
  const items = [
    {
      key: "1",
      label: "Tasks",
      children: (
        <AreaTask
          tasks={tasks}
          setTasks={setTasks}
          getInspection={getInspection}
          setGetInspection={setGetInspection}
          inspection={inspection}
          setInspection={setInspection}
          selectedTask={selectedTask}
          setSelectedTask={setSelectedTask}
        />
      ),
    },
    {
      key: "2",
      label: "Productivity  Order",
      children: <Productivity area={areaId} />,
    },
    {
      key: "3",
      label: "Tasks Overflow",
      children: (
        <>
          <OverViewsWorksite
            selectedTask={selectedTask}
            overview={overview}
            setOverView={setOverView}
          />
          {overview?.data?.workers?.length > 0 && (
            <div className="mt-5">
              <OverflowTable overview={overview} />
            </div>
          )}
        </>
      ),
    },
  ];
  return (
    <div className="bg-white py-2">
      <Tabs
        tabBarExtraContent={
          <Button
            className={
              width > 767
                ? "h-[45px] flex items-center"
                : "h-[35px] w-[125px] ml-3 mr-3 flex items-center"
            }
            onClick={() => {
              setIssueDetails(true);
              navigate(
                `/site-manager/worksite/details/issues/${dataStore?.id}/${area}`
              );
            }}
          >
            <IssueIcon className={width > 767 ? "" : "h-[25px] w-[25px] "} />{" "}
            Issues
          </Button>
        }
        className="details-tab areas-tab"
        activeKey={selectedKey}
        items={items}
        onChange={onChange}
      />
    </div>
  );
};
export default AreaDetails;
