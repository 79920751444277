import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Unarchive } from "../../../../assets/svg";
import { toast } from "react-toastify";
import { PayRollService } from "../../../../config/axiosUrl";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../../components/PaginationAPI/Pagination";
import Confirmation from "../../../../components/Confirmation";
import { TextShimmer } from "../../../../components/shimmer/shimmer";
import { NoSearchTableSvg } from "../../../../assets/svg/tableDefaul";
import { NoTableOption } from "../../../../components/noOption/noOption";
import DefaultPage from "./Defaultpage";
const AllowanceTable = ({ getAPI, reactiveAPI, label, path, search }) => {
  const navigate = useNavigate();
  const [archivedPC, setArchivedPC] = useState({
    data: [],
    search: "",
    loading: true,
  });

  const [unarchive, setUnarchive] = useState({
    isSubmitting: false,
    visible: false,
    data: {},
    reget: false,
  });

  const getArchivePC = useCallback((res) => {
    setArchivedPC((prev) => ({
      ...prev,
      data: res?.data?.data?.data || [],
      loading: false,
    }));
  }, []);

  const unArchive = useCallback(
    async (id) => {
      await new Promise((resolve) => {
        setUnarchive((prev) => ({
          ...prev,
          isSubmitting: true,
        }));
        resolve(true);
      });

      PayRollService.post(reactiveAPI, {
        id,
      })
        .then((res) => {
          if (res?.data?.status) {
            setUnarchive((prev) => ({
              ...prev,
              isSubmitting: false,
              visible: false,
              data: {},
              reget: !prev.reget,
            }));
            toast.success(res?.data?.message);
          } else {
            setUnarchive((prev) => ({
              ...prev,
              isSubmitting: false,
            }));
            toast.error(res?.data?.status);
          }
        })
        .catch((err) => {
          setUnarchive((prev) => ({
            ...prev,
            isSubmitting: false,
          }));
          toast.error("Failed to Reactivate Absence");
        });
    },
    [reactiveAPI]
  );

  const onReactiveClick = useCallback((data) => {
    setUnarchive((prev) => ({
      ...prev,
      visible: !!data,
      data,
    }));
  }, []);

  useEffect(() => {
    setArchivedPC((prev) => ({
      ...prev,
      loading: true,
    }));
  }, [getAPI]);
  let noSearchOption = {
    svg: <NoSearchTableSvg />,
    disclaimer: "There were no search results found",
    description: "Sorry, No Item Matches Your Search Term.",
  };

  return (
    <Fragment>
      {archivedPC.loading ? (
        <TextShimmer
          data={{
            line: 20,
            gap: 15,
          }}
        />
      ) : (
        <Fragment>
          <Confirmation
            open={unarchive.visible}
            isSubmitting={unarchive.isSubmitting}
            title={`Are you sure you want to reactivate this ${label}`}
            details={unarchive.data?.name}
            onCancel={() => onReactiveClick()}
            onConfirm={() => unArchive(unarchive.data.id)}
            buttonText="Reactivate"
          />
          <div className="flex justify-between items-center bg-white p-5 pt-2">
            <h1>{label}</h1>
            <button
              className="text-[#FE650C] underline"
              onClick={() => navigate(path)}
            >
              View all Active {label}
            </button>
          </div>
          <table className="w-full">
            <thead className="bg-[#E8F3FF] text-center">
              <tr className="text-left">
                <th className=" px-4 py-3 w-[80%]">
                  <div className="flex items-center ">Name</div>
                </th>
                <th className=" px-4 py-3 ">
                  <div className="flex items-center justify-center">
                    Actions
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {archivedPC.data.length > 0 ? (
                archivedPC.data.map((item, index) => (
                  <tr className="border-b-2 border-[#F0F0F0]" key={index}>
                    <td className="px-4 py-3">
                      <div className="flex items-center">{item.name}</div>
                    </td>
                    <td className="px-4 py-3">
                      <div
                        className="flex items-center justify-center gap-1 select-none cursor-pointer"
                        onClick={() => onReactiveClick(item)}
                      >
                        <Unarchive /> Reactivate
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2" className="px-4 py-3 text-center">
                    {archivedPC.search ? (
                      <NoTableOption data={noSearchOption} />
                    ) : (
                      <DefaultPage />
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Fragment>
      )}
      <Pagination
        api={getAPI}
        apiService={PayRollService}
        getRes={getArchivePC}
        extraParams={{
          search,
        }}
        dependecy={[archivedPC.search, unarchive.reget, getAPI, search]}
      />
    </Fragment>
  );
};

export default AllowanceTable;
