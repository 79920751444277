import moment from "moment";
import React from "react";
import { FaCalendar, FaClock, FaDollarSign } from "react-icons/fa";

const LineChartInfo = ({ data }) => {
  console.log(data);
  return (
    <div>
      {
        <>
          <p className="my-8">
            <span className="text-lg text-[#A1A1A1] flex items-center">
              <FaDollarSign className="mr-2" />
              Estimated project cost:
            </span>
            <span className="text-2xl font-semibold">
              {data?.data?.overall_cost
                ? Number(data?.data?.overall_cost).toLocaleString("en-AU")
                : "0 "}
            </span>
          </p>

          <p className="my-8">
            <span className="text-lg text-[#A1A1A1] flex items-center">
              <FaDollarSign className="mr-2" />
              Total Spent:
            </span>
            <span className="text-2xl font-semibold">
              {data?.data?.total_spent
                ? parseFloat(data?.data?.total_spent).toFixed(2) ||
                  data?.data?.total_spent
                : 0}
            </span>
          </p>

          <p className="my-8">
            <span className="text-lg text-[#A1A1A1] flex items-center">
              <FaClock className="mr-2" />
              Current Work rate:
            </span>
            <span className="text-2xl font-semibold">0</span>
          </p>

          <p className="my-8">
            <span className="text-lg text-[#A1A1A1] flex items-center">
              <FaCalendar className="mr-2" />
              Estimated Finish Date:
            </span>
            <span className="text-2xl font-semibold">
              {moment?.(data?.data?.worksite_timelines?.finish_date)?.format(
                "DD-MM-YYYY"
              )}
            </span>
          </p>
        </>
      }
    </div>
  );
};

export default LineChartInfo;
