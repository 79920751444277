import React, { useEffect, useState } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import Sidebar from "../shared/Sidebar/Sidebar";
import TopBar from "../shared/TopBar/TopBar";
import { LoggedInUser } from "../auth/appAuth";

const AdminLayout = () => {
  let path = useLocation();

  const [user, setUser] = useState(LoggedInUser() ? true : false);

  useEffect(() => {
    setUser(LoggedInUser() ? true : false);
  }, [path?.pathname]);

  return (
    <div>
      <TopBar />
      <div className="lg:flex">
        <div className="lg:w-1/6 ">
          <Sidebar />
        </div>
        <div className="lg:w-5/6 content-holder">
          {user ? <Outlet /> : <Navigate to="/" replace={true} />}
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
