import React, { useState, useRef, useEffect } from "react";
import { Button, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useNavigate, useParams } from "react-router-dom";
import {
  CreateEditTerminationEmail,
  GetTermimationEmailById,
} from "../../../helper/setup/induction/emailTermination";
import { ButtonSpinner } from "../../../components/spinners";
import { TextShimmer } from "../../../components/shimmer/shimmer";

const CreateTerminationEmail = () => {
  let navigate = useNavigate();
  let { id } = useParams();

  let subject = useRef();
  let send_to_copy = useRef();
  let body = useRef();

  const initialvalue = {
    id: id ? id : "",
    isSave: id ? 0 : 1,
    subject: "",
    send_to_copy: "",
    body: "",

    errors: {
      subject: "",
      send_to_copy: "",
      body: "",
    },
    isLoading: false,
    isShimmer: true,
  };

  const [email, setEmail] = useState(initialvalue);

  const handleEmail = {
    getEmailData: (e) => {
      console.log(e);
      setEmail({
        ...email,
        [e?.target?.name]: e?.target?.value,
      });
    },
    submitEmail: async () => {
      if (email?.subject == "") {
        setEmail({
          ...email,
          errors: {
            subject: "Enter subject",
            send_to_copy: "",
            body: "",
          },
        });
        subject.current.focus();
      } else if (email?.send_to_copy == "") {
        setEmail({
          ...email,
          errors: {
            subject: "",
            send_to_copy: "send_to_copy",
            body: "",
          },
        });
        send_to_copy.current.focus();
      } else if (email?.body == "") {
        setEmail({
          ...email,
          errors: {
            subject: "",
            send_to_copy: "",
            body: "Enter body",
          },
        });
        body.current.focus();
      } else {
        setEmail({
          ...email,
          errors: {
            subject: "",
            send_to_copy: "",
            body: "",
          },
          isLoading: true,
        });
        let data = await CreateEditTerminationEmail(email);
        console.log(data);
        if (data?.status) {
          console.log(email);
          navigate("/admin/settings/induction/emails/termination");
          setEmail({
            ...email,
            isLoading: false,
          });
        } else {
          setEmail({
            ...email,
            isLoading: false,
          });
        }
      }
    },
  };

  useEffect(() => {
    (async () => {
      if (id) {
        let data = await GetTermimationEmailById(email);
        console.log(data?.data?.data);
        if (data?.status) {
          setEmail({
            ...email,
            ...data?.data?.data,
            isShimmer: false,
          });
          setTimeout(() => {
            subject.current.focus();
          }, 100);
        }
      } else {
        setEmail({
          ...email,
          isShimmer: false,
        });
        setTimeout(() => {
          subject.current.focus();
        }, 100);
      }
    })();
  }, []);

  return (
    <>
      {email?.isShimmer ? (
        <div className="mt-5 lg:w-1/2 w-full mx-auto bg-white p-5 shadow-sm">
          <TextShimmer data={{ gap: 15, line: 30 }} />
        </div>
      ) : (
        <>
          <form className="mt-5">
            <div className="lg:w-1/2 w-full mx-auto bg-white p-5 shadow-sm">
              <p className="fs-18 font-bold">
                {email?.id ? "Editing" : "Creating"} Termination Email
              </p>

              <div className="mt-5">
                <label className="block font-normal">Subject</label>
                <Input
                  className={
                    email?.errors?.subject
                      ? "w-full mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                      : "w-full mt-4"
                  }
                  ref={subject}
                  name="subject"
                  value={email?.subject}
                  onChange={handleEmail?.getEmailData}
                />
                {email?.errors?.subject && (
                  <p className="pt-2 text-red-600 text-sm">
                    {email?.errors?.subject}
                  </p>
                )}
              </div>
              <div className="mt-5">
                <label className="block font-normal">Send To Copy</label>
                <TextArea
                  ref={send_to_copy}
                  className={
                    email?.errors?.send_to_copy
                      ? "w-full mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                      : "w-full mt-4"
                  }
                  name="send_to_copy"
                  value={email?.send_to_copy}
                  onChange={handleEmail?.getEmailData}
                />
                {email?.errors?.send_to_copy && (
                  <p className="pt-2 text-red-600 text-sm">
                    {email?.errors?.send_to_copy}
                  </p>
                )}
              </div>
              <div className="mt-5">
                <label className="block font-normal">Body</label>
                <TextArea
                  rows={10}
                  ref={body}
                  className={
                    email?.errors?.body
                      ? "w-full mt-4 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                      : "w-full mt-4"
                  }
                  name="body"
                  value={email?.body}
                  onChange={handleEmail?.getEmailData}
                />
                {email?.errors?.body && (
                  <p className="pt-2 text-red-600 text-sm">
                    {email?.errors?.body}
                  </p>
                )}
                <p className="text-sm mt-2">
                  Note : A copy of this email will be sent to a worker upon
                  termination, from the email of the Site manager performing the
                  termination.
                </p>
              </div>
            </div>

            <div className="lg:w-1/2 mt-5 w-full mx-auto bg-transparent py-5">
              <div className="text-right">
                <Button
                  className="btn-blue text-white h-[40px] ml-auto flex justify-center items-center w-1/4 bg-blue-disable"
                  onClick={handleEmail?.submitEmail}
                  disabled={email?.isLoading ? true : false}
                >
                  {email?.isLoading ? (
                    <ButtonSpinner />
                  ) : email?.id ? (
                    "Update Changes"
                  ) : (
                    "Save Changes"
                  )}
                </Button>
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default CreateTerminationEmail;
