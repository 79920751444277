import React, { useState } from 'react';
import { Logout } from '../helper/user/auth';
import { Link } from "react-router-dom";
import { Dropdown } from "antd";
import { Fileurl } from '../config/url';

const LogOutDetails = ({ user }) => {
    const [load, setLoad] = useState(false);
    const handleTopbar = {
        logout: () => {
            Logout();
        },
    };
    const menu = (
        <div className="bg-white p-4 max-w-full w-[300px] rounded-md shadow-md">
            <div className="text-center">
                <img src={user?.users_profile_details?.length > 0 ? Fileurl + user?.users_profile_details[0].data:''} className="mx-auto mb-4 h-[150px]" alt="" />
            </div>

            {!load && (
                <div className="unload text-center">
                    <h2 className="text-xl font-bold">
                        {user?.name ? user?.name : "N/A"}
                    </h2>
                    <p className="text-black mb-4">
                        {user?.employee_id ? user?.employee_id : "N/A"}
                    </p>

                    <p className="text-gray-400 mb-4">
                        {user?.email ? user?.email : "N/A"}
                    </p>

                    <div className="flex justify-center items-center mt-5">
                        <Link
                            className="font-bold"
                            onClick={() => {
                                setLoad(true);
                            }}
                        >
                            Details
                        </Link>
                        <span className="inline-block mx-4">|</span>
                        <Link to="/site-manager/login" onClick={handleTopbar?.logout}>
                            Sign Out
                        </Link>
                    </div>
                </div>
            )}

            {load && (
                <div className="loaded">
                    <h2 className="text-xl font-bold">
                        {user?.name ? user?.name : "N/A"}
                    </h2>
                    <p className="text-gray-400 mb-4">
                        {" "}
                        {user?.email ? user?.email : "N/A"}
                    </p>

                    <p className="text-gray-400">Card ID</p>
                    <p className="text-black mb-4">
                        {user?.employee_id ? user?.employee_id : "N/A"}
                    </p>

                    <p className="text-gray-400">Employed As</p>
                    <p className="text-black mb-4">{user?.role}</p>

                    <p className="text-gray-400">Phone Number</p>
                    <p className="text-black mb-4">{user?.mobile ? user?.mobile : '-'}</p>

                    <p className="text-gray-400">Address</p>
                    <p className="text-black mb-4">
                        {user?.address ? user?.address : '-'}
                    </p>

                    <div className="flex justify-center items-center mt-5">
                        <Link to="/site-manager/login" onClick={handleTopbar?.logout}>
                            Sign Out
                        </Link>
                    </div>
                </div>
            )}
        </div>
    );
    return (
        <Dropdown
            overlay={menu}
            trigger={["click"]}
            placement="bottomRight"
        >
            <div className="rounded-full h-[35px] w-[35px] flex ml-5 border cursor-pointer">
                <img
                    src={user?.users_profile_details?.length > 0 ? Fileurl + user?.users_profile_details[0].data:''}
                    className="rounded-full"
                    alt=""
                    onClick={() => {
                        setLoad(false);
                    }}
                />
            </div>
        </Dropdown>
    )
}
export default LogOutDetails