import React, { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import SiteManagerSidebar from "../components/siteManagerSidebar";
import { Authuser } from "../auth/appAuth";

const SiteManagerDashboard = () => {
  const initialvalue = {
    id: "",
    name: "",
    email: "",
    employee_id: "",
    mobile: "",
    role: "",
    verified: "",
    last_login: "",
    status: "",
  };
  const [user, setUser] = useState(initialvalue);
  const [userData, setUserData] = useState(localStorage.getItem('cree8_auth'))
  const [screenSize, setScreenSize] = React.useState(window.innerWidth);
  useEffect(() => {
    let data = Authuser();
    setUser({
      ...data,
    });
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
  }, []);

  if (!userData) {
    return <Navigate to="/site-manager/login" replace />;
  }
  return (
    <div className="flex justify-between items-start md:flex-row flex-col bg-white">
      {screenSize < 768 ?<div className="w-[160px] md:min-h-screen h-0">
        <SiteManagerSidebar />
      </div>:
      <div className="w-[160px] p-[15px] md:min-h-screen h-0">
      <SiteManagerSidebar />
    </div>
      }
      {screenSize < 768 ?
        <div className="w-full">
          <Outlet />
        </div>
        :
        <div className="dashboard-contents sitemanager-dashboard w-full p-[15px] h-screen overflow-y-scroll">
          <Outlet />
        </div>}
    </div>
  );
};

export default SiteManagerDashboard;
