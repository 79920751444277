import { Button } from "antd";
import React, { useState } from "react";
import { FaArrowLeft, FaPlus } from "react-icons/fa";
import HourlyRateTaskTable from "../../../shared/DataTable/HourlyRateTaskTable";
import { TfiAngleDown, TfiAngleUp } from "react-icons/tfi";
import ProductivityOrdersTable from "../../../shared/DataTable/ProductivityOrders";
import AvailableRateTaskModal from "../../../shared/Modal/AvailableRateTaskModal";
import DetailsTop from "../../../pages/Admin/Worksite/WorksiteDetails/DetailsTop";
import MultySelect from "../../multySelect/multyselect";
import { ButtonSpinner } from "../../spinners";
import AssignQA from "../../../pages/Admin/QA/AssignQA";

const HourlyRateTaskEstimations = ({
  multySelect,
  setMultySelect,
  data,
  setData,
  handle,
}) => {
  console.log(data);
  const [visible1, setVisible1] = useState(true);
  const [visible2, setVisible2] = useState(true);

  const [visibleAvailable, setVisibleAvailable] = useState(false);

  const availableToggler = () => {
    setVisibleAvailable(!visibleAvailable);
  };
  const hrteToggler = () => {
    setVisible1(!visible1);
  };

  const productivityToggler = () => {
    setVisible2(!visible2);
  };

  return (
    <>
      {visibleAvailable && (
        <AvailableRateTaskModal setVisibleAvailable={setVisibleAvailable} />
      )}

      {multySelect?.isMultySelect && (
        <MultySelect
          data={multySelect}
          setData={setMultySelect}
          search={handle?.getHourlyRatetask}
          submit={handle?.addHourlyRateTask}
        />
      )}

      <div className="bg-white ">
        <div
          className="flex justify-between items-center border-b p-4 mb-4"
          /* onClick={hrteToggler} */
        >
          <div
            className="tracking-tight cursor-pointer"
            onClick={handle?.getAreasPage}
          >
            <div className="flex">
              <div>
                <div>
                  <div className="text-xl pt-2 pr-2">
                    <FaArrowLeft />
                  </div>
                </div>
              </div>
              <div className="font-medium text-2xl">
                Hourly Rate Task Estimations
              </div>
            </div>
          </div>
          <div className="flex justify-end items-center">
            <Button
              // onClick={availableToggler}
              onClick={handle?.openMultyModal}
              className="flex justify-center items-center py-3 border border-[#111111] h-[42px]"
            >
              Add New{" "}
              <div className="bg-orange-500 p-2 rounded-md text-white ml-3">
                <FaPlus />
              </div>
            </Button>

            <button className="ml-4" onClick={hrteToggler}>
              {visible1 ? (
                <TfiAngleUp className="text-3xl" />
              ) : (
                <TfiAngleDown className="text-3xl" />
              )}
            </button>
          </div>
        </div>

        <div className="p-4">
          {visible1 && (
            <>
              {data?.hourly_rate_tasks_estimations?.length <= 0 ? (
                <>
                  <div className="h-full flex justify-center items-center flex-col bg-white">
                    <div
                      className="py-8 cursor-pointer"
                      onClick={handle?.openMultyModal}
                    >
                      <span>No Hourly rate task exists for the area.</span>
                      <span className="text-[#0F4C7D]">Add new</span>
                    </div>
                  </div>
                </>
              ) : (
                <HourlyRateTaskTable
                  data={data}
                  setData={setData}
                  handle={handle}
                />
              )}
            </>
          )}
        </div>
      </div>

      <div className="bg-white mt-6">
        <div className="flex justify-between items-center border-b p-4 mb-4">
          <h3 className="text-2xl font-medium tracking-tight">
            Productivity Orders
          </h3>
          <div className="flex justify-end items-center">
            <AssignQA />

            {window.location.pathname.includes("site-manager")
              ? " "
              : data?.productivity_orders?.length > 0 && (
                  <Button
                    className="flex justify-center mr-4 items-center py-3 border border-[#111111] h-[42px]"
                    onClick={handle?.getMerge}
                  >
                    Merge
                  </Button>
                )}
            {data?.isAddNewLoading ? (
              <Button className="flex justify-center items-center py-3 border border-[#111111] h-[42px]">
                <div className="px-5">
                  <div className="px-2">
                    <ButtonSpinner />
                  </div>
                </div>
              </Button>
            ) : (
              <Button
                onClick={handle?.addProductiveOrder}
                className="flex justify-center items-center py-3 border border-[#111111] h-[42px]"
              >
                Add New
                <div className="bg-orange-500 p-2 rounded-md text-white ml-3">
                  <FaPlus />
                </div>
              </Button>
            )}

            <button className="ml-4" onClick={productivityToggler}>
              {visible2 ? (
                <TfiAngleUp className="text-3xl" />
              ) : (
                <TfiAngleDown className="text-3xl" />
              )}
            </button>
          </div>
        </div>

        <div className="p-4">
          {visible2 && (
            <>
              {data?.productivity_orders?.length <= 0 ? (
                <>
                  <div className="h-full flex justify-center items-center flex-col bg-white">
                    <div
                      className="py-8 cursor-pointer"
                      onClick={handle?.addProductiveOrder}
                    >
                      <span>No Productivity orders exists for the area.</span>
                      <span className="text-[#0F4C7D]">Add now</span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <ProductivityOrdersTable
                    data={data}
                    setData={setData}
                    handle={handle}
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default HourlyRateTaskEstimations;
