import React from "react";
import { Route, Routes } from "react-router-dom";
import InductionEmail from "./InductionEmail";
import TerminationEmail from "./TerminationEmail";

const EmailRoutes = () => {
  return (
    <Routes>
      <Route path="induction" element={<InductionEmail />} />
      <Route path="termination" element={<TerminationEmail />} />
    </Routes>
  );
};

export default EmailRoutes;
