import React from "react";
import AgreementForm from "./AgreementForm";

const CreateAgreement = () => {
  return (
    <div className="px-6 pt-6 pb-10">
      <AgreementForm />
    </div>
  );
};

export default CreateAgreement;
