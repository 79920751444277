import React from "react";
import { TableDefaultSvg } from "../../assets/svg/tableDefaul";
import { TableDefaultPage } from "../../components/default/defaultPage";

const CodeTable = () => {
  let deafaulPage = {
    disclaimer: "No Data Found",
    description: "There are no data available",
    buttonText: "",
    svg: <TableDefaultSvg />,
    redirect: "",
  };

  return (
    <>
      <TableDefaultPage data={deafaulPage} />
    </>
    /*  <table className="w-full">
      <thead className="bg-[#E8F3FF] text-center">
        <tr className="text-left">
          <th className=" px-4 py-3 ">
            <div className="flex items-center justify-start">Code</div>
          </th>
          <th className=" px-4 py-3 ">
            <div className="flex items-center justify-center">Task</div>
          </th>
          <th className=" px-4 py-3 ">
            <div className="flex items-center justify-center">Hours</div>
          </th>
        </tr>
      </thead>
      <tbody className="bg-white">
        {data?.map((item, index) => (
          <tr key={index} className="border-b border-[#F0F0F0]">
            <td className="px-4 py-3">
              <div className="flex items-center justify-start">{item.code}</div>
            </td>
            <td className="px-4 py-3">
              <div className="flex items-center justify-center">
                {item.task}
              </div>
            </td>
            <td className="px-4 py-3">
              <div className="flex items-center justify-center">
                {item.hours}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table> */
  );
};

export default CodeTable;
