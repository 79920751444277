import React, { useEffect } from "react";
import { Success } from "../../assets/svg";

const ThankyouPage = () => {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // Close the current window after 10 seconds
      window.close();
    }, 10000);

    return () => clearTimeout(timeoutId); // Cleanup the timeout when the component unmounts
  }, []); // Empty dependency array ensures the effect runs only once after the initial render

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        fontSize: "17px",
        fontWeight: "bold",
      }}
    >
      <div
        style={{
          width: "400px",

          height: "500px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Success />
        <div
          style={{ fontWeight: "bolder", fontSize: "27px", marginTop: "30px" }}
        >
          Thank You
        </div>
        <div style={{ fontSize: "13px", marginTop: "10px" }}>
          Your application has been submitted successfully{" "}
        </div>
      </div>
    </div>
  );
};

export default ThankyouPage;
