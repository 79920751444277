import { Button } from "antd";
import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Navigate, useNavigate, useParams } from "react-router-dom";

const MergeHistory = () => {
  const { id } = useParams();
  const { area } = useParams();
  let navigate = useNavigate();

  const handleMergeHistory = {
    getMergeCreate: () => {
      navigate(`/admin/worksite/details/areas/cards/merge/${id}/${area}`);
    },
    getCards: () => {
      navigate(`/admin/worksite/details/areas/cards/${id}/${area}`);
    },
  };

  return (
    <>
      <div>
        <div className="bg-[#E8E8E8] py-3 flex px-3">
          <Button
            className="justify-center items-center px-4 mr-3 h-[42px]  w-42  text-cyan-500 w-42 border border-cyan-500 "
            onClick={handleMergeHistory?.getMergeCreate}
          >
            Merge
          </Button>
          <Button className="justify-center items-center px-4 mr-3 h-[42px] btn-blue btn">
            Merging History
          </Button>
        </div>

        <div>
          <div className="py-5" onClick={handleMergeHistory?.getCards}>
            <div className="flex">
              <div>
                <div className="pt-1 pl-4 pr-3">
                  <FaArrowLeft />
                </div>
              </div>
              <div>Merge History</div>
            </div>
          </div>

          <div className="border border-gray-200"></div>
        </div>
      </div>
    </>
  );
};

export default MergeHistory;
