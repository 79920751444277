import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CheckInduction } from "../../helper/induction/induction";
import InductionHeader from "./induction-header";
import InductionTab from "./induction-tab";

const Induction = () => {
  let disclaimer_id = new URLSearchParams(window.location.search).get(
    "disclaimer_id"
  );
  let worker_id = new URLSearchParams(window.location.search).get("worker_id");

  const initialvlaues = {
    isShimmer: true,
  };

  const [induction, setInduction] = useState(initialvlaues);
  const [userName, setUserName] = useState("");
  console.log("the induction usename is", userName);
  let navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const params = {
        disclaimer_id: disclaimer_id ? disclaimer_id : "12345",
        worker_id: worker_id,
      };
      let data = await CheckInduction(params);
      if (data?.status) {
        setUserName(data?.data?.data?.workerData?.name);
        setInduction({
          ...induction,
          isShimmer: false,
        });
      }
      /* else {
        navigate("/unauthorized");
      } */
    })();
  }, []);

  return (
    <div>
      <InductionHeader />
      <InductionTab userName={userName} />
    </div>
  );
};

export default Induction;
