import React, { Fragment, useEffect } from "react";
import { FaAngleDown, FaAngleUp, FaSearch } from "react-icons/fa";
import { useState } from "react";
import WorksiteProductivityOrders from "../../../../../shared/DataTable/WorksiteProductivityOrders";
import { Button, Checkbox, Dropdown } from "antd";
import { ExportCsv } from "../../../../../helper/worksite/worksiteDetails.js/productivityOrder";
import { useParams } from "react-router-dom";
import { GetAreas } from "../../../../../helper/worksite/worksiteDetails.js/areas";
import { TextShimmer } from "../../../../../components/shimmer/shimmer";
import { TableDefaultPage } from "../../../../../components/default/defaultPage";
import AreasDefaultSvg from "../../../../../assets/svg/areasDefault";
import { NoTableOption } from "../../../../../components/noOption/noOption";
import { NoSearchTableSvg } from "../../../../../assets/svg/tableDefaul";
import InfiniteScroll from "react-infinite-scroll-component";
import { ButtonSpinner } from "../../../../../components/spinners";
import { toast } from "react-toastify";
import ExportCSV from "../../ExportCSV";
import useWindowDimensions from "../../../../../utils/routes/useWindowDimensions";

const ProductivityOrders = ({ isTab, isarea, area }) => {
  const { width } = useWindowDimensions();
  const tabContent = [
    {
      label: "Total",
      key: "all",
    },
    {
      label: "Approved",
      key: "1",
    },
    {
      label: "Active",
      key: "0",
    },
    {
      label: "Remaining",
      key: "remainig",
    },
    {
      label: "Lost",
      key: "lost",
    },
  ];
  const tabContentTab = [
    {
      label: "Total",
      key: "all",
    },
    {
      label: "Approved",
      key: "1",
    },
    {
      label: "Active",
      key: "0",
    },
    {
      label: "Remaining",
      key: "remainig",
    },
    {
      label: "Lost",
      key: "lost",
    },
  ];
  let { id } = useParams();

  const initialValues = {
    data: [],
    areas: [],
    selectedArea: [],
    id: [],
    poStatus: "all",
    extra: {},
    search: "",
    isShimmer: true,
    toggleIds: [],
    toggleOpen: false,
  };

  const [activeTab, setActiveTab] = useState("all");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [open, setOpen] = useState([0, 1, 2, 3, 4]);
  const [productivityOrder, setProductivityOrder] = useState(initialValues);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [isSelectAll, setSelectAll] = useState(false);
  const [isAnimatePulse, setIsAnimatePulse] = useState(false);

  const handleProductivityOrder = {
    export: async () => {
      const params = {
        worksite_id: id,
        type: "PO",
        poStatus: "all",
      };

      let data = await ExportCsv(params);
      if (data?.status) {
        toast(data?.message);
      } else {
        toast(data?.message);
      }
    },
    search: async (e) => {
      const params = {
        worksite_id: id,
        search: e?.target?.value,
        poStatus: productivityOrder?.poStatus,
      };

      let data = await handleProductivityOrder?.get(params);
    },
    getAreas: async (getArea) => {
      const params = {
        worksite_id: id,
        search: getArea?.search,
      };
      let data = await GetAreas(params);
      if (data?.status == true) {
        setProductivityOrder({
          ...productivityOrder,
          areas: data?.data?.data,
        });
      }
    },
    searchAreas: async (e) => {
      e?.preventDefault();
      const params = {
        worksite_id: id,
        search: e?.target?.value,
      };

      let data = await handleProductivityOrder?.getAreas(params);
    },
    selectAll: async (e) => {
      if (e?.target?.checked) {
        let allIds = [];
        productivityOrder?.areas?.map((area, index) => {
          allIds?.push(area?._id);
        });
        setSelectedAreas(allIds);
        setSelectAll(true);

        const params = {
          worksite_id: id,
          type: "PO",
          poStatus: productivityOrder?.poStatus,
          cursor: productivityOrder?.cursor,
          search: productivityOrder?.search,
          id: allIds,
        };

        let data = await handleProductivityOrder?.get(params);
      } else {
        let allIds = [];
        setSelectedAreas(allIds);
        setSelectAll(false);
      }
    },
    selectArea: async (e, id) => {
      if (e?.target?.checked) {
        let allIds = [...selectedAreas];
        allIds?.push(id);
        setSelectedAreas(allIds);

        const params = {
          worksite_id: id,
          type: "PO",
          poStatus: productivityOrder?.poStatus,
          cursor: productivityOrder?.cursor,
          search: productivityOrder?.search,
          id: allIds,
        };

        let data = await handleProductivityOrder?.get(params);
      } else {
        let allIds = [...selectedAreas];
        allIds?.splice(allIds?.indexOf(id), 1);
        setSelectedAreas(allIds);

        const params = {
          worksite_id: id,
          type: "PO",
          poStatus: productivityOrder?.poStatus,
          cursor: productivityOrder?.cursor,
          search: productivityOrder?.search,
          id: allIds,
        };

        let data = await handleProductivityOrder?.get(params);
      }
    },
    fetch: async () => {
      const params = {
        ...productivityOrder,
        worksite_id: id,
        type: "PO",
        cursor: productivityOrder?.extra?.cursor,
      };

      if (
        productivityOrder?.data?.length != productivityOrder?.extra?.totalItems
      ) {
        let data = await GetAreas(params);
        if (data?.status) {
          let allPo = [...productivityOrder?.data];
          data?.data?.data?.map((po, index) => {
            allPo?.push({ ...po });
            setProductivityOrder({
              ...productivityOrder,
              data: allPo,
              extra: { ...data?.extra },
              isShimmer: false,
            });
          });
        }
      }
    },
    get: async (poData) => {
      setIsAnimatePulse(true);

      const params = {
        worksite_id: id,
        type: "PO",
        poStatus: poData?.poStatus,
        cursor: poData?.cursor,
        skip: poData?.skip,
        search: poData?.search,
        area_id: poData?.area_id,
        status: poData?.status,
        id: poData?.id?.toString(),
      };
      let data = await GetAreas(params);
      if (data?.status) {
        setProductivityOrder({
          ...productivityOrder,
          data: [...data?.data?.data],
          extra: { ...data?.extra },
          poStatus: poData?.poStatus,
          search: poData?.search,
          id: poData?.id,
          isShimmer: false,
        });
        setIsAnimatePulse(false);
      } else {
        setIsAnimatePulse(false);
      }
    },
    toggleUpDown: () => {
      if (productivityOrder?.toggleOpen) {
        let allIds = [];
        setProductivityOrder({
          ...productivityOrder,
          toggleIds: allIds,
          toggleOpen: false,
        });
      } else {
        let allIds = [];
        let poIds = [...productivityOrder?.data];
        productivityOrder?.data?.map((po, index) => {
          allIds?.push(po?.id);
        });

        setProductivityOrder({
          ...productivityOrder,
          toggleIds: allIds,
          toggleOpen: true,
        });
      }
    },
  };

  const onTabClick = async (item) => {
    setActiveTab(item.key);

    const params = {
      worksite_id: id,
      type: "productivity order",
      status: 0,
      poStatus: item.key,
    };

    let data = await handleProductivityOrder?.get(params);
  };

  function toggleTable(id) {
    if (id < 0) {
      if (open.length) {
        setOpen([]);
      } else {
        setOpen(Array.from(Array(5).keys()));
      }
    } else {
      setOpen((prev) => {
        let state = [...prev];

        if (prev.includes(id)) {
          state.splice(state.indexOf(id), 1);
        } else {
          state.push(id);
        }
        return state;
      });
    }
  }

  function toggleDropdown() {
    setDropdownOpen((prev) => !prev);
  }

  function AreaCheck() {
    return (
      <div
        className="min-w-[25vw] max-h-[35vh] bg-white p-2 rounded-lg drop-shadow-lg shadow-lg"
        onBlur={toggleDropdown}
        animation={false}
      >
        <span className="flex justify-between p-2">
          <p>Area Name</p>
          <p>
            Select All{" "}
            <Checkbox
              checked={isSelectAll}
              onChange={handleProductivityOrder?.selectAll}
            />
          </p>
        </span>
        <hr className="border-[#F5F5F5] border-b-4" />
        <div className="max-h-[20vh] overflow-y-scroll">
          {productivityOrder?.areas?.map((area, index) => (
            <div
              key={area?._id}
              className="flex justify-between p-2 border-b border-[#F5F5F5]"
            >
              <div>
                <label htmlFor={area?._id} key={area?._id}>
                  {area?.abbreviation}&nbsp;{area?.name}
                </label>
              </div>
              <div>
                <Checkbox
                  id={area?._id}
                  key={area?._id}
                  checked={selectedAreas.includes(area?._id)}
                  onChange={(e) =>
                    handleProductivityOrder?.selectArea(e, area?._id)
                  }
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  let deafaulPage = {
    disclaimer: "No areas Found",
    description: "Create Your First area",
    buttonText: "Create New",
    svg: <AreasDefaultSvg />,
    redirect: `/admin/worksite/details/areas-create/${id}`,
  };

  let noSearchOption = {
    svg: <NoSearchTableSvg />,
    disclaimer: "There were no search results found",
    description: "Sorry, No Item Matches Your Search Term.",
  };

  useEffect(() => {
    (async () => {
      const params = {
        worksite_id: id,
        type: "productivity order",
        status: 0,
        poStatus: "all",
        id: area,
      };
      let data = await handleProductivityOrder?.get(params);
    })();
  }, []);

  return (
    <div className="px-4">
      <Fragment>
        {productivityOrder?.isShimmer ? (
          <TextShimmer data={{ gap: 15, line: 15 }} />
        ) : (
          <Fragment>
            {productivityOrder?.data?.length <= 0 &&
            !productivityOrder?.search ? (
              <Fragment>
                <div className="py-8 h-[70vh]">
                  <TableDefaultPage data={deafaulPage} />
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div
                  className={
                    isAnimatePulse
                      ? "flex flex-col gap-4 animate-pulse"
                      : "flex flex-col gap-4"
                  }
                >
                  {!isarea && (
                    <div className="mt-5 flex justify-between bg-[#e6e6e6] p-2 overflow-x-auto ">
                      <div>
                        <nav className="flex gap-2 font-lexend w-100 ">
                          {isTab
                            ? tabContentTab.map((item) => (
                                <button
                                  className={`p-2 px-4 font-sm rounded-md duration-250 min-w-[8vw] ${
                                    activeTab === item.key
                                      ? "bg-[#0F4C7D] text-white"
                                      : "text-[#0F4C7D] bg-[white] border-2 border-[#0F4C7D]"
                                  }`}
                                  onClick={() => onTabClick(item)}
                                >
                                  <p>{item?.label}</p>
                                </button>
                              ))
                            : tabContent.map((item) => (
                                <button
                                  className={`p-2 px-4 font-sm rounded-md duration-250 min-w-[8vw] ${
                                    activeTab === item.key
                                      ? "bg-[#0F4C7D] text-white"
                                      : "text-[#0F4C7D] bg-[white] border-2 border-[#0F4C7D]"
                                  }`}
                                  onClick={() => onTabClick(item)}
                                >
                                  <p>{item?.label}</p>
                                </button>
                              ))}
                        </nav>
                      </div>
                      {!isTab && (
                        <div>
                          <ExportCSV />
                        </div>
                      )}
                    </div>
                  )}
                  <div className="flex justify-between items-center py-3">
                    <form action="" className="md:w-1/3">
                      {/*     <div className="border border-[#000] p-1 rounded-sm w-full flex justify-start items-center gap-3">
                        <FaSearch className="scale-90 text-gray-400" />
                        <input
                          type="text"
                          placeholder="Search by Area or Productivity   "
                          className="w-full border-none outline-none text-sm"
                          onChange={handleProductivityOrder?.search}
                        />
                      </div> */}
                      {!isarea && (
                        <div
                          className={
                            width > 767
                              ? "border border-[#111111] flex items-center p-1 rounded-md "
                              : "border border-[#111111] flex items-center p-1 rounded-md w-90 h-8 mr-3 "
                          }
                        >
                          <FaSearch className="ml-2" />{" "}
                          <input
                            placeholder="Search by Area or Productivity Orders"
                            className={
                              width > 767
                                ? "h-[31px] bg-transparent text-sm w-full px-2"
                                : "h-[31px] bg-transparent fs-8 w-full px-2"
                            }
                            onChange={handleProductivityOrder?.search}
                          />
                        </div>
                      )}
                    </form>

                    {!isarea && (
                      <group className="flex gap-3 items-center">
                        <div className="border-[1px] rounded-md border-black text-center">
                          <Dropdown
                            trigger={["click"]}
                            placement="bottomRight"
                            dropdownRender={(menu) => (
                              <div className="bg-white">
                                <div className="p-2">
                                  <div className="border border-[#000] p-2 rounded-md w-full flex justify-start items-center gap-3">
                                    <FaSearch className="scale-90 text-gray-400" />
                                    <input
                                      type="text"
                                      placeholder="Search by Area"
                                      className="w-full border-none outline-none text-sm"
                                      onChange={
                                        handleProductivityOrder?.searchAreas
                                      }
                                    />
                                  </div>
                                </div>
                                <AreaCheck />
                              </div>
                            )}
                            className="bg-white rounded-lg hover:bg-white"
                          >
                            <div
                              className={
                                width > 767
                                  ? "px-12 py-2 cursor-pointer"
                                  : "py-2 cursor-pointer w-[70px] h-[30px] smallText"
                              }
                              onClick={handleProductivityOrder?.getAreas}
                            >
                              All area
                            </div>
                          </Dropdown>
                        </div>
                        <Button
                          className={
                            width > 767
                              ? "bg-[#FE650C] py-2"
                              : "bg-[#FE650C] py-2 px-2"
                          }
                          onClick={handleProductivityOrder?.toggleUpDown}
                        >
                          {productivityOrder?.toggleOpen ? (
                            <FaAngleUp className="scale-125 fill-white" />
                          ) : (
                            <FaAngleDown className="scale-125 fill-white" />
                          )}
                        </Button>
                      </group>
                    )}
                  </div>

                  {productivityOrder?.data?.length <= 0 &&
                  productivityOrder?.search ? (
                    <Fragment>
                      <NoTableOption data={noSearchOption} />
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div
                        id="scrollableDiv"
                        className="h-[400]"
                        style={{
                          overflowY: width <= 767 ? "scroll" : "visible",
                          maxHeight: width <= 767 ? "50vh" : "auto",
                        }}
                      >
                        <InfiniteScroll
                          className=""
                          dataLength={productivityOrder?.data?.length}
                          next={handleProductivityOrder?.fetch}
                          hasMore={true}
                          loader={
                            <div>
                              <ButtonSpinner />
                            </div>
                          }
                          height={400}
                          endMessage={<div></div>}
                          scrollableTarget="scrollableDiv"
                        >
                          {productivityOrder?.data?.map((po, index) => {
                            return (
                              <div className="" key={index}>
                                <WorksiteProductivityOrders
                                  id={index}
                                  selected={open}
                                  onToggle={() => toggleTable(index)}
                                  handle={handleProductivityOrder}
                                  data={po}
                                  allData={productivityOrder}
                                  setAllData={setProductivityOrder}
                                  isarea={isarea}
                                  isTab={isTab}
                                  // activeTab={
                                  //   tabContent.find(
                                  //     (item) => activeTab === item.key
                                  //   )?.label
                                  // }

                                  activeTab={
                                    tabContent.find((item) => {
                                      if (
                                        item.key === "remainig" ||
                                        item.key === "lost"
                                      ) {
                                        return item.key === activeTab;
                                      }
                                      return false;
                                    })?.label
                                  }
                                />
                              </div>
                            );
                          })}
                        </InfiniteScroll>
                      </div>
                    </Fragment>
                  )}
                </div>
              </Fragment>
            )}
          </Fragment>
        )}
      </Fragment>
    </div>
  );
};

export default ProductivityOrders;
