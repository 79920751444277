import { ApiAuthorizaion } from "../../../auth/appAuth";
import { UserService } from "../../../config/axiosUrl";

const HandleAdministrator = async (administrator) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const formData = new FormData();
    formData.append("id", administrator?.id);
    formData.append("profile_image", administrator?.profile_image);
    formData.append("name", administrator?.name);
    formData.append("email", administrator?.email);
    formData.append("password", administrator?.password);
    formData.append("cpassword", administrator?.confirmpassword);
    formData.append("isSave", administrator?.isSave);
    formData.append("employee_id", administrator?.employee_id);
    formData.append("role", "ADMINISTRATOR");

    let data = await UserService.post(
      "/api/v1/administrators/save-edit-administrator?dev=5",
      formData,
      authorization
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};

const GetAdministratorDetails = async () => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    let data = await UserService?.get("/api/v1/administrators/get-list", {
      headers: authorization?.headers,
    });
    return data?.data;
  } catch (error) {
    return error;
  }
};

const DeleteAdministratorDetails = async (admin) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    const params = {
      id: admin?.id,
    };

    let data = await UserService?.delete(
      `/api/v1/administrators/delete-admin`,
      {
        headers: authorization?.headers,
        params: {
          id: admin?.id,
        },
      }
    );
    return data?.data;
  } catch (error) {
    return error;
  }
};

export {
  HandleAdministrator,
  GetAdministratorDetails,
  DeleteAdministratorDetails,
};
