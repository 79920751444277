import { Button, Input, Select, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { FaQuestionCircle, FaTimes } from "react-icons/fa";
import { ButtonSpinner } from "../../components/spinners";
import {
  PayRollService,
  UserService,
  WorkService,
} from "../../config/axiosUrl";
import { SearchData } from "../../helper/global/global";
import {
  CreateEditWorkers,
  DeleteWorkers,
  GetWorkers,
} from "../../helper/personnel/workers";
import { GetEmployeeStatus } from "../../helper/setup/payroll/employeentstatus";
import { toast } from "react-toastify";
import { TextShimmer } from "../../components/shimmer/shimmer";
import { ImageUploder } from "../../components/FileDropzone/imageUpload";
import { Fileurl } from "../../config/url";
import { ImageCancelSvg } from "../../assets/svg/imagecancelsvg";
import { useParams } from "react-router-dom";

const AddNewWorkerModal = ({ setVisibleWorkerModal, data, setData }) => {
  const params = useParams();

  const closeModal = () => {
    setVisibleWorkerModal(false);
  };

  const initialvalues = {
    id: data?.selectedData?.id ? data?.selectedData?.id : "",
    employee_id: data?.selectedData?.employee_id
      ? data?.selectedData?.employee_id
      : "",
    worker_details_id: data?.selectedData?.worker_details?.[0]?.id
      ? data?.selectedData?.worker_details?.[0]?.id
      : "",
    employment_status_id: data?.selectedData?.id
      ? data?.selectedData?.worker_details?.[0]?.employment_status?.id
      : null,
    work_site_id: data?.selectedData?.id
      ? data?.selectedData?.worker_details?.[0]?.worksites?.id
      : null,
    worker_designation_id:
      data?.selectedData?.worker_details?.[0]?.worker_designation?.id || null,
    name: data?.selectedData?.name ? data?.selectedData?.name : "",
    lastname: data?.selectedData?.last_name
      ? data?.selectedData?.last_name
      : "",
    email: data?.selectedData?.email ? data?.selectedData?.email : "",
    role: "WORKER",
    is_send_shift_receipts: data?.selectedData?.id
      ? data?.selectedData?.worker_details?.[0]?.is_send_shift_receipts
      : 0,
    isSave: data?.selectedData?.id ? 0 : 1,
    errors: {
      employment_status_id:
        data?.selectedData?.worker_details?.[0]?.employment_status?.status == 2
          ? "Employment status has been deleted"
          : "",
      work_site_id:
        data?.selectedData?.worker_details?.[0]?.worksites?.status == 2
          ? "Worksite has been deleted"
          : "",
      name: "",
      lastname: "",
      employee_id: "",
      email: "",
    },
    employementStatusOptions: data?.selectedData?.worker_details?.[0]
      ?.employment_status?.id
      ? [
          {
            value:
              data?.selectedData?.worker_details?.[0]?.employment_status?.id,
            label:
              data?.selectedData?.worker_details?.[0]?.employment_status?.name,
          },
        ]
      : [],
    worksiteOptions: data?.selectedData?.worker_details?.[0]?.worksites?.id
      ? [
          {
            value: data?.selectedData?.worker_details?.[0]?.worksites?.id,
            label: data?.selectedData?.worker_details?.[0]?.worksites?.name,
          },
        ]
      : [],
    isLoading: false,
    isShimmer: false,
    designationOptions: data?.selectedData?.worker_details?.[0]
      ?.worker_designation?.id
      ? [
          {
            value:
              data?.selectedData?.worker_details?.[0]?.worker_designation?.id,
            label:
              data?.selectedData?.worker_details?.[0]?.worker_designation?.name,
          },
        ]
      : [],
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "0px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 130,
    height: 130,
    boxSizing: "border-box",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
    width: "30vw",
    height: "20vh",
    position: "relative",
    objectFit: "contain",
  };

  const img = {
    display: "block",
    width: "100%",
    borderRadius: "10px",
  };

  const cancelsvg = {
    position: "absolute",
    right: 4,
    top: 4,
  };

  let errorKeys = Reflect.ownKeys(initialvalues?.errors);

  let errors = useRef([]);
  errorKeys?.map((error, ndex) => (errors.current[error] = React.createRef()));

  const [workers, setWorkers] = useState(initialvalues);
  console.log("the workeess", workers);
  const [files, setFiles] = useState([]);

  const removeImgPreview = () => {
    let allData = { ...data };
    allData.selectedData.users_profile_details.data = "";

    setData({
      ...allData,
    });
  };

  const handleWorkers = {
    getTableData: async () => {
      let getData = await GetWorkers(params);
      if (getData?.status) {
        setData({
          ...data,
          data: [...getData?.data?.data],
          extra: { ...getData?.extra },
          selectedData: {},
        });
        return getData;
      }
    },

    getEmployementStatus: async (e) => {
      let loadingOptions = [{ value: "isLoading", label: <ButtonSpinner /> }];
      setWorkers({
        ...workers,
        employementStatusOptions: loadingOptions,
      });

      const params = {
        value: e,
        api: "/api/v1/setting/payroll/get-employment-status-data?dev=4",
        service: PayRollService,
      };

      let allEmployementStatus = [];
      let allEmployementStatusIds = [];
      let getData = await SearchData(params);

      if (getData?.status) {
        getData?.data?.data?.map((employeeStatus, index) => {
          allEmployementStatus?.push({
            value: employeeStatus?.id,
            label: employeeStatus?.name,
          });
          allEmployementStatusIds?.push(employeeStatus?.id);
        });

        if (data?.selectedData?.id) {
          if (
            !allEmployementStatusIds?.includes(
              data?.selectedData?.worker_details?.[0]?.employment_status?.id
            )
          ) {
            allEmployementStatus?.push({
              value:
                data?.selectedData?.worker_details?.[0]?.employment_status?.id,
              label:
                data?.selectedData?.worker_details?.[0]?.employment_status
                  ?.name,
            });
          }
        }

        setWorkers({
          ...workers,
          employementStatusOptions: allEmployementStatus,
        });
      } else {
        setWorkers({
          ...workers,
          employementStatusOptions: [],
        });
      }
    },

    getWorkerDesignation: async (e) => {
      let loadingOptions = [{ value: "isLoading", label: <ButtonSpinner /> }];
      setWorkers({
        ...workers,
        designationOptions: loadingOptions,
      });

      const params = {
        value: e,
        api: "/api/v1/personal/workers/worker-designation/get-worker-designation",
        service: UserService,
      };

      let allDesignationOptions = [];
      let allDesignationIds = [];
      let getData = await SearchData(params);

      if (getData?.status) {
        getData?.data?.data?.map((designation) => {
          allDesignationOptions.push({
            value: designation.id,
            label: designation.name,
          });
          allDesignationIds.push(designation.id);
        });

        if (data?.selectedData?.id) {
          if (
            !allDesignationIds.includes(
              data?.selectedData?.worker_details?.[0]?.worker_designation?.id
            )
          ) {
            allDesignationOptions.push({
              value:
                data?.selectedData?.worker_details?.[0]?.worker_designation?.id,
              label:
                data?.selectedData?.worker_details?.[0]?.worker_designation
                  ?.name,
            });
          }
        }

        setWorkers({
          ...workers,
          designationOptions: allDesignationOptions,
        });
      } else {
        setWorkers({
          ...workers,
          designationOptions: [],
        });
      }
    },

    getWorksite: async (e) => {
      let loadingOptions = [{ value: "isLoading", label: <ButtonSpinner /> }];
      setWorkers({
        ...workers,
        worksiteOptions: loadingOptions,
      });

      const params = {
        value: e,
        api: "/api/v1/worksite/get-worksite-data?dev=4",
        service: WorkService,
      };

      let allWorksiteOptions = [];
      let allWorksiteIds = [];
      let getData = await SearchData(params);

      if (getData?.status) {
        getData?.data?.data?.map((worksite, index) => {
          allWorksiteOptions?.push({
            value: worksite?.id,
            label: worksite?.name,
          });
          allWorksiteIds?.push(worksite?.id);
        });

        if (data?.selectedData?.id) {
          if (
            !allWorksiteIds?.includes(
              data?.selectedData?.worker_details?.[0]?.worksites?.id
            )
          ) {
            allWorksiteOptions?.push({
              value: data?.selectedData?.worker_details?.[0]?.worksites?.id,
              label: data?.selectedData?.worker_details?.[0]?.worksites?.name,
            });
          }
        }

        setWorkers({
          ...workers,
          worksiteOptions: allWorksiteOptions,
        });
      } else {
        setWorkers({
          ...workers,
          worksiteOptions: [],
        });
      }
    },
    get: (e) => {
      setWorkers({
        ...workers,
        [e?.target?.name]: e?.target?.value,
      });
    },
    check: (e) => {
      setWorkers({
        ...workers,
        [e?.target?.name]: Number(e?.target?.checked),
      });
    },
    select: (e, name) => {
      if (e != "isLoading") {
        setWorkers({
          ...workers,
          [name]: e,
        });
      }
    },

    submit: () => {
      let errorCount = 0;
      errorKeys?.map((value, index) => {
        if (errorCount == index) {
          if (!workers?.[value] && value !== "employee_id") {
            setWorkers({
              ...workers,
              errors: {
                ...initialvalues?.errors,
                [value]: `Enter ${value?.replace(/_|id/g, " ")} `,
              },
            });
            if (index !== 0 && index !== 1 && value !== "employee_id") {
              errors?.current?.[value]?.current?.focus();
            }
          } else {
            if (value === "email") {
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i;

              if (!emailRegex.test(workers?.email)) {
                setWorkers({
                  ...workers,
                  errors: {
                    ...initialvalues?.errors,
                    email: "Enter a valid email address with lowercase",
                  },
                });
                errors?.current["email"]?.current?.focus();
                return; // Stop the submission if the email is invalid
              }
            }

            errorCount++;
            setWorkers({
              ...workers,
              errors: {
                ...initialvalues?.errors,
              },
            });

            if (errorCount === errorKeys?.length) {
              handleWorkers?.create();
            }
          }
        }
      });
    },

    create: async () => {
      setWorkers({
        ...workers,
        isLoading: true,
      });
      console.log("yje create workers", workers);
      let getData = await CreateEditWorkers({
        ...workers,
        profile_image: files[0],
      });
      if (getData?.status) {
        let tableData = await handleWorkers?.getTableData();
        if (tableData?.status) {
          setWorkers(initialvalues);
          toast?.success(getData?.message);
          setVisibleWorkerModal(false);
        }
      } else {
        if (getData?.status_code == 501) {
          setWorkers({
            ...workers,
            errors: {
              ...initialvalues?.errors,
              email:
                getData?.message === "Email is Already used."
                  ? getData?.message
                  : "",
              employee_id:
                getData?.message === "Employee Id is Already taken."
                  ? getData?.message
                  : "",
              status: false,
            },
            isLoading: false,
          });
          errors?.current["email"]?.current?.focus();
        } else {
          setWorkers({
            ...workers,
            isLoading: false,
          });
          toast?.error(getData?.message);
        }
      }
    },
    delete: async () => {
      setWorkers({
        ...workers,
        isLoading: true,
      });

      let getData = await DeleteWorkers(workers);
      if (getData?.status) {
        let tableData = await handleWorkers?.getTableData();
        if (tableData?.status) {
          setWorkers(initialvalues);
          toast?.success(getData?.message);
          setVisibleWorkerModal(false);
        }
      } else {
        toast?.error(getData?.message);
        setWorkers({
          ...workers,
          isLoading: false,
        });
      }
    },
  };

  useEffect(() => {
    setTimeout(() => {
      errors?.current["employment_status_id"]?.current?.focus();
    }, 100);
  }, []);

  return (
    <div className="modal">
      <div
        className={
          data?.selectedData?.delete
            ? "modal-content"
            : "modal-content modal-scroll"
        }
      >
        <div className="text-right">
          <button className="close-modal" onClick={closeModal}>
            <FaTimes />
          </button>
        </div>

        {workers?.isShimmer && data?.selectedData?.id ? (
          <TextShimmer data={{ gap: 15, line: 20 }} />
        ) : (
          <>
            {data?.selectedData?.delete ? (
              <>
                <h2 className="text-xl font-bold">
                  Are you sure want to delete this worker?{" "}
                </h2>
                <div className="item mt-8">
                  <table>
                    <tbody>
                      <tr>
                        <td className="font-bold">Name :</td>
                        <td className="pl-5">
                          {data?.selectedData?.name}{" "}
                          {data?.selectedData?.last_name}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="item mt-8 text-center">
                  <Button
                    onClick={handleWorkers?.delete}
                    className="btn btn-blue w-1/5 bg-blue-disable"
                  >
                    {workers?.isLoading ? <ButtonSpinner /> : "Delete"}
                  </Button>
                </div>
              </>
            ) : (
              <div className="modal-body">
                <div className="text-xl font-bold ">
                  {workers?.id ? "Editing" : "Creating New"} Worker
                </div>

                <form className="mt-4">
                  {data?.selectedData?.id ? (
                    <>
                      <div>
                        {data?.selectedData?.users_profile_details?.data ? (
                          <div style={thumb}>
                            <div style={thumbInner}>
                              <img
                                src={
                                  Fileurl +
                                  data?.selectedData?.users_profile_details
                                    ?.data
                                }
                                style={img}
                              />
                              <div style={cancelsvg} onClick={removeImgPreview}>
                                <ImageCancelSvg />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <ImageUploder files={files} setFiles={setFiles} />
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="">
                        <ImageUploder files={files} setFiles={setFiles} />
                      </div>
                    </>
                  )}

                  <div className="item">
                    <label htmlFor="stat">Employement Status</label>
                    <Select
                      showSearch
                      placeholder="Select Employement Status"
                      optionFilterProp="children"
                      className="mt-3  w-full"
                      name="employment_status_id"
                      ref={errors?.current["employment_status_id"]}
                      status={
                        workers?.errors?.employment_status_id ? "error" : ""
                      }
                      filterOption={false}
                      onFocus={handleWorkers?.getEmployementStatus}
                      options={workers?.employementStatusOptions}
                      value={workers?.employment_status_id}
                      onChange={(e) =>
                        handleWorkers?.select(e, "employment_status_id")
                      }
                      onSearch={handleWorkers?.getEmployementStatus}
                    />
                    {workers?.errors?.employment_status_id && (
                      <p className="pt-2 text-red-600 text-sm">
                        {workers?.errors?.employment_status_id}
                      </p>
                    )}
                  </div>
                  <div className="item mt-3">
                    <label htmlFor="site">Worksite</label>
                    <Select
                      showSearch
                      placeholder="Select Worksite"
                      optionFilterProp="children"
                      className="mt-3 block w-full"
                      ref={errors?.current["work_site_id"]}
                      status={workers?.errors?.work_site_id ? "error" : ""}
                      filterOption={false}
                      onFocus={handleWorkers?.getWorksite}
                      options={workers?.worksiteOptions}
                      value={workers?.work_site_id}
                      onChange={(e) => handleWorkers?.select(e, "work_site_id")}
                      onSearch={(value) => handleWorkers?.getWorksite(value)}
                    />
                    {workers?.errors?.work_site_id && (
                      <p className="pt-2 text-red-600 text-sm">
                        {workers?.errors?.work_site_id}
                      </p>
                    )}
                  </div>

                  <div className="item mt-3">
                    <label htmlFor="employee_id">Employee Id</label>
                    <Input
                      type="text"
                      id="employee_id"
                      className={
                        workers?.errors?.employee_id
                          ? "mt-3 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                          : "mt-3"
                      }
                      ref={errors?.current["employee_id"]}
                      name="employee_id"
                      value={workers?.employee_id}
                      onChange={handleWorkers?.get}
                      autoComplete="off"
                    />
                    {workers?.errors?.employee_id && (
                      <p className="pt-2 text-red-600 text-sm">
                        {workers?.errors?.employee_id}
                      </p>
                    )}
                  </div>

                  <div className="flex justify-between gap-2">
                    {" "}
                    <div className="item mt-3 w-full">
                      <div className="flex justify-between items-center">
                        <label htmlFor="name">First Name</label>
                        <Tooltip title="Enter first name">
                          <FaQuestionCircle className="fs-10" />
                        </Tooltip>
                      </div>
                      <Input
                        type="text"
                        id="name"
                        className={
                          workers?.errors?.name
                            ? "mt-3 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                            : "mt-3"
                        }
                        ref={errors?.current["name"]}
                        name="name"
                        value={workers?.name}
                        onChange={handleWorkers?.get}
                        autoComplete="off"
                      />
                      {workers?.errors?.name && (
                        <p className="pt-2 text-red-600 text-sm">
                          {workers?.errors?.name}
                        </p>
                      )}
                    </div>
                    <div className="item mt-3 w-full">
                      <div className="flex justify-between items-center">
                        <label htmlFor="name">Last Name</label>
                        <Tooltip title="Enter Last name">
                          <FaQuestionCircle className="fs-10" />
                        </Tooltip>
                      </div>
                      <Input
                        type="text"
                        id="lastname"
                        className={
                          workers?.errors?.lastname
                            ? "mt-3 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                            : "mt-3"
                        }
                        ref={errors?.current["lastName"]}
                        name="lastname"
                        value={workers?.lastname}
                        onChange={handleWorkers?.get}
                        autoComplete="off"
                      />
                      {workers?.errors?.lastname && (
                        <p className="pt-2 text-red-600 text-sm">
                          {workers?.errors?.lastname}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="item mt-3">
                    <label>Email</label>
                    <Input
                      type="email"
                      id="email"
                      className={
                        workers?.errors?.email
                          ? "mt-3 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                          : "mt-3"
                      }
                      ref={errors?.current["email"]}
                      name="email"
                      value={workers?.email}
                      onChange={handleWorkers?.get}
                      autoComplete="off"
                      required
                    />
                    {workers?.errors?.email && (
                      <p className="pt-2 text-red-600 text-sm">
                        {workers?.errors?.email}
                      </p>
                    )}
                    <p className="fs-14 mt-1">
                      *Workers without an email address will not be inducted{" "}
                    </p>
                  </div>

                  <div className="item mt-3 flex flex-col">
                    <label className="mb-3" htmlFor="worker_designation_id">
                      Worker Designation
                    </label>
                    <Select
                      name="worker_designation_id"
                      value={workers.worker_designation_id}
                      onFocus={handleWorkers?.getWorkerDesignation}
                      options={workers.designationOptions}
                      onChange={(e) =>
                        handleWorkers.select(e, "worker_designation_id")
                      }
                    />
                    {workers.errors.worker_designation_id && (
                      <span className="error-text">
                        {workers.errors.worker_designation_id}
                      </span>
                    )}
                  </div>

                  <div className="item mt-3">
                    <label htmlFor="shift">Shift Receipts</label>
                    <div className="flex justify-start items-center mt-3">
                      <input
                        type="checkbox"
                        id="sh"
                        name="is_send_shift_receipts"
                        checked={Boolean(workers?.is_send_shift_receipts)}
                        onChange={handleWorkers?.check}
                      />
                      <label htmlFor="sh" className="ml-2">
                        Send Shift Receipts to Worker
                      </label>
                    </div>
                  </div>
                  <div className="item mt-3 text-center">
                    <Button
                      onClick={handleWorkers?.submit}
                      className="btn btn-blue w-1/5 bg-blue-disable"
                    >
                      {workers?.isLoading ? (
                        <ButtonSpinner />
                      ) : workers?.id ? (
                        "Update"
                      ) : (
                        "Create"
                      )}
                    </Button>
                  </div>
                </form>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AddNewWorkerModal;
