import { Button } from "antd";
import React from "react";

const DefaulGlobal = ({
  DefaultImage,
  shouldUseImg = false,
  pageHandler,
  header,
  buttonTitle = "Add",
  description,
  hideButton = false,
}) => {
  return (
    <div className="flex flex-col items-center justify-center text-center gap-5 w-100 min-h-[60vh] bg-white">
      {DefaultImage &&
        (shouldUseImg ? (
          <img src={DefaultImage} alt="default" />
        ) : (
          <DefaultImage className="" />
        ))}
      <span>
        <p className="font-semibold text-xl text-black mt-3">{header}</p>
        <p className="text-[#BFBFBF] text-sm">{description}</p>
      </span>
      {!hideButton && (
        <div className="my-3">
          <button
            onClick={pageHandler}
            className="bg-[#0F4C7D] text-white rounded-lg py-2 px-5"
          >
            {buttonTitle}
          </button>
        </div>
      )}
    </div>
  );
};

export default DefaulGlobal;
