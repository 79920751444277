import React from "react";
import NotesTable from "../../../shared/DataTable/NotesTable";
import { FaSearch } from "react-icons/fa";
import { DatePicker, Input } from "antd";
import { useState } from "react";

const Notes = () => {
  const [selectedDate, setSelectedDate] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const handleDateChange = (date, dateString) => {
    if (dateString) {
      setSelectedDate(dateString); // Store the date in the desired format directly
    } else {
      setSelectedDate("");
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <div className="p-6">
      <div className="flex bg-white p-4 justify-between items-center">
        <div className="border border-[#111111] flex items-center p-1 rounded-md w-full lg:w-1/4">
          <input
            placeholder="Search by Worksite"
            className="h-[31px] bg-transparent text-sm w-full px-2"
            value={searchInput}
            onChange={handleSearchInputChange}
          />
        </div>

        <DatePicker
          className="p-2 border-[#111111] text-black"
          onChange={handleDateChange}
          format="YYYY-MM-DD"
        />
      </div>

      <div className="pt-5 bg-white">
        <NotesTable selectedDate={selectedDate} searchInput={searchInput} />
      </div>
    </div>
  );
};

export default Notes;
