import React, { PureComponent, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

function CustomToolTip({ active, payload, label }) {
  return (
    <div className="bg-[#F2F2F2] rounded-sm p-4 drop-shadow-lg shadow-lg">
      {payload.map((chartItem) => (
        <div className="flex flex-col gap-1">
          <p className="font-lexend font-bold text-sm">
            {chartItem.payload.name}
          </p>
          <p className="font-lexend text-sm">
            Hours Spent: {chartItem.payload.hours}
          </p>
        </div>
      ))}
    </div>
  );
}

class CustomizedYAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={5}
          dx={-20}
          fontSize={14}
          textAnchor="middle"
          fill="#A1A1A1"
          fontFamily='"Lexend", sans-serif'
          transform="rotate(270)"
        >
          {payload.value}
        </text>
      </g>
    );
  }
}

class CustomizedXAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props;

    return (
      <g margin={10} transform={`translate(${x},${y})`}>
        <text
          x={-10}
          y={0}
          dy={5}
          dx={-20}
          fontSize={14}
          textAnchor="middle"
          fill="#A1A1A1"
          fontFamily='"Lexend", sans-serif'
          transform="rotate(270)"
        >
          {payload.value}
        </text>
      </g>
    );
  }
}

const BarChartWorkerTimeline = ({ data }) => {
  let allData = [];

  if (data?.data?.length > 0) {
    data?.data?.map((graphData, ind) => {
      allData?.push({
        name: graphData?.hourly_rate_task_name?.[0],
        hours: graphData?.hours,
      });
    });
  } else {
    allData?.push({ name: "0", hours: 0 });
  }

  return (
    <ResponsiveContainer className={"barC"} width="100%" height="99.5%">
      <BarChart
        width={500}
        height={300}
        data={allData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          height={160}
          textAnchor="end"
          claeToFit="true"
          verticalAnchor="start"
          interval={0}
          angle="-90"
          stroke="#A1A1A1"
        />
        <YAxis />
        <Tooltip fill="transparent" content={<CustomToolTip />} />
        <Bar
          dataKey="hours"
          name="Hours"
          barSize={20}
          stackId="hours"
          fill="#FFA500"
        />
      </BarChart>
    </ResponsiveContainer>
  );
  // }
};

export default BarChartWorkerTimeline;
