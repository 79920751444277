import { Button, Input } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { FaTimes } from "react-icons/fa";
import { ButtonSpinner } from "../../components/spinners";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { WorkService } from "../../config/axiosUrl";
import { GetAreas } from "../../helper/worksite/worksiteDetails.js/areas";

const CreateEditAreaModal = ({
  area,
  errors,
  data,
  setEditModalVisible,
  handleAreas,
}) => {
  let { id } = useParams();

  const [editedArea, setEditedArea] = useState({
    name: area?.name || "",
    abbreviation: area?.abbreviation || "",
  });

  const closeModal = () => {
    setEditModalVisible(false);
  };

  useEffect(() => {
    errors?.current?.["name"]?.current?.focus();
  }, []);

  const handle = {
    create: () => {
      console.log("Edited Area:", editedArea);
      const params = {
        abbreviation: editedArea?.abbreviation,
        name: editedArea?.name,
        isSave: 0,
        worksite_id: id,
        id: area?._id,
      };

      WorkService.post("/api/v1/personal/workers/create-edit-area", params)
        .then((res) => {
          handleAreas?.getAllAreas();
          toast?.success(res?.data?.message);
        })
        .catch((error) => {
          console.error("Error updating notes:", error);
        });

      closeModal();
    },
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedArea((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="text-right">
          <button className="close-modal" onClick={closeModal}>
            <FaTimes />
          </button>
        </div>

        <div className="modal-body mt-3">
          <h2 className="text-xl font-bold">Editing New Area</h2>

          <form className="mt-4">
            <div className="item mt-5">
              <label htmlFor="name">Area Name</label>
              <Input
                type="text"
                id="name"
                name="name"
                value={editedArea.name}
                autoComplete="off"
                onChange={handleInputChange}
              />
            </div>
            <div className="item mt-5">
              <label htmlFor="abbreviation">Area Abbreviation</label>
              <Input
                type="text"
                id="abbreviation"
                name="abbreviation"
                value={editedArea.abbreviation}
                autoComplete="off"
                onChange={handleInputChange}
              />
            </div>
            <div className="item mt-5 text-center">
              <Button
                className="btn btn-blue w-1/5 bg-blue-disable"
                onClick={handle?.create}
                disabled={data?.isLoading}
              >
                {data?.isLoading ? <ButtonSpinner /> : "Edit"}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateEditAreaModal;
