import React, { useState, useEffect } from "react";
import { FaSearch, FaTimes } from "react-icons/fa";
import { ButtonSpinner } from "../../../../../../components/spinners";
import { Button, Checkbox } from "antd";
import { TextShimmer } from "../../../../../../components/shimmer/shimmer";
import {
  GetWorkersType,
  MassSignOffOpt,
} from "../../../../../../helper/worksite/worksite";
import { useParams } from "react-router-dom";

const SignofModal = ({
  onClose,
  data,
  setData,
  search,
  worksite,
  setWorksite,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const id = useParams();
  const saveChanges = async () => {
    setIsLoading(true);
    setWorksite({
      ...worksite,
      is_mass_clock_off: 1,
    });
    const response = await MassSignOffOpt(data, id?.id, worksite);
    if (response?.status) {
      onClose();
      const param = { type: "Rostered", id: id?.id };
      const res = await GetWorkersType(param);
      if (res?.status) {
        setWorksite({
          ...worksite,
          data: res?.data?.data,
        });
      }
    }
  };
  const handleMultySelect = {
    check: (e, id) => {
      if (e?.target?.checked) {
        let allselectedOption = [...data?.selectedOption];
        allselectedOption?.push(id);

        setData({
          ...data,
          selectedOption: allselectedOption,
        });
      } else {
        let allselectedOption = [...data?.selectedOption];
        allselectedOption?.splice(allselectedOption?.indexOf(id), 1);

        setData({
          ...data,
          selectedOption: allselectedOption,
        });
      }
    },
  };
  const closeModal = () => {
    setData({
      ...data,
      isMultySelecteMassSignOff: false,
    });
    onClose();
  };
  useEffect(() => {
    (async () => {
      await search();
    })();
  }, []);
  return (
    <div className="modal">
      <div className="modal-content">
        <div className="text-right">
          <button className="close-modal" onClick={closeModal}>
            <FaTimes />
          </button>
        </div>

        <div className="modal-body mt-2">
          {data?.isShimmer ? (
            <>
              <TextShimmer data={{ gap: 15, line: 20 }} />
            </>
          ) : (
            <>
              <h2 className="text-xl font-bold">Sign off Shifts</h2>
              <p className="text-[#A1A1A1]">
                Total Number of selected Workers{" "}
                <span className="text-[#000]">
                  {data?.selectedOption?.length - 1}
                </span>
              </p>
              <div className="mt-5">
                <div className="mb-5">
                  <div className="border border-[#111111] flex items-center p-1 rounded-md w-full  my-2 bg-white">
                    <FaSearch className="ml-2" />{" "}
                    <input
                      placeholder={data?.searchBarTitle}
                      className="h-[31px] bg-transparent text-sm w-full px-2"
                      onChange={search}
                    />
                  </div>
                </div>
                <div className="border divide-y rounded-md">
                  <div className="px-6 py-2 bg-slate-100 flex justify-between">
                    <label className="text-sm font-bold">Worker Name</label>
                    <label className="text-sm font-bold">Email</label>
                    <label className="text-sm font-bold">Select All</label>
                  </div>
                  {data?.options?.map((item, index) => {
                    return (
                      <div
                        className="px-6 py-2 hover:bg-slate-100 cursor-pointer flex justify-between"
                        key={index}
                      >
                        <label className="text-sm">{item?.name}</label>
                        <label className="text-sm">{item?.email}</label>

                        <div>
                          <Checkbox
                            id={index}
                            key={index}
                            checked={data?.selectedOption?.includes(item?.id)}
                            onChange={(e) =>
                              handleMultySelect?.check(e, item?.id)
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="">
                  <div className="pt-4">
                    <Button
                      className="float-right btn btn-blue w-1/5 bg-blue-disable"
                      onClick={saveChanges}
                    >
                      {isLoading ? <ButtonSpinner /> : "Sign Off"}
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default SignofModal;
