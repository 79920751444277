import React from "react";
import { ApiAuthorizaion } from "../../auth/appAuth";
import { UserService } from "../../config/axiosUrl";

const CheckInduction = async (induction) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };
    const params = {
      disclaimer_id: induction?.disclaimer_id,
      worker_id: induction?.worker_id,
    };

    let data = await UserService?.get("/api/v1/personal/workers/induction", {
      headers: authorization?.headers,
      params: params,
    });

    return data?.data;
  } catch (error) {
    return error;
  }
};

const CreateInduction = async (induction) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    let formData = new FormData();

    formData?.append("employment_status_id", induction?.employment_status_id);

    formData?.append("name", induction?.name);
    formData?.append("gender", induction?.gender);
    formData?.append("dob", induction?.dob);
    formData?.append("address", induction?.address);
    formData?.append("city", induction?.city);
    formData?.append("post_code", induction?.post_code);
    formData?.append("mobile", induction?.mobile);
    formData?.append("driver_licence", induction?.driver_licence);
    formData?.append("shirt_size", induction?.shirt_size);
    formData?.append("pant_size", induction?.pant_size);
    formData?.append("super_fund_name", induction?.super_fund_name);
    formData?.append("super_fund_number", induction?.super_fund_number);
    formData?.append("tax_file_number", induction?.tax_file_number);
    formData?.append("bert", induction?.bert);
    formData?.append("qLeave", induction?.qLeave);

    formData?.append("bank_bsb", induction?.bank_bsb);
    formData?.append("bank_account_number", induction?.bank_account_number);

    formData?.append("c_name", induction?.c_name);
    formData?.append("c_mobile", induction?.c_mobile);
    formData?.append("c_existing_injuries", induction?.c_existing_injuries);
    formData?.append("injuries_desc", induction?.c_existing_injuries_text);
    formData?.append("c_medicle_condition", induction?.c_medicle_condition);
    formData?.append(
      "c_has_ever_made_a_worker_compensation_claim",
      induction?.c_has_ever_made_a_worker_compensation_claim
    );
    formData?.append("c_blood_type", induction?.c_blood_type);
    formData?.append("c_is_subcontractor", induction?.c_is_subcontractor);

    formData?.append(
      "workers_experiences",
      JSON.stringify(induction?.workers_experiences)
    );
    formData?.append("trade_certificate_0", induction?.trade_certificate_0);
    formData?.append("trade_certificate_1", induction?.trade_certificate_1);
    formData?.append("trade_certificate_2", induction?.trade_certificate_2);
    formData?.append("trade_certificate_3", induction?.trade_certificate_3);
    formData?.append("trade_certificate_4", induction?.trade_certificate_4);
    formData?.append(
      "workers_previous_employement_details",
      JSON.stringify(induction?.workers_previous_employement_details)
    );

    formData?.append("is_have_working_visa", induction?.is_have_working_visa);
    formData?.append("visa_details", induction?.is_have_working_visa_text);
    formData?.append("license_file", induction?.licence_file);
    formData?.append("blue_card_file", induction?.blue_card_file);
    formData?.append("worker_id", induction?.worker_id);
    formData?.append("disclaimer_id", induction?.disclaimer_id);

    let data = await UserService?.post(
      "/api/v1/personal/workers/induction-save",
      formData,
      authorization
    );

    return data?.data;
  } catch (error) {
    return error;
  }
};

export { CheckInduction, CreateInduction };
