import React from "react";
import { Route, Routes } from "react-router-dom";
import UnionFees from "./ExpensesItem2/UnionFees";
import EmployeePurchase from "./ExpensesItem2/EmployeePurchase";
import ChildSupport from "./ExpensesItem2/ChildSupport";
import SiteDonation from "./ExpensesItem2/SiteDonation";
import Superannuation2 from "./ExpensesItem2/Superannuation2";
import Misc2 from "./ExpensesItem2/Misc2";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { PayRollService } from "../../../config/axiosUrl";
const ExpensesRoutes2 = () => {
  const [type, setType] = useState([]);
  console.log("the type uis", type);
  const getType = () => {
    PayRollService.get(`api/v1/payroll/setting/expense-type-list`).then(
      (res) => {
        console.log("the actual response: ", res?.data?.data?.data);
        const desiredOrder = [
          "Superannuation",
          "Union Fees",
          "Employee Purchase",
          "Child support",
          "Site donation",
          "MISC",
        ];

        const filteredData = res?.data?.data?.data
          .filter((item) => item.type === 2)
          .sort((a, b) => {
            const indexA = desiredOrder.indexOf(a.expense_type_name);
            const indexB = desiredOrder.indexOf(b.expense_type_name);

            // If either item is not found in desiredOrder, push it to the end
            return indexA - indexB;
          });

        setType(filteredData);
      }
    );
  };
  useEffect(() => {
    getType();
  }, []);

  const [workerId, setWorkerId] = useState(null);

  const handleWorkerIdChange = (newWorkerId) => {
    setWorkerId(newWorkerId);
  };

  return (
    <Routes>
      <Route
        path="Superannuation2"
        element={
          <Superannuation2
            {...type[0]}
            onWorkerIdChange={handleWorkerIdChange}
            workerId={workerId}
          />
        }
      />
      <Route
        path="UnionFees"
        element={<UnionFees {...type[1]} workerId={workerId} />}
      />
      <Route
        path="EmployeePurchase"
        element={<EmployeePurchase {...type[2]} workerId={workerId} />}
      />
      <Route
        path="ChildSupport"
        element={<ChildSupport {...type[3]} workerId={workerId} />}
      />
      <Route
        path="SiteDonation"
        element={<SiteDonation {...type[4]} workerId={workerId} />}
      />
      <Route
        path="Misc2"
        element={<Misc2 {...type[5]} workerId={workerId} />}
      />
    </Routes>
  );
};

export default ExpensesRoutes2;
