import React, { Fragment, useEffect, useState } from "react";

import worksite from "../../../../assets/images/worksite/worksite.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { FaEdit, FaPlusSquare } from "react-icons/fa";
import { Card, DatePicker, Select } from "antd";
import AvailableSiteManagerModal from "../../../../shared/Modal/AvailableSiteManagerModal";
import MultySelect from "../../../../components/multySelect/multyselect";
import { UserService } from "../../../../config/axiosUrl";
import { SearchData } from "../../../../helper/global/global";
import { AddSiteManager } from "../../../../helper/worksite/worksiteDetails.js/worksitedetails";
import { toast } from "react-toastify";
import DefaultCree8Img from "../../../../assets/images/Default/defaulCree8.png";
import { Fileurl } from "../../../../config/url";
import { TableDefaultPage } from "../../../../components/default/defaultPage";
import { TextShimmer } from "../../../../components/shimmer/shimmer";
import { GetAreas } from "../../../../helper/worksite/worksiteDetails.js/areas";
import QualityAssuranceSvg from "../../../../assets/svg/qualityAssurance";
import InspectionSvg from "../../../../assets/svg/Inspectionsvg";
import AssignQA from "../../QA/AssignQA";

const DetailsTop = ({ data, setData, handle }) => {
  const [visibleAvailable, setVisibleAvailable] = React.useState(false);
  const { id } = useParams();
  const { area } = useParams();

  let location = useLocation();
  let navigate = useNavigate();

  /* const worksiteData = {
    worksiteName: "Worksite Name",
    status: "Active",
    jobNumber: 2453,
    siteManager: "Site Manager",
    workedHours: 7514.2,
    workedHoursPercentage: 60,
    requiredHours: 12524.2,
    requiredHoursPercentage: 15,
    lostHours: 5010,
    lostHoursPercentage: 25,
    remainingHours: 5010,
    remainingHoursPercentage: 25,
    deficitHours: 5010,
    deficitHoursPercentage: 25,
  }; */

  const initialWorksiteDetails = {
    areaOption: [],
    setAreaCode: "",
  };
  const [worsiteDetail, setWorksiteDetails] = useState(initialWorksiteDetails);

  const initialMultySelectValue = {
    options: [],
    isMultySelect: false,
    selectedOption: data?.selectedOptions,
    isLoading: false,
    isShimmer: true,
    title: "Available Site Manager",
    searchBarTitle: "Search by site manager Name",
    buttonTitle: "Add",
  };

  const [multySelect, setMultySelect] = useState(initialMultySelectValue);

  const visibleAvailableHandler = () => {
    let buttonStatus = data?.selectedOptions?.length > 0 ? "Update" : "Add";
    setMultySelect({
      ...multySelect,
      isMultySelect: true,
      selectedOption: data?.selectedOptions,
      buttonTitle: buttonStatus,
    });
  };

  const handleWorksite = {
    getInspection: () => {
      navigate(`/admin/worksite/get-inspection/${id}/${area}`);
    },
    getSiteManager: async (e) => {
      if (!e?.target?.value) {
        setMultySelect({
          ...multySelect,
          isShimmer: true,
          isMultySelect: true,
        });
      }

      const params = {
        value: e?.target?.value,
        api: "/api/v1/personal/site-manager/get-list",
        service: UserService,
      };
      let data = await SearchData(params);

      if (data?.status) {
        setMultySelect({
          ...multySelect,
          options: [...data?.data?.data],
          isShimmer: false,
          isMultySelect: true,
        });
      }
    },
    addSiteManager: async () => {
      let deleted_ids = [];
      data?.addedSiteManager?.forEach((siteMng, index) => {
        if (!multySelect?.selectedOption?.includes(siteMng?.site_manager_id)) {
          deleted_ids?.push(siteMng?.id);
        }
      });

      setMultySelect({
        ...multySelect,
        isLoading: true,
      });
      const params = {
        ids: multySelect?.selectedOption,
        worksite_id: id,
        deleted_ids: deleted_ids,
      };
      let getdata = await AddSiteManager(params);
      if (getdata?.status) {
        let worksiteData = await handle?.getWorksite();
        if (worksiteData?.status) {
          setMultySelect(initialMultySelectValue);
          toast?.success(getdata?.message);
        }
      } else {
        toast?.error(getdata?.message);
        setMultySelect({
          ...multySelect,
          isLoading: false,
        });
      }
    },
    searchAreas: async (e) => {
      const params = {
        search: e,
      };
      let areaData = await handleWorksite?.getAreas(params);
    },
    getAreas: async (areaData) => {
      const params = {
        search: areaData?.search,
        worksite_id: id,
      };
      let getData = await GetAreas(params);
      let areaOption = [];
      if (getData?.status) {
        getData?.data?.data?.forEach((area) => {
          areaOption?.push({ value: area?._id, label: area?.name });
        });

        setWorksiteDetails({
          ...worsiteDetail,
          areaOption: areaOption,
        });
      }
    },
    navigateAreas: async (e) => {
      navigate(`/admin/worksite/details/areas/cards/${id}/${e}`);
    },
  };

  return (
    <>
      {multySelect?.isMultySelect && (
        <MultySelect
          data={multySelect}
          setData={setMultySelect}
          search={handleWorksite?.getSiteManager}
          submit={handleWorksite?.addSiteManager}
        />
      )}

      {data?.isShimmer ? (
        <div>
          <div>
            <TextShimmer data={{ gap: 15, line: 6 }} />
          </div>
        </div>
      ) : (
        <div>
          <div className="flex justify-start items-start">
            <img
              src={
                data?.worksite?.picture
                  ? Fileurl + data?.worksite?.picture
                  : DefaultCree8Img
              }
              alt="worksite"
              className="rect-image rounded-lg"
            />
            <div className="ml-5 w-full">
              <div className="flex justify-between items-center">
                <div className="flex justify-start items-center">
                  <h1 className="text-2xl font-bold">{data?.worksite?.name}</h1>
                  <div className="ml-16 flex items-center">
                    <span
                      className={
                        data?.worksite?.status == 1
                          ? "text-xs text-white bg-green-500 px-2 py-1 rounded-md"
                          : "text-xs text-white bg-red-500 px-2 py-1 rounded-md"
                      }
                    >
                      {data?.worksite?.status == 0 ? "Inactive" : "Active"}
                    </span>
                    <Link className="ml-3" to={`/admin/worksite/edit/${id}`}>
                      <FaEdit />
                    </Link>
                  </div>
                </div>
                <DatePicker onChange={handle?.getDate} />
              </div>

              {location?.pathname ===
              `/admin/worksite/details/areas/cards/${id}/${area}` ? (
                <div className="">
                  <div className="flex justify-between">
                    <div>
                      <div className="mt-6">
                        <span className="text-sm text-gray-400">
                          Job Number&nbsp;:&nbsp;
                        </span>
                        <span className="text-sm">
                          {data?.worksite?.job_no}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div className="mt-3">
                        <div className="border rounded-md flex">
                          <div className="px-2 text-sm py-3 border-r rounded-sm">
                            {data?.selectedArea?.abbreviation}
                          </div>
                          <div className="">
                            <Select
                              showSearch
                              placeholder="Select area"
                              optionFilterProp="children"
                              className="text-sm"
                              size="small"
                              style={{ width: 200 }}
                              bordered={false}
                              name="company_id"
                              value={data?.selectedArea?.name}
                              filterOption={false}
                              onFocus={handleWorksite?.getAreas}
                              options={worsiteDetail?.areaOption}
                              onChange={handleWorksite?.navigateAreas}
                              onSearch={handleWorksite?.searchAreas}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="flex">
                        <div className="">
                          <div
                            className="mt-6 px-4 text-sm"
                            onClick={handleWorksite?.getInspection}
                          >
                            <div className="flex cursor-pointer">
                              <div className="pr-1">
                                <InspectionSvg />
                              </div>
                              <div>Inspection</div>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div
                            className="mt-6 text-sm"
                            onClick={() =>
                              navigate(
                                `/admin/worksite/quality-assurance/${id}/${area}`
                              )
                            }
                          >
                            <div className="flex cursor-pointer">
                              <div className="pr-1">
                                <QualityAssuranceSvg />
                              </div>
                              <div> Quality Assurance</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <p className="text-lg fw-[500] mt-2 w-full flex">
                    Site Manager
                    <button
                      onClick={visibleAvailableHandler}
                      className="text-orange-600 flex items-center ml-4"
                    >
                      Assign <FaPlusSquare />
                    </button>
                    &nbsp;
                    {data?.worksite?.worksite_managers?.map((value, index) => {
                      return (
                        <>
                          {index > 0 ? "," : ""}
                          &nbsp;<span>{value?.users?.name}</span> &nbsp;
                        </>
                      );
                    })}
                  </p>
                </div>
              )}
              <div className="flex mt-5  w-full gap-5 ">
                {data?.topCards?.map((topcard, index) => {
                  return (
                    <Card
                      key={index}
                      className={`border-l-8 w-full p-2 flex-grow`}
                      style={{ borderColor: `${topcard?.color}` }}
                    >
                      <p className="text-xs">{topcard?.name}</p>
                      <div className="flex mt-2 justify-between items-center">
                        <p className="text-xs">
                          {parseFloat(topcard?.hours)?.toFixed(1)}
                          &nbsp;Hours
                        </p>
                        <p className="text-xs ">
                          {parseFloat(topcard?.percentage)?.toFixed(1) &&
                          parseFloat(topcard?.percentage)?.toFixed(1) !== "NaN"
                            ? parseFloat(topcard?.percentage)?.toFixed(1)
                            : "0.0"}
                          %
                        </p>
                      </div>
                    </Card>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DetailsTop;
