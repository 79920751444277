import React, { useState, useEffect } from "react";
import SiteManagerWorksite from "./items";
import HeaderSearch from "../../../layouts/HeaderSearch";
import LogOutDetails from "../../../layouts/LogOutDetails";
import { Authuser } from "../../../auth/appAuth";
import { GetWorkSite } from "../../../helper/worksite/worksite";
import useWindowDimensions from "../../../utils/routes/useWindowDimensions";
const SiteManagerWorksites = () => {
  const { width } = useWindowDimensions();
  const initialvalue = {
    id: "",
    name: "",
    email: "",
    employee_id: "",
    mobile: "",
    role: "",
    verified: "",
    last_login: "",
    status: "",
  };
  const [user, setUser] = useState(initialvalue);
  const initialValuesWorksite = {
    data: [],
    isStatusLoading: false,
    extra: {},
    isLoadingId: "",
    isLoading: false,
    isFetchLoader: false,
    isShimmer: true,
    date: "",
  };
  const [worksite, setWorksite] = useState(initialValuesWorksite);
  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    localStorage.removeItem("dataStore");
    let data = Authuser();
    setUser({
      ...data,
    });
  }, []);
  const handleSearch = async (e) => {
    setSearchValue(e?.target?.value);
    const params = {
      search: e?.target?.value,
    };
    let data = await GetWorkSite(params);
    if (data?.status) {
      setWorksite({
        ...worksite,
        data: [...data?.data?.data],
        isShimmer: false,
        extra: data?.extra,
        isFetchLoader: false,
        cursor: "",
      });
    } else {
      setWorksite({
        ...worksite,
        isShimmer: false,
      });
    }
  };
  return (
    <>
      <div
        className={
          width > 767
            ? "flex justify-between items-center mb-[20px] header-flex"
            : "flex justify-between items-center mb-[20px] header-flex p-3"
        }
      >
        <p className="text-[24px] w-full">Worksites</p>
        <div className="flex justify-end items-center w-full">
          <HeaderSearch
            placeHolder={"Search by Worksite Name"}
            handle={handleSearch}
            value={searchValue}
          />
          <LogOutDetails user={user} />
        </div>
      </div>
      <SiteManagerWorksite worksite={worksite} setWorksite={setWorksite} />
    </>
  );
};

export default SiteManagerWorksites;
