import React from "react";
import { FaEdit } from "react-icons/fa";
import { AiOutlineEye } from "react-icons/ai";
import { TableDefaultSvg } from "../../assets/svg/tableDefaul";
import { TableDefaultPage } from "../../components/default/defaultPage";
import moment from "moment";
import { useParams, Link } from "react-router-dom";

const TerminatedProfileTable = ({ worker_termination_logs }) => {
  const { id } = useParams();

  let deafaulPage = {
    disclaimer: "No Data",
    description: "No Data Available",
    buttonText: "",
    svg: <TableDefaultSvg />,
    redirect: "",
  };

  return (
    <>
      {worker_termination_logs?.length > 0 ? (
        <table className="w-full">
          <thead className="bg-[#E8F3FF] text-center">
            <tr className="text-left">
              <th className=" px-4 py-3 ">
                <div className="flex items-center justify-start">
                  Employed As
                </div>
              </th>
              <th className=" px-4 py-3 ">
                <div className="flex items-center justify-start">
                  Joining Date
                </div>
              </th>
              <th className=" px-4 py-3 ">
                <div className="flex items-center justify-start">
                  Termination date
                </div>
              </th>
              <th className=" px-4 py-3 ">
                <div className="flex items-center justify-center">Action</div>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {worker_termination_logs?.map((item, index) => (
              <tr key={index} className="border-b border-[#F0F0F0]">
                <td className="px-4 py-3">
                  <div className="flex items-center justify-start">
                    {item.role}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex items-center justify-start">
                    {moment(item?.joining_date)?.format("MMMM, D YYYY")}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex items-center justify-start">
                    {item?.terminated_date
                      ? moment(item?.terminated_date)?.format("MMMM, D YYYY")
                      : moment(new Date())?.format("MMMM, D YYYY")}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex items-center justify-center">
                    <Link
                      to={window.location.toString().includes("site-manager") ? `/site-manager/workers/1/details/terminated/${id}/${item.id}` : `/admin/personnel/workers/details/terminated/${id}/${item.id}`}
                    >
                      <AiOutlineEye className="cursor-pointer" />
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <TableDefaultPage data={deafaulPage} />
      )}
    </>
  );
};

export default TerminatedProfileTable;
