import { Button, Input } from "antd";
import React, { useRef, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import { ButtonSpinner } from "../../components/spinners";
import { CreateEditWorkers } from "../../helper/personnel/workers";

const EditProfileModal = ({
  setModalVisible,
  worker,
  setWorker,
  handleWorkers,
}) => {
  const initialvalues = {
    ...worker,
    id: worker?.id,
    worker_details_id: worker?.worker_details?.[0]?.id,
    employment_status_id: worker?.worker_details?.[0]?.employment_status?.id,
    work_site_id: worker?.worker_details?.[0]?.worksites?.id,
    name: worker?.name,
    lastname: worker?.last_name,
    email: worker?.email,
    worker_designation_id: worker?.worker_details?.[0]?.worker_designation_id,
    role: "WORKER",
    is_send_shift_receipts: worker?.worker_details?.[0]?.is_send_shift_receipts,
    errors: {
      name: "",
      email: "",
      lastname: "",
    },
    isLoading: false,
  };

  const [editWorker, setEditWorker] = useState(initialvalues);

  let errorKeys = Reflect?.ownKeys(initialvalues?.errors);

  let errors = useRef([]);
  errorKeys?.map((value, index) => {
    errors.current[value] = React?.createRef();
  });
  const handleEditWorker = {
    get: (e) => {
      setEditWorker({
        ...editWorker,
        [e?.target?.name]: e?.target?.value,
      });
    },
    check: (e) => {
      setEditWorker({
        ...editWorker,
        [e?.target?.name]: Number(e?.target?.checked),
      });
    },
    submit: async () => {
      let errorCount = 0;
      errorKeys?.map((value, index) => {
        if (errorCount == index) {
          if (!editWorker?.[value]) {
            setEditWorker({
              ...editWorker,
              errors: {
                ...initialvalues?.errors,
                [value]: `Enter ${value?.replace(/_|id/g, " ")} `,
              },
            });
            errors?.current?.[value]?.current?.focus();
          } else {
            errorCount++;
            setEditWorker({
              ...editWorker,
              errors: {
                ...initialvalues?.errors,
              },
            });
            if (errorCount == errorKeys?.length) {
              handleEditWorker?.create();
            }
          }
        }
      });
    },
    create: async () => {
      setEditWorker({
        ...editWorker,
        isLoading: true,
      });
      console.log("edit wor", editWorker);
      let getData = await CreateEditWorkers(editWorker);
      if (getData?.status) {
        setWorker({
          ...editWorker,
        });
        if (handleWorkers) {
          await handleWorkers();
        }
        setModalVisible(false);
        toast?.success(getData?.message);
      } else {
        if (getData?.status_code == 501) {
          setEditWorker({
            ...editWorker,
            errors: {
              ...initialvalues?.errors,
              email: getData?.message,
            },
            isLoading: false,
          });
          errors?.current["email"]?.current?.focus();
        } else {
          setEditWorker({
            ...editWorker,
            isLoading: false,
          });
          toast?.error(getData?.message);
        }
      }
    },
  };

  const closeModal = () => {
    setModalVisible(false);
  };
  return (
    <div className="modal">
      <div className="modal-content">
        <div className="text-right">
          <button className="close-modal" onClick={closeModal}>
            <FaTimes />
          </button>
        </div>

        <div className="modal-body mt-3">
          <h2 className="text-xl font-bold">Editing Workers Details</h2>

          <form className="mt-4">
            <div className="item">
              <label htmlFor="val">MYOB Employee Card ID</label>
              <Input
                type="text"
                className="mt-3 border-none p-0 text-[#757575] font-semibold"
                defaultValue={editWorker?.employee_id}
                name="val"
                id="val"
                readOnly
              />
            </div>
            <div className="item mt-5">
              <label htmlFor="name">First Name</label>
              <Input
                type="text"
                id="name"
                className={
                  editWorker?.errors?.name
                    ? "mt-3 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                    : "mt-3"
                }
                ref={errors?.current["name"]}
                name="name"
                value={editWorker?.name}
                onChange={handleEditWorker?.get}
                autoComplete="off"
              />
              {editWorker?.errors?.name && (
                <p className="pt-2 text-red-600 text-sm">
                  {editWorker?.errors?.name}
                </p>
              )}
            </div>
            <div className="item mt-5">
              <label htmlFor="name">Last Name</label>
              <Input
                type="text"
                id="lastname"
                className={
                  editWorker?.errors?.name
                    ? "mt-3 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                    : "mt-3"
                }
                ref={errors?.current["lastname"]}
                name="lastname"
                value={editWorker?.lastname}
                onChange={handleEditWorker?.get}
                autoComplete="off"
              />
              {editWorker?.errors?.name && (
                <p className="pt-2 text-red-600 text-sm">
                  {editWorker?.errors?.lastname}
                </p>
              )}
            </div>
            <div className="item mt-5">
              <label htmlFor="email">Email</label>
              <Input
                type="text"
                id="email"
                className={
                  editWorker?.errors?.email
                    ? "mt-3 border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                    : "mt-3"
                }
                ref={errors?.current["email"]}
                name="email"
                value={editWorker?.email}
                onChange={handleEditWorker?.get}
                autoComplete="off"
              />
              {editWorker?.errors?.email && (
                <p className="pt-2 text-red-600 text-sm">
                  {editWorker?.errors?.email}
                </p>
              )}
            </div>
            <div className="item mt-5">
              <label htmlFor="shift">Shift Receipts</label>
              <div className="flex justify-start items-center mt-3">
                <input
                  type="checkbox"
                  id="sh"
                  name="is_send_shift_receipts"
                  checked={Boolean(editWorker?.is_send_shift_receipts)}
                  onChange={handleEditWorker?.check}
                />
                <label htmlFor="sh" className="ml-2">
                  Send Shift Receipts to Worker
                </label>
              </div>
            </div>
            <div className="item mt-5 text-center">
              <Button
                className="btn btn-blue w-1/4 bg-blue-disable"
                onClick={handleEditWorker?.submit}
                disabled={editWorker?.isLoading}
              >
                {editWorker?.isLoading ? <ButtonSpinner /> : " Save Changes"}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditProfileModal;
