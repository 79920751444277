import React from "react";

const InspectionSvg = ({ data }) => {
  let height = data?.height ? data?.height : 18;
  let width = data?.width ? data?.width : 18;

  return (
    <svg
      data-name="#000000ff"
      xmlns="http://www.w3.org/2000/svg"
      width={height}
      height={width}
      viewBox="0 0 14.383 14.383"
    >
      <path
        data-name="Path 1421"
        d="M8.8 0H9a5.487 5.487 0 0 1 5.271 4.414 5.845 5.845 0 0 1 .105.962v.2a5.478 5.478 0 0 1-7.742 4.884 5.576 5.576 0 0 1-1.3-.828l-.694.694c.105.108.217.21.318.322a.421.421 0 0 1 .054.468.949.949 0 0 1-.181.21L1.92 14.251a.468.468 0 0 1-.271.132h-.085a.489.489 0 0 1-.264-.143L.13 13.073A.457.457 0 0 1 0 12.8v-.084a.488.488 0 0 1 .131-.253l2.908-2.907a.841.841 0 0 1 .26-.212.422.422 0 0 1 .447.084l.306.305c.233-.23.464-.462.695-.694A5.48 5.48 0 0 1 8.8 0m-.356.866a4.631 4.631 0 1 0 2.568.484A4.622 4.622 0 0 0 8.444.866M1.018 12.772c.2.2.4.394.594.593q1.222-1.216 2.439-2.438c-.2-.2-.393-.4-.6-.6-.807.82-1.624 1.628-2.433 2.445Z"
      />
      <path
        data-name="Path 1422"
        d="M8.5 1.707a3.8 3.8 0 1 1-1.949.8 3.788 3.788 0 0 1 1.949-.8m.056.842a2.952 2.952 0 1 0 1.92.434 2.966 2.966 0 0 0-1.921-.434Z"
      />
      <path
        data-name="Path 1423"
        d="M10.293 4.337a.424.424 0 0 1 .417-.1.413.413 0 0 1 .277.264.427.427 0 0 1-.11.45l-2.1 2.094a.431.431 0 0 1-.625-.029q-.616-.619-1.235-1.236a.424.424 0 1 1 .6-.6q.485.482.967.966Z"
      />
    </svg>
  );
};

export default InspectionSvg;
