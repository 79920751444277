import { Switch } from "antd";
import React, { useContext } from "react";
import { FaCircle, FaInfoCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Fileurl } from "../../../config/url";
import DefaultCree8Img from "../../../assets/images/Default/defaulCree8.png";
import { ButtonSpinner } from "../../../components/spinners";
import moment from "moment/moment";
import { AppContext } from "../../../layouts/Main";
import useWindowDimensions from "../../../utils/routes/useWindowDimensions";
const WorksiteCards = ({ data, setData, handle }) => {
  const { width } = useWindowDimensions();
  const [dataStore, setDataStore] = useContext(AppContext);
  const storeContext = (item) => {
    setDataStore(item);
    localStorage.setItem("dataStore", JSON.stringify(item));
  };
  return (
    <div
      className={
        width > 767
          ? "h-[80vh] w-100 overflow-y-scroll scroll-display-none "
          : "h-[80vh] w-100 overflow-y-scroll scroll-display-none p-3"
      }
      onScroll={handle?.fetch}
      style={{
        overflowY: width <= 767 ? "scroll" : "visible",
        maxHeight: width <= 767 ? "65vh" : "auto",
      }}
    >
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
        {data?.data?.length > 0 ? (
          data?.data?.map((item, index) => (
            <div
              className="bg-white rounded-lg border border-gray-200"
              key={index}
            >
              <div className="flex p-3 justify-between items-center">
                {item.work_status === "On time" ? (
                  <button className="flex justify-center items-center border px-3 py-2 rounded-md border-green-500">
                    <span className="text-green-500 flex items-center">
                      {" "}
                      <FaCircle className="mr-2" /> On Time
                    </span>
                    <FaInfoCircle className="ml-2 text-xs" />
                  </button>
                ) : (
                  <div className="h-[38px]"></div>
                )}
                {data?.isLoadingId == item?.id ? (
                  <ButtonSpinner />
                ) : (
                  <>
                    {" "}
                    <Switch
                      className={
                        item?.status == 0
                          ? "switch-bg-color-active"
                          : "switch-bg-color-in-active "
                      }
                      checked={item?.status == 1 ? false : true}
                      onChange={(checked) =>
                        handle?.changeStatus(checked, item?.id)
                      }
                    />
                  </>
                )}
              </div>

              <div className="text-center mt-4">
                <div className="worksite-img">
                  <img
                    src={
                      item?.picture ? Fileurl + item?.picture : DefaultCree8Img
                    }
                    className="block mx-auto"
                    alt=""
                  />
                </div>
                <Link
                  onClick={() => storeContext(item)}
                  to={`/site-manager/worksite/details/timesheet/${item?.id}/1`}
                  state={{ data: item }}
                  className="text-xl"
                >
                  {item?.name ? item?.name : "N/A"}
                </Link>

                <table className="mt-5 w-full">
                  <tbody>
                    <tr>
                      <td>
                        <p className="text-gray-500 text-sm">Site Manager</p>
                      </td>
                      <td>
                        <p className="text-gray-500 text-sm">Job Number</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="">
                          {item?.siteManager ? item?.siteManager : "N/A"}
                        </p>
                      </td>
                      <td>
                        <p className="">
                          {item?.job_no ? item?.job_no : "N/A"}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <hr className="my-4" />

                <table className="w-full tttable">
                  <tbody>
                    <tr>
                      <td>
                        <p className="text-gray-500 text-sm">Rostered</p>
                      </td>
                      <td>
                        <p className="text-gray-500 text-sm">Working</p>
                      </td>
                      <td>
                        <p className="text-gray-500 text-sm">Finished</p>
                      </td>
                      <td>
                        <p className="text-gray-500 text-sm">Absent</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="">
                          {item?.rostered ? item?.rostered : "N/A"}
                        </p>
                      </td>
                      <td>
                        <p className="">
                          {" "}
                          {item.working ? item.working : "N/A"}
                        </p>
                      </td>
                      <td>
                        <p className="">
                          {" "}
                          {item.finished ? item.finished : "N/A"}
                        </p>
                      </td>
                      <td>
                        <p className="">{item.absent ? item.absent : "N/A"}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <p className="bg-[#D5E9FE] p-2 mt-4">
                  Estimated End Date:{" "}
                  {moment(item?.worksite_timelines[0]?.finish_date).format(
                    "DD MMM YYYY"
                  )}
                </p>
              </div>
            </div>
          ))
        ) : (
          <p>No Worksite Found...</p>
        )}
      </div>
      {data?.isFetchLoader && (
        <div className="py-5">
          <ButtonSpinner />
        </div>
      )}
    </div>
  );
};

export default WorksiteCards;
