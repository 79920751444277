import React, { useCallback, useState } from "react";
import { FaAngleDown, FaAngleUp, FaPlus } from "react-icons/fa";
import { Button } from "antd";
import { Archive, Edit, Trash } from "../../../../../assets/svg";
import { Fragment } from "react";
import Confirmation from "../../../../../components/Confirmation";
import { toast } from "react-toastify";
import { PayRollService } from "../../../../../config/axiosUrl";

const SiteAllowanceTable = ({
  data,
  toggleModal,
  deleteRecord,
  onAssign,
  allSites,
  onArchive,
}) => {
  const [expanded, setExpanded] = useState(-1);
  const [currentDelete, setCurrentDelete] = useState({
    visible: false,
    item: {},
  });

  const [isDeleting, setIsDeleting] = useState(false);

  const toggleExpand = function (index) {
    setExpanded((prev) => (prev === index ? -1 : index));
  };

  const onDeleteClick = useCallback(function (data, parentIndex, childIndex) {
    setCurrentDelete((prev) => ({
      item: { ...data, parentIndex, childIndex },
      visible: true,
    }));
  }, []);

  const resetDeleteState = useCallback(function () {
    setCurrentDelete((prev) => ({
      ...prev,
      item: {},
      visible: false,
    }));
    setIsDeleting(false);
  }, []);

  async function deleteRec() {
    let { id = "", parentIndex = -1, childIndex = -1 } = currentDelete.item;
    if (id) {
      await new Promise((res) => {
        setIsDeleting(true);
        res(true);
      });

      PayRollService.post(
        "/api/v1/setting/payroll/delete-worksites-site-allowance",
        { id }
      )
        .then((res) => {
          let data = res?.data;

          if (data?.status) {
            let state = allSites.data;
            let parentEl = state[parentIndex];

            parentEl?.payroll_site_allowance_data?.splice(childIndex, 1);

            state[parentIndex] = parentEl;

            deleteRecord(state);
          }
          resetDeleteState();
          toast?.[data?.status ? "success" : "error"](data?.message);
        })
        .catch((err) => {
          setIsDeleting(false);
        });
    }
  }

  const deleteAllowance = useCallback(
    async function () {
      let { id = "", parentIndex = -1 } = currentDelete.item;
      if (id) {
        await new Promise((res) => {
          setIsDeleting(true);
          res(true);
        });
        PayRollService.post("api/v1/setting/payroll/delete-site-allowance", {
          id,
          type: 1,
        }).then((res) => {
          if (res?.data?.status) {
            let state = data;
            state.splice(parentIndex, 1);

            deleteRecord(state);
            toast.success(res?.data?.message);
            resetDeleteState();
          } else {
            toast.error(res?.data?.message);
          }
        });
      }
    },
    [currentDelete.item]
  );
  return (
    <Fragment>
      <Confirmation
        open={currentDelete.visible}
        title={`Are you sure want to delete this  ${
          currentDelete?.item?.childIndex > -1 ? "Worksite" : "Allowance"
        }`}
        details={
          currentDelete?.item?.childIndex > -1
            ? currentDelete?.item?.worksites?.name
            : currentDelete?.item?.name
        }
        onConfirm={
          currentDelete?.item?.childIndex > -1 ? deleteRec : deleteAllowance
        }
        isSubmitting={isDeleting}
        onCancel={resetDeleteState}
      />
      <table className="w-full">
        <thead className="bg-[#E8F3FF] text-center">
          <tr className="text-left">
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-start">Name</div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">
                Hourly Rate
              </div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">Actions</div>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {data?.map((item, index) => (
            <Fragment key={item.id}>
              <tr className="border-b-2 border-[#F0F0F0]">
                <td className="px-4 py-3">
                  <div className="flex items-center justify-start">
                    {item.name}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex items-center justify-center">
                    {item.hourly_rate}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex gap-4 items-center justify-center">
                    <button
                      onClick={() => toggleModal(item, index)}
                      className="text-black bg-transparent border-none"
                    >
                      <Edit title="Edit" />
                    </button>
                    <button
                      className="text-black bg-transparent border-none"
                      onClick={() => onArchive(item)}
                    >
                      <Archive title="Archive" />
                    </button>
                    <button
                      className="text-black bg-transparent border-none"
                      onClick={() => onDeleteClick(item, index)}
                    >
                      <Trash title="Trash" />
                    </button>
                    <Button
                      className="px-2 bg-[#FE650C]"
                      onClick={() => toggleExpand(index)}
                    >
                      {expanded === index ? (
                        <FaAngleUp className="scale-125 fill-white" />
                      ) : (
                        <FaAngleDown className="scale-125 fill-white" />
                      )}
                    </Button>
                  </div>
                </td>
              </tr>
              {expanded === index && (
                <Fragment>
                  {item?.payroll_site_allowance_data?.map(
                    (child, childIndex) => (
                      <tr key={window.crypto.randomUUID()}>
                        <td colSpan={3}>
                          <div className="flex justify-center py-4 px-2">
                            <div className="flex justify-between w-[80%] px-2  border-b-2 pb-2 border-b-[#F0F0F0]">
                              <p>{child?.worksites?.name}</p>

                              <div
                                className="cursor-pointer"
                                onClick={() =>
                                  onDeleteClick(child, index, childIndex)
                                }
                              >
                                <Trash />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  )}
                  <tr>
                    <td colSpan={3}>
                      <div className="flex justify-end w-[90%] py-2">
                        <Button
                          className="flex justify-center items-center py-1 px-2 border border-[#111111] h-[34px]"
                          onClick={() => onAssign({ ...item, index })}
                        >
                          Assign
                          <div className="bg-orange-500 p-1 rounded-md text-white ml-3">
                            <FaPlus />
                          </div>
                        </Button>
                      </div>
                    </td>
                  </tr>
                </Fragment>
              )}
            </Fragment>
          ))}
        </tbody>
      </table>
    </Fragment>
  );
};

export default SiteAllowanceTable;
