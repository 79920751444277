import React from "react";
import { Input, Modal, Select } from "antd";
import { Button } from "antd";
import { Controller } from "react-hook-form";
import { Fragment } from "react";
import InputError from "../../../../components/InputError";
import { useState, useEffect } from "react";
import { PayRollService } from "../../../../config/axiosUrl";
import { FaPlus } from "react-icons/fa";
import { Trash } from "../../../../assets/svg";
import Superannuation2Payroll from "./Superannuation2Payroll";
import Superannuation2PayrollDelete from "./Superannuation2payrollDelete";
import { useForm } from "react-hook-form";
const SuperannuationModal2 = ({
  visible,
  onCancel,
  isEditing,
  editData,
  onSave,
  id,
}) => {
  const [payrollid, setPayrollId] = useState([]);
  const modalTitle = isEditing
    ? "Editing Co-Contribution"
    : "Creating Co-Contribution";

  const { control, handleSubmit, reset } = useForm({
    defaultValues: isEditing ? editData : {},
  });
  const [deleteModal, setDeleteModal] = useState({
    visible: false,
    current: {},
    name: {},
    isDeleting: false,
  });

  useEffect(() => {
    // Reset the form with new initial values when visible and editing
    if (visible && isEditing) {
      reset(editData);
      setSelectedPayrollAllowance(editData.allowance_ids || []);
    } else {
      reset();
      setSelectedPayrollAllowance([]); // Reset the form without preloading data when creating new
    }
  }, [visible, isEditing, reset, editData]);

  const onSubmit = (data) => {
    const superData = {
      ...data,
      allowance_ids: selectedPayrollAllowance,
    };
    onSave(superData);
    reset();
    setSelectedPayrollAllowance([]);
  };
  const [showAddPayrollModal, setShowAddPayrollModal] = useState(false);

  const [selectedPayrollAllowance, setSelectedPayrollAllowance] = useState([]);
  // console.log("the selected paytoll", selectedPayrollAllowance);

  const [selectedOption, setSelectedOption] = useState("Rate");
  const [payroll, setPayroll] = useState([]);
  const [payrollCat, setPayrollCat] = useState([]);

  const getPayrollCategories = () => {
    PayRollService.get(
      `/api/v1/payroll/setting/get-payroll-category-data`
    ).then((res) => {
      setPayrollCat(res?.data?.data?.data);
    });
  };
  useEffect(() => {
    getPayrollCategories();
  }, []);
  const handleAddPayrollClick = () => {
    const getPayroll = () => {
      PayRollService.get(`/api/v1/payroll/setting/all-payroll-allowance-list`)
        .then((res) => {
          const data = res?.data?.data?.data;

          if (data) {
            const allAllowances = [
              ...data.payrollCategoryAllowanceData,
              ...data.payrollSiteAllowanceData,
              ...data.payrollWorkerAllowanceData,
              ...data.payrolltaskAllowanceData,
            ];

            setPayroll(allAllowances);
          }
        })
        .catch((error) => {
          // Handle error
          console.error("Error fetching payroll data:", error);
        });
    };
    setSelectedPayrollAllowance([]);
    getPayroll();
    setShowAddPayrollModal(true);
  };

  const handleSavePayrollOptions = (selectedOptions) => {
    // console.log("the selected options are", selectedOptions);
    const extractedData = selectedOptions.map((option) => ({
      allowance_id: option._id,
      allowance_type: option.allowance_type,
      name: option.name,
    }));

    setSelectedPayrollAllowance(extractedData);
    setShowAddPayrollModal(false);
  };

  const handleCheckboxChange = (event) => {
    setSelectedOption(event.target.id);
  };

  const handleDeleteClick = (itemId) => {
    // console.log("idxxsd", itemId);

    setDeleteModal({
      visible: true,
      current: itemId,
      isDeleting: false,
    });
  };

  const handleDeleteConfirm = () => {
    const params = {
      id: id,
      allowance_id: deleteModal.current.allowance_id,
      allowance_type: deleteModal.current.allowance_type,
      type: 2,
      // Add other parameters needed for deletion
    };
    // console.log("theheeh", params);
    PayRollService.post(
      `api/v1/payroll/setting/delete-assigned-allowance`,
      params
    )
      .then((res) => {
        // Handle success, update the component state
        setSelectedPayrollAllowance((prevData) =>
          prevData.filter(
            (item) => item.allowance_id !== deleteModal.current.allowance_id
          )
        );

        // miscList();
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      })
      .finally(() => {
        // Close the delete modal
        setDeleteModal({
          visible: false,
          current: {},
          isDeleting: false,
        });
      });
  };

  const handleDeleteCancel = () => {
    // Close the delete modal without deleting
    setDeleteModal({
      visible: false,
      current: {},
      isDeleting: false,
    });
  };

  return (
    <div>
      <Modal
        title={modalTitle}
        visible={visible}
        onCancel={onCancel}
        footer={null}
      >
        <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="item mt-5">
            <label htmlFor="name">Co-Contribution Name</label>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input type="text" className="mt-3" {...field} />
              )}
            />
          </div>

          <div className="mt-5 flex gap-5 justify-between items-center">
            <div className="item mt-5">
              <label htmlFor="export_as">Export As</label>
              <Controller
                name="export_as"
                control={control}
                render={({ field }) => (
                  <Input type="text" className="mt-3" {...field} />
                )}
              />
            </div>
            <div className="item mt-5">
              <label htmlFor="display_in_summary_as">
                Display in Summary As
              </label>
              <Controller
                name="display_in_summary_as"
                control={control}
                render={({ field }) => (
                  <Input type="text" className="mt-3" {...field} />
                )}
              />
            </div>
          </div>

          <div className="item mt-5">
            <label htmlFor="capped_work_hour">Capped Work Hour</label>
            <Controller
              name="capped_work_hour"
              control={control}
              render={({ field }) => (
                <Input type="text" className="mt-3" {...field} />
              )}
            />
          </div>
          <div className="item mt-5">
            <div className="flex justify-start items-center mt-3 gap-10">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  id="rate"
                  name="Rate"
                  className="rounded-full h-4 w-4 mr-3"
                  checked={selectedOption === "rate"}
                  onChange={handleCheckboxChange}
                />
                Rate
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  id="percentage"
                  name="Percentage"
                  className="rounded-full h-4 w-4 mr-3"
                  checked={selectedOption === "percentage"}
                  onChange={handleCheckboxChange}
                />
                Percentage
              </label>
            </div>
          </div>

          <Controller
            name={selectedOption}
            control={control}
            render={({ field }) => (
              <div className="item mt-5">
                <label htmlFor={selectedOption}>{selectedOption}</label>
                <Input
                  {...field}
                  type="text"
                  className="mt-3"
                  id="rate"
                  min="0"
                />
              </div>
            )}
          />

          <Controller
            name="payroll_category_id"
            control={control}
            render={({ field }) => (
              <div className="item mt-5">
                <label htmlFor="payroll_category_id">
                  Select Payroll Category
                </label>
                <Select className="mt-3 w-full" {...field}>
                  {payrollCat.map((category) => (
                    <Select.Option key={category.id} value={category._id}>
                      {category.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            )}
          />

          <div className="item mt-5 flex justify-between items-center border-b-2 border-[#F0F0F0] p-4">
            <div>
              <label htmlFor="name" className="font-bold text-lg">
                Applicable Payroll Allowance
              </label>
            </div>
            <div className="flex items-center">
              <Button
                className="flex items-center py-3 border border-[#111111] h-[42px]"
                onClick={handleAddPayrollClick}
              >
                Add
                <div className="bg-orange-500 p-1 rounded-md text-white ml-2">
                  <FaPlus style={{ fontSize: "16px" }} />
                </div>
              </Button>
            </div>
          </div>
          <div>
            <ul>
              {selectedPayrollAllowance.map((optionId) => (
                <li className="item flex justify-between items-center border-b-2 border-[#F0F0F0] p-3">
                  {optionId?.name}
                  {id && (
                    <Button
                      className="text-black bg-transparent border-none"
                      onClick={() => handleDeleteClick(optionId)}
                    >
                      <Trash />
                    </Button>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="item mt-5 justify-between flex ">
            <div className=" mt-3">
              <Controller
                name="export_to_summary"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <input
                    type="checkbox"
                    id="export_to_summary"
                    checked={field.value}
                    {...field}
                  />
                )}
              />
              <label htmlFor="export_to_summary" className="ml-2">
                Export to Summary
              </label>
            </div>
            <Button htmlType="submit" className="btn btn-blue">
              Save
            </Button>
          </div>
        </form>
      </Modal>
      <Superannuation2Payroll
        visible={showAddPayrollModal}
        onCancel={() => setShowAddPayrollModal(false)}
        payroll={payroll}
        onSave={handleSavePayrollOptions}
      />
      <Superannuation2PayrollDelete
        visible={deleteModal.visible}
        onCancel={handleDeleteCancel}
        title="Confirm Deletion"
        itemName={deleteModal.current?.name}
        onConfirm={handleDeleteConfirm}
        buttonText="Delete"
      />
    </div>
  );
};

export default SuperannuationModal2;
