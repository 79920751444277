import React from 'react'

const GreenPlusIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="_000000ff" data-name="#000000ff" width="20.086" height="20.086" viewBox="0 0 20.086 20.086">
            <path id="Path_1300" data-name="Path 1300" d="M9.865,0h.355a10.062,10.062,0,0,1,9.866,9.828v.393a10.065,10.065,0,0,1-9.864,9.864H9.868a10.065,10.065,0,0,1-9.6-7.757A10.439,10.439,0,0,1,0,10.222V9.829A10.06,10.06,0,0,1,9.865,0M9.422,1.581A8.352,8.352,0,0,0,4.865,3.334a7.543,7.543,0,0,0-.677.579,7.084,7.084,0,0,0-.516.541,9.371,9.371,0,0,0-.622.79A8.448,8.448,0,0,0,3.318,15.2a7.8,7.8,0,0,0,.614.718c.159.182.349.332.524.5a9.5,9.5,0,0,0,.789.621,8.451,8.451,0,0,0,9.979-.286,8.258,8.258,0,0,0,1.513-1.508,8.346,8.346,0,0,0,1.73-4.174,8.434,8.434,0,0,0-1.48-5.892A8.6,8.6,0,0,0,13.43,2.263,8.456,8.456,0,0,0,9.422,1.581Z" fill="#15803d" />
            <path id="Path_1301" data-name="Path 1301" d="M141.277,135.489a.777.777,0,0,1,1.265.6c0,1.04,0,2.079,0,3.118,1.034,0,2.067,0,3.1,0a.777.777,0,0,1,.694,1.144.776.776,0,0,1-.687.41c-1.036,0-2.072,0-3.108,0,0,1.033,0,2.067,0,3.1a.79.79,0,0,1-.284.61.777.777,0,0,1-1.271-.609c0-1.034,0-2.068,0-3.1-1.035,0-2.071,0-3.105,0a.776.776,0,0,1-.638-1.226.785.785,0,0,1,.642-.328c1.034,0,2.067,0,3.1,0,0-1.025,0-2.05,0-3.076A.791.791,0,0,1,141.277,135.489Z" transform="translate(-131.723 -130.009)" fill="#15803d" />
        </svg>
    )
}
export default GreenPlusIcon