import React from "react";
import { Route, Routes } from "react-router-dom";
import Superannuation from "./ExpensesItem/Superannuation";
import Bert from "./ExpensesItem/Bert";
import Bewt from "./ExpensesItem/Bewt";
import Cipq from "./ExpensesItem/Cipq";
import WorkCover from "./ExpensesItem/WorkCover";
import PayrollTax from "./ExpensesItem/PayrollTax";
import Misc from "./ExpensesItem/Misc";
import ExpensesFront from "./ExpensesItem2/ExpensesFront";
import { PayRollService } from "../../../config/axiosUrl";
import { useEffect, useState } from "react";

const ExpensesRoutes = () => {
  const [type, setType] = useState([]);
  const getType = () => {
    PayRollService.get(`api/v1/payroll/setting/expense-type-list`).then(
      (res) => {
        // Filter the data to keep only items with type=1
        const filteredData = res?.data?.data?.data.filter(
          (item) => item.type === 1
        );

        // Set the filtered data to setPayroll
        setType(filteredData);
      }
    );
  };
  useEffect(() => {
    getType();
  }, []);

  return (
    <Routes>
      {/* <Route path="ExpensesFront" element={<ExpensesFront />} /> */}
      <Route path="Superannuation" element={<Superannuation {...type[0]} />} />
      <Route path="Bert" element={<Bert {...type[1]} />} />
      <Route path="Bewt" element={<Bewt {...type[2]} />} />
      <Route path="WorkCover" element={<WorkCover {...type[3]} />} />
      <Route path="PayrollTax" element={<PayrollTax {...type[4]} />} />
      <Route path="Cipq" element={<Cipq {...type[5]} />} />
      <Route path="Misc" element={<Misc {...type[6]} />} />
    </Routes>
  );
};

export default ExpensesRoutes;
