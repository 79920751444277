import React from "react";
import { ApiAuthorizaion } from "../../auth/appAuth";
import { SettingService } from "../../config/axiosUrl";

const GetDashboard = async (date, searchData) => {
  try {
    const authorization = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: ApiAuthorizaion(),
      },
    };

    let data = await SettingService?.get(
      `/api/v1/setting/dashboard/panel-dash?date=${date}&search=${
        searchData || ""
      }`,
      {
        headers: authorization?.headers
      }
    );

    return data?.data;
  } catch (errors) {
    return errors;
  }
};

export { GetDashboard };
