import React from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const tabContent = [
  {
    key: "1",
    label: `Induction Email`,
    path: "induction",
  },
  {
    key: "2",
    label: `Termination Email`,
    path: "termination",
  },
];

const EmailHeader = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState();
  const pageFunctions = {
    onTabClick: useMemo(
      () =>
        function (item) {
          let path = item.path;
          if (path) {
            setActiveTab(path);
            navigate(path);
          }
        },
      []
    ),
  };

  useEffect(() => {
    const path = window.location.pathname.split("/");
    setActiveTab(path[path.length - 1]);
  }, []);
  return (
    <div>
      <nav className="flex gap-2 bg-[#E8E8E8] p-2 font-lexend w-100">
        {tabContent.map((item) => (
          <button
            key={item.key}
            className={`p-2 px-4 font-sm rounded-md duration-250 user-select-none ${
              activeTab === item.path
                ? "bg-[#0F4C7D] text-white"
                : "text-[#0F4C7D] bg-[white] border-2 border-[#0F4C7D]"
            }`}
            onClick={() => pageFunctions.onTabClick(item)}
          >
            <p>{item.label}</p>
          </button>
        ))}
      </nav>
    </div>
  );
};

export default EmailHeader;
