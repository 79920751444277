import React, { useCallback, useEffect, useState } from "react";
import ProfileHeader from "./ProfileHeader";
import { useParams } from "react-router-dom";
import { GetWorkersById } from "../../../../../helper/personnel/workers";
import ProfileRoutes from "./ProfileRoutes";

const WorkerProfile = () => {
  const { id } = useParams();

  const [data, setData] = useState();

  const [alive, setAlive] = useState(false);

  const hitAPI = () => {
    setAlive((prev) => !prev);
  };
  const getData = useCallback(async () => {
    let data = await GetWorkersById({ id });
    setData(data);
  }, [id]);

  useEffect(() => {
    getData();
  }, [alive]);

  return (
    <div className="p-4">
      <ProfileHeader />
      <ProfileRoutes data={data} hitAPI={hitAPI} />
    </div>
  );
};

export default WorkerProfile;
