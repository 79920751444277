import { Button, Modal } from "antd";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { FaDownload } from "react-icons/fa";
import { Cross, Spinner } from "../../../assets/svg";
import { WorkService } from "../../../config/axiosUrl";
import moment from "moment/moment";
import { APP_URL } from "../../../config/url";
import axios from "axios";
import { ButtonSpinner } from "../../../components/spinners";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

// var _global =
//   typeof window === "object" && window.window === window
//     ? window
//     : // : typeof self === "object" && self.self === self
//     // ? self
//     typeof global === "object" && global.global === global
//     ? global
//     : this;

const statusItem = {
  1: {
    label: "Successful",
    className: "text-[#41C700]",
  },
  2: {
    label: "Processing",
    className: "text-[#FE650C]",
  },
  3: {
    label: "Failed",
    className: "text-[#DF0505]",
  },
};
const ExportCSV = () => {
  const { id = "" } = useParams();

  const [modalOpen, setModalOpen] = useState(false);

  const [csvData, setCsvData] = useState({
    list: [],
    recent: {},
  });

  const [downloading, setDownloading] = useState({
    loading: false,
    index: -1,
    generating: false,
  });

  const toggleModal = useCallback(() => {
    setModalOpen((prev) => !prev);
  }, []);

  const getAllCSV = useCallback(() => {
    WorkService.get("/api/v1/worksite/area/csv-list").then((res) => {
      let data = res?.data?.data?.data || [];
      setCsvData((prev) => ({
        ...prev,
        list: data,
        recent: data[0],
      }));
    });
  }, []);

  useEffect(() => {
    getAllCSV();
  }, [getAllCSV]);

  const resetDownloading = useCallback(() => {
    setDownloading((prev) => ({
      ...prev,
      loading: false,
      index: -1,
      generating: false,
    }));
  }, []);
  const onDownloadClick = useCallback(
    async (url, index) => {
      await new Promise((res) => {
        setDownloading((prev) => ({
          ...prev,
          loading: true,
          index,
        }));
        res(true);
      });
      axios
        .get(url, { responseType: "blob" })
        .then((res) => {
          const extractFilename = url.split("/");
          let filename = extractFilename[extractFilename.length - 1];
          let href = URL.createObjectURL(res.data);
          let link = document.createElement("a");
          link.href = href;
          console.log(href, filename, extractFilename);
          link.download = filename.includes(".csv")
            ? filename
            : `data_${moment().format("DD/MM/yyyy")}.csv`;
          link.rel = "noopener";
          link.click();
          URL.revokeObjectURL(href);
          resetDownloading();
        })
        .catch((err) => {
          toast.error("Failed to download CSV");
          resetDownloading();
        });
    },
    [resetDownloading]
  );

  const onCancel = useCallback(() => {
    setCsvData((prev) => ({
      ...prev,
      recent: {},
    }));
  }, []);

  const generateCSV = useCallback(async () => {
    await new Promise((res) => {
      setDownloading((prev) => ({
        ...prev,
        generating: true,
      }));
      res(true);
    });

    WorkService.get(
      `/api/v1/worksite/area/get-areas-data-csv?worksite_id=${id}&type=PO&poStatus=all&dev=3&email=prasad.naik@ordrio.com`
    )
      .then((res) => {
        const data = res?.data;

        axios
          .get(data?.data?.download_link, { responseType: "blob" })
          .then((res) => {
            window.open(URL.createObjectURL(res.data));
            setCsvData((prev) => ({
              ...prev,
              recent: {
                download_link: data?.data?.download_link,
                status: 1,
              },
            }));
            setDownloading((prev) => ({
              ...prev,
              generating: false,
            }));
          })
          .catch((err) => {
            setCsvData((prev) => ({
              ...prev,
              recent: {
                status: 2,
              },
            }));
            setDownloading((prev) => ({
              ...prev,
              generating: false,
            }));
          });
      })
      .catch((err) => {
        setDownloading((prev) => ({
          ...prev,
          generating: false,
        }));
      });
  }, [id]);

  return (
    <Fragment>
      <button
        onClick={toggleModal}
        className="btn-light border border-black text-black px-5 rounded-md h-[40px] flex justify-center items-center p-0"
      >
        <FaDownload className="mr-2" /> Export CSV
      </button>

      <Modal
        open={modalOpen}
        onCancel={toggleModal}
        footer={[]}
        centered
        className="min-w-[50%] p-0 m-0"
      >
        <div className="flex items-center justify-between px-5 py-4 my-3 border border-[#E8E8E8] mt-8">
          <span className="flex flex-col justify-center items-between max-w-[70%]">
            {!csvData.recent?.status && (
              <h3 className="text-lg font-semibold">
                Generate CSV of Productivity Orders
              </h3>
            )}

            {csvData.recent?.status === 1 && (
              <h3 className="text-lg font-semibold">
                Productivity Order CSV file ready to download
              </h3>
            )}

            {csvData.recent?.status === 2 && (
              <Fragment>
                <h3 className="text-lg font-semibold">
                  Generating... This may take some time
                </h3>
                <p className="m-0 text-xs">
                  You can wait or come back later because generating the excel
                  file will require some time
                </p>
              </Fragment>
            )}

            {csvData.recent?.status === 3 && (
              <h3 className="text-lg font-semibold">
                Failed to generate an Productivity Orders CSV
              </h3>
            )}
          </span>

          <span className="flex items-center gap-2">
            {!csvData.recent?.status && (
              <Button
                className="bg-[#0F4C7D] text-white px-8"
                onClick={generateCSV}
              >
                {downloading.generating ? <ButtonSpinner /> : "Generate"}
              </Button>
            )}

            {csvData.recent?.status === 1 && (
              <Button
                className="bg-[#0F4C7D] text-white px-8"
                onClick={() => onDownloadClick(csvData.recent.download_link)}
              >
                {downloading.index === -1 && downloading.loading ? (
                  <ButtonSpinner />
                ) : (
                  "Download"
                )}
              </Button>
            )}
            {csvData.recent?.status === 2 && (
              <Button
                className=" px-8 border-[#0F4C7D] text-[#0F4C7D]"
                disabled
              >
                In Progress
              </Button>
            )}

            {csvData.recent?.status === 3 && (
              <Button
                className="bg-[#0F4C7D] text-white px-8"
                onClick={generateCSV}
              >
                Retry
              </Button>
            )}

            {csvData.recent?.status === 1 && (
              <Cross className="cursor-pointer" onClick={onCancel} />
            )}
          </span>
        </div>

        <table className="w-full">
          <thead className="bg-[#E8F3FF] text-center">
            <tr className="text-left">
              <th className=" px-4 py-3 ">
                <div className="flex items-center justify-start">File Name</div>
              </th>
              <th className=" px-4 py-3 ">
                <div className="flex items-center justify-center">Date</div>
              </th>
              <th className=" px-4 py-3 ">
                <div className="flex items-center justify-center">Status</div>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {csvData.list.map((item, index) => (
              <tr className="border-b border-[#F0F0F0]" key={item.id}>
                <td className="px-4 py-3">
                  <div className="flex items-center justify-start gap-1">
                    {`${index + 1}.`}
                    <p
                      onClick={() =>
                        onDownloadClick(item?.download_link, index)
                      }
                      target="_blank"
                      rel="noreferrer"
                      className="underline text-[#0060C0] cursor-pointer"
                    >
                      {item.csv_name}
                    </p>
                    {downloading.index === index && downloading.loading && (
                      <Spinner />
                    )}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex items-center justify-center">
                    {moment(item.created_at).format("DD-MM-yyyy")}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div
                    className={`flex items-center justify-center ${
                      statusItem?.[item.status]?.className
                    }`}
                  >
                    {statusItem?.[item.status]?.label}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal>
    </Fragment>
  );
};

export default ExportCSV;
