import React, { useState } from "react";
import { FaCaretDown, FaEdit, FaTrashAlt } from "react-icons/fa";
import { BiHistory } from "react-icons/bi";
import EditSiteManagerDetailsModal from "../Modal/EditSiteManagerDetailsModal";
import { Link, useParams } from "react-router-dom";
import DeleteSiteManagerModal from "../Modal/DeleteSiteManagerModal";
import { Fileurl } from "../../config/url";
import DefaultCree8Img from "../../assets/images/Default/defaulCree8.png";

const SiteManagerTable = ({ data, setData, handle }) => {
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const visibleEditModal = (item, index) => {
    setData({
      ...data,
      selectedData: { ...item, index: index },
    });
    setVisibleEdit(true);
  };

  const visibleDeleteModal = (item, index) => {
    setData({
      ...data,
      selectedData: { ...item, index: index },
    });
    setVisibleDelete(true);
  };

  const img = {
    display: "block",
    width: 60,
    height: 60,
    borderRadius: "10px",
  };

  return (
    <>
      {visibleEdit && (
        <EditSiteManagerDetailsModal
          setVisibleEdit={setVisibleEdit}
          data={data}
          setData={setData}
          hanle={handle}
        />
      )}

      {visibleDelete && (
        <DeleteSiteManagerModal
          setVisibleDelete={setVisibleDelete}
          data={data}
          setData={setData}
          handle={handle}
        />
      )}
      <table className="w-full">
        <thead className="bg-[#E8F3FF] text-center">
          <tr className="text-left">
            <th className=" px-4 py-3 "></th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">Name</div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">Email</div>
            </th>
            <th className=" px-4 py-3 ">
              <div className="flex items-center justify-center">Actions</div>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {data?.data?.map((item, index) => (
            <tr key={index} className="border-b border-[#F0F0F0]">
              <td className="px-4 py-3">
                <div className="flex items-center justify-center">
                  <img
                    src={
                      item?.users_profile_details?.data
                        ? Fileurl + item?.users_profile_details?.data
                        : DefaultCree8Img
                    }
                    style={img}
                  />
                </div>
              </td>
              <td className="px-4 py-3">
                <div className="flex items-center justify-center">
                  {item.name}
                </div>
              </td>
              <td className="px-4 py-3">
                <div className="flex items-center justify-center">
                  {item.email}
                </div>
              </td>
              <td className="px-4 py-3">
                <div className="flex items-center justify-center">
                  <button
                    className="mx-2"
                    onClick={(e) => visibleEditModal(item, index)}
                  >
                    <FaEdit title="Edit" />
                  </button>
                  <Link
                    to={`/admin/personnel/site-managers/history/${item?.id}`}
                    className="mx-2"
                  >
                    <BiHistory title="History" />
                  </Link>
                  <button
                    className="mx-2"
                    onClick={() => {
                      visibleDeleteModal(item, index);
                    }}
                  >
                    <FaTrashAlt title="Delete" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default SiteManagerTable;
