import { Button } from "antd";
import React from "react";
import { FaTimes } from "react-icons/fa";
import { ButtonSpinner } from "../../../../../../components/spinners";

function DeleteInspectionIssues({ setvisibleDeleteIssues, issueList, setIssueList, handle, formref }) {
    const closeModal = () => {
        setvisibleDeleteIssues(false);
    };
    return (
        <>
            <div className="modal">
                <div className="modal-content">
                    <div className="text-right">
                        <button
                            className="close-modal"
                            onClick={closeModal}
                        >
                            <FaTimes />
                        </button>
                    </div>
                    <div className="modal-body mt-3">
                        <h6 className="text-lg font-bold">
                            Are You Sure You Wish To Delete This Issues
                        </h6>
                        <form className="mt-4">
                            <div className="item">
                                <label htmlFor="val">Name : {issueList?.selectedData?.issue}</label>
                            </div>
                            <div className="item mt-5 text-center">
                                <Button className="btn btn-blue w-1/5 bg-blue-disable" onClick={handle?.delete}
                                    disabled={issueList?.selectedData?.isLoading}>
                                        {issueList?.selectedData?.isLoading ? (
                                        <ButtonSpinner />
                                    ) : (
                                        "Delete"
                                    )}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DeleteInspectionIssues;
