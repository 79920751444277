import React from 'react'
import { FaSearch } from "react-icons/fa";
import { Input } from "antd";
import { GetWorkersType } from '../helper/worksite/worksite';
import { useParams } from 'react-router-dom';
import { GetAreaById, GetAreas, GetInspection } from '../helper/worksite/worksiteDetails.js/areas';

const HeaderSearchWorksite = ({ placeHolder, worksite, setWorksite, workerType, areas, setAreas, getInspection,setGetInspection,inspection,setInspection }) => {
    const { id, area,type,taskid } = useParams()
    const handle = async (e) => {
        if(inspection){
            const params = {
                cursor: "",
                skip: "",
                search: e?.target?.value,
                type: type,
                productivity_orders_id: type == "po" && taskid,
                hourly_rate_tasks_estimations_id: type == "hourly" && taskid,
              };
              let data = await GetInspection(params);
              if (data?.status) {
                setInspection({
                  ...inspection,
                  data: [...data?.data?.data],
                  extra: { ...data?.extra },
                  search: e?.target?.search,
                  isShimmer: false,
                });
              }
        }
       else if (getInspection) {
            const params = {
                id: area,
                worksite_id: id,
                search: e?.target?.value
            };
            let data = await GetAreaById(params)
            if (data?.status) {
                let allData = [];
                data?.data?.data?.hourly_rate_tasks_estimations?.map((hrt, index) => {
                    allData?.push({
                        ...hrt,
                        task_abbrevation: hrt?.hourly_rate_task?.abbreviation,
                        task_name: hrt?.hourly_rate_task?.name,
                        type: "Hourly Rate",
                    });
                });

                data?.data?.data?.productivity_orders?.map((po, index) => {
                    allData?.push({
                        ...po,
                        task_abbrevation: po?.code,
                        task_name: po?.name,
                        type: "Productivity Order",
                    });
                });

                setGetInspection({
                    data: allData,
                    extra: { ...data?.extra },
                    search: e?.target?.value,
                    isShimmer: false,
                });
            }
        }
        else if (areas) {
            const params = {
                worksite_id: id?.id,
                search: e?.target?.value,
            };

            let getData = await GetAreas(params);
            if (getData?.status) {
                setAreas({
                    ...areas,
                    data: [...getData?.data?.data],
                    extra: { ...getData?.extra },
                    search: areas?.search,
                    isShimmer: false,
                });
            }
        }
        else {
            const data = await GetWorkersType({ ...worksite, search: e?.target?.value, type: workerType })
            if (data?.status) {
                setWorksite({
                    ...worksite,
                    data: data?.data?.data,
                    extra: data?.extra,
                    worker_status: workerType,
                    isShimmer: false,
                    cursor: "",
                });
                return data;
            } else {
                setWorksite({
                    ...worksite,
                    isShimmer: false,
                });
                return data;
            }
        }
    }
    return (
        <div className="border py-1 px-4 rounded-md flex justify-between items-center bg-white w-[250px]">
            <FaSearch />
            <Input
                placeholder={placeHolder}
                className="border-none outline-none bg-transparent p-0 ml-3"
                onChange={handle}
            // value={searchValue}
            />
        </div>
    )
}
export default HeaderSearchWorksite